import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import { TextField, FormControl, FormLabel, Checkbox, Switch } from "@mui/material";
import {
	saveOrderEditData,
	updateResultPDF,
	fetchOrderFaxData,
	getOrderDataById,
	createResultLink,
	updateResultLink
} from "../../../../services/clinicPortalServices/orderEditService";
import { createRequisitionPDF } from "../../../../services/clinicPortalServices/requisitionService";
import { fetchPatientWithQuery } from "../../../../services/clinicPortalServices/patientSearchService";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import { fetchPhysiciansUnlocked } from "../../../../services/clinicPortalServices/physicianServices";
import moment from "moment";
import { getUserRole, getUserID, getUserFullName } from "../../../../services/common/util";
import Swal from "sweetalert2";
import { submitCommunication } from "../../../../services/clinicPortalServices/communicationService";
import toastr from 'toastr';
import OrderViewPopup from "./orderViewPopup";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			orderId: props.data._id,
			gender: "U",
			dob: "",
			mrn: "",
			facilitySource: "",
			facilityId: "",
			providerDetails: {},
			selectedProviderId: "",
			providerFirstName: "",
			providerLastName: "",
			providerNPI: "",
			facilityDetails: {},
			receivedDate: "",
			description: "",
			testType: "",
			sample: "",
			result: "",
			collectedDate: "",
			collectorName: "",
			symptoms: [],
			requisition: "",
			code: "",
			codeType: "",
			patientDetails: {},
			patientName: "",
			email: "",
			mobile: "",
			pdfPath: "",
			released: "",
			releasedBy: "",
			results: {},
			passport: "",
			custom_info: "",
			isEnvironmental: false,
			facility_email: "",
			patientId: props.data.patient_id._id,
			showLinkResult: false,
			resultLink: "",
			status: "",
			isResultViewEnabled: false,
			resultId: "",
			isCommunication: false,
			comment: {
				sample: '',
				orderId: '',
				subject: '',
				message: '',
				isMissedfield: false,
			},
			errors: [],
			showPatientChangePopup: false,
			patients: [],
			selectedPatientForChange: {},
			testDetails: {}
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	loadOrderDetails = () => {
		getOrderDataById(this.state.orderId)
			.then((response) => {
				let orderDetails = response.data;
				this.setState({
					patientId: orderDetails && orderDetails.patient_id ? orderDetails.patient_id._id : "",
					patientName: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.first_name + " " + orderDetails.patient_id.last_name : "",
					patientDetails: orderDetails && orderDetails.patient_id ? orderDetails.patient_id : {},
					mrn: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.mrn : "",
					dob: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.date_of_birth : "",
					gender: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.gender : "U",
					email: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.email : "",
					mobile: orderDetails && orderDetails.patient_id ? orderDetails.patient_id.mobile : "",
					facilityId: orderDetails && orderDetails.facility_id ? orderDetails.facility_id._id : "",
					facilitySource: orderDetails ? orderDetails.facility_source : "",
					facilityDetails: orderDetails && orderDetails.facility_id && orderDetails.facility_id._id ? orderDetails.facility_id : {},
					description: orderDetails && orderDetails.test_info ? orderDetails.test_info.description : "",
					diagnosis_code: orderDetails && orderDetails.diagnosis_code && orderDetails.diagnosis_code.length ? orderDetails.diagnosis_code : [],
					testType: orderDetails && orderDetails.test_info ? orderDetails.test_info.test_type : "",
					requisition: orderDetails && orderDetails.lab_order_id ? orderDetails.lab_order_id : "",
					sample: orderDetails && orderDetails.test_info ? orderDetails.test_info.sample : "",
					collectedDate: orderDetails && orderDetails.test_info && orderDetails.test_info.collected ? moment(orderDetails.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "",
					collectorName: orderDetails && orderDetails.test_info && orderDetails.test_info.collector_name ? orderDetails.test_info.collector_name : "",
					receivedDate: orderDetails && orderDetails.test_info && orderDetails.test_info.received ? moment(orderDetails.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "",
					result: orderDetails && orderDetails.results ? orderDetails.results.value : "",
					released: orderDetails && orderDetails.results && orderDetails.results.released ? moment(orderDetails.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "",
					releasedBy: orderDetails && orderDetails.results ? orderDetails.results.releasedBy : "",
					pdfPath: orderDetails && orderDetails.results ? orderDetails.results.pdf_path : "",
					results: orderDetails && orderDetails.results,
					passport: orderDetails && orderDetails.patient_id && orderDetails.patient_id.passport,
					custom_info: orderDetails && orderDetails.patient_id && orderDetails.patient_id.custom_info,
					isEnvironmental: orderDetails && orderDetails.facility_id && orderDetails.facility_id.environmental_monitoring_enabled,
					facility_email: orderDetails && orderDetails.facility_id && orderDetails.facility_id.contact_email,
					symptoms: orderDetails && orderDetails.symptoms && orderDetails.symptoms.length ? orderDetails.symptoms : [],
					testDetails: orderDetails.test_id
				});
				if (orderDetails.facility_id && orderDetails.facility_id._id) {
					this.getPhysicians(orderDetails.facility_id._id);
				}
				fetchFacilitiesForOrders().then(res => {
					let filteredFacilities = [];
					res.data.map(facility => {
						if (facility.testIds && facility.testIds.length) {
							facility.testIds.map(test => {
								if (test._id === orderDetails.test_id._id) {
									filteredFacilities.push(facility);
								}
								return null;
							})
						}
						return null;
					})
					this.setState({ facilities: filteredFacilities })
				});
			});
	};

	handleShow = () => {
		if (this.state.orderId !== "") {
			this.loadOrderDetails();
		}
		this.setState({ show: true, errors: [] });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleCloseLinkResult = () => {
		this.setState({ showLinkResult: false, resultLink: '', isResultViewEnabled: false });
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === 'facilityId') {
			const facility = this.state.facilities.filter(fac => fac._id === e.target.value)[0];
			this.setState({ facilitySource: facility.name, facilityDetails: facility })
			this.getPhysicians(e.target.value);
		}
		if (e.target.name === "selectedProviderId") {
			let index = this.state.providers.findIndex(
				(i) => i._id === e.target.value
			);
			if (index > -1) {
				this.setState({
					providerFirstName: this.state.providers[index].first_name,
					providerLastName: this.state.providers[index].last_name,
					providerNPI: this.state.providers[index].npi,
					providerDetails: this.state.providers[index]
				});
			}
		}
		if (e.target.name === 'symptoms') {
			const value = e.target.value;
			let symptoms = this.state.symptoms;
			if (value === 'None') {
				symptoms = ['None'];
			} else {
				if (symptoms.findIndex((element) => element === value) === -1 && e.target.checked) {
					symptoms.push(value);
				} else if (symptoms.findIndex((element) => element === value) !== -1 && !e.target.checked) {
					symptoms.splice(symptoms.findIndex((element) => element === value), 1);
				}
				symptoms = symptoms.filter(symptom => symptom !== 'None');
			}
			this.setState({ symptoms: symptoms });
		}
	};

	getPhysicians = (facilityId) => {
		fetchPhysiciansUnlocked(facilityId).then((response) => {
			this.setState({
				providers: response.data
			});
			if (response.data.length) {
				this.setState({
					selectedProviderId: response.data[0]._id,
					providerFirstName: response.data[0].first_name,
					providerLastName: response.data[0].last_name,
					providerNPI: response.data[0].npi,
					providerDetails: response.data[0]
				})
			} else {
				this.setState({
					selectedProviderId: '',
					providerFirstName: '',
					providerLastName: '',
					providerNPI: '',
					providerDetails: {}
				})
			}
		});
	};

	handleOrderEditChanges = (isNotify) => {
		let errors = [];
		if (!this.state.testType) {
			errors.push('testType');
		}
		if (!this.state.collectedDate) {
			errors.push('collectedDate');
		}
		if (this.state.collectedDate && moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") === "Invalid date") {
			errors.push('wrongCollectedDate');
		}
		if (!this.state.sample) {
			errors.push('sample');
		}
		if (!this.state.receivedDate) {
			errors.push('receivedDate');
		}
		if (this.state.receivedDate && moment(this.state.receivedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") === "Invalid date") {
			errors.push('wrongReceivedDate');
		}
		if (!this.state.result) {
			errors.push('result');
		}
		if (!this.state.released) {
			errors.push('released');
		}
		if (this.state.released && moment(this.state.released, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") === "Invalid date") {
			errors.push('wrongReleased');
		}
		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			boundActions.startBlockUILoading();
			const editParams = {
				orderId: this.state.orderId,
				description: this.state.description,
				testType: this.state.testType,
				diagnosis_code: this.state.diagnosis_code,
				sample: this.state.sample,
				result: this.state.result,
				collectedDate: this.state.collectedDate ? moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
				collectorName: this.state.collectorName,
				receivedDate: this.state.receivedDate ? moment(this.state.receivedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
				requisition: this.state.requisition,
				patientName: this.state.patientName,
				email: this.state.email,
				mobile: this.state.mobile,
				facility_source: this.state.facilitySource,
				mrn: this.state.mrn,
				dob: this.state.dob,
				pdfPath: this.state.pdfPath,
				released: this.state.released ? moment(this.state.released, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
				releasedBy: this.state.releasedBy,
				results: this.state.results,
				passport: this.state.passport,
				custom_info: this.state.custom_info,
				isEnvironmental: this.state.isEnvironmental,
				facility_email: this.state.facility_email,
				is_notify: isNotify,
				updateRequisition: true,
				facility_id: this.state.facilityId,
				providerFirstName: this.state.providerFirstName,
				providerLastName: this.state.providerLastName,
				provider: this.state.providerFirstName + ' ' + this.state.providerLastName,
				providerNPI: this.state.providerNPI,
				requisition_pdf_path: this.state.sample.toString() + '-' + (this.state.collectedDate ? moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "") + '.pdf',
				symptoms: this.state.symptoms
			};
			saveOrderEditData(editParams).then((userDetails) => {
				editParams.collectedDate = editParams.collectedDate ? moment(editParams.collectedDate, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";
				editParams.receivedDate = editParams.receivedDate ? moment(editParams.receivedDate, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";
				editParams.released = editParams.released ? moment(editParams.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";

				editParams.notify = isNotify.toString();
				editParams.test_id = this.state.testDetails._id;
				editParams.facility_id = this.state.facilityDetails._id;
				updateResultPDF(editParams).then((data) => {
					this.setState({
						show: false
					});
					boundActions.endBlockUILoading();
					// call refresh grid function
					if (this.props.context && this.props.context.componentParent && !(this.props.type && this.props.type === 'patientViewPopup')) {
						this.props.context.componentParent.loadGridData();
					} else if (this.props.data.context && this.props.data.context.componentParent) {
						this.props.data.context.componentParent.collapseAll();
					}
				});

				let pdfParams = {
					patientName: this.state.patientName,
					address1: this.state.patientDetails.address && this.state.patientDetails.address.address1 ? this.state.patientDetails.address.address1 : "",
					address2: this.state.patientDetails.address && this.state.patientDetails.address.address2 ? this.state.patientDetails.address.address2 : "",
					city: this.state.patientDetails.address && this.state.patientDetails.address.city ? this.state.patientDetails.address.city : "",
					state: this.state.patientDetails.address && this.state.patientDetails.address.state ? this.state.patientDetails.address.state : "",
					zip: this.state.patientDetails.address && this.state.patientDetails.address.zip ? this.state.patientDetails.address.zip : "",
					phoneNumber: this.state.patientDetails.mobile ? this.state.patientDetails.mobile : "",
					email: this.state.patientDetails.email ? this.state.patientDetails.email : "",
					dateOfBirth: this.state.patientDetails.date_of_birth ? moment(this.state.patientDetails.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") : "",
					gender: this.state.patientDetails.gender ? this.state.patientDetails.gender : "U",
					sample: this.state.sample ? this.state.sample.toString() : "",
					physicianName: this.state.providerFirstName + " " + this.state.providerLastName,
					facilityName: this.state.facilitySource,
					providerNPI: this.state.providerNPI,
					facilityAddress1: this.state.facilityDetails.address.address1 ? this.state.facilityDetails.address.address1 : "",
					facilityAddress2: this.state.facilityDetails.address.address2 ? this.state.facilityDetails.address.address2 : "",
					facilityCity: this.state.facilityDetails.address.city ? this.state.facilityDetails.address.city : "",
					facilityState: this.state.facilityDetails.address.state ? this.state.facilityDetails.address.state : "",
					facilityZip: this.state.facilityDetails.address.zip ? this.state.facilityDetails.address.zip : "",
					facilityPhone: this.state.facilityDetails.phone_no,
					testType: this.state.testType,
					collectedDate: moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('MM/DD/YYYY'),
					collectedTime: moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('hh:mm A'),
					signature_image: this.state.providerDetails && this.state.providerDetails.signature_image ? this.state.providerDetails.signature_image : "",
					requisition_pdf_path: this.state.sample.toString() + '-' + moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('YYYYMMDDHHmmss') + '.pdf',
					diagnosisCode: this.props.data.diagnosis_code,
					symptoms: this.state.symptoms && this.state.symptoms.length ? this.state.symptoms : [],
					insuranceProvider: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insurance_provider ? this.props.data.patient_insurance_id.insurance_provider : "",
					insuranceMemberId: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_member_id ? this.props.data.patient_insurance_id.insured_member_id : "",
					insuranceGroupNumber: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_group_number ? this.props.data.patient_insurance_id.insured_group_number : "",
					insuranceRelationToInsured: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.relation_to_insured ? this.props.data.patient_insurance_id.relation_to_insured : "",
					insuranceHolderName: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_first_name ? (this.props.data.patient_insurance_id.insured_first_name + " " + this.props.data.patient_insurance_id.insured_last_name) : "",
					insuranceDriverLicense: this.state.patientDetails.driver_license ? this.state.patientDetails.driver_license : "",
					insuranceDriverLicenseState: this.state.patientDetails.driver_license_state ? this.state.patientDetails.driver_license_state : "",
					lab_order_id: this.state.requisition,
					testId: this.state.testDetails._id,
					mrn: this.state.patientDetails.mrn
				}
				createRequisitionPDF(pdfParams).then(res => {
				});
			});
		}
	};

	handleFax = () => {
		fetchOrderFaxData(this.state.orderId)
			.then((response) => {
				alert(response.message);
			});
	};

	handleCommunication = () => {
		const comment = {
			sample: this.props.data.test_info.sample,
			orderId: this.props.data._id,
			subject: '',
			message: '',
		}
		this.setState({ isCommunication: true, comment: comment });
	}
	handleCloseCommunication = () => {
		this.setState({ isCommunication: false });
	}

	onCommunicateTextChange = (event) => {
		const comment = this.state.comment;
		if (event.target.name === 'subject') {
			comment.subject = event.target.value;
		}
		if (event.target.name === 'message') {
			comment.message = event.target.value;
		}
		this.setState({ comment: comment });
	}

	handleSubmitCommunication = () => {
		if (this.state.comment.message !== '' && this.state.comment.subject !== '') {
			submitCommunication(this.state.comment).then(response => {
				toastr.success("Communication saved Succesfully");
				this.setState({ isCommunication: false });
			});
		} else {
			const comment = this.state.comment;
			comment.isMissedfield = true;
			this.setState({ comment: comment });
		}
	}

	handleLink = () => {
		Swal.fire({
			title: 'Did you get the permission?',
			text: 'Did you get the customers or physicians permission via email or phone prior to providing this one time link?',
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				this.createLink();
			}
		});
	};

	createLink = () => {
		const { orderId, patientId } = this.state;
		const params = {
			order_id: orderId,
			patient_id: patientId,
			is_viewed: false,
			is_pdf_viewed: false,
			is_downloaded: false,
			$push: {
				managed: {
					manager_id: getUserID(),
					manager_name: getUserFullName(),
					status: 'Created',
					managed_date: moment().format('YYYYMMDDHHmmss')
				}
			}
		}

		createResultLink(params)
			.then((response) => {
				const data = response.data;
				if (data.success) {
					this.setState({ showLinkResult: true, resultLink: data.link, resultId: data.data._id, status: data.status, isResultViewEnabled: !data.data.is_viewed });
				}

			});
	}

	handleShowPatientChangePopup = () => {
		Swal.fire({
			title: 'You are gonna change patient of order ' + this.props.data.lab_order_id + '?',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then(result => {
			if (result.isConfirmed) {
				boundActions.startBlockUILoading();
				let patientsQuery = {
					facility_id: this.props.data.facility_id,
				}
				fetchPatientWithQuery(patientsQuery).then(res => {
					boundActions.endBlockUILoading();
					this.setState({ patients: res.data });
				})
				this.loadOrderDetails();
				this.setState({ showPatientChangePopup: true });
			}
		})
	}

	handleClosePatientChangePopup = () => {
		this.setState({ showPatientChangePopup: false });
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Order
		</Tooltip>
	);

	renderTooltipChangePatient = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Patient Change
		</Tooltip>
	);

	renderTooltipCopyLink = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Copy Link
		</Tooltip>
	);

	renderTooltipFax = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Fax Order
		</Tooltip>
	);

	renderTooltipHelp = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Communincation
		</Tooltip>
	);

	renderTooltipLink = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Result Link
		</Tooltip>
	);

	renderTooltipView = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			View
		</Tooltip>
	);

	copyCodeToClipboard = () => {
		const el = this.textArea;
		el.select()
		document.execCommand("copy");
	}

	changePatient = () => {
		Swal.fire({
			title: 'Are you sure to assign of order ' + this.props.data.lab_order_id + ' to ' + this.state.selectedPatientForChange.first_name + ' ' + this.state.selectedPatientForChange.last_name + '?',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then(result => {
			if (result.isConfirmed) {
				boundActions.startBlockUILoading();
				const editParams = {
					orderId: this.state.orderId,
					description: this.state.description,
					testType: this.state.testType,
					diagnosis_code: this.state.diagnosis_code,
					sample: this.state.sample,
					result: this.state.result,
					collectedDate: this.state.collectedDate ? moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
					collectorName: this.state.collectorName,
					receivedDate: this.state.receivedDate ? moment(this.state.receivedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
					requisition: this.state.requisition,
					patientName: this.state.selectedPatientForChange.first_name + " " + this.state.selectedPatientForChange.last_name,
					email: this.state.selectedPatientForChange.email ? this.state.selectedPatientForChange.email : "",
					mobile: this.state.selectedPatientForChange.mobile ? this.state.selectedPatientForChange.mobile : "",
					facility_source: this.state.facilitySource,
					mrn: this.state.selectedPatientForChange.mrn ? this.state.selectedPatientForChange.mrn : "",
					dob: this.state.selectedPatientForChange.date_of_birth ? this.state.selectedPatientForChange.date_of_birth : "",
					pdfPath: this.state.pdfPath,
					released: this.state.released ? moment(this.state.released, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "",
					releasedBy: this.state.releasedBy,
					results: this.state.results,
					passport: this.state.selectedPatientForChange.passport ? this.state.selectedPatientForChange.passport : "",
					custom_info: this.state.selectedPatientForChange.custom_info ? this.state.selectedPatientForChange.custom_info : "",
					isEnvironmental: this.state.isEnvironmental,
					facility_email: this.state.facility_email,
					updateRequisition: true,
					facility_id: this.state.facilityId,
					providerFirstName: this.state.providerFirstName,
					providerLastName: this.state.providerLastName,
					provider: this.state.providerFirstName + ' ' + this.state.providerLastName,
					providerNPI: this.state.providerNPI,
					requisition_pdf_path: this.state.sample.toString() + '-' + (this.state.collectedDate ? moment(this.state.collectedDate, "MM/DD/YYYY hh:mm:ss A").format("YYYYMMDDHHmmss") : "") + '.pdf',
					patient_id: this.state.selectedPatientForChange._id,
					symptoms: this.state.symptoms
				};
				saveOrderEditData(editParams).then((userDetails) => {
					editParams.collectedDate = editParams.collectedDate ? moment(editParams.collectedDate, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";
					editParams.receivedDate = editParams.receivedDate ? moment(editParams.receivedDate, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";
					editParams.released = editParams.released ? moment(editParams.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm:ss A") : "";

					editParams.notify = "false";
					editParams.test_id = this.state.testDetails._id;
					editParams.facility_id = this.state.facilityDetails._id;
					updateResultPDF(editParams).then((data) => {
						this.setState({
							showPatientChangePopup: false
						});
						boundActions.endBlockUILoading();
						// call refresh grid function
						if (this.props.context && this.props.context.componentParent) {
							this.props.context.componentParent.loadGridData();
						} else if (this.props.data.context && this.props.data.context.componentParent) {
							this.props.data.context.componentParent.collapseAll();
						}
					});

					let pdfParams = {
						patientName: this.state.selectedPatientForChange.first_name + " " + this.state.selectedPatientForChange.last_name,
						address1: this.state.selectedPatientForChange.address && this.state.selectedPatientForChange.address.address1 ? this.state.selectedPatientForChange.address.address1 : "",
						address2: this.state.selectedPatientForChange.address && this.state.selectedPatientForChange.address.address2 ? this.state.selectedPatientForChange.address.address2 : "",
						city: this.state.selectedPatientForChange.address && this.state.selectedPatientForChange.address.city ? this.state.selectedPatientForChange.address.city : "",
						state: this.state.selectedPatientForChange.address && this.state.selectedPatientForChange.address.state ? this.state.selectedPatientForChange.address.state : "",
						zip: this.state.selectedPatientForChange.address && this.state.selectedPatientForChange.address.zip ? this.state.selectedPatientForChange.address.zip : "",
						phoneNumber: this.state.selectedPatientForChange.mobile ? this.state.selectedPatientForChange.mobile : "",
						email: this.state.selectedPatientForChange.email ? this.state.selectedPatientForChange.email : "",
						dateOfBirth: this.state.selectedPatientForChange.date_of_birth ? moment(this.state.selectedPatientForChange.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") : "",
						gender: this.state.selectedPatientForChange.gender ? this.state.selectedPatientForChange.gender : "U",
						sample: this.state.sample ? this.state.sample.toString() : "",
						physicianName: this.state.providerFirstName + " " + this.state.providerLastName,
						facilityName: this.state.facilitySource,
						providerNPI: this.state.providerNPI,
						facilityAddress1: this.state.facilityDetails.address.address1 ? this.state.facilityDetails.address.address1 : "",
						facilityAddress2: this.state.facilityDetails.address.address2 ? this.state.facilityDetails.address.address2 : "",
						facilityCity: this.state.facilityDetails.address.city ? this.state.facilityDetails.address.city : "",
						facilityState: this.state.facilityDetails.address.state ? this.state.facilityDetails.address.state : "",
						facilityZip: this.state.facilityDetails.address.zip ? this.state.facilityDetails.address.zip : "",
						facilityPhone: this.state.facilityDetails.phone_no,
						testType: this.state.testType,
						collectedDate: moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('MM/DD/YYYY'),
						collectedTime: moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('hh:mm A'),
						signature_image: this.state.providerDetails && this.state.providerDetails.signature_image ? this.state.providerDetails.signature_image : "",
						requisition_pdf_path: this.state.sample.toString() + '-' + moment(editParams.collectedDate, "MM/DD/YYYY hh:mm:ss A").format('YYYYMMDDHHmmss') + '.pdf',
						diagnosisCode: this.props.data.diagnosis_code,
						symptoms: this.state.symptoms && this.state.symptoms.length ? this.state.symptoms : [], /// it will be necessary to discuss with Chris.... because it is patient swap
						insuranceProvider: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insurance_provider ? this.props.data.patient_insurance_id.insurance_provider : "",
						insuranceMemberId: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_member_id ? this.props.data.patient_insurance_id.insured_member_id : "",
						insuranceGroupNumber: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_group_number ? this.props.data.patient_insurance_id.insured_group_number : "",
						insuranceRelationToInsured: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.relation_to_insured ? this.props.data.patient_insurance_id.relation_to_insured : "",
						insuranceHolderName: this.props.data.patient_insurance_id && this.props.data.patient_insurance_id.insured_first_name ? (this.props.data.patient_insurance_id.insured_first_name + " " + this.props.data.patient_insurance_id.insured_last_name) : "",
						insuranceDriverLicense: this.state.selectedPatientForChange.driver_license ? this.state.selectedPatientForChange.driver_license : "",
						insuranceDriverLicenseState: this.state.selectedPatientForChange.driver_license_state ? this.state.selectedPatientForChange.driver_license_state : "",
						lab_order_id: this.state.requisition,
						testId: this.state.testDetails._id,
						mrn: this.state.selectedPatientForChange.mrn
					}
					createRequisitionPDF(pdfParams).then(res => {
					});
				});
			}
		});
	}

	handleSwitchChange = (e) => {
		let params = {
			_id: this.state.resultId,
			is_viewed: !e.target.checked,
			is_downloaded: !e.target.checked,
			is_pdf_viewed: !e.target.checked,
			$push: {
				managed: {
					manager_id: getUserID(),
					manager_name: getUserFullName(),
					status: e.target.checked ? 'Enabled' : 'Disabled',
					managed_date: moment().format('YYYYMMDDHHmmss')
				}
			}
		}
		updateResultLink(params)
			.then((response) => {
				const data = response.data;
				if (data.success) {
					const message = e.target.checked ? 'The link is enabled.' : 'The link is disabled';
					toastr.success(message);
				}
			});
		this.setState({ [e.target.name]: e.target.checked });
	};

	render() {
		return (
			<div>
				{
					this.props.data.results.value !== "In Testing"
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-pen"></i>
						</button>
					</OverlayTrigger>
				}
				{
					this.props.data.results.value !== "In Testing"
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipFax}
					>
						<button className="fax-button" onClick={this.handleFax}>
							<i className="fa fa-fax" aria-hidden="true"></i>
						</button>
					</OverlayTrigger>
				}
				{
					getUserRole() && ['facilityadmin', 'facilityuser'].includes(getUserRole().toLowerCase())
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipHelp}
					>
						<button className="fax-button" onClick={this.handleCommunication}>
							<i className="fa fa-question" aria-hidden="true"></i>
						</button>
					</OverlayTrigger>
				}
				{
					getUserRole() && !['facilityadmin', 'facilityuser'].includes(getUserRole().toLowerCase()) && this.props.data.results.value !== "In Testing"
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipLink}
					>
						<button className="fax-button" onClick={this.handleLink}>
							<i className="fa fa-link" aria-hidden="true"></i>
						</button>
					</OverlayTrigger>
				}
				{
					getUserRole() && ['AGTSuperAdmin'].includes(getUserRole()) && this.state.orderId && this.props.data.results.value !== "In Testing" && !(this.props.type && ["patientViewPopup", "insuranceGrid"].includes(this.props.type))
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipChangePatient}
					>
						<button className="fax-button" onClick={this.handleShowPatientChangePopup}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="18px" style={{ marginTop: "-3px" }}>
								<path fill="currentColor" d="M96 304.1c0-12.16 4.971-23.83 13.64-32.01l72.13-68.08c1.65-1.555 3.773-2.311 5.611-3.578C177.1 176.8 155 160 128 160H64C28.65 160 0 188.7 0 224v96c0 17.67 14.33 32 31.1 32L32 480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-96.39l-50.36-47.53C100.1 327.9 96 316.2 96 304.1zM480 128c35.38 0 64-28.62 64-64s-28.62-64-64-64s-64 28.62-64 64S444.6 128 480 128zM96 128c35.38 0 64-28.62 64-64S131.4 0 96 0S32 28.62 32 64S60.63 128 96 128zM444.4 295.3L372.3 227.3c-3.49-3.293-8.607-4.193-13.01-2.299C354.9 226.9 352 231.2 352 236V272H224V236c0-4.795-2.857-9.133-7.262-11.03C212.3 223.1 207.2 223.1 203.7 227.3L131.6 295.3c-4.805 4.535-4.805 12.94 0 17.47l72.12 68.07c3.49 3.291 8.607 4.191 13.01 2.297C221.1 381.3 224 376.9 224 372.1V336h128v36.14c0 4.795 2.857 9.135 7.262 11.04c4.406 1.893 9.523 .9922 13.01-2.299l72.12-68.07C449.2 308.3 449.2 299.9 444.4 295.3zM512 160h-64c-26.1 0-49.98 16.77-59.38 40.42c1.842 1.271 3.969 2.027 5.623 3.588l72.12 68.06C475 280.2 480 291.9 480 304.1c.002 12.16-4.969 23.83-13.64 32.01L416 383.6V480c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-128c17.67 0 32-14.33 32-32V224C576 188.7 547.3 160 512 160z" />
							</svg>
						</button>
					</OverlayTrigger>
				}
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipView}
				>
					<button className="fax-button" onClick={() => this.setState({ showOrderViewPopup: true })}>
						<i className="fa fa-eye" aria-hidden="true"></i>
					</button>
				</OverlayTrigger>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Order Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Patient Name</label>
										<input
											type="text"
											disabled
											className="form-control"
											name="patientName"
											value={this.state.patientName}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>MRN</label>
										<input
											type="text"
											disabled
											className="form-control"
											name="mrn"
											value={this.state.mrn}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Date Of Birth</label>
										<input
											type="text"
											className="form-control"
											disabled
											name="dob"
											value={this.state.dob}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Gender</label>
										<input
											type="text"
											disabled
											className="form-control"
											name="gender"
											value={this.state.gender}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Facility</label>
										<select
											required
											type="text"
											name="facilityId"
											value={this.state.facilityId}
											onChange={this.handleChange}
											className="form-control"
											disabled={getUserRole() && (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') ? false : true}
										>
											{
												this.state.facilities
												&&
												this.state.facilities.map((fac) => {
													return (
														<option value={fac._id} key={fac._id}>
															{fac.name} {fac.archived === "archived" ? " ( Archived ) " : ""}
														</option>
													);
												})
											}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Physician</label>
										<select
											required
											type="text"
											name="selectedProviderId"
											value={this.state.selectedProviderId}
											onChange={this.handleChange}
											className="form-control"
											disabled={getUserRole() && (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') ? false : true}
										>
											{
												this.state.providers &&
												this.state.providers.map((prov) => {
													return (
														<option value={prov._id} key={prov._id}>
															{prov.first_name + " " + prov.last_name}
														</option>
													);
												})
											}
										</select>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Test Name</label>
										<input
											type="text"
											className="form-control"
											disabled
											value={this.state.testDetails.name}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Test Description</label>
										<input
											type="text"
											className="form-control"
											disabled
											value={this.state.description}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Test Type</label>
										<select
											className="form-control select"
											name="testType"
											value={this.state.testType}
											onChange={this.handleChange}
										>
											{
												this.state.facilityDetails && this.state.facilityDetails.test_type && this.state.facilityDetails.test_type.length && this.state.testDetails && this.state.testDetails.name && this.state.testDetails.name.toLowerCase().includes('sars-cov-2')
												&&
												this.state.facilityDetails.test_type.map((test_type) => {
													return <option key={test_type} value={test_type}>{test_type}</option>;
												})
											}
											{
												this.state.testDetails && this.state.testDetails.name && !this.state.testDetails.name.toLowerCase().includes('sars-cov-2')
												&&
												this.state.testDetails.sample_types.map((type) => {
													return <option key={type} value={type}>{type}</option>;
												})
											}
										</select>
										<div
											className={this.hasError('testType') ? "inline-errormsg" : "hidden"}
											style={{ width: "auto", fontSize: "15px" }}
										>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required
											</i>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Requisition</label>
										<input
											type="text"
											className="form-control"
											name="requisition"
											value={this.state.requisition}
											disabled
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="row">
										<div className="col-12">
											<div className="form-group">
												<label>Sample</label>
												<input
													type="text"
													className="form-control"
													name="sample"
													disabled={getUserRole() && (getUserRole().toLowerCase() === 'agtsuperadmin') ? false : true}
													value={this.state.sample}
													onChange={this.handleChange}
												/>
												<div
													className={this.hasError('sample') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required
													</i>
												</div>
											</div>
										</div>
										{
											!this.state.testDetails.is_bundle
											&&
											<div className="col-12">
												<div className="form-group">
													<label>Result</label>
													<select
														className="form-control select"
														name="result"
														value={this.state.result}
														onChange={this.handleChange}
														disabled={(getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') ? false : true}
													>
														{
															this.state.testDetails && this.state.testDetails.results && this.state.testDetails.results.length
															&&
															this.state.testDetails.results.map((result) => {
																return <option key={result}>{result}</option>;
															})
														}
													</select>
													<div
														className={this.hasError('result') ? "inline-errormsg" : "hidden"}
														style={{ width: "auto", fontSize: "15px" }}
													>
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;This field is required
														</i>
													</div>
												</div>
											</div>
										}
										<div className="col-12">
											<div className="form-group">
												<label>Collected Date</label>
												<input
													type="text"
													className="form-control"
													name="collectedDate"
													value={this.state.collectedDate}
													onChange={this.handleChange}
												/>
												<label style={{ fontSize: "13px" }}>
													Date format - MM/DD/YYYY hh:mi:ss AM/PM
												</label>
												<div
													className={this.hasError('collectedDate') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required
													</i>
												</div>
												<div
													className={this.hasError('wrongCollectedDate') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;Please provide correctly
													</i>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="form-group">
												<label>Received Date</label>
												<input
													type="text"
													className="form-control"
													name="receivedDate"
													value={this.state.receivedDate}
													onChange={this.handleChange}
													disabled={(getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') ? false : true}
												/>
												<label style={{ fontSize: "13px" }}>
													Date format - MM/DD/YYYY hh:mi:ss AM/PM
												</label>
												<div
													className={this.hasError('wrongReceivedDate') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;Please provide correctly
													</i>
												</div>
												<div
													className={this.hasError('receivedDate') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required
													</i>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<label>Released Date</label>
												<input
													type="text"
													className="form-control"
													name="released"
													value={this.state.released}
													onChange={this.handleChange}
													disabled={(getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') ? false : true}
												/>
												<label style={{ fontSize: "13px" }}>
													Date format - MM/DD/YYYY hh:mi:ss AM/PM
												</label>
												<div
													className={this.hasError('released') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required
													</i>
												</div>
												<div
													className={this.hasError('wrongReleased') ? "inline-errormsg" : "hidden"}
													style={{ width: "auto", fontSize: "15px" }}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required
													</i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<FormControl>
											<FormLabel>
												Symptoms <abbr className="text-danger">*</abbr>
											</FormLabel>
											{
												this.state.testDetails && this.state.testDetails.symptoms && this.state.testDetails.symptoms.map((item, index) => {
													return (
														<div key={index}>
															<Checkbox
																checked={
																	this.state.symptoms &&
																	this.state.symptoms.findIndex(
																		(elem) => elem === item
																	) !== -1
																}
																onChange={this.handleChange}
																value={item}
																name="symptoms"
															/>
															<span>{item}</span>
														</div>
													);
												})
											}
										</FormControl>
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="info" onClick={() => this.handleOrderEditChanges(false)}>
							Save
						</Button>
						<Button variant="primary" onClick={() => this.handleOrderEditChanges(true)}>
							Save and notify
						</Button>
					</Modal.Footer>
				</ModalStyled>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showLinkResult}
					onHide={this.handleCloseLinkResult}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							Result Link
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row form-row align-items-center">
							<div className="col-11 col-md-11">
								<input
									type="text"
									className="form-control"
									name="resultLink"
									value={this.state.resultLink}
									ref={(textarea) => this.textArea = textarea}
								/>
							</div>
							<div className="col-1 col-md-1">
								<OverlayTrigger
									placement="top"
									delay={{ show: 50, hide: 100 }}
									overlay={this.renderTooltipCopyLink}
								>
									<button onClick={this.copyCodeToClipboard} className="edit-order-btn">
										<i className="fa fa-clone" ></i>
									</button>
								</OverlayTrigger>
							</div>
							<div className="col-12 mt-4">
								<Switch
									onChange={this.handleSwitchChange}
									checked={this.state.isResultViewEnabled}
									name="isResultViewEnabled"
								/>
								<label>{this.state.isResultViewEnabled ? 'Enabled' : 'Disabled'}</label>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleCloseLinkResult}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPatientChangePopup}
					onHide={this.handleClosePatientChangePopup}
					backdrop="static"
				>
					<Modal.Header closeButton>
						Change Patient
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-12">
								<Autocomplete
									id="patient-box-demo"
									options={this.state.patients}
									value={this.state.selectedPatientForChange}
									onChange={(event, newValue) => {
										if (newValue) {
											this.setState({ selectedPatientForChange: newValue });
										} else {
											this.setState({ selectedPatientForChange: {} });
										}
									}}
									getOptionLabel={(option) => option.first_name ? (option.first_name + ' ' + option.last_name + (option.mrn ? (" (mrn: " + option.mrn + ", birthday: " + moment(option.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") + ", email: " + option.email + ", mobile: " + option.mobile + ")") : "")) : ""}
									renderInput={(params) => <TextField {...params} label="Select Patient" variant="outlined" />}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.changePatient} disabled={this.state.selectedPatientForChange && this.state.selectedPatientForChange._id ? false : true}>
							Change
						</Button>
					</Modal.Footer>
				</ModalStyled>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.isCommunication}
					onHide={this.handleCloseCommunication}
					backdrop="static"
				>
					<Modal.Header closeButton>
						Communication
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-12">
								<TextField
									label="Sample"
									variant="outlined"
									className="form-control filter-search-bar"
									id="subject"
									name="sample"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="dense"
									disabled={true}
									value={this.state.comment.sample}
								/>
							</div>
							<div className="col-md-12">
								<TextField
									label="Subject"
									variant="outlined"
									className="form-control filter-search-bar"
									id="subject"
									name="subject"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="dense"
									value={this.state.comment.subject}
									onChange={this.onCommunicateTextChange}
								/>
							</div>
							<div className="col-md-12">
								<TextField
									label="Message"
									variant="outlined"
									className="form-control filter-search-bar"
									id="message"
									name="message"
									multiline
									rows={4}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ height: "100px" }}
									type="string"
									margin="dense"
									value={this.state.comment.message}
									onChange={this.onCommunicateTextChange}
								/>
							</div>
							{
								this.state.comment.isMissedfield
								&&
								<div className="col-md-12">
									<h5 className="text-center text-danger">Please fill all fields</h5>
								</div>
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleSubmitCommunication}>
							Send
						</Button>
					</Modal.Footer>
				</ModalStyled>
				<OrderViewPopup
					show={this.state.showOrderViewPopup}
					handleClose={() => this.setState({ showOrderViewPopup: false })}
					data={this.props.data}
					context={this.props.context}
					type="order"
				/>
			</div>
		);
	}
}
