import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
//service calls
import { getLocationData } from "../../../../services/clinicPortalServices/locationService";
import LocationSearchMenu from "./locationSearchMenu";
import LogoImages from "../../../appointmentBooking/home/maplist/img";
import { serviceConstants, auditEnums } from "../../../../services/common/constants";
import { getFullAddress, getUserRole, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicLocationGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			name: "",
			code: "",
			contactName: "",
			phoneNum: "",
			contactEmail: "",
			faxNum: "",
			address: "",
			emailNotification: "",
			environmentalMonitoring: "",
			faxType: "",
			isActive: "",
			errors: [],
			columnDefs: [
				{
					headerName: "Actions",
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Name",
					minWidth: 150,
					field: "name",
					resizable: true,
				},
				{
					headerName: "Logo",
					maxWidth: 100,
					field: "image",
					resizable: true,
					cellRenderer: memo(params => {
						if (params.data.image) {
							return <img src={LogoImages[params.data.image].path} width="40px" alt="Logo" />
						}
						return <div></div>
					}),
				},
				{
					headerName: "Facility",
					minWidth: 150,
					field: "facility_id.name",
					resizable: true
				},
				{
					headerName: "Address",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.address && params.data.address.address1) {
							return (
								getFullAddress(params.data.address) + ", USA"
							);
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Contact Number",
					minWidth: 150,
					field: "contact_number",
					resizable: true
				},
				{
					headerName: "Open Hours",
					minWidth: 150,
					field: "contact_number",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.timings.length) {
							return params.data.timings.join(', ');
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Location URL",
					minWidth: 400,
					field: "_id",
					resizable: true,
					cellRenderer: memo(params => {
						const url = serviceConstants.HOST_NAME + '/patientsignup/' + params.data._id;
						return <a className="grid-url" href={url} target="_blank" rel="noreferrer">{url}</a>
					})
				},
				{
					headerName: "Excluded Self-Check-In?",
					minWidth: 150,
					field: "exclude_register",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.exclude_register) {
							return 'YES';
						} else {
							return 'NO';
						}
					},
				},
				{
					headerName: "Accepts Insurance",
					minWidth: 150,
					field: "accept_insurance",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.accept_insurance) {
							return 'YES';
						} else {
							return 'NO';
						}
					},
				},
				{
					headerName: "Listing Status",
					minWidth: 150,
					field: "hide_list",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.hide_list) {
							return 'Hide';
						} else {
							return 'Show';
						}
					},
				}
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
			},
			context: { componentParent: this },
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: []
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getLocationData()
			.then((response) => {
				this.setState({ rowData: response.data });
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.LocationGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.LocationGridExported
		};
		createAudit(auditData);
	};

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		let columnDefs = /customerserviceuser|audit|salesperson/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		return (
			<div className="clinic-contain">
				<LocationSearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					facilities={this.state.rowData}
					context={this.state.context}
				/>

				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicLocationGrid;
