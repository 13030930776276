import { apiInterfaceForJson } from "./apiManager";

export const sendEmailTemplate = (emailDataTemplate) => {
    return apiInterfaceForJson("/notifications/v1/emailTemplate", "POST", emailDataTemplate);
};

export const sendSMSNotification = (mobileNumber, content) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = content;
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
}

export const sendBrowserNotification = (body) => {
    return apiInterfaceForJson("/notifications/v1/browser", "POST", body);
}

export const sendAppPushNotification = (notificationData) => {
    return apiInterfaceForJson("/notifications/v1/pushNotification", "POST", { pushNotificationData: notificationData });
}