import React from 'react';
import AppContainer from './appcontainer.jsx';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Worker } from '@react-pdf-viewer/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Helmet } from 'react-helmet'
import { setCookie } from './services/common/util.js';
import { Provider } from "redux-zero/react";
import store from "./redux/store";

const AppRouter = (props) => {
    setCookie();
    return (
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                    <Router>
                        <Route render={(props) => <AppContainer {...props} />} />
                    </Router>
                </Worker>
                <Helmet>
                    <title>Fast Results - COVID-19 RT-PCR Testing | MyResultNow</title>
                    <meta name="title" content="MyResultNow.com | Reliable and Fast COVID-19 Rt-PCR Test Results " />
                    <meta name="description" content="Book an appointment with a physician at one of our partner locations and you will have your results within 24 hours!" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.myresultnow.com/" />
                    <meta property="og:title" content="MyResultNow.com | Reliable and Fast COVID-19 Rt-PCR Test Results " />
                    <meta property="og:description" content="Book an appointment with a physician at one of our partner locations and you will have your results within 24 hours!" />
                    <meta property="og:image" content="/images/my-result-now-og-image.jpg" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://www.myresultnow.com/" />
                    <meta property="twitter:title" content="MyResultNow.com | Reliable and Fast COVID-19 Rt-PCR Test Results " />
                    <meta property="twitter:description" content="Book an appointment with a physician at one of our partner locations and you will have your results within 24 hours!" />
                    <meta property="twitter:image" content="/images/my-result-now-og-image.jpg" />
                </Helmet>
            </LocalizationProvider>
        </Provider>
    );

}


export default AppRouter;