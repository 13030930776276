import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../../../services/common/optionsData";
import {
	updateCompany,
	createCompany,
	getCompanyDataById,
} from "../../../../services/clinicPortalServices/companyServices";
import { getFacilityData } from "../../../../services/clinicPortalServices/facilityServices";
import { phoneNumberFormatter, getUserRole } from "../../../../services/common/util";
import { Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default class CompanyDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			companyId:
				this.props && this.props.companyId ? this.props.companyId : "",
			name: "",
			contact_name: "",
			contact_email: "",
			contact_mobile: "",
			cost_per_order: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
			facilityIds: [],
			errors: [],
			facilities: [],
			allFacilityIds: [],
			invoice_processing: false
		};
	}

	componentDidMount() {
		if (this.state.companyId !== "") {
			this.loadCompanyDetails();
		}

		getFacilityData().then((response) => {
			this.setState({ facilities: response.data });
			let allFacilityIds = [];
			response.data.map(item => {
				allFacilityIds.push(item._id);
				return null;
			})
			this.setState({ allFacilityIds: allFacilityIds });
		});
	}

	loadCompanyDetails = () => {
		getCompanyDataById(this.state.companyId)
			.then((response) => {
				let companyDetails = response.data[0];
				this.setState({
					name: companyDetails && companyDetails.name ? companyDetails.name : "",
					contact_name: companyDetails && companyDetails.contact_name ? companyDetails.contact_name : "",
					contact_mobile: companyDetails && companyDetails.contact_mobile ? companyDetails.contact_mobile : "",
					contact_email: companyDetails && companyDetails.contact_email ? companyDetails.contact_email : "",
					cost_per_order: companyDetails && companyDetails.cost_per_order ? companyDetails.cost_per_order : "",
					address1:
						companyDetails && companyDetails.address && companyDetails.address.address1
							? companyDetails.address.address1
							: "",
					address2:
						companyDetails && companyDetails.address && companyDetails.address.address2
							? companyDetails.address.address2
							: "",
					city:
						companyDetails && companyDetails.address && companyDetails.address.city
							? companyDetails.address.city
							: "",
					state:
						companyDetails && companyDetails.address && companyDetails.address.state
							? companyDetails.address.state
							: "",
					zip:
						companyDetails && companyDetails.address && companyDetails.address.zip
							? companyDetails.address.zip
							: "",
					country:
						companyDetails && companyDetails.address && companyDetails.address.country
							? companyDetails.address.country
							: "",
					facilityIds:
						companyDetails && companyDetails.facilityIds
							? companyDetails.facilityIds
							: [],
					invoice_processing: companyDetails && companyDetails.invoice_processing ? companyDetails.invoice_processing : false
				});
			})
	};

	toggleInvoiceProcessing = () => {
		this.setState({ invoice_processing: !this.state.invoice_processing });
	}

	handleChange = (e) => {
		if (e.target.name === "contact_mobile") {
			this.setState((prevState) => ({
				contact_mobile: phoneNumberFormatter(e.target.value, prevState.contact_mobile),
			}));
		} else {
			if (e.target.name === 'facilityIds') {
				if (e.target.value.includes('all')) {
					if (this.state.allFacilityIds.length === this.state.facilityIds.length) {
						this.setState({ facilityIds: [] });
					} else {
						this.setState({ facilityIds: this.state.allFacilityIds });
					}
				} else {
					this.setState({ facilityIds: e.target.value })
				}
			} else {
				this.setState({ [e.target.name]: e.target.value });
			}
		}
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	updateAndCreateCompany = () => {
		let errors = [];

		if (this.state.name === "") {
			errors.push("name");
		}

		if (this.state.contact_name === "") {
			errors.push("contact_name");
		}

		if (this.state.contact_mobile === "") {
			errors.push("contact_mobile");
		}

		if (this.state.contact_email === "") {
			errors.push("contact_email");
		}

		if (this.state.cost_per_order === "") {
			errors.push("cost_per_order");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let companyInfo = {
			id: this.state.companyId,
			name: this.state.name,
			contact_name: this.state.contact_name,
			contact_mobile: this.state.contact_mobile,
			contact_email: this.state.contact_email,
			cost_per_order: this.state.cost_per_order,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			country: this.state.country,
			zip: this.state.zip,
			facilityIds: this.state.facilityIds,
			invoice_processing: this.state.invoice_processing
		};

		if (this.state.companyId !== "") {
			updateCompany(companyInfo)
				.then((response) => {
					this.props.context.componentParent.loadGridData();
					this.props.handleClose();
				})
		} else {
			createCompany(companyInfo)
				.then((response) => {
					this.props.context.componentParent.loadGridData();
					this.props.handleClose();
				})
		}
	};

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Name <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="name"
									value={this.state.name}
									onChange={this.handleChange}
									required
									disabled={(/customerserviceuser/.test(getUserRole().toLowerCase()))}
									className={
										this.hasError("name")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
								<div
									className={
										this.hasError("name") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Name of Invoicing Contact <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="contact_name"
									value={this.state.contact_name}
									onChange={this.handleChange}
									required
									className={
										this.hasError("contact_name")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
								<div
									className={
										this.hasError("contact_name") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Email of Invoicing Contact  <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="contact_email"
									value={this.state.contact_email}
									onChange={this.handleChange}
									className={
										this.hasError("contact_email")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
							</div>
							<div
								className={
									this.hasError("contact_email") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
						{
							!(/customerserviceuser/.test(getUserRole().toLowerCase()))
							&&
							<div className="col-12 col-md-6">
								<div className="form-group">
									<label>
										Cost per Order <span className="text-danger"> *</span>{" "}
									</label>
									<input
										type="text"
										name="cost_per_order"
										value={this.state.cost_per_order}
										onChange={this.handleChange}
										className={
											this.hasError("cost_per_order")
												? "form-control is-invalid"
												: "form-control"
										}
									/>
									<div
										className={
											this.hasError("cost_per_order") ? "inline-errormsg" : "hidden"
										}
									>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>}
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Phone of invoicing Contact <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="tel"
									name="contact_mobile"
									value={this.state.contact_mobile}
									onChange={this.handleChange}
									placeholder="(XXX) XXX-XXXX"
									className={
										this.hasError("contact_mobile")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
							</div>
							<div
								className={
									this.hasError("contact_mobile") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address1</label>
								<input
									type="text"
									name="address1"
									value={this.state.address1}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address2</label>
								<input
									type="text"
									name="address2"
									value={this.state.address2}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>City</label>
								<input
									type="text"
									name="city"
									value={this.state.city}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>State</label>
								<select
									className="form-control select"
									name="state"
									value={this.state.state}
									onChange={this.handleChange}
								>
									{states.map((state) => {
										return (
											<option key={state.value} value={state.value}>
												{state.state}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Zip Code</label>
								<input
									type="text"
									name="zip"
									value={this.state.zip}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>Facility</label>
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.state.facilities
										&&
										this.state.facilities.length > 0
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.state.facilityIds}
											onChange={this.handleChange}
											label="Select Facility"
											name="facilityIds"
											disabled={(/customerserviceuser|agtadmin/.test(getUserRole().toLowerCase()))}
											multiple
											renderValue={(selected) => {
												if (selected.length === this.state.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.state.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.state.facilityIds.length === this.state.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.state.facilityIds.length === this.state.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.state.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.state.facilityIds.includes(fac._id) ? true : false} />
															{fac.name}&nbsp;{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<MenuItem onClick={this.toggleInvoiceProcessing} disabled={(/customerserviceuser/.test(getUserRole().toLowerCase()))}>
									<Checkbox checked={this.state.invoice_processing} ></Checkbox>
									Invoice Processing
								</MenuItem>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-12"
							style={{
								paddingTop: "10px",
								borderTop: "1px solid rgba(0,0,0,.2",
							}}
						>
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.updateAndCreateCompany}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
