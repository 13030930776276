import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { TextField } from "@mui/material";

export default class VendorInfoCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			vendors: this.props.data.vendors
		};
	}

	handleShow = () => {
		this.setState({
			show: true,
			vendors: this.props.data.vendors
		});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					<i className="fas fa-store"></i>
				</button>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Vendor Info</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							this.state.vendors && this.state.vendors.length > 0
							&&
							this.state.vendors.map((vendor, index) => {
								return <div className="row input-box form-row" key={index}>
									<div className="col-12">
										<label style={{ margin: "10px 0px" }}>Vendor {index + 1}</label>
									</div>
									<div className="col-12 col-md-6">
										<div className="form-group">
											<TextField
												label="Name"
												value={vendor.vendor_name}
												onChange={(e) => this.handleVendorItemChange("vendor_name", e.target.value, index)}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="form-group">
											<TextField
												label="Contact"
												value={vendor.vendor_contact}
												onChange={(e) => this.handleVendorItemChange("vendor_contact", e.target.value, index)}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="form-group">
											<TextField
												label="Phone"
												value={vendor.vendor_phone}
												onChange={(e) => this.handleVendorItemChange("vendor_phone", e.target.value, index)}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className="form-group">
											<TextField
												label="Email"
												value={vendor.vendor_email}
												onChange={(e) => this.handleVendorItemChange("vendor_email", e.target.value, index)}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="form-group">
											<TextField
												label="Address"
												value={vendor.vendor_address}
												onChange={(e) => this.handleVendorItemChange("vendor_address", e.target.value, index)}
											/>
										</div>
									</div>
								</div>
							})
						}
						{
							!(this.state.vendors && this.state.vendors.length > 0)
							&&
							<div className="mb-3">No Vendor Info</div>
						}
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleClose}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
