import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class InternalNoteCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		}
	}

	handleShow = () => {
		if (this.props.data.patient_id?.internal_note) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				{
					this.props.data.patient_id?.internal_note
						?
						<button onClick={this.handleShow} className="edit-order-btn">Has Note</button>
						:
						<></>
				}
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Internal Note</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div style={{ whiteSpace: 'pre-wrap' }}>
							{this.props.data.patient_id?.internal_note}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
