import React, { Component } from "react";
import DashboardSidebar from "./sidebar/sidebar.jsx";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import StickyBox from "react-sticky-box";
import ResultData from "./resultData";
import InsuranceData from "./insuranceData";
import { serviceConstants } from "../../../services/common/constants";
import { fetchDashboardDetails, filterPatients, patientCommunication } from "../../../services/patientPortalServices/dashboardService";
import Swal from "sweetalert2";
import SocialShare from "./../../socialShare";
import { getFacilityDataById } from "../../../services/clinicPortalServices/facilityServices.js";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

class PatientPortalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      usedPatient: null,
      key: 1,
      result: null,
      selectedDateId: "",
      orderDates: [],
      value: "",
      loading: false,
      paymentRelatedOrderResults: [],
      showSidebar: false,
      tabValue: 'order',
      isCommunication: false,
      comment: {
        patientId: '',
        name: '',
        email: '',
        mobile: '',
        orderId: [],
        subject: 'MyResultNow - Patient Support Request',
        message: '',
      },
      commentError: {
        emailError: false,
        messageError: false,
      },
      facilityData: null
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  hideCommunication = () => {
    this.setState({ isCommunication: false });
  }

  showCommunication = () => {
    this.setState({ isCommunication: true });
  }

  onCommunicationTextChange = (e) => {
    let comment = this.state.comment;
    if (e.target.name === "message") {
      comment.message = e.target.value;
    }
    if (e.target.name === "email") {
      comment.email = e.target.value;
    }
    this.setState({ comment: comment });
  }

  handleSelect(key) {
    this.setState({ key });
  }

  componentDidMount = () => {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
      });
    }

    if (window.localStorage.getItem('MATCHED_PATIENTS_DATA')) {
      const patientsData = JSON.parse(window.localStorage.getItem('MATCHED_PATIENTS_DATA'));
      const comment = {
        patientId: patientsData._id,
        name: patientsData.first_name + ' ' + patientsData.last_name,
        email: patientsData.email,
        mobile: patientsData.mobile,
        orderId: [],
        subject: 'MyResultNow - Patient Support Request',
        message: '',
      }
      this.setState({ usedPatient: patientsData, comment: comment });
      this.loadDashboardDetail(patientsData._id);
      getFacilityDataById(patientsData.facility_id).then(res => {
        if (res.data && res.data[0] && res.data[0]._id) {
          this.setState({ facilityData: res.data[0] })
        }
      })
    } else {
      this.props.history.push('/');
    }
  }

  showCommunicator = () => {
    this.setState({ isCommunication: true });
  }

  onSupportRequest = () => {
    if (this.state.comment.email === "" || this.state.comment.message === "") {
      let error = this.state.commentError;
      if (this.state.comment.email === "") {
        error.emailError = true;
      }
      if (this.state.comment.message === "") {
        error.messageError = true;
      }
      this.setState({ commentError: error });
      return;
    }

    patientCommunication(this.state.comment).then(res => {
      this.hideCommunication();
      Swal.fire({
        customClass: {
          container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
        },
        title: 'We have received your support request',
        html: '<p>A support representative will be reviewing your request and will send you a personal response. (usually within 24 hours).</p><p>Our laboratory customer service hours are from 8am to 8pm, 7 days a week. We will reply to you within those hours.</p><p>Thank you for your patience.</p><p>Sincerely,</p><p>The MyResultNow Team</p>',
        icon: 'success'
      })
    });
  }

  loadDashboardDetail = (patientID) => {
    const patientInfo = { patient_id: patientID };
    this.setState({ loading: true });
    fetchDashboardDetails(patientInfo).then((data) => {
      this.setState({ loading: false });
      if (data.data != null && data.data.length > 0) {
        let orderResults = [];
        let paymentRelatedOrderResults = [];
        data.data.map(item => {
          if (item.results && item.results.pdf_path) {
            orderResults.push(item);
            if (item.payment && item.payment.due_amount && Number(item.payment.due_amount) > 0) {
              paymentRelatedOrderResults.push(item);
            } else if (item.mps_payment_id && item.mps_payment_id.due_amount && Number(item.mps_payment_id.due_amount) > 0) {
              item.payment = {
                chart_number: item.mps_payment_id.chart_number,
                total_amount: item.mps_payment_id.total_amount,
                adjustment_amount: 0,
                paid_amount: item.mps_payment_id.paid_amount,
                due_amount: item.mps_payment_id.due_amount
              }
              item.type = "mps_payment";
              paymentRelatedOrderResults.push(item);
            } else if (item.payment && item.payment.due_amount && item.payment.due_amount === "0") {
              paymentRelatedOrderResults.push(item);
            } else if (item.mps_payment_id && item.mps_payment_id.due_amount && item.mps_payment_id.due_amount === "0") {
              item.payment = {
                chart_number: item.mps_payment_id.chart_number,
                total_amount: item.mps_payment_id.total_amount,
                adjustment_amount: 0,
                paid_amount: item.mps_payment_id.paid_amount,
                due_amount: item.mps_payment_id.due_amount,
                paid_time: item.mps_payment_id.paid_time
              }
              item.type = "mps_payment";
              paymentRelatedOrderResults.push(item);
            }
          } else if (item.sample_tracking && item.sample_tracking.length) {
            orderResults.push(item);
          }
          return null;
        })
        orderResults.sort((a, b) => b.test_info.collected - a.test_info.collected);
        paymentRelatedOrderResults.sort((a, b) => {
          if ((a.payment && a.payment.due_amount && a.payment.due_amount === "0") && (b.payment && b.payment.due_amount && Number(b.payment.due_amount) > 0)) {
            return 1;
          } else if ((b.payment && b.payment.due_amount && b.payment.due_amount === "0") && (a.payment && a.payment.due_amount && Number(a.payment.due_amount) > 0)) {
            return -1;
          }
          return 0;
        })
        this.setState({
          result: orderResults,
          paymentRelatedOrderResults: paymentRelatedOrderResults,
          selectedDateId: orderResults[0]._id,
        });

        if (paymentRelatedOrderResults.filter(item => Number(item.payment.due_amount) > 0).length > 0) {
          this.setState({ tabValue: 'insurance' });
        }
      } else {
        this.setState({
          result: null,
          selectedDateId: "",
        });
      }
    }).catch((error) => {
      console.log(error);
    })
    filterPatients({ _id: patientID }).then(res => {
      if (res.data && res.data.length) {
        let usedPatient = Object.assign(this.state.usedPatient, res.data[0]);
        this.setState({ usedPatient: usedPatient, showSidebar: true });
      }
    })
  }

  handleResultsTab = () => {
    this.setState({ tabValue: 'order' });
  }

  handleInsuranceTab = () => {
    this.setState({ tabValue: 'insurance' });
  }

  render() {
    return (
      <div>
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/home">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Dashboard
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Dashboard</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  {
                    this.state.showSidebar
                    &&
                    <DashboardSidebar
                      patientData={this.state.usedPatient}
                      showCommunicator={this.showCommunicator}
                    />
                  }
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9 patientDashboard-content">
                <div className="card schedule-widget mb-0">
                  <div className="schedule-header">
                    <div className="schedule-nav">
                      <ul className="nav-tabs">
                        <li className={this.state.tabValue === 'order' ? "nav-item active" : "nav-item"} onClick={this.handleResultsTab}>
                          Orders
                        </li>
                        {
                          this.state.paymentRelatedOrderResults.length > 0
                          &&
                          <li className={this.state.tabValue === 'insurance' ? "nav-item active" : "nav-item"} onClick={this.handleInsuranceTab}>
                            Payments
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="schedule-cont">
                    {this.state.result != null &&
                      <div>
                        {
                          this.state.tabValue === 'order'
                          &&
                          <ResultData
                            result={this.state.result}
                            patientData={this.state.usedPatient}
                            facilityData={this.state.facilityData}
                          />
                        }
                        {
                          this.state.tabValue === 'insurance'
                          &&
                          <InsuranceData
                            insurance={this.state.paymentRelatedOrderResults}
                            patientData={this.state.usedPatient}
                            stripe={this.state.stripe}
                          />
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="row mobile-patient-footer-section" style={{ display: "none" }}>
              <div
                style={{
                  textAlign: "center",
                  padding: "50px 20px 0px 20px",
                  textTransform: "none",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "large" }}>
                  PLEASE CONSIDER SHARING
                </span>
                <p style={{ paddingTop: "20px" }}>
                  Results to your device in less than 24 hours!
                </p>
                <p>
                  If you are satisfied with the ease and speed of getting your
                  results,please consider sharing our services.
                </p>
                <b>(NOT YOUR RESULTS)</b>
                <p> Let others know, keep people safe!</p>
              </div>
              <SocialShare />
            </div>
          </div>
        </div>
        <ModalStyled
          size="lx"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.isCommunication}
          onHide={this.hideCommunication}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Contact Us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  error={this.state.commentError.emailError}
                  className="form-control"
                  id="reset-form"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="string"
                  margin="dense"
                  value={this.state.comment.email}
                  onChange={this.onCommunicationTextChange}
                />
              </div>
              <div className="col-md-12">
                <TextField
                  label="Message"
                  name="message"
                  variant="outlined"
                  error={this.state.commentError.messageError}
                  className="form-control mt-4"
                  id="reset-form"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={4}
                  type="string"
                  margin="dense"
                  style={{ height: "auto" }}
                  value={this.state.comment.message}
                  onChange={this.onCommunicationTextChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.onSupportRequest}>
              Send
            </Button>
          </Modal.Footer>
        </ModalStyled>
      </div>
    );
  }
}
export default PatientPortalDashboard;
