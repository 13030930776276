const data = {
    "signature_pandadoc.png": {
        label: "Test Signature",
        path: "../images/physician_signatures/signature_pandadoc.png"
    },
    "manbir-takhar-physician.png": {
        label: "Manbir Takhar",
        path: "../images/physician_signatures/manbir-takhar-physician.png"
    },
    "armel-simo-physician.png": {
        label: "Armel Simo",
        path: "../images/physician_signatures/armel-simo-physician.png"
    },
    "kenneth-lessans-passport-health-usa.png": {
        label: "Kenneth Lessans",
        path: "../images/physician_signatures/kenneth-lessans-passport-health-usa.png"
    },
    "sorelle-cooper-signature.png": {
        label: "Sorelle Cooper",
        path: "../images/physician_signatures/sorelle-cooper-signature.png"
    },
    "kiren-dayal-signature.png": {
        label: "Kiren Dayal",
        path: "../images/physician_signatures/kiren-dayal-signature.png"
    },
    "Dr-Elliot-Gorbaty-signature.png": {
        label: "Elliot Gorbaty",
        path: "../images/physician_signatures/Dr-Elliot-Gorbaty-signature.png"
    },
    "ambrish-gupta-signature.png": {
        label: "Ambrish Gupta",
        path: "../images/physician_signatures/ambrish-gupta-signature.png"
    },
    "Malvis-Tamon-Signature.png": {
        label: "Malvis Tamon",
        path: "../images/physician_signatures/Malvis-Tamon-Signature.png"
    },
    "Nkiru-Ezeani-Signature.png": {
        label: "Nkiru Ezeani",
        path: "../images/physician_signatures/Nkiru-Ezeani-Signature.png"
    },
    "syed-naqvi-signature.jpg": {
        label: "Syed Naqvi",
        path: "../images/physician_signatures/syed-naqvi-signature.jpg"
    },
    "lekya-hutton-physician-signature.png": {
        label: "Lekya Hutton",
        path: "../images/physician_signatures/lekya-hutton-physician-signature.png"
    },
    "olikemi-ogunmakinwa-signature.png": {
        label: "Olikemi Ogunmakinwa",
        path: "../images/physician_signatures/olikemi-ogunmakinwa-signature.png"
    },
    "stella-akpuaka-physician.png": {
        label: "Stella Akpuaka",
        path: "../images/physician_signatures/stella-akpuaka-physician.png"
    },
    "victoria-adaramaja-signature.png": {
        label: "Victoria Adaramaja",
        path: "../images/physician_signatures/victoria-adaramaja-signature.png"
    },
    "EVELINE-TAKANG-Signature.png": {
        label: "Eveline Takang",
        path: "../images/physician_signatures/EVELINE-TAKANG-Signature.png"
    },
    "Marijana-Ducic-signature.png": {
        label: "Marijana Ducic",
        path: "../images/physician_signatures/Marijana-Ducic-signature.png"
    },
    "UCHENNA-EKWUNAZU-signature.png": {
        label: "Uchenna Ekwunazu",
        path: "../images/physician_signatures/UCHENNA-EKWUNAZU-signature.png"
    },
    "Haiqin_Deng_signature.png": {
        label: "Haiqin Deng",
        path: "../images/physician_signatures/Haiqin_Deng_signature.png"
    },
    "dr-khalil-signature.png": {
        label: "Dr Khalil",
        path: "../images/physician_signatures/dr-khalil-signature.png"
    },
    "Christiana-Keke-Ekekwe-signature.png": {
        label: "Christiana Keke Ekekwe",
        path: "../images/physician_signatures/Christiana-Keke-Ekekwe-signature.png"
    },
    "scott-d-wissman-physician.png": {
        label: "Scott D Wissman",
        path: "../images/physician_signatures/scott-d-wissman-physician.png"
    },
    "Deborah-Rollins-signature.png": {
        label: "Deborah Rollins",
        path: "../images/physician_signatures/Deborah-Rollins-signature.png"
    },
    "Rhodo-Nguyen-signature.png": {
        label: "Rhodo Nguyen",
        path: "../images/physician_signatures/Rhodo-Nguyen-signature.png"
    },
    "Stella-Jefferies-physician.png": {
        label: "Stella Jefferies",
        path: "../images/physician_signatures/Stella-Jefferies-physician.png"
    },
    "Miranda Mbutambe-signature.png": {
        label: "Miranda Mbutambe",
        path: "../images/physician_signatures/Miranda Mbutambe-signature.png"
    },
    "Elizabeth-Schaubert-Physician.png": {
        label: "Elizabeth Schaubert",
        path: "../images/physician_signatures/Elizabeth-Schaubert-Physician.png"
    },
    "m-hopper-physician-signature.png": {
        label: "M Hopper",
        path: "../images/physician_signatures/m-hopper-physician-signature.png"
    },
    "Duane-McKinney-Physician.png": {
        label: "Duane McKinney",
        path: "../images/physician_signatures/Duane-McKinney-Physician.png"
    },
    "Josephine-Moore-Signature.png": {
        label: "Josephine Moore",
        path: "../images/physician_signatures/Josephine-Moore-Signature.png"
    },
    "Ali-Thakkar-Signature.png": {
        label: "Ali Thakkar",
        path: "../images/physician_signatures/Ali-Thakkar-Signature.png"
    },
    "steven-hirsch-physician-signature.png": {
        label: "Steven Hirsch",
        path: "../images/physician_signatures/steven-hirsch-physician-signature.png"
    },
    "Taha-Nova-Fake-Signature.png": {
        label: "Taha Nova Fake Signature",
        path: "../images/physician_signatures/Taha-Nova-Fake-Signature.png"
    },
    "Taha-Tokhi-Signature.png": {
        label: "Taha Tohki",
        path: "../images/physician_signatures/Taha-Tokhi-Signature.png"
    },
    "MIRANDA-MBUTAMBE-Signature.png": {
        label: "Miranda Mbutambe",
        path: "../images/physician_signatures/MIRANDA-MBUTAMBE-Signature.png"
    },
    "lawrence-josondu-signature.png": {
        label: "Lawrence Josondu",
        path: "../images/physician_signatures/lawrence-josondu-signature.png"
    },
    "Vera-Okoye-Signature.png": {
        label: "Vera Okoye",
        path: "../images/physician_signatures/Vera-Okoye-Signature.png"
    },
    "jenie-ferrer-signature.png": {
        label: "Jenie Ferrer",
        path: "../images/physician_signatures/jenie-ferrer-signature.png"
    },
    "john-doe-signature.png": {
        label: "John Doe",
        path: "../images/physician_signatures/john-doe-signature.png"
    },
    "Khadijat-signature.png": {
        label: "Khadijat",
        path: "../images/physician_signatures/Khadijat-signature.png"
    },
    "Chinyere-Amazu.png": {
        label: "Chinyere Amazu",
        path: "../images/physician_signatures/Chinyere-Amazu.png"
    },
};
export default data;