import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { isUserLoggedIn, getUserRole, getUserAuthToken, getUserDetails } from "../../../services/common/util";
import RequestPickup from "./requestPickup";
import RequestKit from "./requestKit";
import SupportPopup from "./supportPopup";
import UniversalSearch from "./universalSearch";
import { serviceConstants } from '../../../services/common/constants';
import { getDriverDataById } from "../../../services/clinicPortalServices/driverService";
import { getUserDataById } from "../../../services/clinicPortalServices/userService";
import VideoList from "./videoList";

class HeaderMenuDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			role: '',
			avatar_image: ''
		}
	};

	componentDidMount() {
		if (window.localStorage.getItem('DRIVER-AUTH-TOKEN')) {
			let driverId = JSON.parse(window.localStorage.getItem('DRIVER_DETAILS'))._id;
			// get driver details
			getDriverDataById(driverId).then(response => {
				if (response.data && response.data.length) {
					const driverDetails = response.data[0];
					this.setState({
						username: driverDetails.first_name + ' ' + driverDetails.last_name,
						role: 'Driver',
						avatar_image: driverDetails.avatar_image ? serviceConstants.API_HOST_NAME + '/files/driver/' + driverDetails.avatar_image + "?token=" + getUserAuthToken() : ''
					})
				}
			})
		} else {
			if (getUserDetails()) {
				// get user details
				let userId = JSON.parse(getUserDetails())._id;
				getUserDataById(userId).then(response => {
					if (response.data && response.data.length) {
						const userDetails = response.data[0];
						this.setState({
							username: userDetails.first_name + ' ' + userDetails.last_name,
							role: getUserRole(),
							avatar_image: userDetails.avatar_image ? serviceConstants.API_HOST_NAME + '/files/driver/' + userDetails.avatar_image + "?token=" + getUserAuthToken() : ''
						})
					}
				})
			}
		}
	}

	goto = (url) => {
		this.props.history.push(url)
	}

	render() {
		return (
			<div className="nav header-navbar-rht show-always">

				<Dropdown className="user-drop nav-item dropdown has-arrow logged-item" style={{ display: 'flex', alignItems: 'center' }}>
					{
						getUserRole() && /^agtsuperadmin|^agtadmin|^labtech|^customerserviceuser/.test(getUserRole().toLowerCase())
						&&
						<div className="universal-desktop">
							<UniversalSearch />
						</div>
					}

					{/* {
						isUserLoggedIn() && this.props.showClinicMenu && /^agtsuperadmin|^agtadmin|^facilityuser|^facilityadmin/.test(this.state.role.toLowerCase())
						&&
						<RequestKit />
					}
					{
						isUserLoggedIn() && this.props.showClinicMenu && /^agtsuperadmin|^agtadmin|^facilityuser|^facilityadmin/.test(this.state.role.toLowerCase())
						&&
						<RequestPickup
							role={this.state.role}
						/>
					} */}
					{
						isUserLoggedIn() && this.props.showClinicMenu && /^agtsuperadmin|^agtadmin|^facilityuser|^facilityadmin/.test(this.state.role.toLowerCase())
						&&
						<SupportPopup />
					}
					{
						isUserLoggedIn() && this.props.showClinicMenu && /^agtsuperadmin|^agtadmin|^facilityuser|^facilityadmin/.test(this.state.role.toLowerCase())
						&&
						<VideoList />
					}
					<Dropdown.Toggle variant='success' id="dropdown-basic">
						{
							this.state.avatar_image
							&&
							<img src={this.state.avatar_image}
								className="avatar-img rounded-circle" width="31" alt="avatar"
							/>
						}
						{
							!this.state.avatar_image
							&&
							<i
								className="fa fa-user-circle fa-2x header-icon"
								style={{ marginRight: 0 }}
								aria-hidden="true"
							></i>
						}
					</Dropdown.Toggle>

					<Dropdown.Menu className="dropdown-menu-right user-dropdown">
						<div className="user-header">
							<div className="avatar avatar-sm">
								{
									this.state.avatar_image
									&&
									<img src={this.state.avatar_image} alt="avatar" />
								}
								{
									!this.state.avatar_image
									&&
									<i
										className="fa fa-user-circle fa-2x header-icon"
										style={{ fontSize: "40px" }}
										aria-hidden="true"
									></i>
								}
							</div>
							<div className="user-text">
								<h6>{this.state.username}</h6>
								<p className="text-muted mb-0">{this.state.role}</p>
							</div>
						</div>
						{/* {
							this.state.role === 'Driver'
							&&
							<Dropdown.Item onClick={() => this.goto("/driverportal/dashboard")}>
									Dashboard
							</Dropdown.Item>
						} */}
						{
							this.state.role === 'Driver'
							&&
							<Dropdown.Item onClick={() => this.goto("/driverportal/profile")}>
								Profile
							</Dropdown.Item>
						}
						<Dropdown.Item onClick={this.props.logout}>
							Logout
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}

export default HeaderMenuDropdown;
