import React, { Component } from "react";
import { authenticateSecurityNumberAuthentication } from "../../../services/clinicPortalServices/loginService";
import { getPatientAuthToken, getDriverAuthToken, setCookie } from "../../../services/common/util";
import { logoutDriver } from "../../../services/driverPortalServices/loginService";
import { logoutPatient } from "../../../services/patientPortalServices/loginService";
import { updateDevice } from "../../../services/clinicPortalServices/userService";
import Swal from "sweetalert2";
import { dashboardLinks } from "../../../services/common/optionsData";

class UserTwoFactorAuthentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            securityNumber: "",
            loading: false,
            isError: false
        };
    };

    handleChange = (e) => {
        this.setState({ isError: false })
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const userId = this.props.match.params.userId;
        authenticateSecurityNumberAuthentication(userId, this.state.securityNumber).then((res) => {
            this.setState({ loading: false });
            const userInfo = res.data;
            if (!userInfo || (userInfo && userInfo.token.length === 0)) {
                this.setState({
                    isAuthenticationfailed: "YES",
                    loading: false
                });
                return;
            }
            if (userInfo.user.device_id && userInfo.user.device_id !== '') {
                Swal.fire({
                    text: 'You are already logged in another device or web browser, Close the other session?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    customClass: {
                        container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                        cancelButton: 'order-1',
                        confirmButton: 'order-2'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.saveUser(userInfo);
                    }
                    else {
                        this.setState({ loading: false });
                    }
                });
            }
            else {
                this.saveUser(userInfo);
            }
        })
    };
    saveUser(userInfo) {
        // update device id
        if (getDriverAuthToken()) {
            logoutDriver();
        } else if (getPatientAuthToken())
            logoutPatient();
        let newDeviceId = this.makeid(100);
        const facilityIds = userInfo.user.facilityIds.map(facility => {
            return facility._id;
        })
        window.localStorage.clear();
        window.localStorage.setItem("AUTH-TOKEN", userInfo.token);
        setCookie();
        window.localStorage.setItem("DEVICE_ID", newDeviceId);
        window.localStorage.setItem("USER_ID", userInfo.user._id);
        window.localStorage.setItem("USER_DETAILS", JSON.stringify(userInfo.user));
        window.localStorage.setItem("FACILITY_IDS", JSON.stringify(facilityIds));
        window.localStorage.setItem("USER_ROLE", userInfo.user.role);

        let userDeviceInfo = {
            id: userInfo.user._id,
            deviceId: newDeviceId
        };

        updateDevice(userDeviceInfo)
            .then((response) => {
                if (response.data.RESULT === "ERROR") {
                    console.log('error');
                    window.localStorage.clear();
                } else {
                    this.setState({
                        isAuthenticationfailed: "NO",
                        loading: false
                    });
                    window.localStorage.setItem("AUTH-TOKEN", response.data.token);
                    setCookie();
                    window.location.href = dashboardLinks[userInfo.user.role] ? dashboardLinks[userInfo.user.role] : "/clinic/orders";
                }
            })
    }
    makeid(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    render() {
        return (
            <div className="content login-content" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div
                                        className="col-md-12 col-lg-6 login-right"
                                        style={{ border: "none" }}
                                    >
                                        <h4>
                                            Welcome to American Gene Technologies Results Portal
                                            <sup>TM</sup>
                                        </h4>
                                        <br />
                                        <p style={{ fontWeight: "500" }}>
                                            Please enter your information to access your Dashboard.
                                        </p>
                                        <p>
                                            Access to the AGT Portal is restricted solely to
                                            authorized users and is monitored for administrative and
                                            security purpose by the AGT team. All users expressly
                                            consent to such monitoring at time of portal registration.
                                            Any use of this system must be in compliance with AGT
                                            policies, procedures and applicable laws. Unauthorized
                                            access or use of this system may result in portal
                                            termination and civil or criminal liability.
                                        </p>
                                        <p>&copy; 2023 American Gene Technologies</p>
                                    </div>
                                    <div className="col-md-12 col-lg-5 login-right">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Enter 6-Digit Security Number{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="securityNumber"
                                                    value={this.state.securityNumber}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            {this.state.isError && (
                                                <div
                                                    className=" btn-block "
                                                    style={{
                                                        color: "red",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    Invalid Security Number
                                                </div>
                                            )}
                                            <button
                                                className="btn btn-primary btn-block btn-lg login-btn"
                                                type="submit" disabled={this.state.loading}
                                            >
                                                {this.state.loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }}></i>}
                                                {this.state.loading && <span>Validating Security Code ...</span>}
                                                {!this.state.loading && <span>Authenticate</span>}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserTwoFactorAuthentication;
