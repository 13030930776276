const actions = {
    startBlockUILoading: (state) => {
        return {
            blockUILoading: true
        }
    },

    endBlockUILoading: (state) => {
        return {
            blockUILoading: false
        }
    }
};

export default actions;