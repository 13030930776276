import React, { Component } from "react";
import { Checkbox, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Select, Radio, RadioGroup, TextField, InputAdornment, IconButton } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import BreadCrumb from "./breadcrumb";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import TestResultFilter from "../../orders/clinicOrderGrid/testResultFilter";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		}
	}

	render() {
		return (
			<div>
				<BreadCrumb
					onBtnExportZipForRequisitions={() => { this.props.onBtnExportZipForRequisitions() }}
					onBtnExportZipForResults={() => { this.props.onBtnExportZipForResults() }}
					page_size={this.props.page_size}
					onPageSizeChanged={(event) => { this.props.onPageSizeChanged(event) }}
					updateView={(gridView) => { this.props.updateView(gridView) }}
					resetState={() => { this.props.resetState() }}
					onBtnExport={() => { this.props.onBtnExport() }}
					onIsArchivedInformation={(event) => { this.props.onIsArchivedInformation(event) }}
					isArchived={this.props.isArchived}
				/>
				<div className="row mt-4 px-3">
					<div
						className="col-md-12"
					>
						<div className="row">
							<div className="col-md-4 col-12 mb-3">
								<FormControl
									variant="outlined"
									className="filter-select-bar"
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.props.facilities && this.props.facilities.length <= 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
                                            value={this.props.facilityId && this.props.facilityId.length ? this.props.facilityId : ""}
											onChange={this.props.handleFiltersChange}
											name="facility_id"
										>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<span className="mr-2">
																{fac.name}
															</span>
															{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
									{
										this.props.facilities && this.props.facilities.length > 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.props.facilityId}
											onChange={this.props.handleFiltersChange}
											label="Select Facility"
											name="facility_id"
											multiple
											renderValue={(selected) => {
												if (selected.length === this.props.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.props.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.props.facilityId && this.props.facilityId.includes(fac._id) ? true : false} className={fac.type === 'facility' ? 'ml-5' : ''} />
															<span className="mr-2">
																{fac.name}
															</span>
															{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
								<TestResultFilter
									tests={this.props.tests}
									resultValues={this.props.resultValues}
									context={this.props.context}
									updateResultValues={this.props.updateResultValues}
									updateBundleIds={this.props.updateBundleIds}
									bundleIds={this.props.bundleIds}
								/>
								<button className="btn btn-info mr-2 mt-3 mb-2" onClick={this.props.handleEnableBulkPayment}>
									{
										this.props.isEnabledBulkPayment ? "Disable Bulk Pay" : "Enable Bulk Pay"
									}
								</button>
								{
									this.props.isEnabledBulkPayment && this.props.bulkPaymentIdsArray && this.props.bulkPaymentIdsArray.length > 0
									&&
									<button className="btn btn-primary mt-3 mb-2" onClick={this.props.processBulkPayment}>
										Process Bulk Pay $ {this.props.bulkPaymentAmount} ({this.props.bulkPaymentIdsArray.length})
									</button>
								}
							</div>
							<div className="col-md-4 col-12 mb-3" style={{ marginTop: "-15px" }}>
								<div className="row">
									<div className="col-12">
										<FormLabel component="legend" style={{ marginBottom: "10px" }}>
											Date Range
										</FormLabel>
									</div>
									<div className="dateRangeBar mb-2">
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'today' ? " active" : "")}
											onClick={() => this.props.updateDateRange('today')}
										>
											Today
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'week' ? " active" : "")}
											onClick={() => this.props.updateDateRange('week')}
										>
											Week
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'month' ? " active" : "")}
											onClick={() => this.props.updateDateRange('month')}
										>
											Month
										</button>
										<button
											className={"btn btn-info dateRangeBtn ml-1 mb-2" + (this.props.date_range === 'custom' ? " active" : "")}
										>
											Custom
										</button>
									</div>
									<div className="dateRangeBar dateRangeBarPickers">
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
											onChange={(dateTime) => this.setState({ from_date: dateTime })}
											onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
											label="From Date"
											renderInput={props => <TextField
												{...props}
												className="ml-1 mr-2 mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
											onChange={(dateTime) => this.setState({ to_date: dateTime })}
											onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
											label="To Date"
											renderInput={props => <TextField
												{...props}
												className="mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
									</div>
									<div className="col-12 mt-3" style={{ paddingLeft: 10 }}>
										<TextField
											label="Current Grid Search"
											variant="outlined"
											id="reset-form"
											InputLabelProps={{
												shrink: true,
											}}
											sx={{ maxWidth: 450 }}
											value={this.props.filter_text}
											type="string"
											margin="dense"
											onChange={this.props.onFilterTextChange}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 mb-3">
								<FormControl component="fieldset">
									<FormLabel component="legend" style={{ marginBottom: "0px" }}>
										Search By
									</FormLabel>
									<RadioGroup
										aria-label="filter_by"
										name="filter_by"
										value={this.props.filter_by}
										onChange={this.props.handleFiltersChange}
										style={{ flexDirection: "row", justifyContent: "center" }}
									>
										<FormControlLabel
											key='received_date'
											value='received_date'
											control={<Radio />}
											label='Received Date'
										/>
										<FormControlLabel
											key='released_date'
											value='released_date'
											control={<Radio />}
											label='Released Date'
										/>
										<FormControlLabel
											key='collected_date'
											value='collected_date'
											control={<Radio />}
											label='Collected Date'
										/>
									</RadioGroup>
								</FormControl>
								<FormControl component="fieldset">
									<FormLabel component="legend" style={{ marginBottom: "0px" }}>
										Payment Status
									</FormLabel>
									<RadioGroup
										aria-label="payment_status"
										name="payment_status"
										value={this.props.payment_status}
										onChange={this.props.handlePaymentStatusChange}
										style={{ flexDirection: "row", justifyContent: "center" }}
									>
										<FormControlLabel
											key='all'
											value='all'
											control={<Radio />}
											label='All'
										/>
										<FormControlLabel
											key='outstanding'
											value='outstanding'
											control={<Radio />}
											label='Outstanding'
										/>
										<FormControlLabel
											key='paid'
											value='paid'
											control={<Radio />}
											label='Paid'
										/>
										<FormControlLabel
											key='no_entry'
											value='no_entry'
											control={<Radio />}
											label='No Entry'
										/>
									</RadioGroup>
								</FormControl>
								<button
									className="btn btn-primary mt-4"
									onClick={() => this.props.clearFilter()}
								>
									<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchMenu;
