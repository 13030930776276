import React, { Component, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from 'moment';
import 'moment-timezone';
import Swal from "sweetalert2";
import toastr from 'toastr';
import { serviceConstants } from "../../../services/common/constants";
import { requisitionOptionsForAccessioningQuant, requisitionOptionsForAccessioningSolana, quantPlateRows, quantPlateColumns, solanaPlateRows } from "../../../services/common/optionsData";
import { dateComparator, getUserDetails, getRowColumnIndexForQuantPlate, getUserAuthToken, popupCenter, getPlateCellCount } from "../../../services/common/util";
import { getRequisitionsForAccessioning } from "../../../services/limsPortalServices/accessioningService";
import { getAllTests } from "../../../services/clinicPortalServices/testService";
import { orderEasyUpdate, getOrderDataById } from "../../../services/clinicPortalServices/orderEditService";
import { generateBarcodeImage } from "../../../services/clinicPortalServices/requisitionService";
import { fetchFacilitiesForOrders } from "../../../services/clinicPortalServices/facilityServices";
import { createPlate, updatePlate, getPlatesForAccessioning, getPlateDataById, plateAccession, generateResultDocument } from "../../../services/limsPortalServices/plateService";
import { getPlateTemplateAllData } from "../../../services/limsPortalServices/plateTemplateService";
import Breadcrumb from "./breadcrumb";
import BarcodeRenderer from "./barcodeRenderer";
import ActionRenderer from "./actionRenderer";
import RejectActionRenderer from "../../clinicPortal/requisitionsNew/clinicRequisitionNewGrid/ActionComponent";
import QuantPlateMap from './quantPlateMap';
import { Autocomplete, TextField, RadioGroup, FormControlLabel, Radio, IconButton, InputAdornment } from "@mui/material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import EventIcon from "@mui/icons-material/Event";
import SelectPlateTemplate from "./selectPlateTemplate";
import CreateRequisition from "../../clinicPortal/requisitions/clinicRequisitionGrid/createRequisition";
import SelectPlateWell from "./selectPlateWell";
import SolanaPlateMap from "../solanaAccessioning/solanaPlateMap";
import { ThemeContext } from "../../../theme/ThemeProvider";

const quantPlateTotalCount = (quantPlateRows.length - 1) * (quantPlateColumns.length - 1);
const solanaPlateTotalCount = solanaPlateRows.length;

class LIMSAccessioningPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			gridName: "Default",
			pageSize: "",
			selectedPlate: null,
			testMachineType: "",
			testMachineTypes: [],
			selectedTestId: "",
			selectedMachine: "",
			regularPlate: null,
			vipPlate: null,
			columnDefs: [
				{
					headerName: "Assign to Plate",
					minWidth: 100,
					resizable: true,
					cellRenderer: "ActionRenderer"
				},
				{
					headerName: "Reject",
					minWidth: 80,
					resizable: true,
					cellRenderer: "RejectActionRenderer"
				},
				{
					headerName: "Run Status",
					minWidth: 100,
					resizable: true,
					valueGetter: params => {
						let importCount = 0;
						if (params.data.sample_tracking && params.data.sample_tracking.length) {
							importCount = params.data.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
						}
						return "Import Count: " + importCount;
					}
				},
				{
					headerName: "VIP Status",
					minWidth: 50,
					field: "is_vip_order",
					resizable: true,
					cellClass: "d-flex align-items-center justify-content-center",
					cellRenderer: memo(params => {
						if (params.data.is_vip_order) {
							return <i className='fas fa-star' style={{ fontSize: "20px" }}></i>
						} else {
							return <i className='far fa-star' style={{ fontSize: "20px" }}></i>
						}
					})
				},
				{
					headerName: "BarCode",
					minWidth: 300,
					resizable: true,
					cellRenderer: "BarcodeRenderer"
				},
				{
					headerName: "Sample ID",
					minWidth: 100,
					resizable: true,
					field: "test_info.sample"
				},
				{
					headerName: "Patient Name",
					minWidth: 100,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.patient_id) {
							return (
								params.data.patient_id.first_name +
								" " +
								params.data.patient_id.last_name
							);
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Patient DOB",
					minWidth: 100,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.patient_id) {
							return moment(params.data.patient_id.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY");
						} else {
							return "";
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Collection Date",
					minWidth: 100,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.test_info.collected) {
							return moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
						} else {
							return "";
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Created Date",
					minWidth: 100,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.createdAt) {
							return moment.tz(params.data.createdAt, "America/New_York").format("MM/DD/YYYY hh:mm A");
						} else {
							return "";
						}
					},
					comparator: dateComparator
				},
			],
			components: {
				BarcodeRenderer: BarcodeRenderer,
				ActionRenderer: ActionRenderer,
				RejectActionRenderer: RejectActionRenderer
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			allFacilities: [],
			allPlates: [],
			requisitionFilters: {
				resultValue: "all",
				filterBy: 'collected_date',
				from_date: moment().subtract(2, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
			},
			allPlateTemplates: [],
			newPlateType: '',
			vipCurrentIndex: null,
			regularCurrentIndex: null,
			showSelectPlateTemplate: false,
			showCreateRequisition: false,
			manualPlateData: null,
			manualOrderInfo: null,
			pickerOpened: false,
			temp_from_date: moment().subtract(2, "days").format("YYYY-MM-DD") + 'T00:00',
			temp_to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00'
		};
	}

	componentDidMount() {
		if (window.socket) {
			window.socket.on('refresh_plate', (plateId) => {
				if (plateId) {
					getPlateDataById(plateId).then(res => {
						let allPlates = this.state.allPlates;
						allPlates = allPlates.map(plate => {
							if (plate._id === plateId && res.data) {
								plate = res.data;
							}
							return plate;
						})
						this.setState({ allPlates: allPlates.filter(item => !item.is_exported) })
						if (!res.data.is_exported) {
							if (this.state.regularPlate && this.state.regularPlate._id === plateId) {
								this.setState({ regularPlate: res.data })
							} else if (this.state.vipPlate && this.state.vipPlate._id === plateId) {
								this.setState({ vipPlate: res.data })
							}
						} else {
							if (this.state.regularPlate && this.state.regularPlate._id === plateId) {
								this.setState({ regularPlate: null, selectedPlate: null })
							} else if (this.state.vipPlate && this.state.vipPlate._id === plateId) {
								this.setState({ vipPlate: null, selectedPlate: null })
							}
						}
					})
				} else {
					this.getPlates();
				}
			})
			window.socket.on('requisition_assigned', (orderId) => {
				console.log('remove ', orderId);
				const rowData = this.state.rowData;
				rowData.map(row => {
					if (row._id === orderId) {
						this.setState({ rowData: rowData.filter(item => item._id !== orderId) });
					}
					return null;
				})
			})
			window.socket.on('requisition_created', (orderId) => {
				console.log('add ', orderId);
				let rowData = this.state.rowData;
				if (!rowData.filter(row => row._id === orderId).length) {
					getOrderDataById(orderId).then(res => {
						if (res.data && res.data.test_id && res.data.test_id._id === this.state.selectedTestId) {
							this.setState({ rowData: [...rowData, res.data] });
							this.gridApi.refreshCells();
						}
					})
				}
			})
		}

		getAllTests({ type: "single" }).then(res => {
			let testMachineTypes = [];
			res.data.map(test => {
				test.machine.map(machine => {
					testMachineTypes.push(test.name + "|||" + machine + "|||" + test._id);
					return null;
				})
				return null;
			})
			this.setState({
				testMachineTypes: testMachineTypes,
				testMachineType: testMachineTypes[0],
				selectedTestId: testMachineTypes[0].split("|||")[2],
				selectedMachine: testMachineTypes[0].split("|||")[1].toLowerCase()
			}, () => {
				fetchFacilitiesForOrders().then(res => {
					this.setState({ allFacilities: res.data });
					this.loadGridData();
				})
				this.getPlates();
			})
		})
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		const filters = {
			filterBy: this.state.requisitionFilters.filterBy,
			resultValue: this.state.requisitionFilters.resultValue,
			from_date: moment(this.state.requisitionFilters.from_date).format("YYYYMMDDHHmmss"),
			to_date: moment(this.state.requisitionFilters.to_date).format("YYYYMMDDHHmmss"),
			test_id: this.state.selectedTestId
		}
		getRequisitionsForAccessioning(filters)
			.then((response) => {
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
					this.setState({ rowData: [] });
				} else {
					let rowData = [];
					response.data.map(row => {
						if (this.state.requisitionFilters.resultValue.includes('invalid')) {
							if (row.sample_tracking && row.sample_tracking.filter(tracking => tracking.eventName.includes("IMPORT") && tracking.status.toLowerCase() === 'invalid').length) {
								rowData.push(row);
							}
						} else if (this.state.requisitionFilters.resultValue.includes('inconclusive')) {
							if (row.sample_tracking && row.sample_tracking.filter(tracking => tracking.eventName.includes("IMPORT") && ["SARS-CoV-2 Inconclusive", "NA"].includes(tracking.result)).length) {
								rowData.push(row);
							}
						} else {
							rowData.push(row);
						}
						return null;
					})
					this.setState({ rowData: rowData });
					this.gridApi.hideOverlay();
				}
			})
	};

	getPlates = () => {
		let params = {
			machine_type: this.state.selectedMachine,
			test_id: this.state.selectedTestId
		}
		getPlatesForAccessioning(params).then(res => {
			if (res.data) {
				let allPlates = res.data;
				if (this.state.regularPlate || this.state.vipPlate) {
					if (this.state.regularPlate && allPlates.find(plate => plate._id === this.state.regularPlate._id)) {
						this.setState({ regularPlate: allPlates.find(plate => plate._id === this.state.regularPlate._id) })
					} else {
						this.setState({ regularPlate: null, selectedPlate: null })
					}
					if (this.state.vipPlate && allPlates.find(plate => plate._id === this.state.vipPlate._id)) {
						this.setState({ vipPlate: allPlates.find(plate => plate._id === this.state.vipPlate._id) })
					} else {
						this.setState({ vipPlate: null, selectedPlate: null })
					}
				}
				allPlates = allPlates.filter(item => !item.is_exported);
				allPlates.sort((a, b) => moment(b.createdAt).format("YYYYMMDDHHmmss") - moment(a.createdAt).format("YYYYMMDDHHmmss"));
				this.setState({ allPlates: allPlates });
			} else {
				this.setState({ allPlates: [] });
			}
		})
	}

	createNewPlate = (type) => {
		if (this.state.selectedMachine === 'quant') {
			getPlateTemplateAllData().then(res => {
				if (res.data && res.data.length) {
					Swal.fire({
						title: "Would like to create New " + (type === 'regular' ? "Regular" : "VIP") + " Quant Plate?",
						text: "You need to select plate template and after new plate is created, it will be selected automatically.",
						icon: "question",
						showCancelButton: true,
						confirmButtonText: "Yes",
						cancelButtonText: "Cancel",
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
							cancelButton: 'order-1',
							confirmButton: 'order-2'
						}
					}).then(result => {
						if (result.isConfirmed) {
							this.setState({
								showSelectPlateTemplate: true,
								newPlateType: type,
								allPlateTemplates: res.data
							});
						}
					})
				} else {
					toastr.warning("Please create plate template to create new plate.")
				}
			})
		} else {
			Swal.fire({
				title: "Would like to create New " + (type === 'regular' ? "Regular" : "VIP") + " Solana Plate?",
				icon: "question",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel",
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
					cancelButton: 'order-1',
					confirmButton: 'order-2'
				}
			}).then(result => {
				if (result.isConfirmed) {
					const userInfo = JSON.parse(getUserDetails());
					const plateData = {
						type: type,
						machine_type: "solana",
						test_id: this.state.selectedTestId,
						cells: {},
						createdBy: userInfo.first_name + ' ' + userInfo.last_name
					}
					createPlate(plateData).then((response) => {
						if (response.data) {
							if (type === 'regular') {
								this.setState({
									regularPlate: response.data,
									regularCurrentIndex: null
								})
							} else {
								this.setState({
									vipPlate: response.data,
									vipCurrentIndex: null
								})
							}
							this.gridApi.refreshCells();
							const plateName = response.data.name;
							generateBarcodeImage({ plateName: plateName }).then((res) => {
								popupCenter(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + plateName + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken(), plateName, "800", "600")
							})
							toastr.success(plateName + " plate created successfully, please print created plate barcode from new tab");
						}
					})
				}
			})
		}
	}

	createNewPlateWithTemplate = (plateTemplate) => {
		let cells = {};
		for (const [key, value] of Object.entries(plateTemplate.control_spots)) {
			cells[key] = {
				is_control_spot: true,
				dateTime: moment().format("YYYYMMDDHHmmss"),
				control_spot_type: value
			}
		}
		const userInfo = JSON.parse(getUserDetails());
		const plateData = {
			type: this.state.newPlateType,
			machine_type: "quant",
			test_id: this.state.selectedTestId,
			cells: cells,
			createdBy: userInfo.first_name + ' ' + userInfo.last_name
		}
		createPlate(plateData).then((response) => {
			if (response.data) {
				if (this.state.newPlateType === 'regular') {
					this.setState({
						regularPlate: response.data,
						regularCurrentIndex: null
					})
				} else {
					this.setState({
						vipPlate: response.data,
						vipCurrentIndex: null
					})
				}
				this.gridApi.refreshCells();
				const plateName = response.data.name;
				generateBarcodeImage({ plateName: plateName }).then((res) => {
					popupCenter(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + plateName + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken(), plateName, "800", "600")
				})
				toastr.success(plateName + " plate created successfully, please print created plate barcode from new tab");
			}
		})
	}

	closePlate = (type) => {
		if (type === 'regular') {
			this.setState({
				regularPlate: null,
				regularCurrentIndex: null,
				selectedPlate: null
			});
		} else {
			this.setState({
				vipPlate: null,
				vipCurrentIndex: null,
				selectedPlate: null
			});
		}
		this.gridApi.refreshCells();
	}

	selectPlate = (plateData) => {
		if (plateData.type === 'regular') {
			this.setState({
				regularPlate: plateData,
				regularCurrentIndex: null
			});
		} else {
			this.setState({
				vipPlate: plateData,
				vipCurrentIndex: null
			});
		}
		this.gridApi.refreshCells();
	}

	assignToPlate = (orderInfo, type) => {
		// should update requisition assign state and sample tracking, should update plate, both should trigger socket events
		let plateData;
		if (type === 'regular') {
			plateData = this.state.regularPlate._id;
		} else {
			plateData = this.state.vipPlate._id;
		}
		getPlateDataById(plateData).then(res => {
			plateData = res.data;
			if (type === 'regular') {
				this.setState({ regularPlate: plateData });
			} else {
				this.setState({ vipPlate: plateData });
			}
			let plateTotalCount = quantPlateTotalCount;
			if (this.state.selectedMachine !== 'quant') {
				plateTotalCount = solanaPlateTotalCount;
			}
			if (getPlateCellCount(plateData.cells) < plateTotalCount) {
				if (!plateData.cells) {
					plateData.cells = {};
				}
				let nextIndex = 0;
				while (1) {
					if (!plateData.cells[nextIndex]) {
						break;
					}
					if (plateData.test_id.name && plateData.test_id.name.toLowerCase().includes("strep")) {
						nextIndex = nextIndex + 2;
					} else {
						nextIndex++;
					}
				}
				const { row, column } = getRowColumnIndexForQuantPlate(nextIndex);
				let wellPosition = quantPlateRows[row] + quantPlateColumns[column];
				if (this.state.selectedMachine !== 'quant') {
					if (plateData.test_id.name && plateData.test_id.name.toLowerCase().includes("strep")) {
						wellPosition = (nextIndex + 1) + ", " + (nextIndex + 2);
					} else {
						wellPosition = nextIndex + 1;
					}
				}
				Swal.fire({
					title: "Place Sample ID " + orderInfo.orderSample + " in Well " + wellPosition + " of Plate " + plateData.name,
					text: orderInfo.patientName + "(" + orderInfo.patientBirthday + "), collected on " + orderInfo.orderCollected + " by " + orderInfo.collectorName + " from " + orderInfo.facilityName,
					icon: "question",
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: "OK",
					denyButtonText: 'Manually Plate',
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
						cancelButton: 'order-1',
						denyButton: 'order-2 btn_info',
						confirmButton: 'order-3 btn_primary',
					}
				}).then(result => {
					if (result.isConfirmed) {
						this.accession(orderInfo, plateData, nextIndex);
					} else if (result.isDenied) {
						this.setState({
							manualPlateData: plateData,
							manualOrderInfo: orderInfo,
							showSelectPlateWell: true
						})
					}
				});
			} else {
				toastr.warning("Selected " + (type === 'vip' ? "VIP" : "Regular") + " Plate doesn't have empty place!");
			}
		});
	}

	accession = (orderInfo, plateData, cellIndex) => {
		const userInfo = JSON.parse(getUserDetails());
		if (plateData.test_id.name && plateData.test_id.name.toLowerCase().includes("strep")) {
			plateData.cells[cellIndex] = {
				orderId: orderInfo.orderId,
				dateTime: moment().format("YYYYMMDDHHmmss"),
				is_control_spot: false,
				accessioned_by: userInfo.first_name + ' ' + userInfo.last_name,
				strep_tube: "GAS"
			};
			plateData.cells[cellIndex + 1] = {
				orderId: orderInfo.orderId,
				dateTime: moment().format("YYYYMMDDHHmmss"),
				is_control_spot: false,
				accessioned_by: userInfo.first_name + ' ' + userInfo.last_name,
				strep_tube: "CG"
			};
		} else {
			plateData.cells[cellIndex] = {
				orderId: orderInfo.orderId,
				dateTime: moment().format("YYYYMMDDHHmmss"),
				is_control_spot: false,
				accessioned_by: userInfo.first_name + ' ' + userInfo.last_name
			};
		}
		const accessionData = {
			plateId: plateData._id,
			cells: plateData.cells,
			accession_action: true,
			cell_index: cellIndex,
			orderId: orderInfo.orderId,
		}
		plateAccession(accessionData).then(res => {
			if (res.RESULT && res.RESULT === 'SUCCESS') {
				if (plateData.type === 'regular') {
					this.setState({
						regularCurrentIndex: cellIndex
					})
				} else {
					this.setState({
						vipCurrentIndex: cellIndex
					})
				}
				popupCenter(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + orderInfo.orderSample + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken(), orderInfo.orderSample, "800", "600")
			} else if (res.RESULT && res.RESULT === "ERROR" && res.REASON === "ORDER_ACCESSION_DUPLICATE") {
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: "That requisition is assigned to the plate already or another one is assigned to that position already",
					text: "We will refresh plates and requisitions data"
				})
				this.loadGridData();
				this.getPlates();
			}
			this.gridApi.setQuickFilter(null);
			document.getElementById("search-box").value = "";
			document.getElementById('search-box').focus();
		})
	}

	handleDateFiltersChange = (dateTime, type) => {
		let requisitionFilters = this.state.requisitionFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(requisitionFilters.to_date).isAfter(dateTime)) {
				requisitionFilters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(requisitionFilters.from_date)) {
				requisitionFilters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setState({ requisitionFilters: requisitionFilters })
			this.loadGridData();
		}
	}

	handleFiltersChange = (e) => {
		let requisitionFilters = this.state.requisitionFilters;
		if (e.target.name === 'resultValue') {
			requisitionFilters.resultValue = e.target.value;
		} else if (e.target.name === 'filterBy') {
			requisitionFilters.filterBy = e.target.value;
		}
		this.setState({ requisitionFilters: requisitionFilters })
		this.loadGridData();
	}

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilters = () => {
		let requisitionFilters = this.state.requisitionFilters;
		requisitionFilters.resultValue = "all";
		requisitionFilters.filterBy = "collected_date";
		requisitionFilters.from_date = moment().subtract(2, "days").format("YYYY-MM-DD") + 'T00:00';
		requisitionFilters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		this.setState({
			requisitionFilters: requisitionFilters
		})
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("search-box").value = "";
		this.loadGridData();
	}

	unAssignOrderFromPlate = (orderInfo, plateInfo, cellIndex) => {
		const orderUpdateData = {
			_id: orderInfo._id,
			is_assigned_to_plate: false,
			un_accession_action: true,
			plate_id: plateInfo._id,
		}
		let cells = plateInfo.cells;
		if (plateInfo.test_id.name && plateInfo.test_id.name.toLowerCase().includes("strep")) {
			delete cells[cellIndex];
			delete cells[cellIndex + 1];
		} else {
			delete cells[cellIndex];
		}
		let plateData = {
			_id: plateInfo._id,
			cellIndex: cellIndex,
			un_accession_action: true,
			testName: plateInfo.test_id.name
		};
		orderEasyUpdate(orderUpdateData).then(res => {
			updatePlate(plateData).then(res => {
				if (plateInfo.type === 'regular') {
					this.setState({
						regularCurrentIndex: null
					})
				} else {
					this.setState({
						vipCurrentIndex: null
					})
				}
			})
		})
	}

	showCreateRequisitionPopup = () => {
		if (this.state.allFacilities && this.state.allFacilities.length && this.state.allFacilities.filter(item => item.archived !== 'archived').length) {
			this.setState({
				showCreateRequisition: true
			})
		}
	}

	handleCloseCreateRequisition = () => {
		this.setState({
			showCreateRequisition: false
		})
	}

	finalizePlate = (type) => {
		let plateData;
		if (type === 'regular') {
			plateData = this.state.regularPlate
		} else {
			plateData = this.state.vipPlate
		}
		Swal.fire({
			title: "Would like to finalize accessioning to " + plateData.name + "?",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then(result => {
			if (result.isConfirmed) {
				const updateData = {
					_id: plateData._id,
					is_exported: true,
					export_action: true,
					exportedTime: moment().format("YYYYMMDDHHmmss")
				}
				updatePlate(updateData).then(res => {
				})
			}
		})
	}

	handleTestMachineTypeChange = (e) => {
		let filters = this.state.requisitionFilters;
		filters.resultValue = "all";
		this.setState({
			testMachineType: e.target.value,
			selectedTestId: e.target.value.split("|||")[2],
			selectedMachine: e.target.value.split("|||")[1].toLowerCase(),
			regularPlate: null,
			vipPlate: null,
			selectedPlate: null,
			manualPlateData: null,
			manualOrderInfo: null,
			vipCurrentIndex: null,
			regularCurrentIndex: null,
			requisitionFilters: filters
		}, () => {
			this.getPlates();
			this.loadGridData();
			this.gridApi.refreshCells();
		})
	}

	generateResultDocumentForPlate = () => {
		if (this.state.regularPlate) {
			generateResultDocument({ plateInfo: this.state.regularPlate }).then(res => {
				if (res && res.RESULT === 'SUCCESS' && res.fileName) {
					window.open(serviceConstants.HOST_NAME + "/public_files/generatedDocuments/" + res.fileName);
				}
			})
		} else {
			toastr.warning("Please select the plate to generate result document.")
		}
	}

	render() {
		return (
			<div className="lims-accessioning-page">
				<Breadcrumb
					showCreateRequisitionPopup={this.showCreateRequisitionPopup}
					generateResultDocumentForPlate={this.generateResultDocumentForPlate}
				/>
				<div className="row plate-section">
					<div className="col-md-4 regular-plate">
						{
							this.state.selectedMachine === 'quant'
							&&
							<QuantPlateMap
								type={'regular'}
								createNewPlate={this.createNewPlate}
								closePlate={this.closePlate}
								plateData={this.state.regularPlate}
								currentIndex={this.state.regularCurrentIndex}
								pageName={'accessioning'}
								unAssignOrderFromPlate={this.unAssignOrderFromPlate}
							/>
						}
						{
							this.state.selectedMachine === 'solana'
							&&
							<SolanaPlateMap
								type={'regular'}
								createNewPlate={this.createNewPlate}
								closePlate={this.closePlate}
								finalizePlate={this.finalizePlate}
								plateData={this.state.regularPlate}
								currentIndex={this.state.regularCurrentIndex}
								pageName={'solana_accessioning'}
								unAssignOrderFromPlate={this.unAssignOrderFromPlate}
							/>
						}
					</div>
					<div className="col-md-4 search-plate-section">
						<div className="form-group">
							<RadioGroup
								aria-label="testMachine"
								name="testMachineType"
								value={this.state.testMachineType}
								onChange={this.handleTestMachineTypeChange}
								style={{ flexDirection: "row", justifyContent: "center" }}
							>
								{
									this.state.testMachineTypes && this.state.testMachineTypes.length > 0
									&&
									this.state.testMachineTypes.map((testMachine) => {
										let temp = testMachine.split("|||");
										let machineName = temp[1];
										let testName = temp[0];
										return (
											<FormControlLabel
												key={testMachine}
												value={testMachine}
												control={<Radio />}
												label={testName + " (" + machineName + ")"}
											/>
										);
									})
								}
							</RadioGroup>
						</div>
						<div className="form-group">
							<Autocomplete
								options={this.state.allPlates}
								value={this.state.selectedPlate}
								onChange={(event, newValue) => {
									if (newValue) {
										this.selectPlate(newValue);
										this.setState({
											selectedPlate: newValue
										})
									} else {
										this.setState({
											selectedPlate: null
										})
									}
								}}
								renderInput={(params) => <TextField {...params} label="Please Select Plate (current plate is always saved)" variant="outlined" />}
								getOptionLabel={(option) => option ? (option.name + " (" + getPlateCellCount(option.cells) + ")") : ""}
							/>
						</div>
						<div className="form-group">
							<TextField
								label="Search Requisitions"
								InputLabelProps={{
									shrink: true,
								}}
								id="search-box"
								variant="outlined"
								onChange={this.onFilterTextChange}
							/>
						</div>
						<RadioGroup
							aria-label="result"
							name="resultValue"
							value={this.state.requisitionFilters.resultValue}
							onChange={this.handleFiltersChange}
							style={{ flexDirection: "row", justifyContent: "center" }}
						>
							{
								this.state.selectedMachine && this.state.selectedMachine === 'quant'
								&&
								requisitionOptionsForAccessioningQuant.map((res) => {
									return (
										<FormControlLabel
											key={res.code}
											value={res.code}
											control={<Radio />}
											label={res.value}
										/>
									);
								})
							}
							{
								this.state.selectedMachine && this.state.selectedMachine === 'solana'
								&&
								requisitionOptionsForAccessioningSolana.map((res) => {
									return (
										<FormControlLabel
											key={res.code}
											value={res.code}
											control={<Radio />}
											label={res.value}
										/>
									);
								})
							}
						</RadioGroup>
						<RadioGroup
							aria-label="filterBy"
							name="filterBy"
							value={this.state.requisitionFilters.filterBy}
							onChange={this.handleFiltersChange}
							style={{ flexDirection: "row", justifyContent: "center", borderTop: "1px solid #a1a1a1" }}
						>
							<FormControlLabel
								key='collected_date'
								value='collected_date'
								control={<Radio />}
								label='Collected Date'
							/>
							<FormControlLabel
								key='created_date'
								value='created_date'
								control={<Radio />}
								label='Created Date'
							/>
						</RadioGroup>
						<div className="form-group row">
							<div className="col-md-6">
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.temp_from_date : this.state.requisitionFilters.from_date}
									onChange={(dateTime) => this.setState({ temp_from_date: dateTime })}
									onOpen={() => this.setState({ temp_from_date: this.state.requisitionFilters.from_date, pickerOpened: true })}
									onClose={() => this.setState({ pickerOpened: false })}
									onAccept={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'from_date')}
									label="From Date"
									renderInput={props => <TextField
										{...props}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
							<div className="col-md-6">
								<MobileDateTimePicker
									value={this.state.pickerOpened ? this.state.temp_to_date : this.state.requisitionFilters.to_date}
									onChange={(dateTime) => this.setState({ temp_to_date: dateTime })}
									onOpen={() => this.setState({ temp_to_date: this.state.requisitionFilters.to_date, pickerOpened: true })}
									onClose={() => this.setState({ pickerOpened: false })}
									onAccept={(dateTime) => this.handleDateFiltersChange(dateTime.$d, 'to_date')}
									label="To Date"
									renderInput={props => <TextField
										{...props}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton edge="end">
														<EventIcon />
													</IconButton>
												</InputAdornment>
											)
										}}
									/>}
								/>
							</div>
						</div>
						<div className="form-group text-center">
							<button className="btn btn-primary" onClick={this.clearFilters}>
								<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
							</button>
						</div>
					</div>
					<div className="col-md-4 vip-plate">
						{
							this.state.selectedMachine === 'quant'
							&&
							<QuantPlateMap
								type={'vip'}
								createNewPlate={this.createNewPlate}
								closePlate={this.closePlate}
								plateData={this.state.vipPlate}
								currentIndex={this.state.vipCurrentIndex}
								pageName={'accessioning'}
								unAssignOrderFromPlate={this.unAssignOrderFromPlate}
							/>
						}
						{
							this.state.selectedMachine === 'solana'
							&&
							<SolanaPlateMap
								type={'vip'}
								createNewPlate={this.createNewPlate}
								closePlate={this.closePlate}
								finalizePlate={this.finalizePlate}
								plateData={this.state.vipPlate}
								currentIndex={this.state.vipCurrentIndex}
								pageName={'solana_accessioning'}
								unAssignOrderFromPlate={this.unAssignOrderFromPlate}
							/>
						}
					</div>
				</div>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 350px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
				<SelectPlateTemplate
					show={this.state.showSelectPlateTemplate}
					onHide={() => this.setState({ showSelectPlateTemplate: false })}
					createNewPlateWithTemplate={this.createNewPlateWithTemplate}
					allPlateTemplates={this.state.allPlateTemplates}
				/>
				<SelectPlateWell
					show={this.state.showSelectPlateWell}
					onHide={() => this.setState({ showSelectPlateWell: false })}
					manualAccession={this.accession}
					manualPlateData={this.state.manualPlateData}
					manualOrderInfo={this.state.manualOrderInfo}
					selectedMachine={this.state.selectedMachine}
				/>
				<CreateRequisition
					show={this.state.showCreateRequisition}
					handleClose={this.handleCloseCreateRequisition}
					context={this.state.context}
					testId={this.state.selectedTestId}
				/>
			</div>
		);
	}
}

export default LIMSAccessioningPage;
