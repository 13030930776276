import { apiInterfaceForFormData, apiInterfaceForJson } from "../common/apiManager";

export const importQuantResultDocument = (data) => {
    return apiInterfaceForJson("/quantStudioResultImport/v1/importResultDocument", "POST", data);
}

export const retestQuantResults = (data) => {
    return apiInterfaceForJson("/quantStudioResultImport/v1/retest", "POST", data);
}

export const releaseQuantResults = (data) => {
    return apiInterfaceForJson("/quantStudioResultImport/v1/release", "POST", data);
}

export const uploadQuantResultDocument = (formData) => {
    return apiInterfaceForFormData("/quantStudioResultImport/v1/uploadResultDocument/", "POST", formData);
};

export const fetchQuantResultDocumentByOrderId = (order_id) => {
    return apiInterfaceForFormData("/quantStudioResultImport/v1/searchOrders", "POST", { order_id: order_id });
}

export const getQuantPCData = (filter) => {
    return apiInterfaceForJson("/quantStudioResultImport/v1/getQuantPCData", "POST", filter);
}