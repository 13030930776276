import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import UrgencySearchbar from "./urgencySearchbar";
import moment from "moment";
import EditBtnCellRender from "./editBtnCellRender";
import CheckboxRenderer from "./checkboxRenderer";
import PatientBtnCellRenderer from "../../orders/clinicOrderGrid/patientBtnCellRenderer";
import FacilityBtnCellRenderer from "../../orders/clinicOrderGrid/facilityBtnCellRenderer";
import PhysicianBtnCellRenderer from "../../orders/clinicOrderGrid/physicianBtnCellRenderer";
import NotificationLogCellRenderer from "../../orders/clinicOrderGrid/notificationLogCellRenderer";
import RequisitionPdfRenderer from "../../requisitions/clinicRequisitionGrid/requisitionPdfRenderer";
import PdfResultRenderer from "../../orders/clinicOrderGrid/pdfResultRenderer";
import SymptomRenderer from "../../orders/clinicOrderGrid/symptomRenderer";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from 'lodash';
import {
	searchUrgencyOrders,
} from "../../../../services/clinicPortalServices/orderSearchService";
import { getUserRole, processCellForClipboard, defaultExcelExportParams } from "../../../../services/common/util";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicOrdersUrgency extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isArchived: false,
			facilityIds: [],
			companies: [],
			user_role: getUserRole(),
			userId: window.localStorage.getItem("USER_ID"),
			search_filters: {
				facility_id: [],
				from_date: moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				result_value: "all",
				dateRange: 'custom',
				filter_by: 'released_date'
			},
			columnDefs: [
				{
					headerName: 'Actions',
					children: [
						{
							headerName: "Actions",
							minWidth: 50,
							maxWidth: 100,
							resizable: true,
							cellStyle: { textAlign: "center" },
							cellRenderer: "editBtnCellRender",
						},
					]
				},
				{
					headerName: 'Order Basic Info',
					children: [
						{
							headerName: "Patient Name",
							minWidth: 200,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.patient_id) {
									return (
										params.data.patient_id.first_name +
										" " +
										params.data.patient_id.last_name
									);
								} else {
									return "";
								}
							},
							cellRenderer: "patientBtnCellRenderer"
						},
						{
							headerName: "Test",
							minWidth: 150,
							field: "test_id.name",
							columnGroupShow: 'open'
						},
						{
							headerName: "Test Type",
							minWidth: 150,
							field: "test_info.test_type",
							columnGroupShow: 'open'
						},
						{
							headerName: "Sample",
							minWidth: 150,
							field: "test_info.sample",
						},
						{
							headerName: "Result",
							field: "results.value",
							minWidth: 150,
							resizable: true,
							cellRenderer: "pdfResultRenderer",
						},
						{
							colId: "collected_date",
							headerName: "Specimen Collected Date",
							minWidth: 200,
							resizable: true,
							field: "test_info.collected",
							cellRenderer: function (params) {
								return params.data.test_info && params.data.test_info.collected ? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Facility Source",
							minWidth: 150,
							resizable: true,
							field: "facility_source",
							cellRenderer: "facilityBtnCellRenderer"
						},
						{
							headerName: "Physician",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.provider) {
									return (
										params.data.provider.first_name +
										" " +
										params.data.provider.last_name
									);
								} else {
									return "";
								}
							},
							cellRenderer: "physicianBtnCellRenderer"
						},
						{
							colId: "received_date",
							headerName: "Received Date",
							minWidth: 200,
							resizable: true,
							field: "test_info.received",
							cellRenderer: function (params) {
								return params.data.test_info && params.data.test_info.received ? moment(params.data.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							colId: "released_date",
							headerName: "Released Date",
							minWidth: 200,
							resizable: true,
							field: "results.released",
							cellRenderer: function (params) {
								return params.data.results.released ? moment(params.data.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Diagnosis Code",
							minWidth: 150,
							resizable: true,
							field: "diagnosis_code",
							valueGetter: function addColumns(params) {
								if (params.data.diagnosis_code && params.data.diagnosis_code.length) {
									return params.data.diagnosis_code.join(', ');
								} else {
									return "";
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Notification Log",
							minWidth: 180,
							field: "notification_state",
							cellRenderer: "notificationLogCellRenderer",
							resizable: true
						},
						{
							headerName: "Checked By Physician",
							field: "checked_by_physician",
							minWidth: 250,
							cellRenderer: "checkboxRenderer",
							resizable: true
						},
						{
							headerName: "Requisition",
							minWidth: 150,
							field: "lab_order_id",
							columnGroupShow: 'open'
						},
						{
							headerName: "Requisition PDF",
							minWidth: 150,
							field: "requisition_pdf_path",
							cellRenderer: "requisitionPdfRenderer",
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Patient Symptoms",
							resizable: true,
							minWidth: 150,
							field: "symptoms",
							cellStyle: { textAlign: "center" },
							cellRenderer: "symptomRenderer",
						},
						{
							headerName: "Is HL7?",
							resizable: true,
							minWidth: 150,
							field: "is_HL7_requisition",
							valueGetter: function addColumns(params) {
								return params.data.is_HL7_requisition ? "YES" : "";
							},
						},
						{
							headerName: "Is VIP Order?",
							resizable: true,
							minWidth: 150,
							field: "is_vip_order",
							valueGetter: function addColumns(params) {
								return params.data.is_vip_order ? "YES" : "NO";
							},
						},
					]
				},
				{
					headerName: 'Insurance Information',
					children: [
						{
							headerName: 'YES/NO',
							resizable: true,
							minWidth: 100,
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id._id) {
									return "YES";
								} else {
									return "NO";
								}
							}
						},
						{
							headerName: 'Insurance Provider',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insurance_provider",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Member ID',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_member_id",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Group Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_group_number",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Relation to Insured',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.relation_to_insured",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Holder Name',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_first_name",
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.insured_first_name) {
									return params.data.patient_insurance_id.insured_first_name + " " + params.data.patient_insurance_id.insured_last_name;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Social Security Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.social_security_number",
							columnGroupShow: 'open'
						},
					]
				}
			],
			components: {
				editBtnCellRender: EditBtnCellRender,
				checkboxRenderer: CheckboxRenderer,
				pdfResultRenderer: PdfResultRenderer,
				requisitionPdfRenderer: RequisitionPdfRenderer,
				facilityBtnCellRenderer: FacilityBtnCellRenderer,
				patientBtnCellRenderer: PatientBtnCellRenderer,
				physicianBtnCellRenderer: PhysicianBtnCellRenderer,
				notificationLogCellRenderer: NotificationLogCellRenderer,
				symptomRenderer: SymptomRenderer
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			facilities: [],
			tests: [],
			testIds: []
		};
		getAllTests({ type: "all" }).then(res => {
			if (res && res.data) {
				let testIds = [];
				res.data.map(test => {
					if (test.name && test.name.toLowerCase().includes("sars-cov-2")) {
						testIds.push(test._id);
					}
					return null;
				})
				this.testIds = testIds;
				this.setState({ tests: res.data.filter(test => test.name && test.name.toLowerCase().includes("sars-cov-2")), testIds: testIds }, () => {
					this.loadFacilities(this.state.isArchived);
				})
			}
		})
	}

	loadFacilities = (isArchived) => {
		let defaultFilters = this.state.search_filters;
		let facilities = this.state.facilities;
		let facilityIds = [];

		let isAllFacilities = false;
		if (defaultFilters.facility_id && defaultFilters.facility_id.length > 0 && defaultFilters.facility_id.length === facilities.length && facilities.length > 0) {
			isAllFacilities = true;
		}

		getCompanyWithFacility(isArchived)
			.then((response) => {
				let facilities = response.data;
				if (facilities.length > 0) {
					facilities.map(fac => {
						facilityIds.push(fac._id);
						return null;
					})
					defaultFilters.facility_id = facilityIds;
				} else {
					defaultFilters.facility_id = facilityIds;
				}
				if (!isAllFacilities && window.localStorage.getItem('FACILITY_ID') && JSON.parse(window.localStorage.getItem('FACILITY_ID')).length) {
					defaultFilters.facility_id = JSON.parse(window.localStorage.getItem('FACILITY_ID'));
				}
				this.setState({
					facilities: facilities,
					search_filters: defaultFilters,
					facilityIds: facilityIds
				}, () => {
					this.loadGridData();
				});
			});
	}

	checkRealFacilities = (searchFilters) => {
		let realFacilities = [];
		searchFilters.facility_id.map(facilityId => {
			if (this.state.facilityIds.includes(facilityId)) {
				realFacilities.push(facilityId);
			}
			return null;
		});
		return realFacilities;
	}

	loadGridData = () => {
		let searchFilters = this.state.search_filters;
		searchFilters.facility_id = this.checkRealFacilities(searchFilters)
		searchFilters.testIds = this.state.testIds;
		this.gridApi.showLoadingOverlay();
		searchUrgencyOrders(searchFilters)
			.then((response) => {
				this.setState({ rowData: response.data });
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
				this.sortByColumn(searchFilters.filter_by, 'desc');
			});
	};

	onIsArchivedInformation = (event) => {
		this.setState({ isArchived: event.target.checked });
		this.loadFacilities(event.target.checked);
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	sortByColumn = (type, sortType) => {
		this.gridColumnApi.applyColumnState({
			state: [
				{
					colId: type,
					sort: sortType,
				},
			],
			defaultState: { sort: null },
		});
	}

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	handleFiltersChange = (e) => {
		let { facilityIds, facilities } = this.state;
		let filters = this.state.search_filters;
		switch (e.target.name) {
			case "result_value": {
				filters.result_value = e.target.value;
				break;
			}
			case "filter_by": {
				filters.filter_by = e.target.value;
				break;
			}
			case "facility_id": {
				let facilityIdChanged = [], isAdded = false, facilitiesIn = [], facilityIdsTemp = [];
				let newFacilityIds = e.target.value;
				if (e.target.value.includes('all')) {
					if (filters.facility_id.length === facilityIds.length) {
						filters.facility_id = [];
					} else {
						filters.facility_id = _.cloneDeep(facilityIds);
					}
				} else {
					if (filters.facility_id.length > newFacilityIds.length) {
						facilityIdChanged = filters.facility_id.find(val => !newFacilityIds.includes(val));
					} else {
						facilityIdChanged = newFacilityIds.find(val => !filters.facility_id.includes(val));
						isAdded = true;
					}
					let facilityChanged = facilities.find(facility => facility._id === facilityIdChanged);
					facilityIdsTemp = _.cloneDeep(filters.facility_id);
					// If company is changed 
					if (facilityChanged.type === 'company') {
						let companyChanged = facilityChanged;
						facilities.forEach(fac => {
							if (fac.parent_id === companyChanged._id) {
								facilitiesIn.push(fac._id);
							}
						});
						let index = facilityIdsTemp.indexOf(companyChanged._id);
						if (facilitiesIn.length > 0) {
							// If company is selected
							if (isAdded) {
								// Add company Id
								facilityIdsTemp.push(companyChanged._id);
								// Add facility Ids
								facilitiesIn.forEach(facId => {
									if (!facilityIdsTemp.includes(facId)) {
										facilityIdsTemp.push(facId);
									}
								});
							} else { // If company is unselected
								// Remove company Id
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
								facilitiesIn.forEach(facId => {
									let index = facilityIdsTemp.indexOf(facId);
									if (index > -1) {
										facilityIdsTemp.splice(index, 1);
									}
								});
							}
						} else {
							isAdded ? facilityIdsTemp.push(companyChanged._id) : facilityIdsTemp.splice(index, 1);
						}
					} else { // If facility is changed
						let companyId = facilityChanged.parent_id;
						let facilitiesIn = [];
						facilities.forEach(fac => {
							if (fac.parent_id === companyId) {
								facilitiesIn.push(fac._id);
							}
						});
						// If facility Id is selected
						if (isAdded) {
							// Add facility Id
							facilityIdsTemp.push(facilityChanged._id);
							if (!filters.facility_id.includes(companyId)) {
								facilityIdsTemp.push(companyId);
							}
						} else { // If facility Id is unselected
							// Remove facility Id
							let index = facilityIdsTemp.indexOf(facilityChanged._id);
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
							// Remove company Id
							let facilitiesInFilters = [];
							facilitiesIn.forEach(fac => {
								if (facilityIdsTemp.includes(fac)) {
									facilitiesInFilters.push(fac);
								}
							});
							if (facilitiesInFilters.length === 0) {
								index = facilityIdsTemp.indexOf(companyId);
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
							}
						}
					}
					filters.facility_id = _.cloneDeep(facilityIdsTemp);
					window.localStorage.setItem('FACILITY_ID', JSON.stringify(filters.facility_id));
				}
				break;
			}
			case "testIds": {
				let testIds = this.state.testIds;
				if (e.target.value.includes('all')) {
					if (testIds.length === this.state.tests.length) {
						testIds = [];
					} else {
						testIds = _.cloneDeep(this.testIds);
					}
				} else {
					testIds = e.target.value;
				}
				this.setState({ testIds: testIds });
				break;
			}
			default: break;
		}
		this.setState({ search_filters: filters }, () => {
			this.loadGridData();
		});
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
	};

	updateDateRange = (dateRange) => {
		let filters = this.state.search_filters;
		if (filters.dateRange !== dateRange) {
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				default: break;
			}
			this.setState({ search_filters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData();
			}
		}

	}

	setDateRange = (searchFilter) => {
		if (moment(searchFilter.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(searchFilter.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				searchFilter.dateRange = 'today';
			} else if (moment(searchFilter.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				searchFilter.dateRange = 'week';
			} else if (moment(searchFilter.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				searchFilter.dateRange = 'month';
			} else {
				searchFilter.dateRange = 'custom';
			}
		} else {
			searchFilter.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		const filters = this.state.search_filters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(filters)
			this.setState({ search_filters: filters });
			this.loadGridData();
		}
	}

	clearFilter = () => {
		let filters = this.state.search_filters;
		filters.facility_id = this.state.facilityIds;
		filters.from_date = moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.result_value = 'all';
		filters.filter_by = 'received_date';

		window.localStorage.removeItem("FACILITY_ID");
		window.localStorage.removeItem("FROM_DATE_RANGE");
		window.localStorage.removeItem("TO_DATE_RANGE");

		this.setState({ search_filters: filters, testIds: this.testIds })
		this.loadGridData();
	};

	render() {
		const { facilities, search_filters } = this.state;
		let columnDefs = this.state.columnDefs;

		if ((/salesperson|salesadmin|labtech/.test(this.state.user_role.toLowerCase()))) {
			const childDefs = this.state.columnDefs[1].children.filter(x => x.headerName.toLowerCase() !== 'checked by physician');
			columnDefs[1].children = childDefs;
			columnDefs = columnDefs.filter(x => x.headerName !== 'Actions');
		}
		return (
			<div className="clinic-contain">
				<UrgencySearchbar
					onIsArchivedInformation={(event) => { this.onIsArchivedInformation(event) }}
					isArchived={this.state.isArchived}
					facilities={facilities}
					facilityId={search_filters.facility_id}
					from_date={search_filters.from_date}
					to_date={search_filters.to_date}
					date_range={search_filters.dateRange}
					result_value={search_filters.result_value}
					filter_by={search_filters.filter_by}
					onFilterTextChange={this.onFilterTextChange}
					handleFiltersChange={this.handleFiltersChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					user_role={this.state.user_role}
					tests={this.state.tests}
					testIds={this.state.testIds}
				/>
				<div
					style={{
						width: "100%",
						height: "100vh",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									processCellForClipboard={processCellForClipboard}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									defaultExcelExportParams={defaultExcelExportParams}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		)
	}
}

export default ClinicOrdersUrgency