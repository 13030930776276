import React, { Component } from "react";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { getCellIndexForQuantPlate, getRowColumnIndexForQuantPlate, getPlateCellCount } from "../../../services/common/util";
import { Autocomplete, TextField, } from "@mui/material";
import { createPlateTemplate, updatePlateTemplate } from "../../../services/limsPortalServices/plateTemplateService";
import Swal from "sweetalert2";
import { quantPlateRows, quantPlateColumns } from "../../../services/common/optionsData";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class PlateTemplateDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showInputPopup: false,
			controlSpotType: null,
			newControlSpotType: null,
			plateTemplateData: {
				control_spots: {}
			},
			plateTemplateId: '',
			cellColumn: '',
			cellRow: '',
			errors: []
		};
	}

	componentDidMount() {
		if (this.props.plateTemplateId) {
			this.setState({
				plateTemplateId: this.props.plateTemplateId,
				plateTemplateData: this.props.plateTemplateData
			})
		} else {
			this.setState({
				plateTemplateData: this.props.plateTemplateData
			})
		}
	}

	handleShowInputPopup = (cellIndex, data) => {
		const { row, column } = getRowColumnIndexForQuantPlate(cellIndex);
		let controlSpotType, newControlSpotType;
		if (data) {
			if (['NC', 'PC', 'NTC'].includes(data)) {
				controlSpotType = data;
			} else {
				newControlSpotType = data;
			}
		} else {
			controlSpotType = '';
			newControlSpotType = '';
		}
		this.setState({
			showInputPopup: true,
			cellRow: row,
			cellColumn: column,
			controlSpotType: controlSpotType,
			newControlSpotType: newControlSpotType
		});
	}

	addControlSpot = () => {
		const index = getCellIndexForQuantPlate(this.state.cellRow - 1, this.state.cellColumn - 1);
		let plateTemplateData = this.state.plateTemplateData;
		plateTemplateData.control_spots[index] = this.state.newControlSpotType ? this.state.newControlSpotType : this.state.controlSpotType;
		this.setState({
			plateTemplateData: plateTemplateData,
			showInputPopup: false
		})
	}

	handleClose = () => {
		this.props.handleClose();
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	saveChanges = () => {
		Swal.fire({
			title: "Please confirm",
			text: "This template has " + getPlateCellCount(this.state.plateTemplateData.control_spots) + " control spots, it is ok to save?",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "Cancel",
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then(result => {
			if (result.isConfirmed) {
				if (!this.state.plateTemplateId) {
					const data = {
						control_spots: this.state.plateTemplateData.control_spots
					}
					createPlateTemplate(data).then(res => {
						this.handleClose();
						this.props.context.componentParent.loadGridData();
					})
				} else {
					const data = {
						_id: this.state.plateTemplateId,
						control_spots: this.state.plateTemplateData.control_spots
					}
					updatePlateTemplate(data).then(res => {
						this.handleClose();
						this.props.context.componentParent.loadGridData();
					})
				}
			}
		})
	}

	renderTooltip = (props) => {
		<Tooltip id="button-tooltip" {...props} >
			Control Spot
		</Tooltip>
	}

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12">
							<div className="form-group">
								<div className="plate-table big-cell d-flex justify-content-center">
									<table>
										<tbody>
											{
												quantPlateRows.map((row, index) => {
													return <tr key={index}>
														{
															quantPlateColumns.map((column, i) => {
																let cellIndex = null;
																let className = '';
																if (i >= 1 && index >= 1) {
																	cellIndex = getCellIndexForQuantPlate(index - 1, i - 1);
																}
																if (this.state.plateTemplateData && this.state.plateTemplateData.control_spots && this.state.plateTemplateData.control_spots[cellIndex]) {
																	className = 'assigned-cell'
																}
																if (i !== 0 && index !== 0) {
																	className += ' cell'
																} else {
																	className += ' constant'
																}
																return <td className={className} onClick={!className.includes('constant') ? (() => this.handleShowInputPopup(cellIndex, this.state.plateTemplateData.control_spots[cellIndex])) : null} key={i}>
																	{(i === 0 && index !== 0) ? row : ""}
																	{(i !== 0 && index === 0) ? column : ""}
																	{(i !== 0 && index !== 0 && this.state.plateTemplateData.control_spots[cellIndex]) ? this.state.plateTemplateData.control_spots[cellIndex] : ""}
																</td>
															})
														}
													</tr>
												})
											}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-12"
							style={{
								paddingTop: "10px",
								borderTop: "1px solid rgba(0,0,0,.2",
							}}
						>
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.saveChanges}
								disabled={getPlateCellCount(this.state.plateTemplateData.control_spots) ? false : true}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showInputPopup}
					onHide={() => { this.setState({ showInputPopup: false }) }}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							Control Spot {quantPlateRows[this.state.cellRow]}{quantPlateColumns[this.state.cellColumn]}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="row">
								<div className="col-md-12">
									<Autocomplete
										options={['NC', 'PC', 'NTC']}
										onChange={(event, newValue) => {
											this.setState({ controlSpotType: newValue })
										}}
										value={this.state.controlSpotType}
										renderInput={(params) => <TextField {...params} label="Please Select" variant="outlined" />}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12 text-center">
									OR
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<TextField
										label="New Control Spot: Example (PC1)"
										variant="outlined"
										onChange={this.handleChange}
										name="newControlSpotType"
										value={this.state.newControlSpotType}
										inputProps={{ maxLength: 5 }}
									/>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={() => { this.setState({ showInputPopup: false }) }}
						>
							Close
						</Button>
						<Button
							style={{ float: "right", marginLeft: "10px" }}
							variant="primary"
							onClick={this.addControlSpot}
							disabled={(this.state.controlSpotType || this.state.newControlSpotType) ? false : true}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
