import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { getUserAuthToken } from "../../../../services/common/util";
import { serviceConstants } from "../../../../services/common/constants";
import { ModalStyled } from '../../../../theme/customizedStyleComponents';


class ScreenShotCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    handleShowImage = () => {
        this.setState({ show: true });
    }

    handleHideImage = () => {
        this.setState({ show: false });
    }

    render() {
        return (
            <div>
                <div onClick={this.handleShowImage} className="text-center" style={{ cursor: "pointer" }}>
                    {
                        this.props.data.screenfile
                        &&
                        <i className="fa fa-image"></i>
                    }
                </div>

                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show}
                    onHide={this.handleHideImage}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Screenshot</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row form-row">
                                <div className="col-12 text-center">
                                    <img className="m-auto" src={serviceConstants.API_HOST_NAME + this.props.data.screenfile + '?' + new Date().getTime() + '&token=' + getUserAuthToken()} alt="" />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleHideImage}>
                            Close
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </div>
        )
    }
}

export default ScreenShotCellRenderer