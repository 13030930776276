import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PhysicianDetails from "../../physician/clinicPhysicianGrid/physicianDetails";
import { getUserRole } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class PhysicianBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			// physicianId: props.data.provider._id,
			physicianNpi: props.data.provider.npi,
		};
	}

	handleShow = () => {
		if (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					{this.props.data.provider.first_name + ' ' + this.props.data.provider.last_name}
				</button>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Physician Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PhysicianDetails
							physicianId={this.state.physicianId}
							physicianNpi={this.state.physicianNpi}
							context={this.props.context}
							handleClose={this.handleClose}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
