import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { patientSignup } from "../../../../services/patientSignupServices/patientSignupFormService";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import moment from "moment";
import { identity, ethnicity, race, relation, states } from "../../../../services/common/optionsData";
import { MobileDatePicker } from '@mui/x-date-pickers';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel, Checkbox, MenuItem, Autocomplete, TextField, IconButton, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { phoneNumberFormatter, checkValidityLicense } from "../../../../services/common/util";
import Swal from "sweetalert2";
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class CreatePatient extends Component {
	constructor(props) {
		super(props);
		this.state = {
			facilities: [],
			facilityId: "",
			facilityName: "",
			step: "selectPatient",
			patientFirstName: "",
			patientLastName: "",
			gender: "U",
			phoneNumber: "",
			email: "",
			is_pregnant: false,
			dateOfBirth: moment().format("MM/DD/YYYY"),
			ethnicity: "",
			race: "",
			address1: "",
			address2: "",
			state: "",
			city: "",
			zip: "",
			passport: "",
			customInfo: "",
			errors: [],
			insuranceId: "",
			insuranceProv1: "",
			insuranceProv2: "",
			memberId: "",
			groupNumber: "",
			relation: "",
			insuredFirstName: "",
			insuredLastName: "",
			driverLicense: "",
			driverLicenseState: "",
			socialSecurityNumber: "",
			saving: false,
			showDatePickerError: false,
			insuranceProviders: []
		}
	}

	componentDidMount() {
		getAllInsuranceProviders().then(res => {
			this.setState({ insuranceProviders: ['Self/Pay', 'Other', ...res.data.filter(item => item.name !== "NO INSURANCE").map(item => { return item.name; })] });
		})
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	handleChange = (e) => {
		if (e.target.name === 'phoneNumber') {
			this.setState((prevState) => ({
				phoneNumber: phoneNumberFormatter(e.target.value, prevState.phoneNumber),
			}));
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
		if (e.target.name === 'facilityId') {
			const facility = this.state.facilities.filter(fac => fac._id === e.target.value)[0];
			this.setState({
				facilityName: facility.name,
			})
		}
		if (this.state.errors.includes(e.target.name) && e.target.value) {
			let errors = [];
			if (/socialSecurityNumber|driverLicense/.test(e.target.name) && e.target.value) {
				errors = this.state.errors.filter(error => !/socialSecurityNumber|driverLicense/.test(error));
			} else {
				errors = this.state.errors.filter(error => error !== e.target.name);
			}
			this.setState({ errors: errors });
		}
	}

	togglePregnancy = () => {
		this.setState({ is_pregnant: !this.state.is_pregnant });
	}

	handleDateFiltersChange = (dateTime, type) => {
		if (type === "dateOfBirth") {
			this.setState({ dateOfBirth: dateTime });
			if (moment(dateTime).format("YYYYMMDD") !== moment().format("YYYYMMDD")) {
				this.setState({ showDatePickerError: false });
			}
		}
	}

	handleClose = () => {
		this.props.handleClose();
		boundActions.endBlockUILoading();
		this.setState({
			facilities: [],
			facilityId: "",
			facilityName: "",
			step: "selectPatient",
			errors: [],
			patientFirstName: "",
			patientLastName: "",
			gender: "U",
			phoneNumber: "",
			email: "",
			is_pregnant: false,
			dateOfBirth: moment().format("MM/DD/YYYY"),
			ethnicity: "",
			race: "",
			address1: "",
			address2: "",
			state: "",
			city: "",
			zip: "",
			passport: "",
			customInfo: "",
			insuranceId: "",
			insuranceProv1: "",
			insuranceProv2: "",
			memberId: "",
			groupNumber: "",
			relation: "",
			insuredFirstName: "",
			insuredLastName: "",
			driverLicense: "",
			driverLicenseState: "",
			socialSecurityNumber: "",
			saving: false,
			showDatePickerError: false,
		});
	}

	handleBack = () => {
		if (this.state.step === 'selectPatient') {
			this.handleClose();
		} else if (this.state.step === 'afterPatient') {
			this.setState({ step: "selectPatient" });
		}
	}

	checkValidSecurityNumber = () => {
		let isValidSecurityNumber = checkValidityLicense(this.state.socialSecurityNumber);
		if (!isValidSecurityNumber && this.state.socialSecurityNumber) {
			Swal.fire({
				title: 'Invalid Social Security Number',
				text: "This looks like an invalid social security number.  It is required for using the " + this.state.insuranceProv1 + ".  Please ensure it is entered correctly.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Try again',
				cancelButtonText: 'Allow it',
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
					cancelButton: 'order-1',
					confirmButton: 'order-2'
				}
			}).then((result) => {
				if (!result.isConfirmed) {
					this.createNewPatient();
				}
			});
		}
		else {
			this.createNewPatient();
		}
	}
	checkValidDriverLicense = () => {
		let isValidDriverLicense = checkValidityLicense(this.state.driverLicense);
		if (!isValidDriverLicense && this.state.driverLicense) {
			Swal.fire({
				title: 'Invalid Driver License',
				text: "This looks like an invalid drivers license. A Drivers License is required to accept insurance. Without the drivers license the order may not be able to be processed by the billing company, in which case we will inform you of the error and you can correct it at that time.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Try again',
				cancelButtonText: 'Allow it',
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
					cancelButton: 'order-1',
					confirmButton: 'order-2'
				}
			}).then((result) => {
				if (!result.isConfirmed) {
					this.checkValidSecurityNumber();
				}
			});
		} else {
			this.checkValidSecurityNumber();
		}
	}

	handleNext = () => {
		if (this.state.step === 'selectPatient') {
			let errors = [];
			if (!this.state.patientFirstName) {
				errors.push("patientFirstName");
			}
			if (!this.state.patientLastName) {
				errors.push("patientLastName");
			}
			if (!this.state.phoneNumber) {
				errors.push("phoneNumber");
			}
			if (!this.state.address1) {
				errors.push("address1");
			}
			if (!this.state.city) {
				errors.push("city");
			}
			if (!this.state.state) {
				errors.push("state");
			}
			if (!this.state.zip) {
				errors.push("zip");
			}
			if (!this.state.ethnicity) {
				errors.push("ethnicity");
			}
			if (!this.state.race) {
				errors.push("race");
			}
			if (moment(this.state.dateOfBirth).format("YYYYMMDD") === 'Invalid date') {
				errors.push('dateOfBirth');
			}
			if (errors.length) {
				this.setState({ errors: errors });
			} else {
				if (moment(this.state.dateOfBirth).format("YYYYMMDD") === moment().format('YYYYMMDD')) {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: 'Birthday Error',
						text: "Birthdate can not be today's date.",
						icon: 'warning',
						confirmButtonText: 'Close'
					}).then((result) => {
						this.setState({ showDatePickerError: true });
						return;
					})
					return;
				}
				this.setState({ step: "afterPatient" });
			}
		} else if (this.state.step === 'afterPatient') {
			let errors = [];
			if (this.state.insuranceProv1) {
				if (this.state.insuranceProv1 === 'Other' && !this.state.insuranceProv2) {
					errors.push("insuranceProv2");
				}
				if (this.state.insuranceProv1 !== "Self/Pay") {
					if (!this.state.memberId) {
						errors.push("memberId");
					}
					if (!this.state.groupNumber) {
						errors.push("groupNumber");
					}
					if (!this.state.relation) {
						errors.push("relation");
					}
					if (this.state.relation !== "Self" && !this.state.insuredFirstName) {
						errors.push("insuredFirstName");
					}
					if (this.state.relation !== "Self" && !this.state.insuredLastName) {
						errors.push("insuredLastName");
					}
				}
			}
			if (errors.length) {
				this.setState({ errors: errors });
			} else {
				this.checkValidDriverLicense();
			}
		}
	}

	createNewPatient = () => {
		// create patient and insurance
		const patientInfo = {
			firstName: this.state.patientFirstName,
			lastName: this.state.patientLastName,
			email: this.state.email,
			phone: this.state.phoneNumber,
			sex: this.state.gender,
			dob: moment(this.state.dateOfBirth).format("YYYY-MM-DD"),
			ethnicity: this.state.ethnicity,
			race: this.state.race,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			passport: this.state.passport,
			custom_info: this.state.customInfo,
			facilityId: this.state.facilityId,
			is_pregnant: this.state.is_pregnant,
			notify: false,
			insuranceProv1: this.state.insuranceProv1,
			insuranceProv2: this.state.insuranceProv2,
			memberId: this.state.memberId,
			groupNumber: this.state.groupNumber,
			relation: this.state.relation,
			insuranceId: this.state.insuranceId,
			insuredFirstName: this.state.insuredFirstName,
			insuredLastName: this.state.insuredLastName,
			driverLicense: this.state.driverLicense,
			driverLicenseState: this.state.driverLicenseState,
			socialSecurityNumber: this.state.socialSecurityNumber,
		};
		this.setState({ saving: true });
		patientSignup(patientInfo).then((response) => {
			if (response.data && response.data.length) {
				this.setState({ saving: false });
				const facilityName = this.state.facilityName;
				const patientName = this.state.patientFirstName + ' ' + this.state.patientLastName;
				this.handleClose();
				this.props.loadGridData();
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: 'Patient Created',
					text: `Patient ${patientName} assigned to (${facilityName}) was created.`,
					icon: 'success'
				});
			}
		});
	}

	loadDataForModal = () => {
		boundActions.startBlockUILoading();
		fetchFacilitiesForOrders().then(res => {
			boundActions.endBlockUILoading();
			this.setState({
				facilities: res.data,
				facilityId: res.data[0]._id,
				facilityName: res.data[0].name
			})
		});
	};

	render() {
		return (
			<ModalStyled
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.handleClose}
				onEnter={this.loadDataForModal}
				backdrop="static"
				dialogClassName="removeTabIndexModal"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.state.step === 'selectPatient' && "Create Patient"}
						{this.state.step === 'afterPatient' && "Create Patient Insurance"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form>
						{
							this.state.step === 'selectPatient'
							&&
							<div className="row form-row">
								<div className="col-12 col-md-6">
									<div className="form-group">
										<FormLabel>
											First Name <abbr className="text-danger">*</abbr>
										</FormLabel>
										<input
											type="text"
											name="patientFirstName"
											value={this.state.patientFirstName}
											onChange={this.handleChange}
											className={this.hasError("patientFirstName") ? "form-control is-invalid" : "form-control"}
										/>
										<div
											className={
												this.hasError("patientFirstName") ? "inline-errormsg" : "hidden"
											}
										>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<FormLabel>
											Last Name <abbr className="text-danger">*</abbr>
										</FormLabel>
										<input
											type="text"
											name="patientLastName"
											value={this.state.patientLastName}
											onChange={this.handleChange}
											className={this.hasError("patientLastName") ? "form-control is-invalid" : "form-control"}
										/>
										<div
											className={
												this.hasError("patientLastName") ? "inline-errormsg" : "hidden"
											}
										>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Facility <abbr className="text-danger">*</abbr>
												</FormLabel>
												<select
													required
													type="text"
													name="facilityId"
													value={this.state.facilityId}
													onChange={this.handleChange}
													className="form-control"
												>
													{
														this.state.facilities
														&&
														this.state.facilities.map((fac) => {
															return (
																<option value={fac._id} key={fac._id}>
																	{fac.name} {fac.archived === "archived" ? " ( Archived ) " : ""}
																</option>
															);
														})
													}
												</select>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel sx={{ marginBottom: 0 }}>
													Date of Birth <abbr className="text-danger">*</abbr>
												</FormLabel>
												<MobileDatePicker
													value={this.state.dateOfBirth}
													onChange={(dateTime) => this.handleDateFiltersChange(dateTime.$d, "dateOfBirth")}
													maxDate={new Date()}
													renderInput={props => <TextField
														{...props}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton edge="end">
																		<EventIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
														margine="none"
													/>}
												/>
												{
													this.state.showDatePickerError
													&&
													<div className="inline-errormsg" >
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;Please select correctly
														</i>
													</div>
												}
												<div
													className={
														this.hasError("dateOfBirth") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is incorrect.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group" style={{ marginTop: "24px" }}>
												<MenuItem
													onClick={this.togglePregnancy}
												>
													<Checkbox checked={this.state.is_pregnant} />
													<div style={{ whiteSpace: "break-spaces" }}>Pregnancy</div>
												</MenuItem>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Gender <abbr className="text-danger">*</abbr>
												</FormLabel>
												<select
													className="form-control select"
													name="gender"
													value={this.state.gender}
													onChange={this.handleChange}
												>
													{identity.map((identity, index) => {
														return (
															<option key={index} value={identity.value}>
																{identity.gender}
															</option>
														);
													})}
												</select>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Phone Number <abbr className="text-danger">*</abbr>
												</FormLabel>
												<input
													type="text"
													name="phoneNumber"
													value={this.state.phoneNumber}
													onChange={this.handleChange}
													className={this.hasError("phoneNumber") ? "form-control is-invalid" : "form-control"}
												/>
												<div
													className={
														this.hasError("phoneNumber") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Email
												</FormLabel>
												<input
													type="text"
													name="email"
													value={this.state.email}
													onChange={this.handleChange}
													className={this.hasError("email") ? "form-control is-invalid" : "form-control"}
												/>
												<div
													className={
														this.hasError("email") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Address1 <abbr className="text-danger">*</abbr>
												</FormLabel>
												<input
													type="text"
													name="address1"
													value={this.state.address1}
													onChange={this.handleChange}
													className={this.hasError("address1") ? "form-control is-invalid" : "form-control"}
												/>
												<div
													className={
														this.hasError("address1") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Address2
												</FormLabel>
												<input
													type="text"
													name="address2"
													value={this.state.address2}
													onChange={this.handleChange}
													className={this.hasError("address2") ? "form-control is-invalid" : "form-control"}
												/>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													City <abbr className="text-danger">*</abbr>
												</FormLabel>
												<input
													type="text"
													name="city"
													value={this.state.city}
													onChange={this.handleChange}
													className={this.hasError("city") ? "form-control is-invalid" : "form-control"}
												/>
												<div
													className={
														this.hasError("city") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													State <abbr className="text-danger">*</abbr>
												</FormLabel>
												<select
													name="state"
													value={this.state.state}
													onChange={this.handleChange}
													className="form-control select"
												>
													{
														states.map((state) => {
															return (
																<option key={state.value} value={state.value}>
																	{state.state}
																</option>
															)
														})
													}
												</select>
												<div
													className={
														this.hasError("state") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Zip Code <abbr className="text-danger">*</abbr>
												</FormLabel>
												<input
													type="text"
													name="zip"
													value={this.state.zip}
													onChange={this.handleChange}
													className={this.hasError("zip") ? "form-control is-invalid" : "form-control"}
												/>
												<div
													className={
														this.hasError("zip") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>

										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													What is your ethnicity? <abbr className="text-danger">*</abbr>
												</FormLabel>
												<select
													required
													type="text"
													name="ethnicity"
													value={this.state.ethnicity}
													onChange={this.handleChange}
													className="form-control select"
												>
													{ethnicity.map((item) => {
														return (
															<option
																key={item.value}
																value={item.value}
															>
																{item.desc}
															</option>
														);
													})}
												</select>
												<div
													className={
														this.hasError("ethnicity") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;This field is required.
													</i>
												</div>
											</div>
										</div>

										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Passport
												</FormLabel>
												<input
													type="text"
													name="passport"
													value={this.state.passport}
													onChange={this.handleChange}
													className={this.hasError("passport") ? "form-control is-invalid" : "form-control"}
												/>
											</div>
										</div>
										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormLabel>
													Custom Info
												</FormLabel>
												<input
													type="text"
													name="customInfo"
													value={this.state.customInfo}
													onChange={this.handleChange}
													className={this.hasError("customInfo") ? "form-control is-invalid" : "form-control"}
												/>
											</div>
										</div>

										<div className="col-12 col-md-4">
											<div className="form-group">
												<FormControl>
													<FormLabel>
														What is your race? <abbr className="text-danger">*</abbr>
													</FormLabel>
													<div
														className={
															this.hasError("race") ? "inline-errormsg" : "hidden"
														}
													>
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;This field is required.
														</i>
													</div>
													<RadioGroup
														aria-label="Race *"
														name="race"
														value={this.state.race}
														onChange={this.handleChange}
														style={{ flexDirection: "column" }}
													>
														{race.map((race) => {
															return (
																<FormControlLabel
																	key={race.value}
																	value={race.value}
																	control={<Radio />}
																	label={race.value}
																/>
															);
														})}
													</RadioGroup>
												</FormControl>
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						{
							this.state.step === 'afterPatient'
							&&
							<div className="row form-row">
								<div className="col-12">
									<div className="row form-row">
										<div className="col-12 col-md-6">
											<div className="form-group">
												<FormLabel>
													Choose your Insurance Provider <abbr className="text-danger">*</abbr>
												</FormLabel>
												<Autocomplete
													id="insurance-provider-field"
													options={this.state.insuranceProviders}
													value={this.state.insuranceProv1}
													onChange={(event, newValue) => {
														this.handleChange({ target: { value: newValue, name: "insuranceProv1" } })
													}}
													renderInput={(params) => <TextField {...params} label="Insurance Provider" variant="outlined" style={{ background: "white" }} />}
												/>
												<div
													className={
														this.hasError("insuranceProv2") ? "inline-errormsg" : "hidden"
													}
												>
													<i className="fa fa-exclamation-circle" aria-hidden="true">
														&nbsp;Please provide correctly.
													</i>
												</div>
											</div>
										</div>
										<div className="col-12 col-md-6">
											{
												this.state.insuranceProv1 === "Other"
												&&
												<div className="form-group">
													<FormLabel>
														Insurance Provider (If Other)
													</FormLabel>
													<input
														type="text"
														value={this.state.insuranceProv2}
														onChange={this.handleChange}
														className="form-control"
														name="insuranceProv2"
													/>
												</div>
											}
										</div>
									</div>
									<div className="row form-row">
										<div className="col-12 col-md-12">
											<div className="form-group">
												<FormLabel>
													Social Security Number
												</FormLabel>
												<input
													type="number"
													value={this.state.socialSecurityNumber}
													onChange={this.handleChange}
													className={this.hasError("socialSecurityNumber") ? "form-control is-invalid" : "form-control"}
													name="socialSecurityNumber"
												/>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<FormLabel>
													Driver's License # (required for filling an insurance claim)
												</FormLabel>
												<input
													type="text"
													value={this.state.driverLicense}
													onChange={this.handleChange}
													className={this.hasError("driverLicense") ? "form-control is-invalid" : "form-control"}
													name="driverLicense"
												/>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												<FormLabel>
													Driver License State
												</FormLabel>
												<select
													value={this.state.driverLicenseState}
													onChange={this.handleChange}
													className={"form-control"}
													name="driverLicenseState"
												>
													{
														states.map((state) => {
															return (
																<option key={state.value} value={state.value}>
																	{state.state}
																</option>
															);
														})
													}
												</select>
											</div>
										</div>
									</div>
									{
										this.state.insuranceProv1 !== "Self/Pay"
										&&
										<div className="row form-row">
											<div className="col-12 col-md-6">
												<div className="form-group">
													<FormLabel>
														Insurance Member ID
													</FormLabel>
													<input
														type="text"
														value={this.state.memberId}
														onChange={this.handleChange}
														className="form-control"
														name="memberId"
													/>
													<div
														className={
															this.hasError("memberId") ? "inline-errormsg" : "hidden"
														}
													>
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;This field is required.
														</i>
													</div>
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className="form-group">
													<FormLabel>
														Insurance Group Number
													</FormLabel>
													<input
														type="text"
														value={this.state.groupNumber}
														onChange={this.handleChange}
														className="form-control"
														name="groupNumber"
													/>
													<div
														className={
															this.hasError("groupNumber") ? "inline-errormsg" : "hidden"
														}
													>
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;This field is required.
														</i>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="form-group">
													<FormLabel>
														Relation to Insured
													</FormLabel>
													<select
														value={this.state.relation}
														onChange={this.handleChange}
														className="form-control"
														name="relation"
													>
														{
															relation.map((item) => {
																return (
																	<option value={item.value} key={item.value}>
																		{item.desc}
																	</option>
																);
															})
														}
													</select>
													<div
														className={
															this.hasError("relation") ? "inline-errormsg" : "hidden"
														}
													>
														<i className="fa fa-exclamation-circle" aria-hidden="true">
															&nbsp;This field is required.
														</i>
													</div>
												</div>
											</div>
											{
												this.state.relation !== "Self"
												&&
												<>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<FormLabel>
																Insurance Holder First Name
															</FormLabel>
															<input
																type="text"
																value={this.state.insuredFirstName}
																onChange={this.handleChange}
																className="form-control"
																name="insuredFirstName"
															/>
															<div
																className={
																	this.hasError("insuredFirstName") ? "inline-errormsg" : "hidden"
																}
															>
																<i className="fa fa-exclamation-circle" aria-hidden="true">
																	&nbsp;This field is required.
																</i>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<FormLabel>
																Insurance Holder Last Name
															</FormLabel>
															<input
																type="text"
																value={this.state.insuredLastName}
																onChange={this.handleChange}
																className="form-control"
																name="insuredLastName"
															/>
															<div
																className={
																	this.hasError("insuredLastName") ? "inline-errormsg" : "hidden"
																}
															>
																<i className="fa fa-exclamation-circle" aria-hidden="true">
																	&nbsp;This field is required.
																</i>
															</div>
														</div>
													</div>
												</>
											}
										</div>
									}
								</div>
							</div>
						}
					</form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleBack}>
						{
							this.state.step === 'selectPatient' ? "Close" : "Back"
						}
					</Button>
					<Button variant="primary" onClick={this.handleNext}>
						{
							this.state.saving
							&&
							<i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }}></i>
						}
						{
							this.state.step === 'afterPatient' ? "Create" : "Next"
						}
					</Button>
				</Modal.Footer>
			</ModalStyled>
		);
	}
}
