export const serviceConstants = {
	HOST_NAME: process.env.REACT_APP_HOST_NAME,
	API_HOST_NAME: process.env.REACT_APP_API_HOST_NAME,
	SOCKET_HOST_NAME: process.env.REACT_APP_SOCKET_HOST_NAME,
	// GOOGLE_API_KEY_FOR_MAP: process.env.REACT_APP_GOOGLE_API_KEY_FOR_MAP,
	// GOOGLE_API_KEY_FOR_GEOCODING: process.env.REACT_APP_GOOGLE_API_KEY_FOR_GEOCODING,
	STRIPE_API_PUBLIC_KEY: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY,
	AG_GRID_LICENSE_KEY: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
	VERSION: "3.83"
};

export const templateIds = {
	htmlEmailWithLogo: 'd-7500c1dcce8246e48c6a76cec0a01f2e',
};

export const auditEnums = {
	IDENTIFIERS: {
		PatientRecord: "Patient_Record",
		OrderRecord: "Order_Record",
		ExportRecord: "Export_Record",
		ImportRecord: "Import_Record"
	},
	EVENTTYPES: {
		PatientLogIn: "Patient_LoggedIn",
		PatientLogOut: "Patient_LogOut",
		OrderResultViewed: "Order_Result_Viewed",
		OrderResultDownloaded: "Order_Result_Downloaded",
		OrderGridExported: "Order_Grid_Exported",
		RequisitionGridExported: "Requisition_Grid_Exported",
		PatientGridExported: "Patient_Grid_Exported",
		CorretedPatientGridForMPSExported: "Corrected_Patient_Grid_For_MPS_Exported",
		PatientInsuranceGridExported: "Patient_Insurance_Grid_Exported",
		PatientDuplicateGridExported: "Patient_Duplicate_Grid_Exported",
		InvoiceGridExported: "Invoice_Grid_Exported",
		SelfPaymentGridExported: "Self_Payment_Grid_Exported",
		MPSIncViewGridExported: "MPS_Inc_View_Grid_Exported",
		MPSOutstandingGridExported: "MPS_Outstanding_Grid_Exported",
		MPSPaidGridExported: "MPS_Paid_Grid_Exported",
		PatientPaymentGridExported: "Patient_Payment_Grid_Exported",
		AuditGridExported: "Audit_Grid_Exported",
		BrooksLIMSRecordGridExported: "Brooks_LIMS_Record_Grid_Exported",
		StateReportGridExported: "State_Report_Grid_Exported",
		EndOfDayReportGridExported: "EndOfDay_Report_Grid_Exported",
		FailedSMSEmailReportGridExported: "FailedSMSEmail_Report_Grid_Exported",
		OrderTotalGridExported: "Order_Total_Grid_Exported",
		SelfCheckInReportGridExported: "SelfCheckIn_Report_Grid_Exported",
		CompanyGridExported: "Company_Grid_Exported",
		FacilityGridExported: "Facility_Grid_Exported",
		LocationGridExported: "Location_Grid_Exported",
		PhysicianGridExported: "Physician_Grid_Exported",
		UserGridExported: "User_Grid_Exported",
		FacilityPickupTimesGridExported: "FacilityPickupTimes_Grid_Exported",
		FacilityPatientTestResultManagementGridExported: "Facility_Patient_Test_Result_Management_Grid_Exported",
		FacilityInventoryGridExported: "FacilityInventory_Grid_Exported",
		LabInventoryGridExported: "LabInventory_Grid_Exported",
		LabInventoryTrackingHistoryGridExported: "LabInventory_Tracking_History_Grid_Exported",
		DeliveryPickupOrderGridExported: "DeliveryPickupOrder_Grid_Exported",
		DriverShiftInformationGridExported: "DriverShift_Information_Grid_Exported",
		DriverGridExported: "Driver_Grid_Exported",
		NotificationTypeGridExported: "NotificationType_Grid_Exported",
		FacilityNotificationManagementGridExported: "FacilityNotification_Management_Grid_Exported",
		VideoManagementGridExported: "Video_Management_Grid_Exported",
		LIMSResultImportGridExported: "LIMS_ResultImport_Grid_Exported",
		InsuranceProviderGridExported: "Insurance_Provider_Grid_Exported",
		MaintenanceGridExported: "Maintenance_Grid_Exported",
		SelfPaymentGridDocumentImported: "Self_Payment_Grid_Document_Imported",
	}
}

export const patientImagePath = '/files/patient/images/';

export const CropImgMaxWidth = 600;