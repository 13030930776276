import React, { Component } from "react";
import { Checkbox } from "@mui/material";

export default class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            notificationTypeId: this.props.data._id
        };
    }

    checkedHandler = (event) => {
        let checked = event.target.checked;
        this.props.context.componentParent.checkItem(this.state.notificationTypeId, checked)
    }

    render() {
        return (
            <div className="text-center assign-actions">
                <Checkbox checked={this.props.data.checked} onClick={this.checkedHandler} ></Checkbox>
            </div>
        );
    }
}
