import React, { Component } from "react";
// import HomeBanner from "./homebanner";
// import SearchTestLoc from "./searchtestloc";
// import SalesTeam from "./salesTeam";
// import MapList from "./maplist";
// import FormInfo from "./forminfo";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const script = document.createElement('script');
    script.src = "/js/front-page-gtag.js";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    return (
      <div>
        <iframe title="gtm" src="https://www.googletagmanager.com/ns.html?id=GTM-TXQQX4K" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
        <div className="main-wrapper">
          {/* <HomeBanner />
          <br />
          <SearchTestLoc />
          <br />
          <MapList />
          <SalesTeam />
          <FormInfo /> */}
          <div className="container text-center d-flex align-items-center justify-content-center" style={{ height: "calc(100vh - 212px)" }}>
            <div>
              <h1 style={{fontSize: '30px'}}>Thank you for your support</h1>
              <p className="m-5" style={{fontSize: '20px'}}>My Result Now is no longer open for new registrations or testing. Existing users and facilities can still log in to access their information.  We appreciate your past patronage. For inquiries, please contact results@myresultnow.com</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
