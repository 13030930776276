import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { mergeInsuranceData } from "../../../../services/clinicPortalServices/patientInsuranceService";
import moment from "moment";
import { Checkbox, MenuItem } from "@mui/material";
import toastr from "toastr";
import Swal from "sweetalert2";
import { Typography, Avatar, CardContent, CardHeader, Card } from "@mui/material";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from 'redux-zero/utils';

const boundActions = bindActions(actions, store);

export default class InsuranceDuplicatedDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			insuranceIds: this.props.bulkCheckedIds,
			insuranceSelectedId: "",
		};
	}

	componentDidMount() {
	}

	handleDominantSelect = (insuranceId) => {
		let { insuranceIds } = this.state;
		if (!insuranceIds.includes(insuranceId)) {
			insuranceIds.push(insuranceId);
			this.setState({ insuranceIds });
		}
		this.setState({ insuranceSelectedId: insuranceId });
	};

	handleMerge = () => {
		const { insuranceIds, insuranceSelectedId } = this.state;
		const mergeinsuranceIds = insuranceIds.filter(item => item !== insuranceSelectedId);
		if (!insuranceSelectedId) {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'Main insurance not choosed!',
				text: 'Choose the main insurance and try again.',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
		}
		else if (mergeinsuranceIds.length === 0) {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'No insurance merged!',
				text: 'Choose insurances that will be merged at least one.',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
		}
		else {
			const mergeDetails = {
				ids: mergeinsuranceIds,
				selectedId: insuranceSelectedId
			}
			boundActions.startBlockUILoading();
			mergeInsuranceData(mergeDetails).then(res => {
				boundActions.endBlockUILoading();
				toastr.success("Insurances are merged!");
				this.props.emptyInsurancesDuplicated();
				this.props.handleCloseDuplicatedDetails();
				this.props.loadGridData();
			});
		}
	}

	toggleRemoveMerge = (insuranceId) => {
		let { insuranceIds } = this.state;
		if (insuranceIds.includes(insuranceId)) {
			insuranceIds = insuranceIds.filter(item => item !== insuranceId);
		} else {
			insuranceIds.push(insuranceId);
		}
		this.setState({ insuranceIds });
	}

	render() {
		const { insuranceSelectedId, insuranceIds } = this.state;
		return (
			<div className="row">
				<div className="col-12 text-center mb-3">
					<h4>Which insurance would you like to keep?</h4>
				</div>
				{
					this.props.insurancesDuplicated.map(insurance => {
						return (
							<div className="col-12 col-md-6" key={insurance._id}>
								<Card
									onClick={() => this.handleDominantSelect(insurance._id)}
									style={{
										border: "1px solid #dbdad9",
										borderColor: insuranceSelectedId === insurance._id ? "blue" : "#dbdad9",
										backgroundColor: (insuranceIds.includes(insurance._id) || (insuranceSelectedId === insurance._id)) ? "white" : "#8080805c",
										cursor: "pointer"
									}}
								>
									<CardHeader
										avatar={
											<Avatar aria-label="recipe" className='patientAvatar' style={{ backgroundColor: '#0369b3' }}>
												{insurance.patient.first_name[0]}
											</Avatar>
										}
										title={insurance.patient.first_name + ' ' + insurance.patient.last_name + ' (' + insurance.patient.mrn + ')'}
										subheader={"Last Updated: " + moment(insurance.updatedAt).format("MM/DD/YYYY hh:mm A")}
									/>
									<CardContent>
										<Typography variant="body1" style={{ fontWeight: 'bold' }} component="h6">
											Insurance ID
										</Typography>
										<Typography variant="body2" className="mb-2" component="p">
											{insurance._id}
										</Typography>
										<Typography variant="body1" style={{ fontWeight: 'bold' }} component="h6">
											Insurance Member ID
										</Typography>
										<Typography variant="body2" className="mb-2" component="p">
											{insurance.insured_member_id ? insurance.insured_member_id : 'Undefined'}
										</Typography>
										<Typography variant="body1" style={{ fontWeight: 'bold' }} component="h6">
											Insurance Provider
										</Typography>
										<Typography variant="body2" className="mb-2" component="p">
											{insurance.insurance_provider ? insurance.insurance_provider : 'Undefined'}
										</Typography>
									</CardContent>
								</Card>
								<MenuItem
									className="mb-3 pl-0" onClick={() => this.toggleRemoveMerge(insurance._id)} disabled={insuranceSelectedId === insurance._id}>
									<Checkbox checked={!(insuranceIds.includes(insurance._id) || (insuranceSelectedId === insurance._id))} ></Checkbox>
									Remove from merge
								</MenuItem>
							</div>
						);
					})
				}
				<div className="col-12"
					style={{
						paddingTop: "12px",
						marginTop: "12px",
						borderTop: "1px solid rgba(0,0,0,.2",
					}}
				>
					<Button
						variant="primary"
						style={{ float: "right", marginLeft: "10px" }}
						onClick={this.handleMerge}
					>
						Merge
					</Button>
					<Button
						variant="secondary"
						style={{ float: "right" }}
						onClick={this.props.handleCloseDuplicatedDetails}
					>
						Cancel
					</Button>
				</div>
			</div>
		);
	}
}