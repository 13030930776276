import { apiInterfaceForJson } from "../common/apiManager";

export const getAllMaintenanceSlots = () => {
    return apiInterfaceForJson("/maintenanceSlot/v1/", "GET");
};

export const searchMaintenanceSlot = (query) => {
    return apiInterfaceForJson("/maintenanceSlot/v1/search", "POST", query);
}

export const getMaintenanceSlotById = (id) => {
    return apiInterfaceForJson("/maintenanceSlot/v1/" + id, "GET");
}

export const createMaintenanceSlot = (info) => {
    return apiInterfaceForJson("/maintenanceSlot/v1/", "POST", info);
}

export const updateMaintenanceSlot = (info) => {
    return apiInterfaceForJson("/maintenanceSlot/v1/", "PUT", info);
}

export const deleteMaintenanceSlot = (info) => {
    return apiInterfaceForJson("/maintenanceSlot/v1/delete", "POST", info);
}