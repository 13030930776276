import moment from "moment";
import { apiInterfaceForJson } from "../common/apiManager";

export const stateReports = (params) => {
	let filterParams = {
		from_date: moment(params.from_date).format("YYYYMMDDHHmmss"),
		to_date: moment(params.to_date).format("YYYYMMDDHHmmss"),
		facility_id: params.facility_id,
		state: params.stateReportsType
	}
	return apiInterfaceForJson("/report/v1/stateReports", "POST", { params: filterParams });
};

export const dailyReports = (params) => {
	let filterParams = {
		from_date: moment(params.from_date).format("YYYYMMDDHHmmss"),
		to_date: moment(params.to_date).format("YYYYMMDDHHmmss"),
		reportType: params.reportType
	}
	return apiInterfaceForJson("/report/v1/dailyReports", "POST", { params: filterParams });
}

export const failedNotificationReports = (params) => {
	let from_date = moment(params.from_date).format("YYYYMMDDHHmmss");
	let to_date = moment(params.to_date).format("YYYYMMDDHHmmss");

	let filterParams = {
		facility_id: params.facility_id,
		from_date: from_date,
		to_date: to_date
	};
	return apiInterfaceForJson("/order/v1/filter", "POST", filterParams);
}

export const notifyFacilityViaEmail = (params) => {
	return apiInterfaceForJson("/report/v1/notifyFacilityViaEmail", "POST", params);
}

export const notifyFacilityViaSMS = (params) => {
	return apiInterfaceForJson("/report/v1/notifyFacilityViaSMS", "POST", params);
}
