import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
	updateVideo,
	createVideo,
	getVideoDataById
} from "../../../../services/clinicPortalServices/videoService";
import toastr from 'toastr';
import { rolesVideo } from "../../../../services/common/optionsData";

export default class VideoDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			videoId: this.props && this.props.videoId ? this.props.videoId : "",
			title: "",
			embed_code: "",
			role: "",
			errors: [],
		};
	}

	componentDidMount() {
		if (this.state.videoId !== "") {
			this.loadDetails();
		}
	}

	loadDetails = () => {
		getVideoDataById(this.state.videoId).then(res => {
			this.setState({
				title: res.data.title,
				embed_code: res.data.embed_code,
				role: res.data.role,
			})
		})
	};

	handleClose = () => {
		this.setState({
			show: false
		});
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	saveChanges = () => {
		let errors = [];

		if (!this.state.title) {
			errors.push("title");
		}
		if (!this.state.embed_code) {
			errors.push("embed_code");
		}
		if (!this.state.role) {
			errors.push("role");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let videoInfo = {
			_id: this.state.videoId,
			title: this.state.title,
			embed_code: this.state.embed_code,
			role: this.state.role,
		};
		if (this.state.videoId) {
			updateVideo(videoInfo)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						toastr.warning(response.message);
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
					}
				})
		} else {
			delete videoInfo._id;
			createVideo(videoInfo)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						toastr.warning(response.message);
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
					}
				})
		}
	};

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12">
							<div className="form-group">
								<label>
									Title <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="title"
									value={this.state.title}
									onChange={this.handleChange}
									required
									className={
										this.hasError("title")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
								<div
									className={
										this.hasError("title") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									Embed Code <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="embed_code"
									value={this.state.embed_code}
									onChange={this.handleChange}
									required
									className={
										this.hasError("key")
											? "form-control is-invalid"
											: "form-control"
									}
								/>
								<div
									className={
										this.hasError("key") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>Role</label>
								<select
									name="role"
									value={this.state.role}
									onChange={this.handleChange}
									className={
										this.hasError("role")
											? "form-control is-invalid select"
											: "form-control  select"
									}
								>
									<option key="" value="">Please Select</option>
									{
										rolesVideo.map(role => {
											return (<option key={role.key} value={role.value}>{role.value}</option>)
										})
									}
								</select>
								<div
									className={
										this.hasError("role") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-12"
							style={{
								paddingTop: "10px",
								borderTop: "1px solid rgba(0,0,0,.2",
							}}
						>
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.saveChanges}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
