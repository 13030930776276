import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Breadcrumb from "./breadcrumb";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<Breadcrumb />
				<div className="row" style={{ padding: "12px" }}>
					<div className="col-md-3">
						<TextField
							label="Current Grid Search"
							variant="outlined"
							id="reset-form"
							InputLabelProps={{
								shrink: true,
							}}
							type="string"
							margin="dense"
							onChange={this.props.onFilterTextChange}
						/>
					</div>
					<div className="col-md-9" style={{ display: "flex", justifyContent: "flex-end" }}>
						<div>
							<button
								className="btn btn-primary button-info-grid"
								onClick={() => this.props.onBtnExport()}
							>
								<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchMenu;
