import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import PatientDuplicatedDetails from './patientDuplicatedDetails';
import PatientDetails from '../../patients/clinicPatientGrid/patientDetails';
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class MasterBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showDetails: false,
			patientDetails: {}
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleShowDetails = (patientDetails) => {
		this.setState({ showDetails: true, patientDetails: patientDetails }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleCloseDetails = () => {
		this.setState({ showDetails: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Review Duplicates
		</Tooltip>
	);
	renderTooltipDismiss = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Ignore Duplicates
		</Tooltip>
	);


	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<Button
						onClick={this.handleShow}
						className="py-1 px-2 mr-1"
					>
						Review
					</Button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDismiss}
				>
					<Button
						onClick={() => this.props.context.componentParent.handleDismiss(this.props.data.patientIds)}
						className="py-1 px-2 btn-info"
					>
						Dismiss
					</Button>
				</OverlayTrigger>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Review Possible Duplicate Patients</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDuplicatedDetails
							data={this.props.data}
							handleShowDetails={this.handleShowDetails}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showDetails}
					onHide={this.handleCloseDetails}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.state.patientDetails}
							handleClose={this.handleCloseDetails}
							handleCloseDuplicatesPopup={this.handleClose}
							context={this.props.context}
							type='patient_duplicates_grid'
						/>
					</Modal.Body>
				</ModalStyled>
			</div >
		);
	}
}
