import React, { Component } from "react";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import BreadCrumb from "./breadcrumb";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<BreadCrumb />
				<div className="row" style={{ padding: "12px", paddingBottom: "0px" }}>
					<div className="col-md-6">
						<FormControl
							variant="outlined"
							className="filter-select-bar"
						>
							<InputLabel
								id="facility-label"
							>
								Select Facility
							</InputLabel>
							<Select
								labelId="facility-label"
								label="Select Facility"
								value={this.props.facilityId}
								onChange={this.props.handleChange}
								name="facilityId"
							>
								{
									this.props.facilities.map((fac) => {
										return (
											<MenuItem
												key={fac._id}
												value={fac._id}
											>
												<span className="mr-2">
													{fac.name}
												</span>
												{
													fac.archived === "archived"
													&&
													<b>{" ( Archived )"}</b>
												}
											</MenuItem>
										);
									})
								}
							</Select>
						</FormControl>
					</div>
					<div className="col-md-3">
						<TextField
							label="Current Grid Search"
							variant="outlined"
							InputLabelProps={{
								shrink: true,
							}}
							type="string"
							margin="dense"
							onChange={this.props.onFilterTextChange}
						/>
					</div>
					<div className="col-md-3 d-flex align-items-start justify-content-end">
						<button
							className="btn btn-primary button-info-grid"
							onClick={() => this.props.clearFilter()}
						>
							<i className="fa fa-times" aria-hidden="true"></i> Clear Filter
						</button>
						<button
							className="btn btn-primary button-info-grid"
							onClick={() => this.props.onBtnExport()}
						>
							<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchMenu;
