import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';


export default class DraftEditor extends Component {
    constructor(props) {
        super(props);
        if (props.initHtml) {
            const contentBlock = htmlToDraft(props.initHtml);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.state = {
                    editorState,
                };
            }
        } else {
            this.state = {

            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initHtml !== this.props.initHtml && this.props.initHtml) {
            const contentBlock = htmlToDraft(this.props.initHtml);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                    editorState,
                });
            }
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        return (
            <div style={{ border: "1px solid #dcdcdc", padding: "5px", backgroundColor: "white" }}>
                <Editor
                    editorState={this.state.editorState}
                    placeholder='&nbsp;&nbsp;&nbsp;Please edit paragrah text here'
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>
        );
    }
}
