import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { updateDeliveryPickupOrder, deleteDeliveryPickupOrder, sendNotificationAfterDriverChanged, sendNotificationAfterPickupDeleted, sendNotificationAfterConfirmedPickup } from "../../../../services/clinicPortalServices/deliveryPickupOrderServices";
import { sendAppPushNotification } from "../../../../services/common/service";
import Swal from "sweetalert2";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import toastr from 'toastr';
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAssignDriver: false,
			deleteDeliveryPickupId: props.data._id,
			driver: '',
			errors: [],
		};
	}

	handleShow = () => {
		this.setState({ showAssignDriver: true });
	};

	handleClose = () => {
		this.setState({ showAssignDriver: false });
	};

	handleAssign = () => {
		const deliveryPickupOrderInfo = {
			_id: this.props.data._id,
			driver_id: this.state.driver._id,
			facility_id: this.props.data.facility_id._id,
			start_time: this.props.data.start_time,
			end_time: this.props.data.end_time,
			driver_assigned: true,
			origin_driver: this.props.data.driver_id.first_name + " " + this.props.data.driver_id.last_name
		}
		updateDeliveryPickupOrder(deliveryPickupOrderInfo).then(res => {
			const driverName = this.state.driver.first_name + ' ' + this.state.driver.last_name
			sendNotificationAfterDriverChanged(this.state.driver.mobile, driverName);
			toastr.success('Driver changed successfully!');
			toastr.success('Sent Notification to Driver');
			this.props.context.componentParent.loadGridData();
		})
	}

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this order!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const info = {
					_id: this.state.deleteDeliveryPickupId,
					start_time: this.props.data.start_time,
					end_time: this.props.data.start_time,
					facility_id: this.props.data.facility_id._id
				};
				deleteDeliveryPickupOrder(info)
					.then((response) => {
						if (this.props.data.is_checking) {
							const driverName = this.props.data.driver_id.first_name + ' ' + this.props.data.driver_id.last_name;
							const facilityName = this.props.data.facility_id.name;
							sendNotificationAfterPickupDeleted(this.props.data.driver_id.mobile, driverName);
							const pushNotificationData = {
								"to": this.props.data.driver_id.mobile_device_token,
								"notification": {
									"title": "Pickup Deleted",
									"body": "The next facility: " + facilityName + " on your route did not have samples to pick up",
									"sound": "default",
									"badge": 1
								},
								"priority": "high",
								"content_available": true,
								"data": {
									"type": "delete_pickup",
									"pickup": {
										"id": this.props.data._id
									}
								}
							}
							sendAppPushNotification(pushNotificationData);
						}
						this.props.context.componentParent.loadGridData();
					})
			}
		});
	}

	handleProceed = () => {
		const deliveryPickupOrderInfo = {
			_id: this.props.data._id,
			facility_id: this.props.data.facility_id._id,
			start_time: this.props.data.start_time,
			end_time: this.props.data.end_time,
			is_checking: false,
		}
		updateDeliveryPickupOrder(deliveryPickupOrderInfo).then(res => {
			const driverName = this.props.data.driver_id.first_name + ' ' + this.props.data.driver_id.last_name;
			const facilityName = this.props.data.facility_id.name;
			sendNotificationAfterConfirmedPickup(this.props.data.driver_id.mobile, driverName, facilityName);
			const pushNotificationData = {
				"to": this.props.data.driver_id.mobile_device_token,
				"notification": {
					"title": "Please continue driving",
					"body": "Lab contacted to the facility: " + facilityName + ", please continue as normal",
					"sound": "default",
					"badge": 1
				},
				"priority": "high",
				"content_available": true,
				"data": {
					"type": "proceed_pickup",
					"pickup": {
						"id": this.props.data._id
					}
				}
			}
			sendAppPushNotification(pushNotificationData);
			toastr.success('Sent Notification to Driver');
			this.props.context.componentParent.loadGridData();
		})
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Assign Driver
		</Tooltip>
	);

	renderTooltipProceed = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Proceed Pickup
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Order
		</Tooltip>
	);

	render() {
		const availableDrivers = this.props.context.componentParent.state.drivers.filter(driver => {
			if (driver._id !== this.props.data.driver_id._id) {
				let available = false;
				driver.shift_working_days.map(shift => {
					if (this.props.data.start_time > shift.start && this.props.data.end_time < shift.end) {
						available = true;
					}
					return null;
				})
				return available;
			}
			return false;
		});
		return (
			<div>
				{
					!this.props.data.isCompleted
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-user"></i>
						</button>
					</OverlayTrigger>
				}
				{
					this.props.data.is_checking
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipProceed}
					>
						<button onClick={this.handleProceed} className="edit-order-btn">
							<i className="fas fa-check-circle"></i>
						</button>
					</OverlayTrigger>
				}
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipDelete}
				>
					<button onClick={this.handleDelete} className="edit-order-btn">
						<i className="fas fa-trash"></i>
					</button>
				</OverlayTrigger>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showAssignDriver}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Select Driver</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12">
									<div className="form-group">
										<Autocomplete
											id="box-demo"
											noOptionsText={'There is no driver currently available - Please add driver you require to the schedule.'}
											options={availableDrivers}
											value={this.state.driver && this.state.driver._id ? this.state.driver : null}
											onChange={(event, newValue) => {
												this.setState({
													driver: newValue
												})
											}}
											getOptionLabel={(option) => option ? option.first_name + " " + option.last_name + " (" + option.driver_user_name + ")" : ""}
											renderInput={(params) => <TextField {...params} label="Driver" variant="outlined" />}
										/>
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={this.handleAssign} disabled={this.state.driver && this.state.driver._id ? false : true}>
							Save
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div >
		);
	}
}
