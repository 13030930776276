import { removeUserDevice } from "../clinicPortalServices/loginService";
import { goPatientLogin } from "../patientPortalServices/loginService";
import { goDriverLogin } from "../driverPortalServices/loginService";
import { isUserLoggedIn, getPatientAuthToken, getDriverAuthToken, getUserAuthToken } from "./util";
import axios from "axios";
import { serviceConstants } from "./constants";

export const handleError = (error) => {
    if (error.status === 401) {
        if (isUserLoggedIn()) {
            if (error.message === 'Token has wrong Device ID') {
                window.localStorage.clear();
                window.location.href = '/';
            } else {
                removeUserDevice();
                window.location.href = '/';
            }
        } else if (getPatientAuthToken()) {
            goPatientLogin();
        } else if (getDriverAuthToken()) {
            goDriverLogin();
        } else {
            window.localStorage.clear();
            window.location.href = "/";
        }
    }
};

export const apiInterfaceForJson = (url, method, data, signal) => {
    let token = getUserAuthToken();
    if (!token) {
        token = getPatientAuthToken();
    }
    return axios.request({
        url: serviceConstants.API_HOST_NAME + url,
        method: method,
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        data: data,
        signal: signal
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        if (error.response) {
            handleError(error.response);
        }
    })
}

export const apiInterfaceForFormData = (url, method, formData) => {
    let token = getUserAuthToken();
    if (!token) {
        token = getPatientAuthToken();
    }
    return axios.request({
        url: serviceConstants.API_HOST_NAME + url,
        method: method,
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + token
        },
        data: formData,
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        handleError(error.response);
    })
}