import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import FacilityDetails from "../../facility/clinicFacilityGrid/facilityDetails";
import { getUserRole } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class FacilityBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			facilityId: props.data.facility_id._id,
		};
	}

	handleShow = () => {
		if (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin') {
			this.setState({ show: true }, () => {
				let elements = document.getElementsByClassName("removeTabIndexModal");
				if (elements.length) {
					for (let i = 0; i < elements.length; i++) {
						let dialog = elements[i];
						dialog.parentNode.removeAttribute('tabindex');
					}
				}
			});
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					{this.props.data.facility_source}
				</button>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Facility Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<FacilityDetails
							facilityId={this.state.facilityId}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
