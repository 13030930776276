import React, { Component } from "react";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import InsuranceBreadCrumb from "./insuranceBreadcrumb";

class InsuranceSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedEvent: null,
		};
	}

	closeSelect = (e) => {
		if (this.state.selectedEvent != null) {
			this.props.handelGridLoading();
			this.setState({ selectedEvent: null })
		}
	}

	handleSelectChange = (event) => {
		this.setState({ selectedEvent: event }, () => {
			this.props.handleFiltersChange(event);
		});
	}

	render() {
		return (
			<div>
				<InsuranceBreadCrumb
					onBtnBulkEdit={this.props.onBtnBulkEdit}
					onBtnReviewSelection={this.props.onBtnReviewSelection}
					isBulkEdit={this.props.isBulkEdit}
					showReviewButton={this.props.showReviewButton}
					onIsArchivedInformation={(event) => { this.props.onIsArchivedInformation(event) }}
					isArchived={this.props.isArchived}
				/>
				<div className="row" style={{ padding: "12px" }}>
					<div
						className="col-md-8"
						style={{ borderRight: "2px solid lightGray" }}
					>
						<div className="row">
							<div className="col-md-6">
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.props.facilities && this.props.facilities.length <= 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
                                            value={this.props.facilityId && this.props.facilityId.length ? this.props.facilityId : ""}
											onChange={this.handleSelectChange}
											onClose={this.closeSelect}
											name="facility_id"
											disabled={this.props.gridLoading}
										>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<span className="mr-2">
																{fac.name}
															</span>
															{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
									{
										this.props.facilities && this.props.facilities.length > 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.props.facilityId}
											onChange={this.handleSelectChange}
											onClose={this.closeSelect}
											label="Select Facility"
											name="facility_id"
											multiple
											disabled={this.props.gridLoading}
											renderValue={(selected) => {
												if (selected.length === this.props.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.props.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.props.facilityId && this.props.facilityId.includes(fac._id) ? true : false} className={fac.type === 'facility' ? 'ml-5' : ''} />
															<span className="mr-2">
																{fac.name}
															</span>
															{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
							</div>
							<div className="col-md-3">
								<TextField
									label="Current Grid Search"
									variant="outlined"
									id="reset-form"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="dense"
									onChange={this.props.onFilterTextChange}
								/>
							</div>
							<div className="col-md-3">
								<span>
									<button
										className="btn btn-primary button-info-grid"
										onClick={() => this.props.clearFilter()}
									>
										<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
									</button>
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="col grid-buttons">
							<div>
								<TextField
									style={{ width: "100px" }}
									label="Page Size"
									variant="outlined"
									className="mt-3"
									id="page-size"
									InputLabelProps={{
										shrink: true,
									}}
									type="number"
									size="small"
									margin="none"
									onChange={this.props.onPageSizeChanged}
								/>
								<button
									className="btn btn-primary button-info-grid"
									onClick={() => this.props.saveState()}
								>
									<i className="far fa-save"></i> Save
								</button>
								<button
									className="btn btn-primary button-info-grid"
									onClick={() => this.props.resetState()}
								>
									{" "}
									<i className="fa fa-repeat"></i> Default
								</button>
								<button
									className="btn btn-primary button-info-grid"
									onClick={() => this.props.onBtnExport()}
								>
									<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
								</button>
							</div>
						</div>
					</div>
				</div>
			</div >
		);
	}
}

export default InsuranceSearchMenu;
