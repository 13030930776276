import React, { Component } from 'react';
import AuditBreadcrumb from './breadcrumb';
import { TextField } from "@mui/material";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		}
	};

	render() {
		return (
			<div>
				<AuditBreadcrumb
					onBtnExport={this.props.onBtnExport}
				/>
				<div className="row mt-3" style={{ padding: "12px" }}>
					<div className="col-md-6">
						<TextField
							label="Current Grid Search"
							variant="outlined"
							id="reset-form"
							InputLabelProps={{
								shrink: true,
							}}
							sx={{ maxWidth: 500 }}
							type="string"
							margin="dense"
							onChange={this.props.onFilterTextChange}
						/>
					</div>
					<div className='col-md-6 text-right'>
						<button
							className="btn btn-primary mt-3"
							onClick={() => this.props.clearFilter()}
						>
							<i className="fa fa-times" aria-hidden="true"></i> Clear Filter
						</button>
					</div>
				</div>
			</div >
		);
	}
}

export default SearchMenu;