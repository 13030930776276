import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
	updateTest,
	createTest,
	getTestById,
	getAllTestsForManagementPage,
} from "../../../../services/clinicPortalServices/testService";
import Swal from "sweetalert2";
import toastr from "toastr";
import { testMachines } from "../../../../services/common/optionsData";
import { FormControl, Checkbox, MenuItem, Select, InputLabel } from "@mui/material";
import { getUserDetails } from "../../../../services/common/util";

export default class TestDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			testId: this.props && this.props.testId ? this.props.testId : "",
			name: "",
			method_name: "",
			form_dropdown_priority: "",
			code: "",
			order_code_description: "",
			collection_requirements: "",
			specimen_transport_temperature: "",
			machine: [],
			loinc_code: "",
			cpt_codes: [],
			hcpcs_codes: [],
			results: [],
			sample_types: [],
			symptoms: [],
			diagnosis_codes: [],
			hide_from_dropdown: false,
			is_bundle: false,
			not_allowed_single_test: false,
			tests: [],
			testIds: [],
			errors: [],
		};

		this.userName = '';
		if (getUserDetails()) {
			this.userName = JSON.parse(getUserDetails()).user_name;
		}
		getAllTestsForManagementPage({ type: "single" }).then(res => {
			this.setState({ tests: res.data })
		})
	}

	componentDidMount() {
		if (this.state.testId !== "") {
			this.loadDetails();
		}
	}

	loadDetails = () => {
		getTestById(this.state.testId)
			.then((response) => {
				let details = response.data;

				this.setState({
					name: details.name ? details.name : "",
					method_name: details.method_name ? details.method_name : "",
					form_dropdown_priority: details.form_dropdown_priority,
					order_code_description: details.order_code_description ? details.order_code_description : "",
					collection_requirements: details.collection_requirements ? details.collection_requirements : "",
					specimen_transport_temperature: details.specimen_transport_temperature ? details.specimen_transport_temperature : "",
					code: details.code ? details.code : "",
					machine: details.machine ? details.machine : [],
					loinc_code: details.loinc_code ? details.loinc_code : "",
					cpt_codes: details.cpt_codes ? details.cpt_codes : [],
					hcpcs_codes: details.hcpcs_codes && details.hcpcs_codes.length ? details.hcpcs_codes : [],
					results: details.results && details.results.length ? details.results : [],
					sample_types: details.sample_types && details.sample_types.length ? details.sample_types : [],
					symptoms: details.symptoms && details.symptoms.length ? details.symptoms : [],
					diagnosis_codes: details.diagnosis_codes && details.diagnosis_codes.length ? details.diagnosis_codes : [],
					hide_from_dropdown: details.hide_from_dropdown ? true : false,
					is_bundle: details.is_bundle ? true : false,
					not_allowed_single_test: !details.is_bundle && details.not_allowed_single_test ? true : false,
					testIds: details.is_bundle && details.testIds && details.testIds.length ? details.testIds : []
				});
			})
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	addArrayItem = (type, value) => {
		if (type && value) {
			let state = this.state;
			switch (type) {
				case "results":
					if (!state.results.find(result => result === value)) {
						state.results.push(value);
					}
					break;
				case "cpt_codes":
					if (!state.cpt_codes.find(code => code === value)) {
						state.cpt_codes.push(value);
					}
					break;
				case "hcpcs_codes":
					state.hcpcs_codes.push(value);
					break;
				case "sample_types":
					if (!state.sample_types.find(sample_type => sample_type === value)) {
						state.sample_types.push(value);
					}
					break;
				case "symptoms":
					if (!state.symptoms.find(symptom => symptom === value)) {
						state.symptoms.push(value);
					}
					break;
				case "diagnosis_codes":
					state.diagnosis_codes.push(value);
					break;
				default:
					break;
			}
			this.setState(state);
		}
	}

	addItem = (type) => {
		Swal.fire({
			title: 'Please input the value.',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off'
			},
			showCancelButton: true,
			confirmButtonText: 'OK',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed && result.value) {
				this.addArrayItem(type, result.value);
			}
		});
	}

	removeArrayItem = (type, index) => {
		if (type) {
			let state = this.state;
			switch (type) {
				case "results":
					state.results.splice(index, 1);
					break;
				case "cpt_codes":
					state.cpt_codes.splice(index, 1);
					break;
				case "hcpcs_codes":
					state.hcpcs_codes.splice(index, 1);
					break;
				case "sample_types":
					state.sample_types.splice(index, 1);
					break;
				case "symptoms":
					state.symptoms.splice(index, 1);
					break;
				case "diagnosis_codes":
					state.diagnosis_codes.splice(index, 1);
					break;
				default: break;
			}
			this.setState(state);
		}
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	saveChanges = () => {
		let errors = [];

		if (this.state.name === "") {
			errors.push("name");
		}
		if (this.state.method_name === "") {
			errors.push("method_name");
		}
		if (this.state.code === "") {
			errors.push("code");
		}
		if (this.state.form_dropdown_priority === "") {
			errors.push("form_dropdown_priority");
		}
		if (!this.state.machine.length) {
			errors.push("machine");
		}
		if (this.state.loinc_code === "") {
			errors.push("loinc_code");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let details = {
			name: this.state.name,
			method_name: this.state.method_name,
			form_dropdown_priority: this.state.form_dropdown_priority,
			order_code_description: this.state.order_code_description,
			code: this.state.code,
			collection_requirements: this.state.collection_requirements,
			specimen_transport_temperature: this.state.specimen_transport_temperature,
			machine: this.state.machine,
			loinc_code: this.state.loinc_code,
			cpt_codes: this.state.cpt_codes,
			hcpcs_codes: this.state.hcpcs_codes,
			results: this.state.results,
			sample_types: this.state.sample_types,
			symptoms: this.state.symptoms,
			diagnosis_codes: this.state.diagnosis_codes,
			hide_from_dropdown: this.state.hide_from_dropdown,
			is_bundle: this.state.is_bundle,
			not_allowed_single_test: !this.state.is_bundle ? this.state.not_allowed_single_test : false,
			testIds: this.state.is_bundle ? this.state.testIds : []
		};
		if (this.state.testId !== "") {
			details._id = this.state.testId;
			updateTest(details)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							text: response.message,
							icon: 'error',
							confirmButtonText: 'Ok'
						});
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
						toastr.success(response.message);
					}
				})
		} else {
			createTest(details)
				.then((response) => {
					if (response.RESULT === "ERROR") {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							text: response.message,
							icon: 'error',
							confirmButtonText: 'Ok'
						});
					} else {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose();
						toastr.success(response.message);
					}
				})
		}
	};

	handleSubObjectItem = (event, subType, subIndex, type) => {
		if (type === 'diagnosis_codes') {
			let value = event.target.value;
			let diagnosis_codes = this.state.diagnosis_codes;
			diagnosis_codes.map((item, i) => {
				if (i === subIndex) {
					item[subType] = value;
				}
				return null;
			})
			this.setState({ diagnosis_codes: diagnosis_codes })
		} else if (type === 'hcpcs_codes') {
			let value = event.target.value;
			let hcpcs_codes = this.state.hcpcs_codes;
			hcpcs_codes.map((item, i) => {
				if (i === subIndex) {
					item[subType] = value;
				}
				return null;
			})
			this.setState({ hcpcs_codes: hcpcs_codes })
		}
	}

	toggleState = (type) => {
		if (type === 'hide_from_dropdown') {
			this.setState({ hide_from_dropdown: !this.state.hide_from_dropdown })
		} else if (type === 'is_bundle') {
			this.setState({ is_bundle: !this.state.is_bundle })
		} else if (type === 'not_allowed_single_test') {
			this.setState({ not_allowed_single_test: !this.state.not_allowed_single_test })
		}
	}

	render() {
		return (
			<div>
				<div className="row form-row">
					<div className="col-md-12">
						<div className="form-group">
							<label>
								Name <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="name"
								value={this.state.name}
								onChange={this.handleChange}
								required
								className={
									this.hasError("name")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
							<div
								className={
									this.hasError("name") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<MenuItem onClick={() => this.toggleState('is_bundle')}>
								<Checkbox checked={this.state.is_bundle} />
								<div style={{ whiteSpace: "break-spaces" }}>Is Bundle?</div>
							</MenuItem>
						</div>
					</div>
					<div className="col-12 col-md-6">
						{
							this.state.is_bundle
							&&
							<div className="form-group">
								<FormControl
									variant="outlined"
									style={{ width: "100%" }}
								>
									<InputLabel id="machine-label">
										Tests for bundle
									</InputLabel>
									<Select
										labelId="machine-label"
										id="machine-select"
										value={this.state.testIds}
										onChange={this.handleChange}
										label="Tests for bundle"
										className={this.hasError("testIds") ? "select is-invalid" : ""}
										name="testIds"
										multiple
										style={{ paddingBottom: "5px" }}
										renderValue={(selected) => {
											let displayStrings = [];
											this.state.tests.map((test) => {
												if (selected.includes(test._id)) {
													displayStrings.push(test.name);
												}
												return null;
											})
											return displayStrings.join(', ');
										}}
									>
										{
											this.state.tests.map((test, index) => {
												return (
													<MenuItem
														key={index}
														value={test._id}
													>
														<Checkbox checked={this.state.testIds.includes(test._id) ? true : false} />
														{test.name}
													</MenuItem>
												);
											})
										}
									</Select>
								</FormControl>
								<div
									className={
										this.hasError("testIds") ? "inline-errormsg" : "hidden"
									}
								>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						}
						{
							!this.state.is_bundle
							&&
							<div className="form-group">
								<MenuItem onClick={() => this.toggleState('not_allowed_single_test')}>
									<Checkbox checked={this.state.not_allowed_single_test} />
									<div style={{ whiteSpace: "break-spaces" }}>Allow as individual test of Panel only?</div>
								</MenuItem>
							</div>
						}
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Code <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="code"
								value={this.state.code}
								onChange={this.handleChange}
								required
								className={
									this.hasError("code")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
							<div
								className={
									this.hasError("code") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Method Name <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="method_name"
								value={this.state.method_name}
								onChange={this.handleChange}
								required
								className={
									this.hasError("method_name")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
							<div
								className={
									this.hasError("method_name") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Form Dropdown Priority <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="number"
								name="form_dropdown_priority"
								value={this.state.form_dropdown_priority}
								onChange={this.handleChange}
								required
								className={
									this.hasError("form_dropdown_priority")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
							<div
								className={
									this.hasError("form_dropdown_priority") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								LOINC Code <span className="text-danger"> *</span>{" "}
							</label>
							<input
								type="text"
								name="loinc_code"
								value={this.state.loinc_code}
								onChange={this.handleChange}
								required
								className={
									this.hasError("loinc_code")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
							<div
								className={
									this.hasError("loinc_code") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
								Machine <span className="text-danger"> *</span>{" "}
							</label>
							<FormControl
								variant="outlined"
								style={{ width: "100%" }}
							>
								<InputLabel id="machine-label">
									Machine
								</InputLabel>
								<Select
									labelId="machine-label"
									id="machine-select"
									value={this.state.machine}
									onChange={this.handleChange}
									label="Test Type"
									className={this.hasError("machine") ? "select is-invalid" : ""}
									name="machine"
									multiple
									style={{ paddingBottom: "5px" }}
									renderValue={(selected) => {
										let displayStrings = [];
										Object.entries(testMachines).map(([key, value]) => {
											if (selected.includes(key)) {
												displayStrings.push(value);
											}
											return null;
										})
										return displayStrings.join(', ');
									}}
								>
									{
										Object.entries(testMachines).map(([key, value]) => {
											return (
												<MenuItem
													key={key}
													value={key}
												>
													<Checkbox checked={this.state.machine.includes(key) ? true : false} />
													{value}
												</MenuItem>
											);
										})
									}
								</Select>
							</FormControl>
							<div
								className={
									this.hasError("machine") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6">
						<div className="form-group">
							<label>
							</label>
							<MenuItem onClick={() => this.toggleState('hide_from_dropdown')} style={{ marginTop: "10px" }}>
								<Checkbox checked={this.state.hide_from_dropdown} />
								<div style={{ whiteSpace: "break-spaces" }}>Hide from dropdown</div>
							</MenuItem>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label>
								Order Code Description
							</label>
							<input
								type="text"
								name="order_code_description"
								value={this.state.order_code_description}
								onChange={this.handleChange}
								required
								className={
									this.hasError("order_code_description")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label>
								Collection Requirements and Patient Prep Information
							</label>
							<input
								type="text"
								name="collection_requirements"
								value={this.state.collection_requirements}
								onChange={this.handleChange}
								required
								className={
									this.hasError("collection_requirements")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-group">
							<label>
								Specimen Transport Temperature
							</label>
							<input
								type="text"
								name="specimen_transport_temperature"
								value={this.state.specimen_transport_temperature}
								onChange={this.handleChange}
								required
								className={
									this.hasError("specimen_transport_temperature")
										? "form-control is-invalid"
										: "form-control "
								}
							/>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="form-group">
							<label>
								CPT Codes <i className="fa fa-plus-circle btn" onClick={() => this.addItem("cpt_codes")}></i>
							</label>
							<ul>
								{
									this.state.cpt_codes && this.state.cpt_codes.length > 0
									&&
									this.state.cpt_codes.map((code, index) => {
										return <li key={index}>{code} <i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("cpt_codes", index)}></i></li>
									})
								}
							</ul>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="form-group">
							<label>
								Diagnosis Codes <i className="fa fa-plus-circle btn" onClick={() => this.addArrayItem("diagnosis_codes", { code: "", text: "" })}></i>
							</label>
							<ul>
								{
									this.state.diagnosis_codes && this.state.diagnosis_codes.length > 0
									&&
									this.state.diagnosis_codes.map((diagnosis_code, index) => {
										return <li key={index}>
											<div className="d-flex align-items-center">
												<input className="form-control" style={{ width: "22%" }} placeholder="Code" value={diagnosis_code.code} onChange={(e) => this.handleSubObjectItem(e, "code", index, "diagnosis_codes")} />
												<input className="form-control ml-2" style={{ width: "62%" }} placeholder="Text" value={diagnosis_code.text} onChange={(e) => this.handleSubObjectItem(e, "text", index, "diagnosis_codes")} />
												<i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("diagnosis_codes", index)}></i>
											</div>
										</li>
									})
								}
							</ul>
							<div
								className={
									this.hasError("results") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="">
							<label>
								Results <i className="fa fa-plus-circle btn" onClick={() => this.addItem("results")}></i>
							</label>
							<ul>
								{
									this.state.results && this.state.results.length > 0
									&&
									this.state.results.map((result, index) => {
										return <li key={index}>{result} <i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("results", index)}></i></li>
									})
								}
							</ul>
							<div
								className={
									this.hasError("results") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="form-group">
							<label>
								Sample Types <i className="fa fa-plus-circle btn" onClick={() => this.addItem("sample_types")}></i>
							</label>
							<ul>
								{
									this.state.sample_types && this.state.sample_types.length > 0
									&&
									this.state.sample_types.map((sample_type, index) => {
										return <li key={index}>{sample_type} <i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("sample_types", index)}></i></li>
									})
								}
							</ul>
							<div
								className={
									this.hasError("sample_types") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="form-group">
							<label>
								Symptoms <i className="fa fa-plus-circle btn" onClick={() => this.addItem("symptoms")}></i>
							</label>
							<ul>
								{
									this.state.symptoms && this.state.symptoms.length > 0
									&&
									this.state.symptoms.map((symptom, index) => {
										return <li key={index}>{symptom} <i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("symptoms", index)}></i></li>
									})
								}
							</ul>
							<div
								className={
									this.hasError("symptoms") ? "inline-errormsg" : "hidden"
								}
							>
								<i className="fa fa-exclamation-circle" aria-hidden="true">
									&nbsp;This field is required.
								</i>
							</div>
						</div>
					</div>
					<div className="col-12 input-box">
						<div className="form-group">
							<label>
								HCPCS Codes for MPS <i className="fa fa-plus-circle btn" onClick={() => this.addArrayItem("hcpcs_codes", { code: "", text: "" })}></i>
							</label>
							<ul>
								{
									this.state.hcpcs_codes && this.state.hcpcs_codes.length > 0
									&&
									this.state.hcpcs_codes.map((hcpcs_code, index) => {
										return <li key={index}>
											<div className="d-flex align-items-center">
												<input className="form-control" style={{ width: "22%" }} placeholder="Code" value={hcpcs_code.code} onChange={(e) => this.handleSubObjectItem(e, "code", index, "hcpcs_codes")} />
												<input className="form-control ml-2" style={{ width: "62%" }} placeholder="Text" value={hcpcs_code.text} onChange={(e) => this.handleSubObjectItem(e, "text", index, "hcpcs_codes")} />
												<i className="fa fa-times-circle btn" onClick={() => this.removeArrayItem("hcpcs_codes", index)}></i>
											</div>
										</li>
									})
								}
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div
						className="col-12"
						style={{
							paddingTop: "10px",
							borderTop: "1px solid rgba(0,0,0,.2",
						}}
					>
						{
							['spmobileprods', 'creid'].includes(this.userName)
							&&
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.saveChanges}
							>
								Save Changes
							</Button>
						}
						<Button
							style={{ float: "right" }}
							variant="secondary"
							onClick={this.props.handleClose}
						>
							Close
						</Button>
					</div>
				</div>
			</div >
		);
	}
}
