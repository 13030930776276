import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import {
	stateReports,
} from "../../../../services/clinicPortalServices/reportService";
import { getViewSettings, saveViewDefault } from "../../../../services/clinicPortalServices/userViewSettings";
import { dateComparator, getUserDetails } from "../../../../services/common/util";
import ReportsSearchMenu from "./ReportsSearchMenu";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicStateReportsGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchFilters: {
				from_date: moment().subtract(0, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'today',
				stateReportsType: "ALL",
			},
			gridName: "StateReport",
			pageSize: "",
			columnDefs: [
				{
					headerName: "Test_Name",
					minWidth: 200,
					resizable: true,
					field: "test_id.name"
				},
				{
					headerName: "Result",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.results.value === 'Positive SARS-CoV-2') {
							return 'Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 Not Detected') {
							return 'Not Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 Inconclusive') {
							return 'Inconclusive';
						} else if (params.data.results.value === 'SARS-CoV-2 POSITIVE') {
							return 'Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 NEGATIVE') {
							return 'Not Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 INVALID') {
							return 'Invalid Result';
						}
					},
				},
				{
					headerName: "Unit",
					minWidth: 80,
					resizable: true,
				},
				{
					headerName: "Order_Number",
					field: "lab_order_id",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Collect_Date",
					field: "test_info.collected",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.test_info && params.data.test_info.collected
							? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY")
							: "";
					},
					comparator: dateComparator
				},
				{
					headerName: "Date_Verified",
					field: "results.released",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.results.released
							? moment(params.data.results.released, "YYYYMMDDHHmmss").format(
								"MM/DD/YYYY"
							)
							: "";
					},
					comparator: dateComparator
				},
				{
					headerName: "Pt_Number",
					field: "patient_id.mrn",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_Fname",
					field: "patient_id.first_name",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_Lname",
					field: "patient_id.last_name",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Date_of_Birth",
					field: "patient_id.date_of_birth",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.patient_id.date_of_birth
							? moment(params.data.patient_id.date_of_birth, "YYYY-MM-DD").format(
								"MM/DD/YYYY"
							)
							: "";
					},
					comparator: dateComparator
				},
				{
					headerName: "Sex",
					field: "patient_id.gender",
					minWidth: 100,
					resizable: true,
				},
				{
					headerName: "Pt_Race",
					field: "patient_id.race",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_Phone",
					field: "patient_id.mobile",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_St1",
					field: "patient_id.address.address1",
					minWidth: 250,
					resizable: true,
				},
				{
					headerName: "Pt_St2",
					field: "patient_id.address.address2",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_City",
					field: "patient_id.address.city",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_ST",
					field: "patient_id.address.state",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Pt_Zip",
					field: "patient_id.address.zip",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Ordering_Facility",
					field: "facility_source",
					minWidth: 250,
					resizable: true,
				},
				{
					headerName: "Last Name, First Name",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider) {
							return params.data.provider.last_name + ', ' + params.data.provider.first_name;
						}
					},
				},
				{
					headerName: "Dt_St1",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider && params.data.provider.address && params.data.provider.address.address1) {
							return params.data.provider.address.address1;
						} else {
							return params.data.facility_id.address ? params.data.facility_id.address.address1 : '';
						}
					},
				},
				{
					headerName: "Dt_St2",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider && params.data.provider.address && params.data.provider.address.address2) {
							return params.data.provider.address.address2;
						} else {
							return params.data.facility_id.address ? params.data.facility_id.address.address2 : '';
						}
					},
				},
				{
					headerName: "Dt_City",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider && params.data.provider.address && params.data.provider.address.city) {
							return params.data.provider.address.city;
						} else {
							return params.data.facility_id.address ? params.data.facility_id.address.city : '';
						}
					},
				},
				{
					headerName: "Dt_ST",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider && params.data.provider.address && params.data.provider.address.state) {
							return params.data.provider.address.state;
						} else {
							return params.data.facility_id.address ? params.data.facility_id.address.state : '';
						}
					},
				},
				{
					headerName: "Dt_Zip",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.provider && params.data.provider.address && params.data.provider.address.zip) {
							return params.data.provider.address.zip;
						} else {
							return params.data.facility_id.address ? params.data.facility_id.address.zip : '';
						}
					},
				},
				{
					headerName: "Dt_Ph",
					field: "provider.mobile",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Ref_range",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.results.value === 'Positive SARS-CoV-2') {
							return 'Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 Not Detected') {
							return 'Not Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 Inconclusive') {
							return 'Inconclusive';
						} else if (params.data.results.value === 'SARS-CoV-2 POSITIVE') {
							return 'Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 NEGATIVE') {
							return 'Not Detected';
						} else if (params.data.results.value === 'SARS-CoV-2 INVALID') {
							return 'Invalid Result';
						}
					},
				},
				{
					headerName: "Specimen_Source",
					field: "test_info.test_type",
					minWidth: 200,
					resizable: true,
				},
				{
					headerName: "Reporting_Facility",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						return 'American Gene Technologies Inc.';
					},
				},
				{
					headerName: "Reporting_CLIA",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						return '21D2206868';
					},
				},
				{
					headerName: "Order_Date",
					field: "order_date",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						return params.data.order_date
							? moment(params.data.order_date, "YYYYMMDDHHmmss").format(
								"MM/DD/YYYY"
							)
							: "";
					},
					comparator: dateComparator
				},
				{
					headerName: "Test_Code",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						return '94500-6';
					},
				},
				{
					headerName: "Result_Code",
					minWidth: 150,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.results.value === 'Positive SARS-CoV-2') {
							return '260373001';
						} else if (params.data.results.value === 'SARS-CoV-2 Not Detected') {
							return '260415000';
						} else if (params.data.results.value === 'SARS-CoV-2 Inconclusive') {
							return '419984006';
						} else if (params.data.results.value === 'SARS-CoV-2 POSITIVE') {
							return '260373001';
						} else if (params.data.results.value === 'SARS-CoV-2 NEGATIVE') {
							return '260415000';
						} else if (params.data.results.value === 'SARS-CoV-2 INVALID') {
							return '455371000124106';
						}
					},
				},
				{
					headerName: "Device Identifier",
					minWidth: 160,
					resizable: true,
				},
				{
					headerName: "Pt_Ethnicity",
					field: "patient_id.ethnicity",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Ordering_Provider_NPI",
					field: "provider.npi",
					minWidth: 200,
					resizable: true,
				},
				{
					headerName: "Patient_County",
					field: "patient_id.county",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Performing_Facility",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						return 'American Gene Technologies Inc.';
					},
				},
				{
					headerName: "Performing_Facility_CLIA",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						return '21D2206868';
					},
				},
				{
					headerName: "Performing_Facility_Zip",
					minWidth: 250,
					resizable: true,
					valueGetter: function addColumns(params) {
						return '20850';
					},
				},
				{
					headerName: "First_test",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Healthcare_Worker",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Symptomatic",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Symptom_Onset",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "Hospitalized",
					minWidth: 150,
					resizable: true,
				},
				{
					headerName: "ICU",
					minWidth: 100,
					resizable: true,
				},
				{
					headerName: "Congregate_Care_Setting",
					minWidth: 200,
					resizable: true,
				},
				{
					headerName: "Pregnant",
					minWidth: 120,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.patient_id.is_pregnant) {
							return 'Yes';
						} else {
							return 'No';
						}
					},
				},
			],
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
		};
	}

	updateDateRange = (dateRange) => {
		let filters = this.state.searchFilters;
		if (filters.dateRange !== dateRange) {
			filters.dateRange = dateRange;
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					break;
				default:
					break;
			}
			this.setState({ searchFilters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData();
			}
		}
	}

	setDateRange = (searchFilters) => {
		if (moment(searchFilters.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'today';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'week';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'month';
			} else {
				searchFilters.dateRange = 'custom';
			}
		} else {
			searchFilters.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		let filters = this.state.searchFilters;
		let filterIsValid = true;

		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}

		if (filterIsValid) {
			this.setDateRange(filters);
			this.setState({ searchFilters: filters });
			this.loadGridData();
		}
	}

	handleFiltersChange = (e) => {
		const filters = this.state.searchFilters;

		switch (e.target.name) {
			case "type":
				filters.stateReportsType = e.target.value;
				break;
			default:
				break;
		}

		this.setState({ searchFilters: filters });
		this.loadGridData();
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridSchema();
	};

	loadGridData = () => {
		let searchFilters = this.state.searchFilters;
		if (this.gridApi) {
			this.gridApi.showLoadingOverlay();
		}
		stateReports(searchFilters)
			.then((response) => {
				let rowData = [];
				rowData = response.data;
				this.setState({ rowData: rowData });
				if (!rowData.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.StateReportGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.StateReportGridExported
		};
		createAudit(auditData);
	};

	onPageSizeChanged = () => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	loadGridSchema = () => {
		let userId = window.localStorage.getItem("USER_ID");
		let params = {
			user_id: userId,
			type: settingConstants.GRID,
			page: settingConstants.STATE_REPORTS
		}
		getViewSettings(params)
			.then((response) => {
				const columnState =
					response.data && response.data.length > 0 &&
					response.data[0].grid_views.find((item) => {
						return item.name === this.state.gridName;
					}).columns;
				if (columnState) {
					this.gridColumnApi.applyColumnState({
						state: columnState,
						applyOrder: true,
					});
				} else {
					this.gridColumnApi.resetColumnState();
				}

				const pageSize =
					response.data && response.data.length > 0 &&
					response.data[0].grid_views.find((item) => {
						return item.name === this.state.gridName;
					}).page_size;
				document.getElementById("page-size").value =
					pageSize && pageSize > 0 ? pageSize : 20;
				this.onPageSizeChanged();
			})
	};

	saveState = () => {
		let userId = window.localStorage.getItem("USER_ID");
		const columnState = this.gridColumnApi.getColumnState();
		let pageSize = document.getElementById("page-size").value;
		let saveObject = {
			user_id: userId,
			type: settingConstants.GRID,
			page: settingConstants.STATE_REPORTS,
			grid_views: [
				{
					name: this.state.gridName,
					page_size: pageSize,
					columns: columnState,
				},
			],
		};
		saveViewDefault(saveObject)
			.then(() => {
				toastr.success("Saved successfully.");
			})
	};

	resetState = () => {
		this.gridColumnApi.resetColumnState();
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";

		const filters = this.state.searchFilters;

		filters.stateReportsType = "ALL";

		filters.from_date = moment().subtract(0, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';

		this.setState({ searchFilters: filters });
		this.loadGridData();
	};

	render() {
		return (
			<div className="clinic-contain">
				<ReportsSearchMenu
					handleFiltersChange={this.handleFiltersChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					from_date={this.state.searchFilters.from_date}
					to_date={this.state.searchFilters.to_date}
					date_range={this.state.searchFilters.dateRange}
					onFilterTextChange={this.onFilterTextChange}
					stateReportsType={this.state.searchFilters.stateReportsType}
					clearFilter={this.clearFilter}
					onPageSizeChanged={this.onPageSizeChanged}
					saveState={this.saveState}
					resetState={this.resetState}
					onBtnExport={this.onBtnExport}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 290px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicStateReportsGrid;
