import React, { Component } from "react";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { Link } from "react-router-dom";

class ReportsBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-6 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Audit & Reports
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Failed SMS/Email Reports
									</li>
								</ol>
							</nav>
							<div style={{ display: "flex" }}>
								<h2 className="breadcrumb-title">Failed SMS/Email Reports</h2>
								<FormControlLabel
									value="includeArchived"
									control={<Switch checked={this.props.isArchived} onChange={this.props.onIsArchivedInformation} />}
									label="Show Archives"
									labelPlacement="end"
									className="m-0 show-archives-control breadcrumb-control"
									style={{ color: "white" }}
								/>
							</div>
						</div>
						<div className="col-md-6 col-12">
							<div className="row breadcrumb-btns">
								<ul className="export-ul">
									<li>
										<TextField
											style={{ width: "100px" }}
											label="Page Size"
											variant="outlined"
											className="breadcrumb-pagesize breadcrumb-mui-component"
											id="page-size"
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											size="small"
											margin="none"
											onChange={this.props.onPageSizeChanged}
										/>
									</li>
									<li>
										<button
											className="btn"
											style={{ color: 'white' }}
											onClick={() => this.props.saveState()}
										>
											<i className="far fa-save"></i> Save
										</button>
									</li>
									<li>
										<button
											className="btn"
											style={{ color: 'white' }}
											onClick={() => this.props.resetState()}
										>
											{" "}
											<i className="fa fa-repeat"></i> Default
										</button>
									</li>
									<li>
										<button
											className="btn"
											style={{ color: 'white' }}
											onClick={() => this.props.onBtnExport()}
										>
											<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ReportsBreadcrumb;
