import React, { Component } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import DeliveryPickupOrderBreadcrumb from "./vipPaymentBreadcrumb";
import { MobileDatePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";

class VipPaymentSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		};
	}

	render() {
		return (
			<div>
				<DeliveryPickupOrderBreadcrumb />
				<div className="row" style={{ padding: "12px" }}>
					<div className="col-md-3 mt-2">
						<MobileDatePicker
							value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
							onChange={(dateTime) => this.setState({ from_date: dateTime })}
							onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
							onClose={() => this.setState({ pickerOpened: false })}
							onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
							label="From Date"
							renderInput={props => <TextField
								{...props}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<EventIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>}
						/>
					</div>
					<div className="col-md-3 mt-2">
						<MobileDatePicker
							value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
							onChange={(dateTime) => this.setState({ to_date: dateTime })}
							onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
							onClose={() => this.setState({ pickerOpened: false })}
							onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
							label="To Date"
							renderInput={props => <TextField
								{...props}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton edge="end">
												<EventIcon />
											</IconButton>
										</InputAdornment>
									)
								}}
							/>}
						/>
					</div>
					<div className="col-md-2">
						<TextField
							label="Current Grid Search"
							variant="outlined"
							className="form-control"
							id="reset-form"
							InputLabelProps={{
								shrink: true,
							}}
							type="string"
							margin="dense"
							onChange={this.props.onFilterTextChange}
						/>
					</div>
					<div className="col-md-2">
						<button
							className="btn btn-primary button-info-grid"
							onClick={() => this.props.clearFilter()}
						>
							<i className="fa fa-times" aria-hidden="true"></i> Clear Filter
						</button>
					</div>
					<div className="col-md-2" style={{ display: "flex", justifyContent: "flex-end" }}>
						<div>
							<button
								className="btn btn-primary button-info-grid"
								onClick={() => this.props.onBtnExport()}
							>
								<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default VipPaymentSearchMenu;
