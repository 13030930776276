import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import PhysicianDetails from "./physicianDetails";
import { deletePhysician, lockPhysician, unLockPhysician } from "../../../../services/clinicPortalServices/physicianServices";
import { getUserRole } from "../../../../services/common/util";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			physicianDetails: {},
			physicianId: props.data._id,
			physicianNpi: props.data.npi,
			errors: [],
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this physician!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const physicianInfo = {
					_id: this.state.physicianId,
					first_name: this.props.data.first_name,
					last_name: this.props.data.last_name
				};
				deletePhysician(physicianInfo)
					.then((response) => {
						this.props.context.componentParent.loadGridData();
					})
			}
		});
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Physician
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Physician
		</Tooltip>
	);

	renderTooltipLock = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Lock Physician
		</Tooltip>
	)

	lockPhysician = () => {
		Swal.fire({
			title: 'Lock Physician',
			text: 'You are about to lock ' + this.props.data.first_name + ' ' + this.props.data.last_name,
			icon: 'info',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				lockPhysician(this.state.physicianId).then(PhysicianRes => {
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	}

	renderTooltipUnLock = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Release Lockd Physician
		</Tooltip>
	)

	unLockPhysician = () => {
		unLockPhysician(this.state.physicianId).then(PhysicianRes => {
			this.props.context.componentParent.loadGridData();
		})
	}

	render() {
		return (
			<div>
				{
					!(/customerserviceuser/.test(getUserRole().toLowerCase())) &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-pen"></i>
						</button>
					</OverlayTrigger>
				}
				{/* {
					!(/customerserviceuser/.test(getUserRole().toLowerCase())) &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipDelete}
					>
						<button onClick={this.handleDelete} className="edit-order-btn">
							<i className="fas fa-trash"></i>
						</button>
					</OverlayTrigger>
				} */}

				{
					!(/customerserviceuser|agtadmin/.test(getUserRole().toLowerCase()))
					&&
					<>
						{
							this.props.data.locked === "locked"
							&&
							<>
								<OverlayTrigger
									placement="top"
									delay={{ show: 50, hide: 100 }}
									overlay={this.renderTooltipUnLock}
								>
									<button onClick={this.unLockPhysician} className="edit-order-btn">
										<i className="fas fa-lock-open"></i>
									</button>
								</OverlayTrigger>
							</>
						}
						{
							this.props.data.locked !== "locked"
							&&
							<OverlayTrigger
								placement="top"
								delay={{ show: 50, hide: 100 }}
								overlay={this.renderTooltipLock}
							>
								<button onClick={this.lockPhysician} className="edit-order-btn">
									<i className="fas fa-lock"></i>
								</button>
							</OverlayTrigger>
						}
					</>
				}
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Physician Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PhysicianDetails
							physicianId={this.state.physicianId}
							physicianNpi={this.state.physicianNpi}
							context={this.props.context}
							handleClose={this.handleClose}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
