import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { phoneNumberFormatter, isValidEmail, isValidPhoneNumber } from "../../../services/common/util";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
import { getDetailsFacility, submitError } from "../../../services/patientPortalServices/loginService";
import { FormControl, InputLabel, MenuItem, Select, TextField, IconButton, InputAdornment } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";

class PatientPortalIssueContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            headString: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            dob: moment().format("MM/DD/YYYY"),
            facilityInfo: [],
            facilitySelected: "",
            errors: [],
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    }

    componentDidMount() {
        let headString = `<h4 style="line-height: 1.5em;"><span style="color: rgb(39,43,65);background-color: rgb(255,255,255);font-size: 1.125rem;font-family: Poppins, sans-serif;">Please</span> <span style="color: rgb(255,1,0);background-color: rgb(255,255,255);font-size: 1.125rem;font-family: Poppins, sans-serif;"><strong>fill out the form</strong></span> <span style="color: rgb(39,43,65);background-color: rgb(255,255,255);font-size: 1.125rem;font-family: Poppins, sans-serif;">with the information you used to register on MyResultNow or that you gave to the provider at the time of your test.</span></h4>`;
        this.setState({ headString });
    }

    continue = (e) => {
        let errors = [];
        if (!this.state.firstName) {
            errors.push("firstName");
        }
        if (!this.state.lastName) {
            errors.push("lastName");
        }
        if (this.state.email && !isValidEmail(this.state.email)) {
            errors.push("invalidEmail");
        }
        if (this.state.phone && !isValidPhoneNumber(this.state.phone)) {
            errors.push("invalidPhoneNumber");
        }
        if (!this.state.dob) {
            errors.push("birthday");
        }
        if (errors.length) {
            this.setState({ errors: errors })
        } else {
            this.setState({ errors: [] })
            const { email, phone } = this.state;
            if (email.replace(/\s/g, '') === "" && phone.replace(/\s/g, '') === "") {
                Swal.fire({
                    customClass: {
                        container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
                    },
                    title: 'Email or Phone number required',
                    text: "You need to fill out either Email or Phone number that you signed up with before.",
                    icon: 'warning',
                    confirmButtonText: 'Close'
                }).then((result) => {
                })
                return;
            } else if (moment().format("YYYYMMDD") === moment(this.state.dob).format("YYYYMMDD")) {
                Swal.fire({
                    customClass: {
                        container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
                    },
                    title: 'Birthday Error',
                    text: "Birthdate can not be today's date.",
                    icon: 'warning',
                    confirmButtonText: 'Close'
                }).then((result) => {
                })
                return;
            } else {
                this.nextStep();
            }
        }
    };

    back = (e) => {
        e.preventDefault();
        this.prevStep();
    };

    handleArrayItemChange = (e) => {
        let value = e.target.value;
        this.setState({ facilitySelected: value, errors: [] })
    }

    nextStep = () => {
        let patientInfo = {};
        if (isValidEmail(this.state.email)) {
            patientInfo.email = this.state.email;
        } else {
            patientInfo.mobile = this.state.phone;
        }
        getDetailsFacility(patientInfo)
            .then((res) => {
                let patients = res.data;
                let facilityInfo = [];
                if (patients.length > 0) {
                    patients.forEach(patient => {
                        if (patient.facility_id) {
                            facilityInfo.push(patient.facility_id);
                        }
                    });
                    if (facilityInfo.length > 0) {
                        facilityInfo = facilityInfo.reduce((unique, o) => {
                            if (!unique.some(obj => obj._id === o._id)) {
                                unique.push(o);
                            }
                            return unique;
                        }, []);
                        this.setState({
                            step: 2,
                            facilityInfo
                        });
                    }
                    else {
                        this.errorMsg();
                    }
                } else {
                    this.errorMsg();
                }
            })
            .catch((error) => {
                this.errorMsg();
            });
    };

    errorMsg = () => {
        Swal.fire({
            customClass: {
                container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
            },
            title: "No Matching Record",
            html: `Please email <a href="mailto: results@myresultnow.com" style="text-decoration: underline;">results@myresultnow.com</a> as we can't find you in the system.`,
            icon: 'warning',
            confirmButtonText: 'Close'
        });
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    walkSteps = () => {
        const { step } = this.state;
        const {
            firstName,
            lastName,
            email,
            phone,
            dob,
            facilityInfo,
            facilitySelected,
        } = this.state;

        switch (step) {
            case 1:
                return (
                    <div>
                        <div className="row" style={{ justifyContent: "center" }}>
                            <div className="col-12">
                                <div className="card row-bg-color ">
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h2 className="card-title">Your Information</h2>
                                            <p className="card-info">
                                                Please enter your information that you signed up with before
                                            </p>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="signup-label-font">
                                                        Full Name <span className="text-danger"> *</span>
                                                    </label>

                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="firstName"
                                                        value={firstName}
                                                        onChange={this.handleChange("firstName")}
                                                    />
                                                    <label className="home-page-label">
                                                        First Name
                                                    </label>
                                                    {
                                                        this.hasError("firstName")
                                                        &&
                                                        <div
                                                            type="invalid"
                                                            className="inline-errormsg"
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field is required.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label></label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="lastName"
                                                        style={{ marginTop: "8px" }}
                                                        value={lastName}
                                                        onChange={this.handleChange("lastName")}
                                                    />
                                                    <label className="home-page-label">
                                                        Last Name
                                                    </label>
                                                    {
                                                        this.hasError("lastName")
                                                        &&
                                                        <div
                                                            type="invalid"
                                                            className="inline-errormsg"
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field is required.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="signup-label-font">
                                                        Email <span className="text-danger"> *</span>
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        value={email}
                                                        onChange={this.handleChange("email")}
                                                    />
                                                    <label className="home-page-label">
                                                        {" "}
                                                        example@example.com{" "}
                                                    </label>
                                                    {
                                                        this.hasError("invalidEmail")
                                                        &&
                                                        <div
                                                            type="invalid"
                                                            className="inline-errormsg"
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                Enter a valid email.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="signup-label-font">
                                                        Phone <span className="text-danger"> *</span>{" "}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="tel"
                                                        name="phone"
                                                        value={phone}
                                                        placeholder="(XXX) XXX-XXXX"
                                                        onChange={this.handleChange("phone")}
                                                    />
                                                    {
                                                        this.hasError("invalidPhoneNumber")
                                                        &&
                                                        <div
                                                            type="invalid"
                                                            className="inline-errormsg"
                                                        >
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field is required.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="signup-label-font">
                                                        Date of Birth
                                                        <span className="text-danger"> *</span>
                                                    </label>

                                                    <MobileDatePicker
                                                        value={dob}
                                                        onChange={date => this.handleDateChange(date.$d)}
                                                        renderInput={props => <TextField
                                                            {...props}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end">
                                                                            <EventIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />}
                                                    />
                                                    {
                                                        this.hasError("birthday")
                                                        &&
                                                        <div className="inline-errormsg">
                                                            <i
                                                                className="fa fa-exclamation-circle"
                                                                aria-hidden="true"
                                                            >
                                                                &nbsp;This field is required.
                                                            </i>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row next-button btn-patientinfo-next">
                                                <Button
                                                    className="btn-pagebreak-next"
                                                    type="button"
                                                    onClick={this.continue}
                                                    id="btnNext"
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div style={{ textAlign: "center", fontSize: "18px" }}>
                        <div className="form-bottom-border form-row">
                            {
                                facilityInfo.length === 1
                                &&
                                <div>
                                    We see that your received a test at {facilityInfo[0].name}.
                                    <br />
                                    If you click submit we will send an email and SMS to that facility to inform them you are unable to login and they will be in touch to help you.
                                    <br />
                                    <br />
                                </div>
                            }
                            {
                                facilityInfo.length > 1
                                &&
                                <div>
                                    We see that your received a test at facilities in the following dropdown.
                                    <br />
                                    If you choose one and click submit we will send an email and SMS to that facility to inform them you are unable to login and they will be in touch to help you.
                                    <br />
                                    <br />
                                    <div className="col-12 text-left">
                                        <div className="form-group">
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: "100%", marginTop: "5px" }}
                                            >
                                                <InputLabel id="facility-label">Select Facility</InputLabel>
                                                <Select
                                                    labelId="facility-label"
                                                    id="facility-select"
                                                    value={facilitySelected}
                                                    onChange={(e) => this.handleArrayItemChange(e)}
                                                    label="Select Facility"
                                                    name="facilitySelected"
                                                >
                                                    {
                                                        facilityInfo.map((fac, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={fac.name}
                                                                >
                                                                    {fac.name}&nbsp;{
                                                                        fac.archived === "archived"
                                                                        &&
                                                                        <b>{" ( Archived )"}</b>
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            <div className={this.hasError("facilitySelected") ? "inline-errormsg" : "hidden"}>
                                                <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                    &nbsp;This field is required.
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row next-button">
                            <div>
                                <Button
                                    className="btn-pagebreak-previous"
                                    onClick={this.back}
                                >
                                    Back
                                </Button>
                            </div>
                            <div>
                                <div>
                                    <Button
                                        className="btn-pagebreak-submit"
                                        type="button"
                                        onClick={this.handleSubmit}
                                        id="btnSubmit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return <h1>User Forms not working.</h1>;
        }
    }

    handleDateChange = (date) => {
        this.setState({ dob: date });
    }

    handleChange = (input) => (e) => {
        const value = e.target.value;
        switch (input) {
            case "phone":
                this.setState((prevState) => ({
                    phone: phoneNumberFormatter(value, prevState.phone),
                }));
                break;

            default:
                this.setState({ [input]: e.target.value });
                break;
        }
    };

    handleSubmit = () => {
        const { facilityInfo, facilitySelected, firstName, lastName, email, phone, dob } = this.state;
        let patientInfo = { firstName, lastName, email, phone, dob };
        let facilities = [];
        if (facilityInfo.length > 1) {
            if (facilitySelected === "") {
                this.setState({ errors: ['facilitySelected'] });
                return;
            } else {
                facilities = facilityInfo.filter(facility => facility.name === facilitySelected);
            }
        } else {
            facilities = facilityInfo;
        }
        let submitInfo = { facilities, patientInfo };
        submitError(submitInfo)
            .then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        customClass: {
                            container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
                        },
                        title: 'Message sent successfully',
                        text: "Message was sent to the facility successfully. They will provide the result or get in touch with you soon.",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        this.props.history.push("/");
                        return;
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        return (
            <div className="content login-content">
                <div className="container">
                    <div className="banner-text">Patient Login Request</div>
                    <div className="mt-3 text-center">
                        <div className="paragraph_html" dangerouslySetInnerHTML={{ __html: this.state.headString }} />
                    </div>
                    <div className="card mt-3 gr2">
                        <div className="card-body">
                            {this.walkSteps()}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default PatientPortalIssueContainer;

