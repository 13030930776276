import { apiInterfaceForJson } from "../common/apiManager";

export const getCompanyData = () => {
    return apiInterfaceForJson("/company/v1", "GET");
};

export const getCompanyWithFacility = (hasArchived) => {
    return apiInterfaceForJson("/company/v1/facility", "POST", { archived: hasArchived });
};

export const getCompanyDataById = (companyId) => {
    return apiInterfaceForJson("/company/v1/" + companyId, "GET");
};

export const createCompany = (companyDetails) => {
    let companyInfo = {
        "address": {
            'address1': companyDetails.address1,
            'address2': companyDetails.address2,
            'city': companyDetails.city,
            'state': companyDetails.state,
            'zip': companyDetails.zip,
            'country': companyDetails.country
        },
        'name': companyDetails.name,
        'contact_mobile': companyDetails.contact_mobile,
        'contact_email': companyDetails.contact_email,
        'contact_name': companyDetails.contact_name,
        'cost_per_order': companyDetails.cost_per_order,
        'facilityIds': companyDetails.facilityIds,
        'invoice_processing': companyDetails.invoice_processing
    };
    return apiInterfaceForJson("/company/v1/", "POST", companyInfo);
};

export const updateCompany = (companyDetails) => {
    let updateCompanyInfo = {
        "address": {
            'address1': companyDetails.address1,
            'address2': companyDetails.address2,
            'city': companyDetails.city,
            'state': companyDetails.state,
            'zip': companyDetails.zip,
            'country': companyDetails.country
        },
        '_id': companyDetails.id,
        'name': companyDetails.name,
        'contact_mobile': companyDetails.contact_mobile,
        'contact_email': companyDetails.contact_email,
        'contact_name': companyDetails.contact_name,
        'cost_per_order': companyDetails.cost_per_order,
        'facilityIds': companyDetails.facilityIds,
        'invoice_processing': companyDetails.invoice_processing
    };
    return apiInterfaceForJson("/company/v1/", "PUT", updateCompanyInfo);
};

export const deleteCompany = (companyDetails) => {
    let companyInfo = {
        '_id': companyDetails.id,
        'name': companyDetails.name
    };
    return apiInterfaceForJson("/company/v1/delete", "POST", companyInfo);
};