import { serviceConstants } from "../common/constants";
import { getUserAuthToken } from "../common/util";
import { apiInterfaceForJson } from "../common/apiManager";

export const authenticateAndFetchDriverDetails = (driverUserName, password) => {
	return apiInterfaceForJson("/driver/v1/authenticate", "POST", {
		driver_user_name: driverUserName,
		password: password,
	});
};


export const sendForgetPasswordEmail = (email, link) => {
	return apiInterfaceForJson("/driver/v1/sendForgetPasswordEmail", "POST", {
		email: email,
		link: link
	});
};

export const resetPassowrd = (driverId, forgetPasswordKey, password) => {
	return apiInterfaceForJson("/driver/v1/resetPassword", "POST", {
		driverId: driverId,
		forgetPasswordKey: forgetPasswordKey,
		password: password
	});
};

export const createPassowrd = (driverId, createPasswordKey, password) => {
	return apiInterfaceForJson("/driver/v1/createPassword", "POST", {
		driverId: driverId,
		createPasswordKey: createPasswordKey,
		password: password
	});
};


export const authenticateSecurityNumberAuthentication = (driverId, securityNumber) => {
	return apiInterfaceForJson("/driver/v1/twoFactorAuthentication", "POST", {
		driverId: driverId,
		securityNumber: securityNumber,
	});
};

export const logoutDriver = () => {
	logout();
}

export const goDriverLogin = () => {
	window.localStorage.clear();
	window.location.href = "/driverportal";
}

export const logout = () => {
	let token = getUserAuthToken();
	return fetch(`${serviceConstants.API_HOST_NAME}/driver/v1/logout`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
	}).then(response => {
		if (response.ok) {
			window.localStorage.clear();
			window.location.href = "/driverportal";
			return response.json();
		} else {
			throw response;
		}
	}).catch(function (error) {
		window.localStorage.clear();
		window.location.href = "/driverportal";
	});
};
