import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';

export const ThemeContext = React.createContext(
  (themeName) => { }
);

const ThemeProviderWrapper = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'Light';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = () => {
    const themeNameTemp = themeName === 'Light' ? 'Dark' : 'Light';
    localStorage.setItem('appTheme', themeNameTemp);
    _setThemeName(themeNameTemp);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={{ setThemeName, themeName }}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
