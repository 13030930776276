import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { getPatientPhotoUrls } from "../../services/clinicPortalServices/patientEditService";
import { ModalStyled } from "../../theme/customizedStyleComponents";

export default class ImageBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		const { images, insurance_provider, insured_member_id, insured_group_number } = this.props.insurance;
		const { first_name, last_name, driver_license_image, driver_license, driver_license_state } = this.props.patient;
		const { urlLarge, urlSmall } = getPatientPhotoUrls(this.props.type === "driver_license" ? driver_license_image : images[this.props.type]);
		return (
			<>
				<img role="button" src={urlSmall} alt="Failed loading" height="100%" onClick={this.handleShow} />
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title className="image-popup-title">
							{
								this.props.type === "driver_license" ?
									<div className="d-flex align-items-center">
										<h5 className="mr-2"><strong>Patient: </strong>{first_name} {last_name}</h5>
										<h5 className="mr-2"><strong>Driver License Number: </strong>{driver_license}</h5>
										<h5 className="mr-2"><strong>Driver License State: </strong>{driver_license_state}</h5>
									</div>
									:
									<>
										<div className="d-flex align-items-center">
											<h5 className="mr-2"><strong>Patient: </strong>{first_name} {last_name}</h5>
											<h5 className="mr-2"><strong>Member ID: </strong>{insured_member_id}</h5>
											<h5 className="mr-2"><strong>Group Number: </strong>{insured_group_number}</h5>
										</div>
										<div className="d-flex">
											<h5><strong>Provider: </strong>{insurance_provider}</h5>
										</div>
										<div className="d-flex">
											<h5 className="mr-2"><strong>Driver License Number: </strong>{driver_license}</h5>
											<h5 className="mr-2"><strong>Driver License State: </strong>{driver_license_state}</h5>
										</div>
									</>
							}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						<img src={urlLarge} alt="Failed loading" className="mb-2" />
						<div>
							<a href={urlLarge} target='_blank' rel="noreferrer" className="pt-4"><strong>Download Full Image</strong></a>
						</div>
					</Modal.Body>
				</ModalStyled>
			</>
		);
	}
}