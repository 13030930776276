import React, { Component } from "react";
import { Modal, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { fetchInventories, updateInventory, sendSMSNotificationAfterRequestKit, sendEmailNotificationAfterRequestKit, sendBrowserNotificationAfterRequestKit } from "../../../services/clinicPortalServices/inventoryService";
import { fetchFacilitiesForOrders } from "../../../services/clinicPortalServices/facilityServices";
import { fetchLogisticNotificationsByDay } from "../../../services/clinicPortalServices/logisticNotificationService";
import toastr from 'toastr';
import { getUserDetails } from "../../../services/common/util";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class RequestKit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			requestFacility: "",
			requestKitCount: "",
			inventories: [],
			errors: [],
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	}

	componentDidMount() {
		fetchInventories().then(response => {
			let inventoryArray = [];
			let inventories = response.data;
			fetchFacilitiesForOrders().then(response => {
				let facilities = response.data.filter(item => item.archived !== 'archived');
				facilities.map(facility => {
					if (inventories.filter(inventory => inventory.facility_id._id === facility._id).length) {
						inventoryArray.push(inventories.filter(inventory => inventory.facility_id._id === facility._id)[0]);
					}
					return null;
				})
				if (inventoryArray.length) {
					this.setState({ inventories: inventoryArray });
				}
			})
		})
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({
			show: false,
			requestFacility: "",
			requestKitCount: "",
		});
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.value) {
			this.setState({ errors: this.state.errors.filter(item => item !== e.target.name) })
		}
	}

	renderTooltipKit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Request Kit
		</Tooltip>
	);

	completeRequestPickup = () => {
		let errors = [];
		if (!this.state.requestKitCount) {
			errors.push('requestKitCount');
		}
		if (!this.state.requestFacility) {
			errors.push('requestFacility');
		}
		if (errors.length) {
			this.setState({ errors: errors });
			return;
		} else {
			this.setState({ errors: [] });
		}
		const inventoryData = {
			_id: this.state.requestFacility,
			requested_kit_count: this.state.requestKitCount,
			request_kit: true,
			facility_id: this.state.inventories.filter(inventory => inventory._id === this.state.requestFacility)[0].facility_id._id
		}
		updateInventory(inventoryData).then(res => {
			this.handleClose();
			toastr.success("Successfully requested");
		})

		if (getUserDetails()) {
			const userDetails = JSON.parse(getUserDetails());
			const superAdminEmails = ['creid@americangene.com', 'wduong@americangene.com'];
			const userName = userDetails.first_name + ' ' + userDetails.last_name;
			const facilityName = this.state.inventories.filter(inventory => inventory._id === this.state.requestFacility)[0].facility_id.name;
			sendEmailNotificationAfterRequestKit(superAdminEmails, userName, facilityName, this.state.requestKitCount);
			sendBrowserNotificationAfterRequestKit(userName, facilityName, this.state.requestKitCount);
			fetchLogisticNotificationsByDay().then(res => {
				if(res?.data?.length > 0) {
					const superAdminMobiles = res.data;
					superAdminMobiles.map(superAdminMobile => {
						sendSMSNotificationAfterRequestKit(superAdminMobile, userName, facilityName, this.state.requestKitCount);
						return null;
					});
				}
			});
			
		}
	}

	render() {
		return (
			<div>
				{
					this.state.inventories.length > 0
					&&
					<OverlayTrigger
						placement="bottom"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipKit}
					>
						<i
							className="fa fa-vial fa-2x header-icon"
							aria-hidden="true"
							onClick={this.handleShow}
						>
						</i>
					</OverlayTrigger>
				}
				<ModalStyled 
					centered
					size="md" 
					show={this.state.show} 
					onHide={this.handleClose} 
					backdrop="static"
				>
					<Modal.Header closeButton="true">
						<Modal.Title>Request Kit</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<FormControl
										variant="outlined"
										style={{ width: "100%", marginTop: "5px" }}
									>
										<InputLabel>Select Facility</InputLabel>
										<Select
											name="requestFacility"
											value={this.state.requestFacility}
											onChange={this.handleChange}
											label="Select Facility"
										>
											{
												this.state.inventories.length
												&&
												this.state.inventories.map((inventory, index) => {
													return <MenuItem key={index} value={inventory._id}>
														{inventory.facility_id.name}
													</MenuItem>
												})
											}
										</Select>
									</FormControl>
									<div className={this.hasError("requestFacility") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<FormControl
										variant="outlined"
										style={{ width: "100%", marginTop: "5px" }}
									>
										<InputLabel>Select Kit Count</InputLabel>
										<Select
											name="requestKitCount"
											value={this.state.requestKitCount}
											onChange={this.handleChange}
											label="Select Kit Count"
										>
											{
												[50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map((count, index) => {
													return <MenuItem key={index} value={count}>
														{count}
													</MenuItem>
												})
											}
										</Select>
									</FormControl>
									<div className={this.hasError("requestKitCount") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => this.completeRequestPickup()} disabled={(this.state.requestFacility && this.state.requestKitCount) ? false : true}>
							Complete Request
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
