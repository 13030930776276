import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOrderDataById } from "../../../services/clinicPortalServices/orderEditService";
import Swal from "sweetalert2";
import { getRowColumnIndexForQuantPlate, getCellIndexForQuantPlate, getUserAuthToken, dateComparator, popupCenter } from "../../../services/common/util";
import Barcode from "react-barcode";
import moment from "moment";
import { quantPlateRows, quantPlateColumns } from "../../../services/common/optionsData";
import { serviceConstants } from "../../../services/common/constants";
import { AgGridReact } from "ag-grid-react";
import { TextField } from "@mui/material";
import { ThemeContext } from "../../../theme/ThemeProvider";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class QuantPlateMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            showCurrentListPopup: false,
            cellRow: '',
            cellColumn: '',
            cellIndex: '',
            accessionedBy: '',
            accessionedTime: '',
            orderDetails: null,
            rowData: [],
            columnDefs: [
                {
                    headerName: "Position",
                    minWidth: 100,
                    field: "index",
                    valueGetter: params => {
                        const { row, column } = getRowColumnIndexForQuantPlate(params.data.index);
                        return quantPlateRows[row] + quantPlateColumns[column]
                    }
                },
                {
                    headerName: "Requisition",
                    minWidth: 80,
                    field: "orderId.lab_order_id"
                },
                {
                    headerName: "Sample",
                    minWidth: 100,
                    field: "orderId.test_info.sample"
                },
                {
                    headerName: "Collected Date",
                    minWidth: 50,
                    valueGetter: params => {
                        if (params.data.orderId && params.data.orderId.test_info) {
                            return moment(params.data.orderId.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")
                        }
                    },
                    dateComparator: dateComparator
                },
                {
                    headerName: "Patient Name",
                    minWidth: 50,
                    valueGetter: params => {
                        if (params.data.orderId && params.data.orderId.patient_id) {
                            return params.data.orderId.patient_id.first_name + " " + params.data.orderId.patient_id.last_name
                        }
                    }
                },
            ],
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            context: { componentParent: this }
        };
    }

    handleShow = (cellIndex, cellData) => {
        const { row, column } = getRowColumnIndexForQuantPlate(cellIndex);
        if (cellData.is_control_spot) {
            this.setState({
                orderDetails: cellData,
                cellRow: quantPlateRows[row],
                cellColumn: quantPlateColumns[column],
                showPopup: true,
                cellIndex: cellIndex
            })
        } else {
            if (cellData.orderId) {
                getOrderDataById(cellData.orderId._id).then(res => {
                    if (res.data) {
                        this.setState({
                            orderDetails: res.data,
                            cellRow: quantPlateRows[row],
                            cellColumn: quantPlateColumns[column],
                            showPopup: true,
                            cellIndex: cellIndex,
                            accessionedBy: cellData.accessioned_by,
                            accessionedTime: cellData.dateTime
                        })
                    }
                })
            }
        }
    }

    onHide = () => {
        this.setState({ showPopup: false });
    }

    printBarcodeForPlate = (plateName) => {
        window.open(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + plateName + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken());
    }

    unAccession = () => {
        const orderInfo = this.state.orderDetails;
        Swal.fire({
            title: "Would like to unaccession sample " + orderInfo.test_info.sample + "?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                cancelButton: 'order-1',
                confirmButton: 'order-2'
            }
        }).then(result => {
            if (result.isConfirmed) {
                this.props.unAssignOrderFromPlate(orderInfo, this.props.plateData, this.state.cellIndex);
                this.onHide();
            }
        })
    }

    convertArray = (cells) => {
        let cellsArray = [];
        for (let [key, value] of Object.entries(cells)) {
            if (value) {
                value.index = key;
                const { row, column } = getRowColumnIndexForQuantPlate(key);
                value.wellIndex = (row - 1) * (quantPlateColumns.length - 1) + column;
                cellsArray.push(value);
            }
        }
        return cellsArray;
    }

    showCurrentList = () => {
        let cellsArray = this.convertArray(this.props.plateData.cells);
        cellsArray = cellsArray.filter(item => !item.is_control_spot && item.orderId && item.orderId._id);
        if (cellsArray.length) {
            this.setState({
                showCurrentListPopup: true,
                rowData: cellsArray
            })
        }
    }

    onHideCurrentListPopup = () => {
        this.setState({ showCurrentListPopup: false });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onFilterTextChange = (e) => {
        this.gridApi.setQuickFilter(e.target.value);
    };

    showBarcode = () => {
        if (this.state.orderDetails && !this.state.orderDetails.is_control_spot) {
            popupCenter(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + this.state.orderDetails.test_info.sample + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken(), this.state.orderDetails.test_info.sample, "800", "600")
        }
    }

    render() {
        let barcodeValue = "";
        if (this.state.orderDetails) {
            if (this.state.orderDetails.is_control_spot) {
                barcodeValue = this.state.orderDetails.control_spot_type + this.state.orderDetails.dateTime;
            } else {
                barcodeValue = this.state.orderDetails.test_info.sample;
            }
        }
        let btnClassName = 'btn-info';
        if (this.props.type === 'vip') {
            btnClassName = 'btn-primary'
        }
        return (
            <div className="d-flex align-items-center justify-content-center mt-3" style={{ flexDirection: "column" }}>
                {
                    this.props.pageName === 'accessioning'
                    &&
                    <div>
                        <button className={"btn text-center " + btnClassName} onClick={() => this.props.createNewPlate(this.props.type)}>Create New</button>
                        {
                            this.props.plateData
                            &&
                            <button className={"btn text-center ml-2 " + btnClassName} onClick={() => this.props.closePlate(this.props.type)}>Close</button>
                        }
                    </div>
                }
                {
                    this.props.plateData
                    &&
                    <div className={"plate-table"}>
                        <table>
                            <tbody>
                                {
                                    quantPlateRows.map((row, index) => {
                                        return <tr key={index}>
                                            {
                                                quantPlateColumns.map((column, i) => {
                                                    let cellIndex = null;
                                                    let className = '';
                                                    if (i >= 1 && index >= 1) {
                                                        cellIndex = getCellIndexForQuantPlate(index - 1, i - 1);
                                                    }
                                                    if (this.props.plateData.cells && this.props.plateData.cells[cellIndex]) {
                                                        className = 'assigned-cell'
                                                    }
                                                    if (i !== 0 && index !== 0) {
                                                        className += ' cell'
                                                    } else {
                                                        className += ' constant'
                                                    }
                                                    if (cellIndex !== null && this.props.currentIndex !== null && cellIndex === this.props.currentIndex) {
                                                        className += ' current-cell-' + this.props.type;
                                                    }
                                                    return <td key={i} className={className} onClick={className.includes('assigned-cell') ? (() => this.handleShow(cellIndex, this.props.plateData.cells[cellIndex])) : null}>
                                                        {(i === 0 && index !== 0) ? row : ""}
                                                        {(i !== 0 && index === 0) ? column : ""}
                                                        {(i !== 0 && index !== 0 && this.props.plateData.cells && this.props.plateData.cells[cellIndex] && this.props.plateData.cells[cellIndex].is_control_spot ? this.props.plateData.cells[cellIndex].control_spot_type : "")}
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
                {
                    this.props.pageName === 'accessioning' && this.props.plateData
                    &&
                    <h4 className="text-center mt-3 " style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => this.showCurrentList()} >View Accessioned List</h4>
                }
                <br />
                {
                    this.props.pageName === 'accessioning'
                    &&
                    <h4 className="text-center">{this.props.type === 'regular' ? "NORMAL PLATE" : (this.props.type === 'vip' ? "VIP PLATE" : "")}</h4>
                }
                {
                    this.props.plateData && this.props.pageName === 'accessioning'
                    &&
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => this.printBarcodeForPlate(this.props.plateData.name)}
                    >
                        <Barcode
                            value={this.props.plateData.name}
                            format="CODE39"
                            width={1}
                            marginTop={0}
                        />
                    </div>
                }
                <ModalStyled
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showPopup}
                    onHide={this.onHide}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Sample Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="row">
                                <div className="col-md-5" onClick={this.showBarcode} style={{ cursor: "pointer" }}>
                                    <Barcode
                                        value={barcodeValue}
                                        format="CODE39"
                                        width={1}
                                        marginTop={0}
                                    />
                                </div>
                                {
                                    this.state.orderDetails && !this.state.orderDetails.is_control_spot
                                    &&
                                    <div className="col-md-7">
                                        <h4>{this.state.cellRow}{this.state.cellColumn} Sample</h4>
                                        <h5>Test Name: {this.state.orderDetails.test_id.name}</h5>
                                        <h5>Specimen Type: {this.state.orderDetails.test_info.test_type}</h5>
                                        <h5>Patient Full Name: {this.state.orderDetails.patient_id.first_name + " " + this.state.orderDetails.patient_id.last_name}</h5>
                                        <h5>Patient DOB: {moment(this.state.orderDetails.patient_id.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY")}</h5>
                                        <h5>Patient Gender: {this.state.orderDetails.patient_id.gender}</h5>
                                        <h5>Collection Date: {moment(this.state.orderDetails.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}</h5>
                                        <h5>Creation Date: {moment.tz(this.state.orderDetails.createdAt, "America/New_York").format("MM/DD/YYYY hh:mm A")}</h5>
                                        <h5>Created By: {this.state.orderDetails.provider.first_name + " " + this.state.orderDetails.provider.last_name}</h5>
                                        <h5>VIP Stauts: {this.state.orderDetails.is_vip_order ? "VIP Order" : "None"}</h5>
                                        {
                                            this.state.accessionedBy
                                            &&
                                            <h5>Accessioned By: {this.state.accessionedBy}</h5>
                                        }
                                        {
                                            this.state.accessionedTime
                                            &&
                                            <h5>Accessioned Time: {moment(this.state.accessionedTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}</h5>
                                        }
                                        {
                                            this.props.pageName === 'accessioning' && this.props.plateData && !this.props.plateData.is_exported
                                            &&
                                            <h5><div style={{ cursor: "pointer", textDecoration: "underline", float: "right" }} onClick={this.unAccession}>Un-accession This Sample</div></h5>
                                        }
                                    </div>
                                }
                                {
                                    this.state.orderDetails && this.state.orderDetails.is_control_spot
                                    &&
                                    <div className="col-md-7">
                                        <h4>{this.state.cellRow}{this.state.cellColumn} Sample</h4>
                                        <h5>Specimen Type: {this.state.orderDetails.control_spot_type}</h5>
                                        <h5>Control: Yes</h5>
                                        <h5>Creation Date: {moment(this.state.orderDetails.dateTime, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}</h5>
                                        <h5>Created By: {this.props.plateData ? this.props.plateData.createdBy : ''}</h5>
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onHide}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
                <ModalStyled
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCurrentListPopup}
                    onHide={this.onHideCurrentListPopup}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.props.plateData ? this.props.plateData.name : ""}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <div style={{ width: "300px" }}>
                                        <TextField
                                            label="Current Grid Search"
                                            variant="outlined"
                                            className="form-control"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="string"
                                            size="small"
                                            onChange={this.onFilterTextChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ThemeContext.Consumer>
                                {({ themeName }) => (
                                    <div
                                        id="currentListGrid"
                                        style={{
                                            height: "400px",
                                            width: "100%",
                                        }}
                                        className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
                                    >
                                        <AgGridReact
                                            columnDefs={this.state.columnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            masterDetail={true}
                                            onGridReady={this.onGridReady}
                                            rowData={this.state.rowData}
                                            pagination={true}
                                            paginationPageSize={20}
                                            paginationNumberFormatter={this.state.paginationNumberFormatter}
                                            context={this.state.context}
                                        />
                                    </div>
                                )}
                            </ThemeContext.Consumer>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.onHideCurrentListPopup}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </div>
        );
    }
}
