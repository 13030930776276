import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import 'moment-timezone';
import { dateComparator, getRowColumnIndexForQuantPlate, getPlateCellCount } from "../../../services/common/util";
import { getPlateTemplateAllData } from "../../../services/limsPortalServices/plateTemplateService";
import { quantPlateRows, quantPlateColumns } from "../../../services/common/optionsData";
import SearchMenu from "./searchMenu";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import { ThemeContext } from "../../../theme/ThemeProvider";

class LIMSPlateTemplatesGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			pageSize: "",
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 150,
					resizable: true,
					cellRenderer: "EditBtnCellRenderer"
				},
				{
					headerName: "Name",
					minWidth: 180,
					resizable: true,
					valueGetter: function (params) {
						let control_spots = []
						if (params.data.control_spots && getPlateCellCount(params.data.control_spots)) {
							for (const [key, value] of Object.entries(params.data.control_spots)) {
								if (value) {
									const { row, column } = getRowColumnIndexForQuantPlate(key);
									control_spots.push(
										quantPlateRows[row] + quantPlateColumns[column] + " (" + value + ")"
									);
								}
							}
						}
						return control_spots.join(', ');
					}
				},
				{
					headerName: "Control Spots",
					minWidth: 180,
					resizable: true,
					valueGetter: function (params) {
						let control_spots = []
						if (params.data.control_spots && getPlateCellCount(params.data.control_spots)) {
							for (const [key, value] of Object.entries(params.data.control_spots)) {
								if (value) {
									const { row, column } = getRowColumnIndexForQuantPlate(key);
									control_spots.push(
										quantPlateRows[row] + quantPlateColumns[column] + " (" + value + ")"
									);
								}
							}
						}
						return control_spots.join(', ');
					}
				},
				{
					headerName: "Created At",
					minWidth: 180,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.createdAt) {
							return moment.tz(params.data.createdAt, "America/New_York").format("MM/DD/YYYY hh:mm A");
						} else {
							return "";
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Is Default?",
					minWidth: 180,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.is_default) {
							return "YES";
						} else {
							return "NO";
						}
					},
					comparator: dateComparator
				},
			],
			components: {
				EditBtnCellRenderer: EditBtnCellRenderer
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			importing: false,
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		getPlateTemplateAllData()
			.then((response) => {
				this.setState({ rowData: response.data });
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	render() {
		return (
			<div className="lims-container">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					addTemplate={this.addTemplate}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 350px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default LIMSPlateTemplatesGrid;
