import { serviceConstants } from "../common/constants";
import { apiInterfaceForJson } from "../common/apiManager";
import moment from 'moment';

export const filterDeliveryPickupOrders = (params) => {
    return apiInterfaceForJson("/deliverypickuporder/v1/filter", "POST", params);
}

export const createDeliveryPickupOrder = (deliveryPickupOrderDetails) => {
    let deliveryPickupOrderInfo = {
        facility_id: deliveryPickupOrderDetails.facility_id,
        driver_id: deliveryPickupOrderDetails.driver_id,
        start_time: deliveryPickupOrderDetails.start_time,
        end_time: deliveryPickupOrderDetails.end_time,
        type: deliveryPickupOrderDetails.type,
        isCompleted: deliveryPickupOrderDetails.isCompleted
    };
    if (deliveryPickupOrderDetails.kit_count) {
        deliveryPickupOrderInfo.kit_count = deliveryPickupOrderDetails.kit_count;
    }
    if (deliveryPickupOrderDetails.earliest_arrival_time) {
        deliveryPickupOrderInfo.earliest_arrival_time = deliveryPickupOrderDetails.earliest_arrival_time;
    }
    return apiInterfaceForJson("/deliverypickuporder/v1/", "POST", deliveryPickupOrderInfo);
};

export const updateDeliveryPickupOrder = (deliveryPickupOrderDetails) => {
    return apiInterfaceForJson("/deliverypickuporder/v1/", "PUT", deliveryPickupOrderDetails);
};

export const deleteDeliveryPickupOrder = (deliveryPickupOrderDetails) => {
    return apiInterfaceForJson("/deliverypickuporder/v1/delete", "POST", deliveryPickupOrderDetails);
};

export const sendNotificationAfterRequestPickup = (mobileNumber, facilityName, requestedTime, earliestArrivalTime) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = 'Hello,\n\nA new pick-up request was created on MyResultNow.\nIt is for ' + facilityName + ' at ' + requestedTime + '.\nEarliest Arrival Time is ' + earliestArrivalTime + '.\n\nYou must visit visual update page and assign this to a driver.';
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};

export const sendBrowserNotificationAfterRequestPickup = (facilityName, requestedTime) => {
    let messageData = {
        title: 'A new pick-up request was created',
        text: 'It is for ' + facilityName + ' at ' + requestedTime,
        target: 'superadmin'
    };
    return apiInterfaceForJson("/notifications/v1/browser", "POST", messageData);
};

export const sendNotificationAfterPickupCompleted = (mobileNumber, driverName, facilityName) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = '**Driver Pickup Notification** \n' + driverName + ' picked up samples at ' + facilityName + ' at ' + moment().format("MM/DD/YYYY hh:mm A") + '.';
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};

export const sendNotificationAfterDriverChanged = (mobileNumber, driverName) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = 'Hello ' + driverName + ', you have been assigned to a facility that was not originally in your route.  Please login to MyResultNow to view the new location and add make sure it is added to your route for today. \n' + serviceConstants.HOST_NAME + '/driverportal';
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};

export const sendNotificationAfterPickupDeleted = (mobileNumber, driverName) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = 'Hello ' + driverName + ', The next facility on your route did not have samples to pick up, It should be removed from your route and we are now checking the next one before you depart.';
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};

export const sendNotificationAfterConfirmedPickup = (mobileNumber, driverName, facilityName) => {
    let messageData = {};
    messageData.toMobile = mobileNumber;
    messageData.body = 'Hello ' + driverName + ', The lab has contacted the facility: ' + facilityName + ', please continue as normal.';
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};