import React, { Component } from "react";
import { FormControl, FormLabel, InputLabel, Select, MenuItem, TextField, IconButton, InputAdornment } from "@mui/material";
import OrderReportsBreadcrumb from "./orderReportsBreadcrumb";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import EventIcon from "@mui/icons-material/Event";

class OrderReportsMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<div>
				<OrderReportsBreadcrumb
					onPageSizeChanged={(event) => { this.props.onPageSizeChanged(event) }}
					saveState={() => { this.props.saveState() }}
					resetState={() => { this.props.resetState() }}
					onBtnExport={() => { this.props.onBtnExport() }}
					onIsArchivedInformation={(event) => { this.props.onIsArchivedInformation(event) }}
					isArchived={this.props.isArchived}
				/>
				<div className="row mt-3" style={{ padding: "12px" }}>
					<div className="filter-search-bar px-3">
						<div className="row">
							<div className="col-md-8">
								<div className="row pl-3" style={{ marginTop: "-15px" }}>
									<div className="col-md-12">
										<FormLabel component="legend" style={{ marginBottom: "10px" }}>
											Date Range
										</FormLabel>
									</div>
									<div className="dateRangeBar">
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'today' ? " active" : "")}
											onClick={() => this.props.updateDateRange('today')}
										>
											Today
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'week' ? " active" : "")}
											onClick={() => this.props.updateDateRange('week')}
										>
											Week
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'month' ? " active" : "")}
											onClick={() => this.props.updateDateRange('month')}
										>
											Month
										</button>
										<button
											className={"btn btn-info dateRangeBtn ml-1 mb-2" + (this.props.date_range === 'custom' ? " active" : "")}
										>
											Custom
										</button>
									</div>
									<div className="dateRangeBar dateRangeBarPickers">
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
											onChange={(dateTime) => this.setState({ from_date: dateTime })}
											onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
											label="From Date"
											renderInput={props => <TextField
												{...props}
												className="ml-1 mr-2 mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
											onChange={(dateTime) => this.setState({ to_date: dateTime })}
											onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
											label="To Date"
											renderInput={props => <TextField
												{...props}
												className="mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-4 text-right">
								<FormControl
									variant="outlined"
									className="filter-select-bar text-left"
								>
									<InputLabel
										id="facility-label"
									>
										Select Instrument Serial Number
									</InputLabel>
									<Select
										labelId="serialNumber-label"
										label="Select Instrument Serial Number"
										value={this.props.serialNumber}
										onChange={this.props.handleFiltersChange}
										name="serialNumber"
									>
										{
											this.props.serialNumbers && this.props.serialNumbers.map((serialNumber) => {
												return (
													<MenuItem
														key={serialNumber}
														value={serialNumber}
													>
														{serialNumber}
													</MenuItem>
												);
											})
										}
									</Select>
								</FormControl>
								<button
									className="btn btn-primary"
									onClick={() => this.props.clearFilter()}
								>
									<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OrderReportsMenu;
