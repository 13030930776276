import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import FileBrowse from "../../../patientSignup/signup/patientPhotoUploadInfo/fileBrowse";
import { uploadFiles } from "../../../../services/common/util";
import { easyUpdatePatient, triggerADTProcessForPatient, getPatientPhotoUrls } from "../../../../services/clinicPortalServices/patientEditService";
import moment from 'moment';
import { checkValidityLicense } from "../../../../services/common/util";
import { states } from "../../../../services/common/optionsData";
import Swal from "sweetalert2";
import { withTranslation } from 'react-i18next';
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class DriverLicensePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientId: props.data._id,
			driverLicense: props.data.driver_license,
			driverLicenseState: props.data.driver_license_state,
			driverLicFileName: props.data.driver_license_image,
			driverLicFile: null,
			errors: [],
			disableEdit: this.props.type === 'insuranceGrid' ? true : false,
			statesFailed: []
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.show && this.props.show !== prevProps.show) {
			this.setState({
				driverLicense: this.props.data.driver_license,
				driverLicenseState: this.props.data.driver_license_state,
				driverLicFileName: this.props.data.driver_license_image
			})
		}
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	handleClose = () => {
		this.props.handleClose();
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	handleFileDelete = () => {
		this.setState({
			driverLicFileName: this.props.data.driver_license_image,
			driverLicFile: null
		})
	}

	handleReadFile = (input) => (file) => {
		this.setState({
			driverLicFileName: file.name.substring(0, file.name.lastIndexOf(".")) + "_" + moment().format("YYYYMMDDHHmmss") + file.name.substring(file.name.lastIndexOf(".")),
			driverLicFile: file,
			statesFailed: []
		})
	};

	handleFileUpload = () => {
		uploadFiles(this.state)
			.then(res => {
				const { statesFailed } = res;
				if (statesFailed.length > 0) {
					this.setState({
						statesFailed: statesFailed
					});
					statesFailed.map(nameFailed => {
						this.setState({
							[nameFailed]: ''
						})
						return null;
					});
				}
				else {
					this.props.handlePDFfiles();
				}
			})
	};

	handleSave = () => {
		let errors = [];
		if (this.state.driverLicense && !checkValidityLicense(this.state.driverLicense)) {
			errors.push('invalidDriverLicense');
		}
		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			this.setState({ errors: [] });
			if (this.props.data && this.props.data.is_incorrect) {
				Swal.fire({
					title: 'This record was marked as incorrect.',
					html: 'Did you correct the information?',
					icon: 'question',
					width: 800,
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: "Yes, it's fixed",
					confirmButtonColor: '#dc3741',
					denyButtonText: 'No, save without fixing',
					denyButtonColor: '#7066e0',
					cancelButtonText: "No, I'll do it now",
					allowOutsideClick: false,
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
						cancelButton: 'order-1',
						denyButton: 'order-2 btn_info',
						confirmButton: 'order-3 btn_primary',
					}
				}).then((result) => {
					if (result.isConfirmed) {
						this.updateDriverLicense(true);
						triggerADTProcessForPatient({ patientId: this.state.patientId }).then(res => {
						})
					} else if (result.isDenied) {
						this.updateDriverLicense(false);
					} else {
						return;
					}
				});
			} else {
				this.updateDriverLicense(false);
			}
		}
	}

	updateDriverLicense = (isFixedIncorrect = null) => {

		let patientDetails = {
			_id: this.state.patientId,
			driver_license: this.state.driverLicense,
			driver_license_state: this.state.driverLicenseState,
			driver_license_image: this.state.driverLicFileName
		}
		if (isFixedIncorrect) {
			patientDetails.is_fixed_incorrect = true;
		}
		easyUpdatePatient(patientDetails).then(res => {
			if (this.state.driverLicFile) {
				this.handleFileUpload();
			}
			else {
				this.props.handlePDFfiles();
			}
		})
	}

	render() {
		const { statesFailed } = this.state;
		const { t } = this.props;
		return (
			<ModalStyled
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.handleClose}
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Driver License Information</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<form>
							<div className="row form-row">
								<div className="col-12">
									<div className="form-group">
										<label>
											Driver License <span className="text-danger"></span>{" "}
										</label>
										<input
											type="text"
											name="driverLicense"
											value={this.state.driverLicense}
											onChange={this.handleChange}
											required
											className={"form-control"}
											disabled={this.state.disableEdit}
										/>
										<div
											className={
												this.hasError("invalidDriverLicense") ? "inline-errormsg" : "hidden"
											}
										>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is invalid.
											</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label>
											Driver License State
										</label>
										<select
											name="driverLicenseState"
											value={this.state.driverLicenseState}
											onChange={this.handleChange}
											className="form-control"
											disabled={this.state.disableEdit}
										>
											{
												states.map((state) => {
													return (
														<option key={state.value} value={state.value}>
															{state.state}
														</option>
													);
												})
											}
										</select>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										{
											!this.state.disableEdit
											&&
											<label>
												Photo of your Driver License
											</label>
										}
										{
											this.props.data.driver_license_image
											&&
											<a
												className="upload-image-href pull-right"
												href={getPatientPhotoUrls(this.props.data.driver_license_image).urlLarge}
												rel="noreferrer"
												target="_blank"
											>
												<i className="fa fa-eye" aria-hidden="true"></i>{" "}
												View File
											</a>
										}
										{
											!this.state.disableEdit &&
											<>
												<FileBrowse
													handleFileChange={this.handleReadFile("driverLicFileName")}
													fileDelete={this.handleFileDelete}
													disabled={this.state.disableEdit}
													file={this.state.driverLicFile}
												/>
												{
													statesFailed.length > 0
													&&
													<label
														className="inline-errormsg"
														style={{ width: "100%" }}
													>
														<i
															className="fa fa-exclamation-circle"
															aria-hidden="true"
														>
															&nbsp;&nbsp;{t("Upload failed, please upload your .jpeg or .png image again")}
														</i>
													</label>
												}
											</>


										}
									</div>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={this.handleClose}
					>
						Close
					</Button>
					{
						!this.state.disableEdit &&
						<Button
							variant="primary"
							onClick={this.handleSave}
						>
							Save Changes
						</Button>
					}
				</Modal.Footer>
			</ModalStyled>
		);
	}
}
export default withTranslation()(DriverLicensePopup);

