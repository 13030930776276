import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';

import moment from "moment";
import EditBtnCellRenderer from "./editBtnCellRenderer";
import readXlsxFile from 'read-excel-file';
import { searchOrdersForSelfPayment } from "../../../../services/clinicPortalServices/orderSearchService";
import { importSelfPayDocument } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { serviceConstants, auditEnums } from "../../../../services/common/constants";
import { getUserRole, processCellForClipboard, defaultExcelExportParams, getUserDetails } from "../../../../services/common/util";
import SearchMenu from "./searchMenu";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from 'lodash';
import NoteCellRenderer from "../../invoices/ClinicInvoiceGrid/noteCellRenderer";
import Swal from "sweetalert2";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import toastr from "toastr";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

class ClinicSelfPaymentGrid extends Component {

	constructor(props) {
		super(props);

		this.state = {
			stripe: null,
			isArchived: false,
			user_role: getUserRole(),
			searchFilters: {
				facility_id: [],
				from_date: moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'custom',
				status_value: "outstanding",
				search_date: "service_date"
			},
			gridName: "Insurance",
			pageSize: "",
			columnDefs: [
				{
					headerName: "Patient Name",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.patient_id) {
							return (
								params.data.patient_id.first_name +
								" " +
								params.data.patient_id.last_name
							);
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Status",
					minWidth: 140,
					field: "payment.due_amount",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.payment && params.data.payment.due_amount) {
							if (params.data.payment.due_amount <= 0) {
								return 'Paid';
							} else {
								return "Outstanding";
							}
						} else {
							return "No Entry";
						}
					},
					cellRenderer: memo(params => {
						if (params.data.payment && params.data.payment.due_amount) {
							if (params.data.payment.due_amount <= 0) {
								return <div style={{ color: "rgb(18, 222, 18" }} >Paid(${params.data.payment.total_amount})</div>;
							} else {
								return <div style={{ color: "red" }} >Outstanding(${params.data.payment.due_amount})</div>;
							}
						} else {
							return <div>No Entry</div>;
						}
					})
				},
				{
					headerName: "MPS Payment Status",
					minWidth: 140,
					field: "mps_payment_id.due_amount",
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.mps_payment_id && params.data.mps_payment_id.due_amount) {
							if (params.data.mps_payment_id.due_amount <= 0) {
								return 'Paid';
							} else {
								return "Outstanding";
							}
						} else {
							return "No Entry";
						}
					},
					cellRenderer: memo(params => {
						if (params.data.mps_payment_id && params.data.mps_payment_id.due_amount) {
							if (params.data.mps_payment_id.due_amount <= 0) {
								return <div style={{ color: "rgb(18, 222, 18" }} >Paid(${params.data.mps_payment_id.total_amount})</div>;
							} else {
								return <div style={{ color: "red" }} >Outstanding(${params.data.mps_payment_id.due_amount})</div>;
							}
						} else {
							return <div>No Entry</div>;
						}
					})
				},
				{
					headerName: "Notes",
					field: "notes",
					minWidth: 90,
					resizable: true,
					cellRenderer: "noteCellRenderer",
				},
				{
					headerName: "Test",
					minWidth: 100,
					field: "test_id.name",
					resizable: true,
				},

				{
					headerName: "Test Type",
					minWidth: 150,
					field: "test_info.test_type",
					resizable: true,
				},
				{
					headerName: "Requisition",
					maxWidth: 150,
					minWidth: 100,
					field: "lab_order_id",
					resizable: true,
				},
				{
					colId: "collected_date",
					headerName: "Specimen Collected Date",
					minWidth: 200,
					resizable: true,
					field: "test_info.collected",
					cellRenderer: function (params) {
						return params.data.test_info && params.data.test_info.collected ? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
					},
					columnGroupShow: 'open'
				},
				{
					headerName: "Facility Source",
					minWidth: 150,
					resizable: true,
					field: "facility_id.name",
				},
				{
					headerName: "Insurance Provider",
					field: "patient_insurance_id.insurance_provider",
					minWidth: 150,
					sortable: true,
					resizable: true
				}
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
				noteCellRenderer: NoteCellRenderer
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			facilities: [],
		};
		getAllTests({ type: "all" }).then(res => {
			if (res && res.data) {
				let testIds = res.data.map(test => {
					return test._id;
				})
				this.testIds = testIds;
				this.setState({ tests: res.data, testIds: testIds }, () => {
					this.loadFacilities(this.state.isArchived);
				})
			}
		})
	}

	componentDidMount() {
		if (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin' || getUserRole().toLowerCase() === 'accountant') {
			let columnDefs = [{
				headerName: "Actions",
				maxWidth: 100,
				minWidth: 100,
				cellStyle: { textAlign: "center" },
				cellRenderer: "editBtnCellRenderer",
			}, ...this.state.columnDefs];
			this.setState({ columnDefs: columnDefs })
		}
		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
			});
		}
	}

	loadFacilities = (isArchived) => {
		const filters = this.state.searchFilters;
		let facilities = this.state.facilities;

		let isAllFacilities = false;
		if (filters.facility_id.length > 0 && filters.facility_id.length === facilities.length && facilities.length > 0) {
			isAllFacilities = true;
		}

		getCompanyWithFacility(isArchived)
			.then((response) => {
				let facilityIds = [];

				facilities = response.data;
				facilities = facilities.filter(item => !(item.invoice_processing && item.type === 'facility'));
				facilities = facilities.filter(facility => !(facility.type === 'company' && facilities.filter(item => item.parent_id === facility._id).length === 0));
				if (facilities.length > 0) {
					facilities.map(fac => {
						facilityIds.push(fac._id);
						return null;
					})
					this.facilityIds = facilityIds;
					filters.facility_id = facilityIds;
				} else {
					filters.facility_id = facilities[0]._id;
				}

				if (!isAllFacilities && window.localStorage.getItem('FACILITY_ID') && JSON.parse(window.localStorage.getItem('FACILITY_ID')).length) {
					filters.facility_id = JSON.parse(window.localStorage.getItem('FACILITY_ID'))
				}

				if (window.localStorage.getItem('FROM_DATE_RANGE')) {
					filters.from_date = window.localStorage.getItem('FROM_DATE_RANGE');
				}
				if (window.localStorage.getItem('TO_DATE_RANGE')) {
					filters.to_date = window.localStorage.getItem('TO_DATE_RANGE');
				}
				this.setState({ searchFilters: filters, facilities }, () => {
					this.loadGridData();
				});
			});
	};

	onIsArchivedInformation = (event) => {
		this.setState({ isArchived: event.target.checked });
		this.loadFacilities(event.target.checked);
	}

	updateDateRange = (dateRange) => {
		let filters = this.state.searchFilters;
		if (filters.dateRange !== dateRange) {
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				default: break;
			}
			this.setState({ searchFilters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData()
			}
		}
	}

	setDateRange = (searchFilters) => {
		if (moment(searchFilters.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'today';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				searchFilters = 'week';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'month';
			} else {
				searchFilters.dateRange = 'custom';
			}
		} else {
			searchFilters.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		let filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
				window.localStorage.setItem('FROM_DATE_RANGE', filters.from_date);
				window.localStorage.setItem('TO_DATE_RANGE', filters.to_date);
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
				window.localStorage.setItem('FROM_DATE_RANGE', filters.from_date);
				window.localStorage.setItem('TO_DATE_RANGE', filters.to_date);
			} else {
				filterIsValid = false;
			}
		}

		if (filterIsValid) {
			this.setDateRange(filters);
			this.setState({ searchFilters: filters });
			this.loadGridData();
		}
	}

	handleFiltersChange = (e) => {
		let filters = this.state.searchFilters;
		switch (e.target.name) {
			case "status_value": {
				filters.status_value = e.target.value;
				if (e.target.value !== 'paid') {
					filters.search_date = 'service_date';
				}
				break;
			}
			case "search_date": {
				filters.search_date = e.target.value;
				if (e.target.value === 'all_payment') {
					filters.status_value = 'paid';
				}
				if (e.target.value === 'all_outstanding') {
					filters.status_value = 'outstanding';
				}
				break;
			}
			case "facility_id": {
				let { facilities } = this.state;
				let facilityIdChanged = [], isAdded = false, facilitiesIn = [], facilityIdsTemp = [];
				let newFacilityIds = e.target.value;
				if (e.target.value.includes('all')) {
					if (filters.facility_id.length === this.facilityIds.length) {
						filters.facility_id = [];
					} else {
						filters.facility_id = _.cloneDeep(this.facilityIds);
					}
				} else {
					if (filters.facility_id.length > newFacilityIds.length) {
						facilityIdChanged = filters.facility_id.find(val => !newFacilityIds.includes(val));
					} else {
						facilityIdChanged = newFacilityIds.find(val => !filters.facility_id.includes(val));
						isAdded = true;
					}
					let facilityChanged = facilities.find(facility => facility._id === facilityIdChanged);
					facilityIdsTemp = _.cloneDeep(filters.facility_id);
					// If company is changed 
					if (facilityChanged.type === 'company') {
						let companyChanged = facilityChanged;
						facilities.forEach(fac => {
							if (fac.parent_id === companyChanged._id) {
								facilitiesIn.push(fac._id);
							}
						});
						let index = facilityIdsTemp.indexOf(companyChanged._id);
						if (facilitiesIn.length > 0) {
							// If company is selected
							if (isAdded) {
								// Add company Id
								facilityIdsTemp.push(companyChanged._id);
								// Add facility Ids
								facilitiesIn.forEach(facId => {
									if (!facilityIdsTemp.includes(facId)) {
										facilityIdsTemp.push(facId);
									}
								});
							} else { // If company is unselected
								// Remove company Id
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
								facilitiesIn.forEach(facId => {
									let index = facilityIdsTemp.indexOf(facId);
									if (index > -1) {
										facilityIdsTemp.splice(index, 1);
									}
								});
							}
						} else {
							isAdded ? facilityIdsTemp.push(companyChanged._id) : facilityIdsTemp.splice(index, 1);
						}
					} else { // If facility is changed
						let companyId = facilityChanged.parent_id;
						let facilitiesIn = [];
						facilities.forEach(fac => {
							if (fac.parent_id === companyId)
								facilitiesIn.push(fac._id);
						});
						// If facility Id is selected
						if (isAdded) {
							// Add facility Id
							facilityIdsTemp.push(facilityChanged._id);
							if (!filters.facility_id.includes(companyId)) {
								facilityIdsTemp.push(companyId);
							}
						} else { // If facility Id is unselected
							// Remove facility Id
							let index = facilityIdsTemp.indexOf(facilityChanged._id);
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
							// Remove company Id
							let facilitiesInFilters = [];
							facilitiesIn.forEach(fac => {
								if (facilityIdsTemp.includes(fac)) {
									facilitiesInFilters.push(fac);
								}
							});
							if (facilitiesInFilters.length === 0) {
								index = facilityIdsTemp.indexOf(companyId);
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
							}
						}
					}
					filters.facility_id = _.cloneDeep(facilityIdsTemp);
					window.localStorage.setItem('FACILITY_ID', JSON.stringify(filters.facility_id));
				}
				break;
			}
			case "testIds": {
				let testIds = this.state.testIds;
				if (e.target.value.includes('all')) {
					if (testIds.length === this.state.tests.length) {
						testIds = [];
					} else {
						testIds = _.cloneDeep(this.testIds);
					}
				} else {
					testIds = e.target.value;
				}
				this.setState({ testIds: testIds }, () => {
					this.loadGridData();
				});
				break;
			}
			default: break;
		}

		this.setState({ searchFilters: filters });
		if (e.target.name !== "testIds") {
			this.loadGridData();
		}
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	checkRealFacilities = (searchFilters) => {
		let realFacilities = [];
		searchFilters.facility_id.map(facilityId => {
			if (this.state.facilities.find(facility => facility._id === facilityId)) {
				realFacilities.push(facilityId);
			}
			return null;
		});
		return realFacilities;
	}

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		let searchFilters = this.state.searchFilters;
		searchFilters.facility_id = this.checkRealFacilities(this.state.searchFilters);
		searchFilters.testIds = this.state.testIds;
		searchOrdersForSelfPayment(searchFilters)
			.then((response) => {
				this.setState({ rowData: response.data });
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
				this.gridColumnApi.resetColumnState();
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.SelfPaymentGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.SelfPaymentGridExported
		};
		createAudit(auditData);
	};

	onPageSizeChanged = () => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	saveState = () => {
		console.log("save state for self payment");
	};

	resetState = () => {
		this.gridColumnApi.resetColumnState();
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";

		const filters = this.state.searchFilters;

		filters.status_value = "outstanding";
		filters.search_date = "service_date";

		filters.from_date = moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		if (this.state.facilities.length > 1) {
			filters.facility_id = this.facilityIds;
		} else {
			filters.facility_id = this.state.facilities[0]._id;
		}
		window.localStorage.removeItem("FACILITY_ID");
		window.localStorage.removeItem("FROM_DATE_RANGE");
		window.localStorage.removeItem("TO_DATE_RANGE");

		this.setState({ searchFilters: filters, testIds: this.testIds });
		this.loadGridData();
	};

	importDocument = (e) => {
		boundActions.startBlockUILoading();
		readXlsxFile(e.target.files[0]).then((rows) => {

			// patient_mrn <=> 0, patient_name <=> 1, patient_dob <=> 2, collected_date <=> 4, facility_name <=> 5, physician_name <=> 6, physician_npi: 7, received_date <=> 8, diagnosis_codes <=> 9, released_date <=> 10, amount <=> 11
			if (rows[0][0] === "Patient ID" && rows[0][1] === "Patient Name" && rows[0][2] === "DOB" && rows[0][3] === "Test" && rows[0][4] === "Specimen Collected Date" && rows[0][5] === "Facility Source" && rows[0][6] === "Physician" && rows[0][7] === "NPI" && rows[0][8] === "Received Date" && rows[0][9] === "Code" && rows[0][10] === "Released Date" && rows[0][11] === "Amount") {
				importSelfPayDocument(rows).then(res => {
					boundActions.endBlockUILoading();
					if (res.RESULT === 'SUCCESS') {
						let html;
						if (res.unmatchedFilePath) {
							html = "File is imported successfully, following link is unmatched items spreadsheet, please download it if you need, thanks <a href='" + serviceConstants.HOST_NAME + '/public_files/' + res.unmatchedFilePath + "' style='color: #1f74b6' target='_blank'>" + serviceConstants.HOST_NAME + '/public_files/' + res.unmatchedFilePath + "</a>";
						} else {
							html = "All records were successfully updated";
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "File Imported Successfully",
							html: html,
							icon: 'success',
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						}).then((result) => {
							this.loadGridData();
						});
						const userData = JSON.parse(getUserDetails());
						const auditData = {
							identifier: auditEnums.IDENTIFIERS.ImportRecord,
							event_type: auditEnums.EVENTTYPES.SelfPaymentGridDocumentImported,
							user_id: userData._id,
							user_name: userData.user_name + " (" + userData.role + ")",
							update_string: auditEnums.EVENTTYPES.SelfPaymentGridDocumentImported
						};
						createAudit(auditData);
					}
				})
			} else {
				boundActions.endBlockUILoading();
				toastr.warning("Document format is not acceptable, please download sample spreadsheet and refer it.")
			}
		});
		document.getElementById('import_document').value = "";
	}

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu
					onIsArchivedInformation={(event) => { this.onIsArchivedInformation(event) }}
					isArchived={this.state.isArchived}
					facilityId={this.state.searchFilters.facility_id}
					handleFiltersChange={this.handleFiltersChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					facilities={this.state.facilities}
					from_date={this.state.searchFilters.from_date}
					to_date={this.state.searchFilters.to_date}
					date_range={this.state.searchFilters.dateRange}
					onFilterTextChange={this.onFilterTextChange}
					status_value={this.state.searchFilters.status_value}
					search_date={this.state.searchFilters.search_date}
					clearFilter={this.clearFilter}
					onPageSizeChanged={this.onPageSizeChanged}
					saveState={this.saveState}
					resetState={this.resetState}
					onBtnExport={this.onBtnExport}
					importDocument={this.importDocument}
					tests={this.state.tests}
					testIds={this.state.testIds}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 310px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									processCellForClipboard={processCellForClipboard}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									defaultExcelExportParams={defaultExcelExportParams}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicSelfPaymentGrid;
