import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class NoticeHistoryCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		}
	}

	handleShow = () => {
		if (this.props.data.noticeHistory && this.props.data.noticeHistory.length) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		let noticeHistoryLength = 0;
		if (this.props.data.noticeHistory && this.props.data.noticeHistory.length) {
			noticeHistoryLength = this.props.data.noticeHistory.length;
		}
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					{
						noticeHistoryLength === 0
						&&
						"No History"
					}
					{
						noticeHistoryLength > 0
						&&
						noticeHistoryLength + " Log" + (noticeHistoryLength > 1 ? "s" : "")
					}
				</button>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Order Notice History</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.props.data.noticeHistory && this.props.data.noticeHistory.length
								&&
								this.props.data.noticeHistory.map((item, index) => {
									let itemTitle = item.title;
									if (itemTitle === 'To Facility For Strep Release') {
										itemTitle = itemTitle.replace('Strep', this.props.data.test_id.name);
									}
									return <div key={index}>{moment(item.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")} - {itemTitle} - {item.destination}</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
