import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { orderEasyUpdate } from "../../../../services/clinicPortalServices/orderEditService";
import { createMpsPayment, getMpsPaymentDataById, updateMpsPayment, getCheckoutSessionIdForMpsPayment } from "../../../../services/clinicPortalServices/mpsPaymentServices";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, TextField, InputAdornment, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from "@mui/icons-material/Event";
import Swal from "sweetalert2";
import PatientDetails from "../../patients/clinicPatientGrid/patientDetails";
import InsuranceDetails from "../../insurance/clinicInsuranceGrid/insuranceDetails";
import toastr from "toastr";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientName: props.data.patient_id?.first_name + " " + props.data.patient_id?.last_name,
			orderDate: moment(props.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY"),
			orderFacility: props.data.facility_source,
			showPaymentInfo: false,
			showBillingPopup: false,
			showPatientPopup: false,
			showInsurancePopup: false,
			mode: props.data.mps_payment_id ? "update" : "create",
			errors: [],
			chart_number: "",
			location: "",
			total_amount: 0,
			paid_amount: 0,
			due_amount: 0,
			status: "",
			notes: [],
			records: [],
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	handleShow = (type) => {
		if (type === 'showBillingPopup') {
			this.setState({ showBillingPopup: true });
		} else {
			this.setState({ showPaymentInfo: true });
		}
		if (this.state.mode === 'update') {
			getMpsPaymentDataById(this.props.data.mps_payment_id._id).then(res => {
				if (res && res.data) {
					this.setState({
						chart_number: res.data.chart_number,
						location: res.data.location,
						total_amount: res.data.total_amount,
						paid_amount: res.data.paid_amount,
						due_amount: res.data.due_amount,
						status: Number(res.data.due_amount) > 0 ? "Outstanding" : "Paid",
						notes: res.data.notes,
						records: res.data.records,
						paid_from: res.data.paid_from,
						card_holder_info: res.data.card_holder_info
					})
				}
			})
		}
	}

	handleShowPatientPopup = () => {
		this.setState({ showPatientPopup: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	}

	handleShowInsurancePopup = () => {
		this.setState({ showInsurancePopup: true });
	}

	addNote = () => {
		let notes = this.state.notes;
		notes.push({
			note: "",
			applied_date: moment().format("MM/DD/YYYY"),
		})
		this.setState({ notes: notes });
	}

	removeNote = (index) => {
		let notes = this.state.notes;
		notes.splice(index, 1)
		this.setState({ notes: notes });
	}

	addRecord = () => {
		let records = this.state.records;
		records.push({
			date_created: moment().format("MM/DD/YYYY"),
			billing_number: "",
			transaction_code: "",
			billing_status_code: "",
			primary_insurance_name: "",
			secondary_insurance_name: "",
			amount: "",
			total_amount: "",
			insurance_adjustment: "",
			primary_insurance_payment: ""
		})
		this.setState({ records: records });
	}

	removeRecord = (index) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you remove this record item?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				let records = this.state.records;
				records.splice(index, 1)
				this.setState({ records: records });
			}
		});
	}

	handleChange = (e, type, index) => {
		if (type === 'record') {
			let records = this.state.records;
			records = records.map((record, i) => {
				if (i === index) {
					record[e.target.name] = e.target.value
					if (e.target.name === "total_amount" || e.target.name === "insurance_adjustment" || e.target.name === "primary_insurance_payment") {
						record.amount = Number(record.total_amount) - Number(record.insurance_adjustment) - Number(record.primary_insurance_payment);
						record.amount = Math.round(record.amount * 100) / 100;
					}
				}
				return record;
			})
			this.setState({ records: records });
		} else if (type === 'note') {
			let notes = this.state.notes;
			notes[index][e.target.name] = e.target.value;
			this.setState({ notes: notes });
		} else {
			let name = e.target.name;
			let value = e.target.value;
			if (name === 'total_amount' && Number(value) >= Number(this.state.paid_amount)) {
				let due_amount = (Math.round((value - this.state.paid_amount) * 100) / 100).toFixed(2);
				this.setState({ due_amount: due_amount, total_amount: value })
				if (Number(due_amount) <= 0) {
					this.setState({ status: 'Paid' });
				} else {
					this.setState({ status: 'Outstanding' });
				}
			} else if (name === 'paid_amount' && Number(this.state.total_amount) >= Number(value)) {
				let due_amount = (Math.round((this.state.total_amount - value) * 100) / 100).toFixed(2);
				this.setState({ due_amount: due_amount, paid_amount: value })
				if (Number(due_amount) <= 0) {
					this.setState({ status: 'Paid' });
				} else {
					this.setState({ status: 'Outstanding' });
				}
			} else if (!name.includes('amount')) {
				this.setState({ [e.target.name]: e.target.value });
			}
		}
	}

	handleDateChange = (dateTime, type, index) => {
		if (type === 'record') {
			let records = this.state.records;
			records[index].date_created = dateTime;
			this.setState({ records: records });
		} else {
			let notes = this.state.notes;
			notes[index].applied_date = dateTime;
			this.setState({ notes: notes });
		}
	}

	handleClose = () => {
		this.setState({
			showPaymentInfo: false,
			showBillingPopup: false,
			showPatientPopup: false,
			showInsurancePopup: false,
			errors: [],
			chart_number: "",
			location: "",
			total_amount: "",
			paid_amount: "",
			due_amount: "",
			status: "",
			notes: [],
			records: []
		});
	};

	handleSubmit = () => {
		let errors = [];
		if (!this.state.chart_number) {
			errors.push('chart_number');
		}
		this.state.records.map((record => {
			if (!record.total_amount || Number(record.total_amount) < 0) {
				errors.push('total_amount');
			}
			if (!record.amount || Number(record.amount) < 0) {
				errors.push('record');
			}
			return null;
		}))
		this.state.notes.map((note => {
			if (!note.note) {
				errors.push('note');
			}
			return null;
		}))

		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			this.setState({ errors: [] });
			let totalRecordAmount = 0;
			this.state.records.map(record => {
				totalRecordAmount += Number(record.amount);
				return null;
			})
			if (!this.state.records.length) {
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: 'There is no record',
					text: 'Since this is insurance reconciliation, we expect an insurance record.  Please create at least one record with the insurance information.',
					icon: 'error',
				})
				return;
			}
			if (Number(totalRecordAmount) <= 0) {
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: 'Record Amount Correction Needed',
					text: 'If adding a record, you must include the amount.  It can not be zero.',
					icon: 'error',
				})
				return;
			}
			this.setState({ total_amount: Number(totalRecordAmount), due_amount: (Number(totalRecordAmount) - Number(this.state.paid_amount)), status: (Number(totalRecordAmount) - Number(this.state.paid_amount)) > 0 ? 'Outstanding' : 'Paid' }, () => {
				if (this.state.mode === 'create') {
					let data = {
						order_id: this.props.data._id,
						chart_number: this.state.chart_number,
						location: this.state.location,
						total_amount: this.state.total_amount,
						paid_amount: this.state.paid_amount,
						due_amount: Number(this.state.due_amount).toString(),
						records: this.state.records,
						notes: this.state.notes
					}
					createMpsPayment(data).then(res => {
						if (res && res.data) {
							let orderData = {
								_id: this.props.data._id,
								mps_payment_id: res.data._id
							}
							orderEasyUpdate(orderData).then(response => {
								this.handleClose();
								this.props.context.componentParent.loadGridData();
							})
						}
					})
				} else {
					let data = {
						_id: this.props.data.mps_payment_id._id,
						order_id: this.props.data._id,
						chart_number: this.state.chart_number,
						location: this.state.location,
						total_amount: this.state.total_amount,
						paid_amount: this.state.paid_amount,
						due_amount: Number(this.state.due_amount).toString(),
						records: this.state.records,
						notes: this.state.notes
					}
					updateMpsPayment(data).then(res => {
						this.handleClose();
						this.props.context.componentParent.loadGridData();
					})
				}
			});
		}
	}

	handleManualPay = () => {
		Swal.fire({
			title: 'You have selected Manual Payment',
			text: 'You confirm payment received outside MRN. Note will be added and record will be viewable on MPS paid page if save and continue.',
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Save & Continue',
			cancelButtonText: 'Cancel',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				let notes = this.state.notes;
				notes.push({
					note: "Payment Manually entered - Paid outside MRN",
					applied_date: moment().format("MM/DD/YYYY")
				})
				let data = {
					_id: this.props.data.mps_payment_id._id,
					order_id: this.props.data._id,
					chart_number: this.state.chart_number,
					location: this.state.location,
					total_amount: this.state.total_amount,
					paid_amount: Number(this.state.total_amount),
					due_amount: "0",
					records: this.state.records,
					notes: notes,
					paid_from: "Outside of MRN",
					paid_time: moment().format("YYYYMMDDHHmmss")
				}
				updateMpsPayment(data).then(res => {
					this.handleClose();
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	}

	processPayment = () => {
		boundActions.startBlockUILoading();
		let paymentInfo = {
			mps_payment_ids: [this.props.data.mps_payment_id._id],
			due_amount: this.props.data.mps_payment_id.due_amount,
			first_name: this.props.data.patient_id.first_name,
			last_name: this.props.data.patient_id.last_name,
			lab_order_id: this.props.data.lab_order_id,
			lab_order_ids: [this.props.data.lab_order_id],
			email: this.props.data.patient_id.email,
			from: "mpsView"
		}
		getCheckoutSessionIdForMpsPayment(paymentInfo).then(res => {
			if (res && res.sessionId) {
				this.props.context.componentParent.state.stripe.redirectToCheckout({ sessionId: res.sessionId });
			}
		})
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Billing
		</Tooltip>
	);

	renderTooltipProcessPayment = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Process Payment
		</Tooltip>
	);

	renderTooltipPaymentInfo = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Payment Info
		</Tooltip>
	)

	renderTooltipPatientEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Patient
		</Tooltip>
	)

	renderTooltipEditInsurance = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Insurance
		</Tooltip>
	)

	renderTooltipCheckBulk = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Check Item
		</Tooltip>
	)

	handleBulkItemChecked = () => {
		if ((this.props.context.componentParent.state.selectedBulkPaymentPatientId && this.props.context.componentParent.state.selectedBulkPaymentPatientId === this.props.data.patient_id._id) || !this.props.context.componentParent.state.selectedBulkPaymentPatientId) {
			let selectedBulkPaymentPatientId = this.props.data.patient_id._id;
			let bulkPaymentIdsArray = this.props.context.componentParent.state.bulkPaymentIdsArray;
			let bulkPaymentAmount = this.props.context.componentParent.state.bulkPaymentAmount;
			if (bulkPaymentIdsArray.includes(this.props.data.mps_payment_id._id)) {
				bulkPaymentIdsArray = bulkPaymentIdsArray.filter(item => item !== this.props.data.mps_payment_id._id);
				bulkPaymentAmount -= Number(this.props.data.mps_payment_id.due_amount)
			} else {
				bulkPaymentIdsArray.push(this.props.data.mps_payment_id._id);
				bulkPaymentAmount += Number(this.props.data.mps_payment_id.due_amount)
			}
			if (bulkPaymentIdsArray.length === 0) {
				selectedBulkPaymentPatientId = '';
			}
			this.props.context.componentParent.setState({
				bulkPaymentIdsArray: bulkPaymentIdsArray,
				selectedBulkPaymentPatientId: selectedBulkPaymentPatientId,
				bulkPaymentAmount: bulkPaymentAmount
			})
			this.props.context.componentParent.gridApi.refreshCells();
		} else {
			toastr.warning('Please select same patient');
		}
	}

	render() {
		return (
			<div>
				{
					this.props.context.componentParent.state.isEnabledBulkPayment && this.props.data.mps_payment_id && Number(this.props.data.mps_payment_id.due_amount) > 0
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipCheckBulk}
					>
						<Checkbox checked={this.props.context.componentParent.state.bulkPaymentIdsArray.includes(this.props.data.mps_payment_id._id)} onClick={this.handleBulkItemChecked}>
						</Checkbox>
					</OverlayTrigger>
				}
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipPatientEdit}
				>
					<button onClick={this.handleShowPatientPopup} className="edit-order-btn">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18px" style={{ marginTop: "-3px" }}>
							<path d="M223.1 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 223.1 256zM274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512h286.4c-1.246-5.531-1.43-11.31-.2832-17.04l14.28-71.41c1.943-9.723 6.676-18.56 13.68-25.56l45.72-45.72C363.3 322.4 321.2 304 274.7 304zM371.4 420.6c-2.514 2.512-4.227 5.715-4.924 9.203l-14.28 71.41c-1.258 6.289 4.293 11.84 10.59 10.59l71.42-14.29c3.482-.6992 6.682-2.406 9.195-4.922l125.3-125.3l-72.01-72.01L371.4 420.6zM629.5 255.7l-21.1-21.11c-14.06-14.06-36.85-14.06-50.91 0l-38.13 38.14l72.01 72.01l38.13-38.13C643.5 292.5 643.5 269.7 629.5 255.7z" />
						</svg>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEditInsurance}
				>
					<button onClick={this.handleShowInsurancePopup} className="edit-order-btn">
						<i className="fas fa-address-card"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={() => this.handleShow('showBillingPopup')} className="edit-order-btn">
						<i className="fas fa-dollar-sign"></i>
					</button>
				</OverlayTrigger>
				{
					this.props.data.mps_payment_id && Number(this.props.data.mps_payment_id.due_amount) > 0
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipProcessPayment}
					>
						<button onClick={this.processPayment} className="edit-order-btn">
							<i className="fas fa-credit-card"></i>
						</button>
					</OverlayTrigger>
				}
				{
					this.props.data.mps_payment_id && Number(this.props.data.mps_payment_id.due_amount) === 0
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipPaymentInfo}
					>
						<button onClick={() => this.handleShow('showPaymentInfo')} className="edit-order-btn">
							<i className="fas fa-info-circle"></i>
						</button>
					</OverlayTrigger>
				}

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showBillingPopup}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{
								this.state.mode === 'create'
								&&
								"Create MPS Payment Details"
							}
							{
								this.state.mode === 'update'
								&&
								"Update MPS Payment Details"
							}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>Patient Name</label>
									<input
										type="text"
										disabled
										className="form-control"
										name="patientName"
										value={this.state.patientName}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Order Date</label>
									<input
										type="text"
										disabled
										className="form-control"
										name="orderDate"
										value={this.state.orderDate}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Ordering Facility</label>
									<input
										type="text"
										disabled
										className="form-control"
										name="orderFacility"
										value={this.state.orderFacility}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Chart Number</label>
									<input
										type="text"
										className="form-control"
										name="chart_number"
										placeholder="Chart Number"
										value={this.state.chart_number}
										onChange={(e) => this.handleChange(e)}
									/>
									<div className={this.hasError("chart_number") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Location</label>
									<input
										type="text"
										className="form-control"
										name="location"
										placeholder="Location"
										value={this.state.location}
										onChange={(e) => this.handleChange(e)}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Status</label>
									<input
										type="text"
										className="form-control"
										name="status"
										placeholder="Status"
										disabled
										value={this.state.status}
										style={this.state.status ? { color: this.state.status === 'Paid' ? 'rgb(18, 222, 18)' : 'red' } : null}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Total Amount</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											className="form-control"
											name="total_amount"
											placeholder="Total Amount"
											value={this.state.total_amount}
											disabled
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
									<div className={this.hasError("total_amount") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Paid Amount</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											className="form-control"
											name="paid_amount"
											placeholder="Paid Amount"
											value={this.state.paid_amount}
											onChange={(e) => this.handleChange(e)}
											disabled
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label>Due Amount</label>
									<div style={{ display: "flex", alignItems: "center" }}>
										<input
											type="number"
											min={0}
											style={{ textAlign: 'right' }}
											className="form-control"
											name="due_amount"
											placeholder="Due Amount"
											value={this.state.due_amount}
											disabled
										/>
										<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
									</div>
								</div>
							</div>
							<div className="col-12">
								<label>
									Records
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addRecord()}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.records.length > 0
									&&
									this.state.records.map((record, index) => {
										return <Accordion key={index} sx={{ width: "100%" }}>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
											>
												<h4>
													Record {index + 1}
												</h4>
											</AccordionSummary>
											<AccordionDetails>
												<div className="row form-row">
													<div className="col-12 col-md-3">
														<div className="form-group">
															<label>Total Amount</label>
															<div style={{ display: "flex", alignItems: "center" }}>
																<input
																	type="number"
																	min={0}
																	style={{ textAlign: 'right' }}
																	className="form-control"
																	name="total_amount"
																	placeholder="Total Amount"
																	value={record.total_amount}
																	onChange={(e) => this.handleChange(e, 'record', index)}
																/>
																<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
															</div>
														</div>
														<div
															className={!record.total_amount ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
													</div>
													<div className="col-12 col-md-3">
														<div className="form-group">
															<label>Primary Insurance Payment</label>
															<div style={{ display: "flex", alignItems: "center" }}>
																<input
																	type="number"
																	min={0}
																	style={{ textAlign: 'right' }}
																	className="form-control"
																	name="primary_insurance_payment"
																	placeholder="Primary Insurance Payment"
																	value={record.primary_insurance_payment}
																	onChange={(e) => this.handleChange(e, 'record', index)}
																/>
																<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-3">
														<div className="form-group">
															<label>Insurance Adjustment</label>
															<div style={{ display: "flex", alignItems: "center" }}>
																<input
																	type="number"
																	min={0}
																	style={{ textAlign: 'right' }}
																	className="form-control"
																	name="insurance_adjustment"
																	placeholder="Insurance Adjustment"
																	value={record.insurance_adjustment}
																	onChange={(e) => this.handleChange(e, 'record', index)}
																/>
																<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
															</div>
														</div>
													</div>
													<div className="col-12 col-md-3">
														<div className="form-group">
															<label>Amount</label>
															<div style={{ display: "flex", alignItems: "center" }}>
																<input
																	type="number"
																	min={0}
																	style={{ textAlign: 'right' }}
																	className="form-control"
																	name="amount"
																	placeholder="Amount"
																	value={record.amount}
																	onChange={(e) => this.handleChange(e, 'record', index)}
																	disabled
																/>
																<abbr style={{ fontSize: "22px" }}>&nbsp;$</abbr>
															</div>
														</div>
														<div
															className={Number(record.amount) < 0 ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is wrong.
															</i>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Date Created</label>
															<MobileDatePicker
																value={record.date_created}
																onChange={(dateTime) => this.handleDateChange(dateTime.$d, 'record', index)}
																renderInput={props => <TextField
																	{...props}
																	InputProps={{
																		endAdornment: (
																			<InputAdornment position="end">
																				<IconButton edge="end">
																					<EventIcon />
																				</IconButton>
																			</InputAdornment>
																		)
																	}}
																	margine="none"
																/>}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Billing Number</label>
															<input
																type="text"
																className="form-control"
																name="billing_number"
																placeholder="Billing Number"
																value={record.billing_number}
																onChange={(e) => this.handleChange(e, 'record', index)}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Transaction Code</label>
															<input
																type="text"
																className="form-control"
																name="transaction_code"
																placeholder="Transaction Code"
																value={record.transaction_code}
																onChange={(e) => this.handleChange(e, 'record', index)}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Billing Status Code</label>
															<input
																type="text"
																className="form-control"
																name="billing_status_code"
																placeholder="Billing Status Code"
																value={record.billing_status_code}
																onChange={(e) => this.handleChange(e, 'record', index)}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Primary Insurance Name</label>
															<input
																type="text"
																className="form-control"
																name="primary_insurance_name"
																placeholder="Primary Insurance Name"
																value={record.primary_insurance_name}
																onChange={(e) => this.handleChange(e, 'record', index)}
															/>
														</div>
													</div>
													<div className="col-12 col-md-6">
														<div className="form-group">
															<label>Secondary Insurance Name</label>
															<input
																type="text"
																className="form-control"
																name="secondary_insurance_name"
																placeholder="Secondary Insurance Name"
																value={record.secondary_insurance_name}
																onChange={(e) => this.handleChange(e, 'record', index)}
															/>
														</div>
													</div>
												</div>
												<div className="row form-row">
													<div className="col-12">
														<h4 style={{ float: "right", cursor: "pointer" }} onClick={(e) => this.removeRecord(e, index)}>
															Remove Record {index + 1}
															<i className="fa fa-times-circle btn"></i>
														</h4>
													</div>
												</div>
											</AccordionDetails>
										</Accordion>
									})
								}
							</div>
							<div className="col-12 mt-3">
								<label>
									Notes
									&nbsp;&nbsp;
									<i className="fa fa-plus-circle" style={{ cursor: "pointer" }} onClick={() => this.addNote()}></i>
								</label>
							</div>
							<div className="col-12">
								{
									this.state.notes.length > 0
									&&
									this.state.notes.map((note, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-12 col-md-8">
													<div className="form-group">
														<input
															type="text"
															placeholder="Note"
															value={note.note}
															name="note"
															className="form-control"
															onChange={(e) => this.handleChange(e, 'note', index)}
														/>
														<div
															className={!note.note ? "inline-errormsg" : "hidden"}
														>
															<i className="fa fa-exclamation-circle" aria-hidden="true">
																&nbsp;This field is required.
															</i>
														</div>
													</div>
												</div>
												<div className="col-10 col-md-3">
													<div className="form-group">
														<MobileDatePicker
															value={note.applied_date}
															onChange={(dateTime) => this.handleDateChange(dateTime.$d, 'note', index)}
															label="Applied Date"
															renderInput={props => <TextField
																{...props}
																InputProps={{
																	endAdornment: (
																		<InputAdornment position="end">
																			<IconButton edge="end">
																				<EventIcon />
																			</IconButton>
																		</InputAdornment>
																	)
																}}
																margine="none"
															/>}
														/>
													</div>
												</div>
												<div className="col-2 col-md-1" style={{ marginTop: "-20px", display: "flex", alignItems: "center" }}>
													<div>
														<i className="fa fa-times-circle" style={{ cursor: "pointer" }} onClick={() => this.removeNote(index)}></i>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "12px",
									borderTop: "1px solid rgba(0,0,0,0.2)",
								}}
							>
								{
									this.state.mode === 'update' && this.state.status === 'Outstanding'
									&&
									<Button
										variant="info"
										onClick={this.handleManualPay}
									>
										Manual Payment Override
									</Button>
								}
								<Button
									variant="primary"
									style={{ float: "right", marginLeft: "10px" }}
									onClick={this.handleSubmit}
								>
									Save Changes
								</Button>
								<Button
									variant="secondary"
									style={{ float: "right" }}
									onClick={this.handleClose}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPaymentInfo}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							Payment Info
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label>Total Amount</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={"$ " + this.state.total_amount}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Paid From</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.paid_from === 'mpsView' ? "MPS Page" : (this.state.paid_from === 'Outside of MRN' ? "Outside of MRN" : "Patient Portal")}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Email</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.email ? this.state.card_holder_info.email : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Name</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.name ? this.state.card_holder_info.name : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Country</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.country ? this.state.card_holder_info.country : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder ZIP Code</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.zip ? this.state.card_holder_info.zip : ""}
									/>
								</div>
							</div>
							<div className="col-12 mt-3">
								<label>
									Notes
								</label>
							</div>
							<div className="col-12">
								{
									this.state.notes.length > 0
									&&
									this.state.notes.map((note, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-md-8">
													<div className="form-group">
														<input
															type="text"
															placeholder="Note"
															value={note.note}
															name="note"
															className="form-control"
															disabled
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<input
															value={note.applied_date}
															className="form-control"
															disabled
														/>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPatientPopup}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.data.patient_id}
							order={this.props.data}
							context={this.props.context}
							handleClose={this.handleClose}
							type='mpsGrid'
						/>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showInsurancePopup}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Insurance Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InsuranceDetails
							data={this.props.data}
							context={this.props.context}
							handleClose={this.handleClose}
							type="updateFromInsuranceBilling"
						/>
					</Modal.Body>
				</ModalStyled>
			</div >
		);
	}
}
