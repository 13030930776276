import React, { Component } from "react"
import { Checkbox, MenuItem } from "@mui/material";
import { orderUpdateNotifyState } from "../../../../services/clinicPortalServices/orderEditService";

export default class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: this.props.data._id,
            checkedPhysician: this.props.data.checked_by_physician ? this.props.data.checked_by_physician : false,
        };
    }
    toggleChecked = () => {
        if (this.state.checkedPhysician) {
            const updateNoticState = {
                id: this.state.orderId,
                state: false,
            };
            orderUpdateNotifyState(updateNoticState).then(res => {
                this.props.context.componentParent.loadGridData();
            })
        } else {
            const updateNoticState = {
                id: this.state.orderId,
                state: true,
            };
            orderUpdateNotifyState(updateNoticState).then(res => {
                this.props.context.componentParent.loadGridData();
            })
        }
    }

    render() {
        return (
            <div>
                <MenuItem onClick={() => this.toggleChecked()} style={{ padding: '0px' }}>
                    <Checkbox checked={this.state.checkedPhysician} ></Checkbox>
                    {(this.state.checkedPhysician) && 'Checked By Physician'}
                    {!(this.state.checkedPhysician) && 'Not Checked By Physician'}
                </MenuItem>
            </div>
        )
    }
}