import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { fetchQuantResultDocumentByOrderId } from "../../../../services/limsPortalServices/quantStudioImportResultService";
export default class SampleBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			resultData: {}
		};

	}

	handleShow = () => {
		fetchQuantResultDocumentByOrderId(this.props.data._id)
			.then((data) => {
				if (data.data?.length > 0) {
					this.setState({
						show: true,
						resultData: data.data[0]
					});
				}
			});

	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		const { resultData } = this.state;
		return (
			<div>
				<button
					onClick={this.handleShow}
					style={{ border: "none", backgroundColor: "transparent" }}
				>
					{this.props.data.test_info.sample}
				</button>

				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Sample Information ({this.props.data.test_info.sample})</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row form-row">
							<div className="form-group col-12">
								<label style={{ fontWeight: "100" }}>SARS-CoV-2 RT-PCR Interpretive Result</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={resultData.interpretive_result ? resultData.interpretive_result : 'null'}
									disabled={true}
								/>
							</div>
							<div className="form-group col-12">
								<label style={{ fontWeight: "100" }}>MS2</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={resultData.ms2_ct ? resultData.ms2_ct : 'null'}
									disabled={true}
								/>
							</div>
							<div className="form-group col-12">
								<label style={{ fontWeight: "100" }}>N gene</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={resultData.n_gene_ct ? resultData.n_gene_ct : 'null'}
									disabled={true}
								/>
							</div>
							<div className="form-group col-12">
								<label style={{ fontWeight: "100" }}>ORF1ab</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={resultData.orf1ab_ct ? resultData.orf1ab_ct : 'null'}
									disabled={true}
								/>
							</div>
							<div className="form-group col-12">
								<label style={{ fontWeight: "100" }}>S gene</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={resultData.s_gene_ct ? resultData.s_gene_ct : 'null'}
									disabled={true}
								/>
							</div>
							<div
								className="col-12"
								style={{
									paddingTop: "12px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									variant="secondary"
									style={{ float: "right" }}
									onClick={this.handleClose}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
