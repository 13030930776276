import { apiInterfaceForJson } from "../common/apiManager";

export const getAllData = () => {
    return apiInterfaceForJson("/patientPayment/v1", "GET");
};

export const getCheckoutSessionId = (info) => {
    return apiInterfaceForJson("/patientPayment/v1/getCheckoutSessionId", "POST", info);
}

export const successPayment = (info) => {
    return apiInterfaceForJson("/patientPayment/v1/successPayment", "POST", info);
}