import React, { Component } from "react";
import { successPayment } from "../../../services/patientPortalServices/patientPaymentService";

class PatientSuccessPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    componentDidMount() {
        const patientPaymentId = this.props.match.params.patientPaymentId;
        if (patientPaymentId) {
            const data = {
                _id: patientPaymentId
            }
            successPayment(data).then(res => {
            })
        }
    }

    render() {
        return (
            <div className="content login-content">
                <div className="container" style={{ textAlign: 'center', color: 'green' }}>
                    <h1 style={{ textAlign: 'center', color: 'green' }}>Thank you for your payment!</h1>
                    <br />
                    <p>You will receive a receipt to the email address you provided when making your payment.</p>
                </div>
            </div >
        )
    }
}

export default PatientSuccessPayment;

