import { apiInterfaceForFormData, apiInterfaceForJson } from "../common/apiManager";

export const patientSignup = (patientDetails) => {
    let patientInfo = {
        "first_name": patientDetails.firstName,
        "last_name": patientDetails.lastName,
        "email": patientDetails.email,
        "mobile": patientDetails.phone,
        "date_of_birth": patientDetails.dob,
        "gender": patientDetails.sex,
        "ethnicity": patientDetails.ethnicity,
        "race": patientDetails.race,
        "is_assigned": false,
        "is_active": false,
        "address": {
            "address1": patientDetails.address1 ? patientDetails.address1 : "",
            "address2": patientDetails.address2 ? patientDetails.address2 : "",
            "city": patientDetails.city,
            "state": patientDetails.state,
            "zip": patientDetails.zip
        },
        "driver_license": patientDetails.driverLicense,
        "driver_license_state": patientDetails.driverLicenseState,
        "driver_license_image": patientDetails.driverLicFileName,
        "locationDetails": patientDetails.locationDetails ? patientDetails.locationDetails : ""
    };
    if (patientDetails.insuranceProv1) {
        patientInfo.insurance = {
            "insured_first_name": patientDetails.insuredFirstName,
            "insured_last_name": patientDetails.insuredLastName,
            "insured_member_id": patientDetails.memberId,
            "insured_group_number": patientDetails.groupNumber,
            "insurance_provider": patientDetails.insuranceProv1.toLowerCase() === "other" ? patientDetails.insuranceProv2 : patientDetails.insuranceProv1,
            "social_security_number": patientDetails.socialSecurityNumber,
            "certify_no_insurance": patientDetails.certifyNoInsurance,
            "relation_to_insured": patientDetails.relation,
            "is_active": true,
            "images": {
                "insurance_front": patientDetails.insuranceFrontPageFileName,
                "insurance_back": patientDetails.insuranceBackPageFileName,
            }
        }
    }
    if (patientDetails.facilityId) {
        patientInfo.facility_id = patientDetails.facilityId;
    }

    if (patientDetails.hasOwnProperty('notify')) {
        patientInfo.notify = patientDetails.notify
    }

    if (patientDetails.website_sign_up) {
        patientInfo.website_sign_up = patientDetails.website_sign_up;
    }

    if (patientDetails.is_pregnant) {
        patientInfo.is_pregnant = patientDetails.is_pregnant;
    }

    if (patientDetails.passport) {
        patientInfo.passport = patientDetails.passport;
    }

    if (patientDetails.custom_info) {
        patientInfo.custom_info = patientDetails.custom_info;
    }
    return apiInterfaceForJson("/patient/v1/insurance", "POST", patientInfo);
};


export const patientUploadImages = (formData) => {
    return apiInterfaceForFormData("/patientinsurance/v1/uploadimages/", "POST", formData);
};


export const fetchPatientQrResponse = (patientId) => {
    return apiInterfaceForJson("/notifications/v1/qrcode/" + patientId, "POST");
};