import React, {Component} from 'react';

class TermsAndConditions extends Component{
   
    render(){
        return(
            <>
            {/* Breadcrumb */}
            <div className="breadcrumb-bar">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-md-12 col-12">
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/home">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Terms and conditions</li>
                      </ol>
                    </nav>
                    <h2 className="breadcrumb-title">Terms and conditions</h2>
                  </div>
                </div>
              </div>
            </div>
            {/* /Breadcrumb */}
            {/* Page Content */}
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                  <br/>
                  <h2>NOTICE OF HEALTH INFORMATION PRIVACY PRACTICES</h2>
                  <br/>
                  <h2>THIS NOTICE DESCRIBES HOW HEALTH INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</h2>
                  <br/>
                  <p>
                    American Gene Technologies International Inc. (“AGT”) is required by the Health Insurance Portability and Accountability Act of 1996, as amended (“HIPAA”), to maintain the privacy and security of your protected health information (PHI) and to provide you with a notice of AGT’s legal duties and privacy practices with respect to protected health information (PHI) that AGT may collect and maintain about you. This protection extends to any PHI whether in oral, written, or electronic format. This Notice of Health Information Privacy Practices (“Notice”) describes how we may use and disclose your protected health information (PHI) to carry out treatment, payment or health care operations and for other specified purposes that are permitted or required by law. The Notice also describes your rights with respect to your protected health information (“PHI”) when in the hands of AGT and its business associates, which are vendors that may assist us in providing services to you. PHI is any information that identifies you or may be used to identify you (e.g., basic demographic information); that is created or received by a health care provider, health plan, employer or health care clearinghouse; and that relates to your past, present or future physical or mental health or condition and related health care services, or provision of or payment for health care.<br/><br/>
                  
                    We are required by law to abide by the terms of this Notice. We will not use or disclose your PHI without your prior written authorization, except as permitted or required by law and described in this Notice. Please note that if other federal, state, or local laws, rules or regulations restrict or limit the use and disclosure of your PHI in ways that are permitted under this Notice, AGT will only use or disclose your PHI in compliance with the stricter law, rule or regulation. We strongly urge you to read this Notice carefully and thoroughly so that you will understand both our commitment to protecting the privacy of you PHI and how you can participate in the protection of this information.
                  </p>
                  <h3>What PHI We Collect</h3>
                  <p>
                    We attempt to collect the minimal amount of information necessary for AGT to provide our services to you and to obtain payment for those services. This may include your name, address, telephone number, social security number, date of birth, medical history, diagnosis, treatment, provider identification, financial responsibility, health insurance coverage (including group numbers and member identification numbers), and payment information.
                  </p>
                  <h3>How We May Use and Disclose Protected Health Information About You WITHOUT YOUR CONSENT</h3>
                  <p>With the exception of information that may qualify for special protection under state and/or federal law, the following categories describe different ways that we use and disclose your PHI. Not every possible use or disclosure in a category is listed below. However, all of the ways in which we are permitted to use and disclose PHI will fall within one of the categories below. Also, AGT must limit its uses, disclosures, or requests for your PHI to the “minimum necessary” to accomplish the intended purpose of such use, disclosure, or request, except as permitted by law. Please note that, for purposes of this Notice, any references to “we” or “AGT” include all business associates we may engage.<br/><br/>
                  </p>
                  <p>
                  <strong>Treatment:</strong> We may use or disclose your PHI to provide and coordinate the treatment and services you receive. For example, we may use your PHI to perform diagnostic tests, or provide your test results to your physician or other authorized health care provider. We may also disclose your PHI to another testing laboratory if we are unable to perform the testing ourselves and as such need to refer your specimen to that laboratory to perform the requested testing.<br/><br/>
                  </p>
                  <p>
                  <strong>Payment:</strong> We may use and disclose your PHI to others for purposes of receiving payment for treatment and services that you receive. For example, we will submit a claim to you, your health care provider, or your health plan/insurer that includes information that identifies you and the type of services we performed for you.<br/><br/>
                  </p>
                  <p>
                  <strong>Health Care Operations:</strong> AGT may use or disclose your PHI in order to support the health care operations of its business and monitor the quality of the care we provide. For example, we may use information in your health record to evaluate the services we provide or to train AGT’s staff. In addition, “health care operations” include conducting quality assessment and improvement activities, including outcomes evaluation and development of clinical guidelines; patient safety activities; population-based activities relating to protocol development, case management and care coordination, contacting of health care providers and patients with information about treatment alternatives, and related functions that do not include treatment; submitting claims for stop-loss coverage; conducting or arranging for medical review, legal services, and audit services; wellness and disease management programs; and business planning, development, management and general administration of the clinical lab.<br/><br/>
                  </p>
                  <p>
                  <strong>To Communicate with Individuals Involved in Your Care or Payment for Your Care:</strong> We may disclose to a family member, other relative, close personal friend or any other person you identify, PHI that is directly relevant to that person's involvement in your care or payment related to your care. We may disclose the relevant PHI to these persons if you do not object or we can reasonably infer from the circumstances that you do not object to the disclosure. If you are incapacitated, we can make the disclosure if, in the exercise of professional judgment, we believe the disclosure is in your best interests. To the extent permitted under federal and state law, we may disclose PHI of minors to their parents or legal guardians.
                  </p>
                  <p>
                  <strong>Business Associates:</strong> There are some services provided by AGT through contracts with business associates (e.g., billing services), and we may disclose your PHI to AGT’s business associate so that they can perform the job we have asked them to do. To protect your information, however, we require the business associate to enter into a Business Associate Agreement, which specifies the ways in which the business associate may use and disclose your PHI and must appropriately safeguard your information.
                  </p>
                  <p>
                  <strong>Government Agencies:</strong> We may disclose to certain government agencies (e.g., FDA, CMS, OIG, CLIA accreditation organizations, etc.), or persons under the jurisdiction of such agencies, PHI relative to adverse events with respect to products and/or services we provide, or information to enable product recalls, repairs, or replacements.
                  </p>
                  <p>
                  <strong>Worker’s Compensation:</strong> We may disclose your PHI to the extent authorized by and to the extent necessary to comply with laws relating to worker’s compensation or other similar programs established by law. These programs provide benefits for work-related injuries or illness without regard to fault.
                  </p>
                  <p>
                  <strong>Public Health:</strong> As permitted by law, we may disclose your PHI to public health or legal authorities charged with preventing or controlling disease, injury, or disability; to report the abuse or neglect of children, elders, dependent adults, or others; or to a person who may have been exposed to a communicable disease or otherwise be at risk of contracting of spreading the disease or condition.
                  </p>
                  <p>
                  <strong>Law Enforcement or As Otherwise Required by Law:</strong> We may disclose your PHI when required to do so by federal, state, or local law or for law enforcement purposes as permitted by law, such as in response to a valid subpoena or court order and to assist in locating suspects, fugitives or witnesses, or victims of crime.
                  </p>
                  <p>
                  <strong>Health Oversight Activities:</strong> We may disclose your PHI to an oversight agency for activities authorized by law. These oversight activities may include audits, investigations, and inspections necessary for licensure and for the government to monitor the health care system, government programs, and compliance with laws.
                  </p>
                  <p>
                  <strong>Judicial and Administrative Proceedings:</strong> We may disclose your PHI in response to a court or administrative order. We may also disclose PHI in response to a subpoena, discovery request, or other lawful process, but only if efforts have been made, either by the requesting party, or us to tell you about the request or to obtain an order protecting the information requested.
                  </p>
                  <p>
                  <strong>[Research:</strong> Under certain circumstances, we may use and disclose your PHI for research purposes. In many cases, we will ask for your written authorization before using or sharing your PHI with others in order to conduct research. However, under some circumstances, we may use and disclose your PHI without your written authorization if an institutional review board or privacy board, applying specific criteria, determines that the particular research poses no more than minimal risk to your privacy. We may also use or disclose your PHI without your written authorization to determine whether you might qualify to participate in a research project or to prepare a future research project as long as your PHI is not removed from AGT premises. We may also use or disclose a copy of your PHI that has had your name and other information that can readily identify you removed, if the recipient of the information enters into a legal contract agreeing to protect the information from unauthorized access. Under certain circumstances, we may use and disclose your PHI for research purposes, as well as PHI of deceased persons if the research satisfies certain criteria.]
                  </p>
                  <p>
                  <strong>To Avert a Serious Threat to Health or Safety:</strong> We may use and disclose your PHI, if in good faith, we believe the use or disclosure: (i) is necessary to prevent or lessen a serious and imminent threat to your health and safety or the health and safety of the public or another person, and is to a person or persons reasonably able to prevent or lessen the threat, including the target of the threat; or (ii) is necessary for law enforcement authorities to identify or apprehend an individual based on statements made by the individual admitting to participation in a violent crime, or where the individual has escaped from a correctional institution or from lawful custody, or (iii)is necessary for national security, intelligence, or protective services activities.
                  </p>
                  <p>
                  <strong>Military and Veterans:</strong> If you are a member of the armed forces, we may use and disclose PHI about you for activities deemed necessary by appropriate military command authorities to assure the proper execution of a military mission. 
                  </p>
                  <p>
                  <strong>Treatment Alternatives and Health-Related Benefits and Services:</strong> We may use and disclose your PHI to tell you about possible treatment options or alternatives and health-related benefits and services that may be of interest to you.
                  </p>
                  <strong>Sale or Merger:</strong> In the event of a sale or merger with another organization, your PHI will become the property of the new owner.
                  <br/><br/>
                  <h3>Use and Disclosure of PHI (WITH YOUR CONSENT)</h3>
                  <p>AGT will obtain your written authorization before using or disclosing your PHI for purposes other than those provided for in this Notice (or as otherwise permitted or required by law). Examples include any uses and disclosures of your PHI for marketing purposes, and disclosures that constitute a sale of PHI require your written authorization. You may revoke this authorization in writing at any time. Upon receipt of the written revocation, we will stop using or disclosing your PHI, except to the extent that we have already taken action in reliance on the authorization.</p>

                  <h3>Your Rights Regarding Your Health Information/PHI</h3>
                  <p><strong>Obtain a Paper Copy of the Notice</strong> <i>upon request</i>. You may request a paper copy of AGT’s current Notice at any time from the AGT’s Privacy Office. Even if you have agreed to receive the Notice electronically, you are still entitled to a paper copy. All requests for a paper copy of the Notice must be submitted in writing or electronically to AGT at the contact information listed below.</p>
                  
                  <h3>Right To Access and Obtain A Copy Of PHI.</h3>
                  <p>
                  You (or your designated representative) have the right to access and receive a copy of your PHI that may be used to make decisions about your care or payment for your care. If we maintain the information you have requested in an electronic format you may ask for it to be provided to you electronically, and also ask us to electronically send copies to another person. To exercise this right, you must send a written request to AGT.
                  We may deny your request to inspect and copy in certain limited circumstances. If you are denied access to your PHI, you will receive a written denial and information regarding how your denial may be reviewed.
                  </p>
                  <h3>Request A Restriction on Certain Uses And Disclosures Of PHI.</h3> 
                  <p>
                    You have the right to request additional restrictions on how we use or disclose your PHI for treatment, payment, health care operations, and communications to those involved in your care by sending a written request to AGT’s Privacy Office. We will consider your request but are not required to agree to it unless the requested restriction involves a disclosure that is not required by law to a health plan for payment or health care operations purposes and not for treatment, and you, or someone on your behalf, have paid for the service in full out of pocket. If we agree to a restriction on other types of disclosures, we will abide by them, except in emergency situations when the disclosure is for purposed of treatment. All requests for restrictions on the use or disclosure of your PHI must be submitted in writing to AGT at the contact information listed below. We retain the right to terminate an agreed-to restriction if we believe such termination is appropriate. In the event we have terminated an agreed-to restriction, we will notify you of such termination.
                  </p>
                  
                  <h3>Request An Amendment Of PHI.</h3> 
                  <p>
                    You have a right to request that PHI that we maintain about you be amended or corrected. To request an amendment, you must send a written request to AGT at the contact information listed below. You must include a reason that supports your request. We may process your request in accordance with our policy, but original information will not be removed. In certain cases, we may deny your request for an amendment for various reasons, including if we did not create the information or if we believe the current information is accurate and complete. You will be notified in writing if your request is denied. If you request is denied, you have the right to submit a written statement disagreeing with the denial, which, at your request, may be appended or linked to the PHI in question. All requests for an amendment of your PHI must be submitted in writing to AGT.
                  </p>
                  
                  <h3>Receive an Accounting of Disclosures Of PHI.</h3> 
                  <p>You have the right to receive an accounting of the disclosures AGT or its business associates have made of your PHI for most purposes other than treatment, payment, health care operations, and certain other limited purposes. The right to receive an accounting of disclosures is subject to certain exceptions, restrictions, and limitations. To request an accounting, you must submit your request in writing to AGT’ Privacy Office. Your request must specify the time period for which you would like an accounting, but this time period may not be longer than six years prior to your request. All requests for an accounting of the disclosures of your PHI must be submitted in writing to AGT at the contact information listed below.
                  </p>
                  
                  <h3>Request Confidential Communications of PHI By Alternative Means or At Alternative Locations.</h3> 
                  <p>
                    You have a right to request to receive communications of PHI by alternate means or at alternate locations. For instance, you may request that we contact you about medical matters only in writing or at a different residence or post office box. To request confidential communication of your PHI, you must submit a request in writing to AGT’s Privacy Office. Your request must state how or where you would like to be contacted. All requests for communication of PHI by alternative means or at alternative locations must be submitted in writing to AGT.
                  </p>
                  
                  <h3>Right to Receive Notification in the Event of a Breach.</h3> 
                  <p>
                    You have a right to receive notification if there is a breach of your unsecured PHI, except in those instances where we determine that there is a low probability that the PHI has been compromised. After learning of such a breach, we must provide notice to you without unreasonable delay and in no event later than sixty (60) calendar days after AGT’s discovery of the breach, unless a law enforcement official requires us to delay the breach notification.
                  </p>
                  <h3>Security of your PHI</h3>
                  <p>Access to PHI is restricted to only those employees, agents, or contractors of AGT who require it to provide services to you or your healthcare provider(s) or obtain payment from those financially responsible for payment. AGT maintains physical, technical, and procedural safeguards protecting PHI against unauthorized use and disclosure. AGT’ Privacy Office is responsible for overseeing the proper and effective implementation of all required rules and regulations, as well as policies and procedures concerning the use and disclosure or PHI, including ensuring proper educating/training, investigating all issues, complaints and concerns, audit and monitoring compliance by AGT and its employees, agents and contractors. <span style={{backgroundColor: "red"}}>Please note that any e-mail communication you initiate with AGT regarding your PHI is not secured in accordance with the HIPAA security standards. As a general rule, AGT will not communicate with you through e-mail unless the e-mail can be properly encrypted or with your permission/consent.</span>
                  </p>
                  <h3>Complaints/Objections</h3>
                  <p>
                    To file a complaint with AGT, you must submit a written complaint to AGT’s Privacy Office at the address listed below. Any submission must be marked “Confidential,” and should include your name, address, and telephone number where we can contact you (unless you chose to remain anonymous) and a brief description of your concern, issue, or complaint. Filing a complaint will not affect your rights to your personal data or services provided by AGT.
                  </p>
                  <h3>How to Contact Us</h3>
                  <p>If you would like to exercise your rights or would like additional information about AGT's privacy practices, you may contact:</p>
                  <div className="p-4" style={{backgroundColor: "#E0E0E0"}}>
                    <div className="row">
                      <div className="col-sm-2"><p><strong>By Mail: </strong></p></div> 
                      <div className="col-sm-10"><strong>American Gene Technologies International Inc.,</strong></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2"><p><strong>By EMail: </strong></p></div> 
                      <div className="col-sm-10"><a style={{color: "red"}} href="mailto:results@myresultnow.com"><strong>results@myresultnow.com</strong></a></div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2"><strong>By Telephone: </strong></div> 
                      <div className="col-sm-10"></div>
                    </div>
                  </div>
                  <br/>
                  <p>If you believe your privacy rights have been violated, you may also file a complaint with AGT’s Privacy Office or with the applicable agency listed below:</p>
                  <div className="p-4" style={{backgroundColor: "#E0E0E0"}}>
                    <div className="row align-items-center">
                      <div className="col-sm-2"><p><strong>By Mail: </strong></p></div>
                      <div className="col-sm-10">
                        <p><strong>Office for Civil Rights</strong></p>
                        <p><strong>The U.S. Department of Health and Human Services</strong></p>
                        <p><strong>200 Independence Avenue, S.W.</strong></p>
                        <p><strong>Washington, D.C. 20201</strong></p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2"><p><strong>By Telephone: </strong></p></div>
                      <div className="col-sm-10">
                        <strong><a style={{color: "red"}} href="tel:1-202-619-0257">1-202-619-0257</a> or toll free at: <a style={{color: "red"}} href="tel:1-877-696-6775">1-877-696-6775</a></strong>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <h3>Changes to this Notice</h3>
                  <p>
                    AGT reserves the right to change its practices and the terms of this Notice as, and to the extent permitted by law, to make the new Notice effective for all PHI and personal data we maintain without prior notice to you. The new Notice will be available upon request and on our website.
                  </p>
                  <h3>Obtaining a Copy of this Notice</h3>
                  <p>
                    You are permitted to print or make a copy of this Notice for your records. If you do not have the ability to print or make a copy, you may request one by contacting the AGT’s Privacy Office at the address listed above.
                  </p>
                  <h3>Effective Date</h3>
                  <p>
                    This Notice was revised and became effective as of October 12th, 2021.
                  </p>
                  </div>
                </div>
              </div>
            </div>		
            {/* /Page Content */}
          </>
        );
    }
}

export default TermsAndConditions;