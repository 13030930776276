import React, { Component } from "react";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import PatientBreadcrumb from "./patientBreadcrumb";
import { Modal } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class PatientSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showHelpPopup: false
		};
	}
	render() {
		return (
			<div>
				<PatientBreadcrumb
					onIsArchivedInformation={(event) => { this.props.onIsArchivedInformation(event) }}
					isArchived={this.props.isArchived}
					createPatient={this.props.createPatient}
				/>
				<div className="row" style={{ padding: " 12px" }}>
					<div
						className="col-md-8"
						style={{ borderRight: "2px solid lightGray" }}
					>
						<div className="row" style={{ marginBottom: "15px", marginTop: "10px" }}>
							<div className="col-md-7">
								<FormControl
									variant="outlined"
									style={{ width: "100%" }}
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.props.facilities && this.props.facilities.length <= 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
                                            value={this.props.facilityId && this.props.facilityId.length ? this.props.facilityId : ""}
											onChange={this.props.handleFiltersChange}
											name="facility_id"
										>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															{fac.name}&nbsp;{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
									{
										this.props.facilities && this.props.facilities.length > 1
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.props.facilityId}
											onChange={this.props.handleFiltersChange}
											label="Select Facility"
											name="facility_id"
											multiple
											renderValue={(selected) => {
												if (selected.length === this.props.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.props.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.props.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.props.facilityId && this.props.facilityId.includes(fac._id) ? true : false} className={fac.type === 'facility' ? 'ml-5' : ''} />
															<span className="mr-2">{fac.name} </span>
															{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
							</div>
							<div className="col-md-5">
								<TextField
									label="Current Grid Search"
									variant="outlined"
									id="reset-form"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="none"
									onChange={this.props.onFilterTextChange}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-7">
								<FormControl component="fieldset">
									<RadioGroup
										aria-label="result"
										name="type"
										value={this.props.type}
										onChange={this.props.handleFiltersChange}
										style={{ flexDirection: "row", justifyContent: "start" }}
									>
										<FormControlLabel
											key='all'
											value='all'
											control={<Radio />}
											label="All"
										/>
										<FormControlLabel
											key='incorrect'
											value='incorrect'
											control={<Radio />}
											label="Incorrect Items"
										/>
										<FormControlLabel
											key='fixed_incorrect'
											value='fixed_incorrect'
											control={<Radio />}
											label="Fixed incorrection"
										/>
									</RadioGroup>
								</FormControl>
							</div>
							<div className="col-md-5 grid-buttons">
								<div>
									<button
										className="btn btn-primary"
										onClick={() => this.setState({ showHelpPopup: true })}
									>
										<i className="fa fa-question" aria-hidden="true"></i> Help
									</button>
									<button
										className="btn btn-primary ml-2"
										onClick={() => this.props.clearFilter()}
									>
										<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="row">
							<div className="col grid-buttons">
								<div>
									<TextField
										style={{ width: "100px", height: "40px" }}
										label="Page Size"
										variant="outlined"
										className="mt-3"
										id="page-size"
										InputLabelProps={{
											shrink: true,
										}}
										type="number"
										size="small"
										margin="none"
										onChange={this.props.onPageSizeChanged}
									/>
								</div>
								<div>
									<button
										className="btn btn-primary button-info-grid"
										onClick={() => this.props.saveState()}
									>
										<i className="far fa-save"></i> Save
									</button>
									<button
										className="btn btn-primary button-info-grid"
										onClick={() => this.props.resetState()}
									>
										{" "}
										<i className="fa fa-repeat"></i> Default
									</button>
									<button
										className="btn btn-primary button-info-grid"
										onClick={() => this.props.onBtnExport()}
									>
										<i className="fa fa-file-excel-o" aria-hidden="true"></i> Export
									</button>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col grid-buttons">
								<div>
									<button
										onClick={this.props.showQrScannerHandler}
										className="btn btn-primary button-info-grid mt-4"
									>
										<i className="fa fa-qrcode" aria-hidden="true"></i> Scan QR Code
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showHelpPopup}
					onHide={() => this.setState({ showHelpPopup: false })}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Help</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label incorrect-patient-insurance-item"></div>
								<div className="ml-2">Patient and Insurance need correction</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label incorrect-patient-item"></div>
								<div className="ml-2">Patient needs correction</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label incorrect-insurance-item"></div>
								<div className="ml-2">Insurance needs correction</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label fixed-incorrect-patient-insurance-item"></div>
								<div className="ml-2">Both items corrected</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label fixed-incorrect-patient-item"></div>
								<div className="ml-2">Patient corrected by user</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label fixed-incorrect-insurance-item"></div>
								<div className="ml-2">Insurance corrected by user</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label fixed-incorrect-insurance-incorrect-patient-item"></div>
								<div className="ml-2">Patient still incorrect, insurance corrected by user</div>
							</div>
							<div className="col-12 d-flex form-group align-items-center">
								<div className="color-label fixed-incorrect-patient-incorrect-insurance-item"></div>
								<div className="ml-2">Patient corrected by user, Insurance still incorrect</div>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}

export default PatientSearchMenu;
