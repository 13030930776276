import React, { Component } from "react";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, TextField, FormLabel, InputAdornment, IconButton } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import BreadCrumb from "./breadcrumb";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		}
	}

	render() {
		return (
			<div>
				<BreadCrumb
					onBtnExportZipForRequisitions={() => { this.props.onBtnExportZipForRequisitions() }}
					onBtnExportZipForResults={() => { this.props.onBtnExportZipForResults() }}
					page_size={this.props.page_size}
					onPageSizeChanged={(event) => { this.props.onPageSizeChanged(event) }}
					updateView={(gridView) => { this.props.updateView(gridView) }}
					resetState={() => { this.props.resetState() }}
					onBtnExport={() => { this.props.onBtnExport() }}
					onIsArchivedInformation={(event) => { this.props.onIsArchivedInformation(event) }}
					isArchived={this.props.isArchived}
				/>
				<div className="row mt-4 px-3">
					<div
						className="col-md-12"
					>
						<div className="row">
							<div className="col-md-4 col-12 mb-3">
								<div className="row">
									<div className="col-12">
										<FormControl
											variant="outlined"
											className="filter-select-bar"
										>
											<InputLabel id="facility-label">Select Facility</InputLabel>
											{
												this.props.facilities && this.props.facilities.length <= 1
												&&
												<Select
													labelId="facility-label"
													id="facility-select"
													value={this.props.facilityId && this.props.facilityId.length ? this.props.facilityId : ""}
													onChange={this.props.handleFiltersChange}
													name="facility_id"
												>
													{
														this.props.facilities.map((fac) => {
															return (
																<MenuItem
																	key={fac._id}
																	value={fac._id}
																>
																	<span className="mr-2">
																		{fac.name}
																	</span>
																	{
																		fac.archived === "archived"
																		&&
																		<b>{" ( Archived )"}</b>
																	}
																</MenuItem>
															);
														})
													}
												</Select>
											}
											{
												this.props.facilities && this.props.facilities.length > 1
												&&
												<Select
													labelId="facility-label"
													id="facility-select"
													value={this.props.facilityId}
													onChange={this.props.handleFiltersChange}
													label="Select Facility"
													name="facility_id"
													multiple
													renderValue={(selected) => {
														if (selected.length === this.props.facilities.length) {
															return 'All Facilities';
														} else {
															let displayStrings = [];
															this.props.facilities.map(fac => {
																if (selected.includes(fac._id)) {
																	displayStrings.push(fac.name);
																}
																return null;
															})
															return displayStrings.join(', ');
														}
													}}
												>
													<MenuItem className={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? "Mui-selected" : ""} key="all" value="all">
														<Checkbox checked={this.props.facilityId && this.props.facilityId.length === this.props.facilities.length ? true : false} />
														All Facilities
													</MenuItem>
													{
														this.props.facilities.map((fac) => {
															return (
																<MenuItem
																	key={fac._id}
																	value={fac._id}
																>
																	<Checkbox checked={this.props.facilityId && this.props.facilityId.includes(fac._id) ? true : false} className={fac.type === 'facility' ? 'ml-5' : ''} />
																	<span className="mr-2">
																		{fac.name}
																	</span>
																	{
																		fac.archived === "archived"
																		&&
																		<b>{" ( Archived )"}</b>
																	}
																</MenuItem>
															);
														})
													}
												</Select>
											}
										</FormControl>
										<FormControl
											className="filter-select-bar"
											variant="outlined"
										>
											<InputLabel id="test-label">Select Test</InputLabel>
											{
												this.props.tests && this.props.tests.length <= 1
												&&
												<Select
													labelId="test-label"
													id="test-select"
													label="Select Test"
													value={this.props.testIds && this.props.testIds.length ? this.props.testIds : ""}
													onChange={this.props.handleFiltersChange}
													name="testIds"
												>
													{
														this.props.tests.map((test) => {
															return (
																<MenuItem
																	key={test._id}
																	value={test._id}
																>
																	{test.name}
																</MenuItem>
															);
														})
													}
												</Select>
											}
											{
												this.props.tests && this.props.tests.length > 1
												&&
												<Select
													labelId="test-label"
													id="test-select"
													value={this.props.testIds}
													onChange={this.props.handleFiltersChange}
													label="Select Test"
													name="testIds"
													multiple
													renderValue={(selected) => {
														if (selected.length === this.props.tests.length) {
															return 'All Tests';
														} else {
															let displayStrings = [];
															this.props.tests.map(test => {
																if (selected.includes(test._id)) {
																	displayStrings.push(test.name);
																}
																return null;
															})
															return displayStrings.join(', ');
														}
													}}
												>
													<MenuItem className={this.props.testIds && this.props.testIds.length === this.props.tests.length ? "Mui-selected" : ""} key="all" value="all">
														<Checkbox checked={this.props.testIds && this.props.testIds.length === this.props.tests.length ? true : false} />
														All Tests
													</MenuItem>
													{
														this.props.tests.map((test) => {
															return (
																<MenuItem
																	key={test._id}
																	value={test._id}
																>
																	<Checkbox checked={this.props.testIds && this.props.testIds.includes(test._id) ? true : false} />
																	{test.name}
																</MenuItem>
															);
														})
													}
												</Select>
											}
										</FormControl>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="row">
									<div className="col-12">
										<div className="row" style={{ paddingLeft: "10px", marginTop: "-15px" }}>
											<div className="col-12">
												<FormLabel component="legend" style={{ marginBottom: "10px" }}>
													Paid Date Range
												</FormLabel>
											</div>
											<div className="dateRangeBar mb-3">
												<button
													className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'today' ? " active" : "")}
													onClick={() => this.props.updateDateRange('today')}
												>
													Today
												</button>
												<button
													className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'week' ? " active" : "")}
													onClick={() => this.props.updateDateRange('week')}
												>
													Week
												</button>
												<button
													className={"btn btn-info dateRangeBtn mx-1 mb-2" + (this.props.date_range === 'month' ? " active" : "")}
													onClick={() => this.props.updateDateRange('month')}
												>
													Month
												</button>
												<button
													className={"btn btn-info dateRangeBtn ml-1 mb-2" + (this.props.date_range === 'custom' ? " active" : "")}
												>
													Custom
												</button>
											</div>
											<div className="dateRangeBar dateRangeBarPickers">
												<MobileDateTimePicker
													value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
													onChange={(dateTime) => this.setState({ from_date: dateTime })}
													onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
													onClose={() => this.setState({ pickerOpened: false })}
													onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
													label="From Date"
													renderInput={props => <TextField
														{...props}
														className="ml-1 mr-2 mt-2"
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton edge="end">
																		<EventIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>}
												/>
												<MobileDateTimePicker
													value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
													onChange={(dateTime) => this.setState({ to_date: dateTime })}
													onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
													onClose={() => this.setState({ pickerOpened: false })}
													onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
													label="To Date"
													renderInput={props => <TextField
														{...props}
														className="mt-2"
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton edge="end">
																		<EventIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-12 mb-3 text-right">
								<TextField
									label="Current Grid Search"
									variant="outlined"
									className="filter-search-bar"
									id="reset-form"
									InputLabelProps={{
										shrink: true,
									}}
									value={this.props.filter_text}
									type="string"
									margin="dense"
									onChange={this.props.onFilterTextChange}
								/>
								<button
									className="btn btn-primary mt-4"
									onClick={() => this.props.clearFilter()}
								>
									<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SearchMenu;
