import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import PatientDetails from './patientDetails';
import InsuranceInfoPopup from "./insuranceInfoPopup";
import { Checkbox } from "@mui/material";
import { fetchPatientInsuranceData, fetchPatientDataById } from "../../../../services/clinicPortalServices/patientSearchService";
import { easyUpdatePatient } from "../../../../services/clinicPortalServices/patientEditService";
import { easyUpdateInsurance } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { getUserRole, getUserDetails } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import PatientViewPopup from "./patientViewPopup";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export default class MasterBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showInsurancePopup: false,
			showIncorrectActionPopup: false,
			incorrectItems: [],
			errors: []
		};
	}

	handleShow = () => {
		this.setState({ show: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	};

	handleCreateRequisition = () => {
		this.props.context.componentParent.onQrCodeScanHandler(this.props.data._id);
	}

	handleShowInsurancePopup = () => {
		this.setState({ showInsurancePopup: true });
	}

	handleClose = () => {
		this.setState({ show: false, showIncorrectActionPopup: false, incorrectItems: [], errors: [] });
	};

	handleIncorrectAction = () => {
		boundActions.startBlockUILoading();
		this.setState({ showIncorrectActionPopup: true });
		fetchPatientInsuranceData(this.props.data._id)
			.then((response) => {
				boundActions.endBlockUILoading();
				let incorrectItems = [{
					type: "Patient",
					data: this.props.data,
					is_incorrect: this.props.data.is_incorrect ? this.props.data.is_incorrect : false,
					is_fixed_incorrect: this.props.data.is_fixed_incorrect ? this.props.data.is_fixed_incorrect : false,
					incorrect_note: this.props.data.incorrect_note ? this.props.data.incorrect_note : ""
				}];
				if (response.data && response.data.length) {
					response.data.map(insurance => {
						incorrectItems.push({
							type: "Insurance",
							data: insurance,
							is_incorrect: insurance.is_incorrect ? insurance.is_incorrect : false,
							is_fixed_incorrect: insurance.is_fixed_incorrect ? insurance.is_fixed_incorrect : false,
							incorrect_note: insurance.incorrect_note ? insurance.incorrect_note : ""
						})
						return null;
					})
				}
				this.setState({ incorrectItems: incorrectItems });
			})
	}

	handleChange = (e, index) => {
		let incorrectItems = this.state.incorrectItems;
		incorrectItems.map((item, i) => {
			if (i === index) {
				if (e.target.name === 'is_incorrect') {
					item.is_incorrect = !item.is_incorrect;
				} else {
					item[e.target.name] = e.target.value;
				}
			}
			return null;
		})
		this.setState({ incorrectItems: incorrectItems });
	}

	handleIncorrectItems = async () => {
		let errors = [];
		this.state.incorrectItems.map((incorrectItem, index) => {
			if (incorrectItem.is_incorrect && !incorrectItem.incorrect_note) {
				errors.push(index);
			}
			return null;
		})
		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			for (let i = 0; i < this.state.incorrectItems.length; i++) {
				let incorrectItem = this.state.incorrectItems[i];
				let handleItemPromise = new Promise((resolve, reject) => {
					if (incorrectItem.type === "Patient") {
						const patientDetails = {
							_id: incorrectItem.data._id,
							is_incorrect: incorrectItem.is_incorrect,
							is_fixed_incorrect: !incorrectItem.is_incorrect ? false : incorrectItem.is_fixed_incorrect,
							incorrect_note: incorrectItem.is_incorrect ? incorrectItem.incorrect_note : "",
						}
						easyUpdatePatient(patientDetails).then(res => {
							resolve();
						})
					} else {
						const insuranceDetails = {
							_id: incorrectItem.data._id,
							is_incorrect: incorrectItem.is_incorrect,
							is_fixed_incorrect: !incorrectItem.is_incorrect ? false : incorrectItem.is_fixed_incorrect,
							incorrect_note: incorrectItem.is_incorrect ? incorrectItem.incorrect_note : "",
						}
						easyUpdateInsurance(insuranceDetails).then(res => {
							resolve();
						})
					}
				})
				await handleItemPromise;
			}
			this.handleClose();
			fetchPatientDataById(this.props.data._id).then(res => {
				if (res && res.data && res.data[0]) {
					this.props.context.componentParent.updateGridRow(this.props.node.id, res.data[0]);
				}
			})
		}
	}

	handlePatientInfoShow = () => {
		this.setState({ showPatientViewPopup: true })
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Patient
		</Tooltip>
	);

	renderTooltipEditInsurance = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Insurance Info
		</Tooltip>
	);

	renderTooltipCreateRequisition = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Create Requisition
		</Tooltip>
	);

	renderTooltipIncorrectAction = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{getUserRole() && ["AGTSuperAdmin", "AGTAdmin"].includes(getUserRole()) ? "Mark as Incorrect" : "Incorrect Info"}
		</Tooltip>
	)

	renderTooltipPatientInfo = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Full Info
		</Tooltip>
	)

	render() {
		const userInfo = JSON.parse(getUserDetails())
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button
						onClick={this.handleShow}
						style={{ border: "none", backgroundColor: "transparent" }}
					>
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEditInsurance}
				>
					<button
						onClick={this.handleShowInsurancePopup}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-address-card"></i>
					</button>
				</OverlayTrigger>

				{
					(/^agtsuperadmin|^agtadmin|^labtech/.test(getUserRole().toLowerCase())
					|| (/^facilityadmin|^facilityuser/.test(getUserRole().toLowerCase()) && userInfo.facilityIds.length && (userInfo.facilityIds.find(item => ["Test Facility", "American Gene Technologies Testing Facility"].includes(item.name)))))
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipCreateRequisition}
					>
						<button
							onClick={this.handleCreateRequisition}
							style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
						>
							<i className="fas fa-file-medical"></i>
						</button>
					</OverlayTrigger>
				}

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipIncorrectAction}
				>
					<button
						onClick={this.handleIncorrectAction}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-exclamation-triangle"></i>
					</button>
				</OverlayTrigger>

				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipPatientInfo}
				>
					<button
						onClick={this.handlePatientInfoShow}
						style={{ border: "none", backgroundColor: "transparent", fontSize: "18px" }}
					>
						<i className="fas fa-eye"></i>
					</button>
				</OverlayTrigger>

				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.data}
							nodeId={this.props.node.id}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>
				<InsuranceInfoPopup
					data={this.props.data}
					nodeId={this.props.node.id}
					context={this.props.context}
					show={this.state.showInsurancePopup}
					handleClose={() => { this.setState({ showInsurancePopup: false }) }}
				/>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showIncorrectActionPopup}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>{getUserRole() && ["AGTSuperAdmin", "AGTAdmin"].includes(getUserRole()) ? "Please select incorrect items and add notes" : "Incorrect Items Info"}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								{
									this.state.incorrectItems.map((incorrectItem, index) => {
										if (getUserRole() && ["AGTSuperAdmin", "AGTAdmin"].includes(getUserRole())) {
											return <div className="row" key={index}>
												<div className="col-12 form-group">
													<Checkbox
														checked={incorrectItem.is_incorrect}
														name="is_incorrect"
														onClick={(e) => this.handleChange(e, index)}
													/> {incorrectItem.type === 'Patient' ? ("Patient - " + incorrectItem.data.first_name + " " + incorrectItem.data.last_name) : ("Insurance - " + incorrectItem.data.insurance_provider + (incorrectItem.data.relation_to_insured ? (" (" + incorrectItem.data.relation_to_insured + ")") : ""))} <b>{incorrectItem.is_fixed_incorrect ? "(Fixed by Facility User)" : ""}</b>
												</div>
												{
													incorrectItem.is_incorrect
													&&
													<div className="col-12 form-group">
														<textarea
															className="form-control"
															value={incorrectItem.incorrect_note}
															name="incorrect_note"
															onChange={(e) => this.handleChange(e, index)}
															placeholder="Note"
														>
														</textarea>
														{
															this.state.errors.includes(index)
															&&
															<div className="inline-errormsg">
																<i className="fa fa-exclamation-circle" aria-hidden="true">
																	&nbsp;Note should be provided if it is selected as incorrect item.
																</i>
															</div>
														}
													</div>
												}
											</div>
										} else if (incorrectItem.is_incorrect) {
											return <div className="row">
												<div className="col-12 form-group">
													<div>{incorrectItem.type === 'Patient' ? ("Patient - " + incorrectItem.data.first_name + " " + incorrectItem.data.last_name) : ("Insurance - " + incorrectItem.data.insurance_provider + (incorrectItem.data.relation_to_insured ? (" (" + incorrectItem.data.relation_to_insured + ")") : ""))} <b>{incorrectItem.is_fixed_incorrect ? "(Fixed by Facility User)" : ""}</b></div>
													<div><b>Note:</b> {incorrectItem.incorrect_note}</div>
												</div>
											</div>
										}
										return null;
									})
								}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={this.handleClose}
						>
							Close
						</Button>
						{
							getUserRole() && ["AGTSuperAdmin", "AGTAdmin"].includes(getUserRole())
							&&
							<Button
								variant="primary"
								onClick={this.handleIncorrectItems}
							>
								Save Changes
							</Button>
						}
					</Modal.Footer>
				</ModalStyled>
				<PatientViewPopup
					show={this.state.showPatientViewPopup}
					handleClose={() => this.setState({ showPatientViewPopup: false })}
					data={this.props.data}
					context={this.props.context}
				/>
			</div >
		);
	}
}
