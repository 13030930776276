import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";

class BreadCrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="breadcrumb-bar">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-9 col-12">
							<nav aria-label="breadcrumb" className="page-breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item">
										<Link to="/">Home</Link>
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Lab Management
									</li>
									<li className="breadcrumb-item active" aria-current="page">
										Maintenance Management
									</li>
								</ol>
							</nav>
							<div className="d-flex">
								<h2 className="breadcrumb-title">Maintenance Management</h2>
								<FormControlLabel
									value="includeArchived"
									control={<Switch checked={this.props.isCalendar} onChange={this.props.onChangeView} />}
									label="Calendar / Grid"
									labelPlacement="end"
									className="m-0 show-archives-control breadcrumb-control"
									style={{ color: "white" }}
								/>
							</div>
						</div>
						{
							!this.props.isCalendar
							&&
							<div className="col-md-3 col-12">
								<div className="row breadcrumb-btns">
									<ul className="export-ul">
										<li onClick={() => this.props.onBtnExport()}>Export Grid</li>
									</ul>
								</div>
							</div>
						}
					</div>
				</div>
			</div >
		);
	}
}

export default BreadCrumb;
