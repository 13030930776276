import moment from "moment";
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { getOrderDataById } from "../../../../services/clinicPortalServices/orderEditService";
import { getFullAddress, getUserAuthToken, getRowColumnIndexForQuantPlate, dateComparator } from "../../../../services/common/util";
import { serviceConstants } from "../../../../services/common/constants";
import { quantPlateRows, quantPlateColumns } from "../../../../services/common/optionsData";
import { AgGridReact } from "ag-grid-react";
import EditBtnCellRenderer from "../../requisitions/clinicRequisitionGrid/editBtnCellRenderer";
import RequisitionPdfRenderer from "../../requisitions/clinicRequisitionGrid/requisitionPdfRenderer";
import PdfResultRenderer from "./pdfResultRenderer";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { ThemeContext } from "../../../../theme/ThemeProvider";

export default class OrderViewPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orderDetails: {},
			rowData: [],
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: "Action",
					minWidth: 100,
					cellRenderer: EditBtnCellRenderer
				},
				{
					headerName: "Requisition",
					minWidth: 80,
					field: "lab_order_id"
				},
				{
					headerName: "Sample",
					minWidth: 100,
					field: "test_info.sample"
				},
				{
					headerName: "Collected Date",
					minWidth: 50,
					valueGetter: params => {
						if (params.data.test_info.collected) {
							return moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")
						}
					},
					dateComparator: dateComparator
				},
				{
					headerName: "Test Name",
					minWidth: 100,
					field: "test_id.name"
				},
				{
					headerName: "Requisition PDF",
					minWidth: 100,
					cellRenderer: RequisitionPdfRenderer
				},
				{
					headerName: "Result PDF",
					minWidth: 100,
					cellRenderer: PdfResultRenderer
				},
			],
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			context: { componentParent: this }
		}
	}

	componentDidMount() {
		getOrderDataById(this.props.data._id).then(res => {
			this.setState({ orderDetails: res.data })
			if (res.data.orderIds && res.data.orderIds.length) {
				res.data.orderIds.map(item => {
					item.bundle_id = res.data;
					return item;
				})
				this.setState({ rowData: res.data.orderIds })
			}
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.show && this.props.show !== prevProps.show) {
			getOrderDataById(this.props.data._id).then(res => {
				this.setState({ orderDetails: res.data })
				if (res.data.orderIds && res.data.orderIds.length) {
					res.data.orderIds.map(item => {
						item.bundle_id = res.data;
						return item;
					})
					this.setState({ rowData: res.data.orderIds })
				}
			})
		}
	}

	render() {
		let notificatinLogStrings = [];
		let orderNoticeHistoryStrings = [];
		let sampleTrackingStrings = [];
		if (this.state.orderDetails.notification_state) {
			switch (this.state.orderDetails.notification_state) {
				case "NO_ACTION":
					if (this.state.orderDetails.results && ["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(this.state.orderDetails.results.value)) {
						notificatinLogStrings = [moment(this.state.orderDetails.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Delivered Positive Notification to Physician"]
					}
					break;
				case "SENT_TO_PATIENT":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Patient - Failed"]
					break;
				case "DELIVERED_TO_PATIENT":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Patient", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Delivered to Patient - Success"]
					break;
				case "SENT_TO_FACILITY":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Facility", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Delivered to Facility - Success"]
					break;
				case "SENT_TO_PHYSICIAN":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Patient", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Physician"]
					break;
				case "DELIVERED_TO_PHYSICIAN":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Patient", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Physician", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Delivered to Physician"]
					break;
				case "SENT_TO_SUPERADMIN":
					notificatinLogStrings = [moment(this.state.orderDetails.notification_start_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Patient", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Sent to Physician", moment(this.state.orderDetails.notification_checked_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " Delivered to SuperAdmin"]
					break;
				default:
					break;
			}
		}
		if (this.state.orderDetails.noticeHistory && this.state.orderDetails.noticeHistory.length) {
			this.state.orderDetails.noticeHistory.map(item => {
				let itemTitle = item.title;
				if (itemTitle === 'To Facility For Strep Release') {
					itemTitle = itemTitle.replace('Strep', this.state.orderDetails.test_id.name);
				}
				orderNoticeHistoryStrings.push(moment(item.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " - " + itemTitle + " - " + item.destination)
				return null;
			})
		}
		if (this.state.orderDetails.sample_tracking && this.state.orderDetails.sample_tracking.length) {
			this.state.orderDetails.sample_tracking.map((item, index) => {
				let plateName = "";
				let wellPosition = "";
				let eventName = item.eventName;
				if (item.plateId) {
					if (this.props.type === 'universal_search') {
						this.props.plateList.map(plate => {
							if (plate._id === item.plateId) {
								plateName = plate.name;
							}
							return null;
						})
					} else {
						if (this.props.context.componentParent && this.props.context.componentParent.state && this.props.context.componentParent.state.plateList) {
							this.props.context.componentParent.state.plateList.map(plate => {
								if (plate._id === item.plateId) {
									plateName = plate.name;
								}
								return null;
							})
						}
					}
					if (item.cellIndex && !plateName.includes("SOL")) {
						const { row, column } = getRowColumnIndexForQuantPlate(item.cellIndex);
						wellPosition = quantPlateRows[row] + quantPlateColumns[column];
					} else if (item.hasOwnProperty('cellIndex')) {
						wellPosition = Number(item.cellIndex) + 1;
					}
				}
				if (eventName === "IMPORT - FOR RETEST") {
					if (["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(item.result)) {
						eventName += " (Positive)"
					} else {
						eventName += " (Inconclusive / Invalid)"
					}
				}
				sampleTrackingStrings.push(moment(item.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") + " " + eventName + (plateName ? (" - " + plateName + " " + (wellPosition ? ("(" + wellPosition + ")") : "")) : ""));
				return null;
			})
		}
		let currentStatus = "Normal";
		if (this.state.orderDetails.is_cancel) {
			currentStatus = "Cancelled";
		} else if (this.state.orderDetails.passed_24hours && !(this.state.orderDetails.sample_tracking && this.state.orderDetails.sample_tracking.filter(item => item.eventName === 'ACCESSIONED').length)) {
			currentStatus = "Not Received (1 Day)";
		}
		let orderType = "Requisition";
		if (this.state.orderDetails.results && this.state.orderDetails.results.value) {
			orderType = "Order";
		}
		let hl7_status = "";
		if (this.state.orderDetails.is_HL7_requisition) {
			hl7_status += "Incoming HL7";
		}
		if (this.state.orderDetails.HL7_result_sent_status) {
			hl7_status += (hl7_status ? " -> " : "") + "Outgoing (" + this.state.orderDetails.HL7_result_sent_status + ")";
		}
		return (
			<ModalStyled
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.props.handleClose}
				backdrop="static"
				className={this.state.orderDetails.order_type === 'sub_bundle' ? "sub_bundle_order_view_popup" : ""}
			>
				<Modal.Header closeButton>
					<Modal.Title>Full Information</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{
						this.state.orderDetails && this.state.orderDetails.patient_id
						&&
						<div>
							<div className="row">
								<div className="col-12">
									<div><b>Status:</b> {orderType} - {currentStatus} {this.state.orderDetails.order_type === 'bundle' ? "(Panel)" : (this.state.orderDetails.order_type === 'sub_bundle' ? "(Part of Panel)" : "")}</div>
								</div>
								<div className="col-md-5">
									<div><b>Requisition Number:</b> {this.state.orderDetails.lab_order_id}</div>
									<div><b>Sample ID:</b> {this.state.orderDetails.test_info.sample}</div>
									{
										this.state.orderDetails.is_cancel && this.state.orderDetails.cancel_reason
										&&
										<div>
											<b>Cancelled Reason:</b> {this.state.orderDetails.cancel_reason}
										</div>
									}
									{
										this.state.orderDetails.results && this.state.orderDetails.results.value
										&&
										<div>
											<b>Result:</b> <a target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }} href={serviceConstants.API_HOST_NAME + this.state.orderDetails.results.pdf_path + "?" + new Date().getTime() + "&token=" + getUserAuthToken()}>{this.state.orderDetails.results.value}</a>
										</div>
									}
									{
										this.state.orderDetails.test_info && this.state.orderDetails.test_info.received
										&&
										<div>
											<b>Received Date:</b> {moment(this.state.orderDetails.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}
										</div>
									}
									{
										this.state.orderDetails.results && this.state.orderDetails.results.released
										&&
										<div>
											<b>Released Date:</b> {moment(this.state.orderDetails.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}
										</div>
									}
									{
										this.state.orderDetails.results && this.state.orderDetails.results.released
										&&
										<div>
											<b>HL7 Status:</b> {hl7_status}
										</div>
									}
								</div>
								<div className="col-md-7">
									<div><b>Patient:</b> {this.state.orderDetails.patient_id.first_name + " " + this.state.orderDetails.patient_id.last_name} ({moment(this.state.orderDetails.patient_id.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY")})</div>
									<div><b>Patient ID:</b> {this.state.orderDetails.patient_id.mrn}</div>
									<div><b>Phone/Email:</b> {this.state.orderDetails.patient_id.mobile} / {this.state.orderDetails.patient_id.email ? (this.state.orderDetails.patient_id.email) : ""}</div>
									<div><b>Address:</b> {getFullAddress(this.state.orderDetails.patient_id.address)}</div>
									<div><b>Driver License:</b> {this.state.orderDetails.patient_id.driver_license} <b>Driver License State:</b> {this.state.orderDetails.patient_id.driver_license_state}</div>
								</div>
							</div>
							<hr />
							<div className="row">
								<h4 className="col-12">Order Information</h4>
								{
									this.state.orderDetails.order_type === 'bundle'
									&&
									<ThemeContext.Consumer>
										{({ themeName }) => (
											<div
												id="currentListGrid"
												style={{
													height: "250px",
													width: "100%",
													marginBottom: "20px"
												}}
												className={themeName === "Light" ? "ag-theme-alpine col-12" : "ag-theme-alpine-dark col-12"}
											>
												<AgGridReact
													columnDefs={this.state.columnDefs}
													defaultColDef={this.state.defaultColDef}
													masterDetail={true}
													onGridReady={this.onGridReady}
													rowData={this.state.rowData}
													pagination={true}
													paginationPageSize={20}
													paginationNumberFormatter={this.state.paginationNumberFormatter}
													context={this.state.context}
												/>
											</div>
										)}
									</ThemeContext.Consumer>
								}
								<div className="col-12"><b>Test:</b> {this.state.orderDetails.test_id.name}</div>
								<div className="col-12"><b>Test Type:</b> {this.state.orderDetails.test_info.test_type}</div>
								<div className="col-12"><b>Specimen Collected Date:</b> {moment(this.state.orderDetails.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}</div>
								<div className="col-12 mt-2"><b>Facility Source:</b> {this.state.orderDetails.facility_source}</div>
								<div className="col-12 mt-2"><b>Physician:</b> {this.state.orderDetails.provider.first_name + " " + this.state.orderDetails.provider.last_name}</div>
								<div className="col-12 mt-2"><b>Diagnosis Codes:</b> {this.state.orderDetails.diagnosis_code.join(", ")}</div>
								<div className="col-12 mt-2"><b>Notification Logs:</b></div>
								{
									notificatinLogStrings.map((string, index) => {
										return <div className="col-12" key={index}>{string}</div>
									})
								}
								<div className="col-12 mt-2"><b>Sample Tracking:</b></div>
								{
									sampleTrackingStrings.map((string, index) => {
										return <div className="col-12" key={index}>{string}</div>
									})
								}
								<div className="col-12 mt-2"><b>Requisition PDF:</b> <a target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }} href={serviceConstants.API_HOST_NAME + "/files/requisitionPDF/" + this.state.orderDetails.requisition_pdf_path + "?" + new Date().getTime() + "&token=" + getUserAuthToken()}>{this.state.orderDetails.requisition_pdf_path}</a></div>
								<div className="col-12"><b>Patient Symptoms:</b> {this.state.orderDetails.symptoms.join(", ")}</div>
								<div className="col-12 mt-2"><b>Order Notice History:</b></div>
								{
									orderNoticeHistoryStrings.map((string, index) => {
										return <div className="col-12" key={index}>{string}</div>
									})
								}
							</div>
							<hr />
							<div className="row">
								<h4 className="col-12">Payment Information</h4>
								<div className="col-12"><b>Insurance Provider:</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insurance_provider ? this.state.orderDetails.patient_insurance_id.insurance_provider : ""}</div>
								<div className="col-12 mt-2"><b>Insurance Member ID:</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_member_id ? this.state.orderDetails.patient_insurance_id.insured_member_id : ""}</div>
								<div className="col-12"><b>Insurance Group:</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_group_number ? this.state.orderDetails.patient_insurance_id.insured_group_number : ""}</div>
								<div className="col-12"><b>Relation to Insurance</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.relation_to_insured ? this.state.orderDetails.patient_insurance_id.relation_to_insured : ""}</div>
								<div className="col-12"><b>Insurance Holder:</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_first_name ? this.state.orderDetails.patient_insurance_id.insured_first_name : ""} {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.insured_last_name ? this.state.orderDetails.patient_insurance_id.insured_last_name : ""}</div>
								<div className="col-12"><b>Social Security:</b> {this.state.orderDetails.patient_insurance_id && this.state.orderDetails.patient_insurance_id.social_security_number ? this.state.orderDetails.patient_insurance_id.social_security_number : ""}</div>
							</div>
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={this.props.handleClose}
					>
						Close
					</Button>
				</Modal.Footer>
			</ModalStyled >
		);
	}
}
