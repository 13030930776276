import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Checkbox, MenuItem, Autocomplete, TextField } from "@mui/material";
import FileBrowse from "../../../patientSignup/signup/patientPhotoUploadInfo/fileBrowse";
import {
    createInsurance,
    updateInsurance,
    getInsuranceDataById
} from "../../../../services/clinicPortalServices/patientInsuranceService";
import { orderEasyUpdate } from "../../../../services/clinicPortalServices/orderEditService";
import { relation } from "../../../../services/common/optionsData";
import Swal from "sweetalert2";
import moment from 'moment';
import _ from 'lodash';
import { checkValidityLicense, getUserRole, uploadFiles } from "../../../../services/common/util";
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { getPatientPhotoUrls } from "../../../../services/clinicPortalServices/patientEditService";
import { withTranslation } from 'react-i18next';

class InsuranceDetails extends Component {
    constructor(props) {
        super(props);
        let insuranceId, patientName, patientId, reasonInactive, isActive;
        if (props.type === 'updateFromInsurance') {
            insuranceId = props.data._id;
            patientName = props.data.patient.first_name + " " + props.data.patient.last_name;
            patientId = props.data.patient._id;
            reasonInactive = props.data.reason_inactive;
            isActive = props.data.hasOwnProperty('is_active') && !props.data.is_active ? false : true;
        } else {
            if (props.data.patient_insurance_id && props.data.patient_insurance_id._id) {
                insuranceId = props.data.patient_insurance_id._id;
                reasonInactive = props.data.patient_insurance_id.reason_inactive;
                isActive = props.data.patient_insurance_id.hasOwnProperty('is_active') && !props.data.patient_insurance_id.is_active ? false : true;
            } else {
                insuranceId = "";
                reasonInactive = "";
                isActive = true;
            }
            patientName = props.data.patient_id.first_name + " " + props.data.patient_id.last_name;
            patientId = props.data.patient_id._id;
        }
        this.state = {
            insuranceId: insuranceId,
            patientName: patientName,
            patientId: patientId,
            insuranceProv1: "",
            insuranceProv2: "",
            memberId: "",
            groupNumber: "",
            relation: "",
            insuredFirstName: "",
            insuredLastName: "",
            socialSecurityNumber: "",
            certifyNoInsurance: false,
            errors: [],
            isValidSecurityNumber: false,
            insuranceFrontPageFileName: null,
            insuranceFrontPageFile: null,
            insuranceBackPageFileName: null,
            insuranceBackPageFile: null,
            reasonInactive: reasonInactive ? reasonInactive : '',
            isActive: isActive ? isActive : false,
            insuranceProviders: [],
            note: "",
            statesFailed: []
        };
    }

    componentDidMount() {
        getAllInsuranceProviders().then(res => {
            this.setState({ insuranceProviders: ['Self/Pay', 'Other', ...res.data.filter(item => item.name !== "NO INSURANCE").map(item => { return item.name; })] });
            if (this.state.insuranceId) {
                this.loadInsuranceDetails();
            }
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    toggleCheckedCertify = () => {
        if (!this.state.certifyNoInsurance) {
            this.setState({ errors: [] });
        } else {
            this.setState({ errors: ["certifyNoInsurance"] });
        }
        this.setState({ certifyNoInsurance: !this.state.certifyNoInsurance });
    }

    toggleCheckedInactive = () => {
        this.setState({ isActive: !this.state.isActive });
    }

    handleReadFile = (type) => (file) => {
        if (type === 'insuranceFrontPageFileName') {
            this.setState({
                insuranceFrontPageFileName: file.name.substring(0, file.name.lastIndexOf(".")) + "_" + moment().format("YYYYMMDDHHmmss") + file.name.substring(file.name.lastIndexOf(".")),
                insuranceFrontPageFile: file,
            });
            if (this.state.statesFailed.includes('insuranceFrontPageFileName')) {
                const statesFailed = this.state.statesFailed.filter(failedName => failedName !== 'insuranceFrontPageFileName' && failedName !== 'insuranceFrontPageFile');
                this.setState({ statesFailed });
            }
        }
        if (type === 'insuranceBackPageFileName') {
            this.setState({
                insuranceBackPageFileName: file.name.substring(0, file.name.lastIndexOf(".")) + "_" + moment().format("YYYYMMDDHHmmss") + file.name.substring(file.name.lastIndexOf(".")),
                insuranceBackPageFile: file
            });
            if (this.state.statesFailed.includes('insuranceBackPageFileName')) {
                const statesFailed = this.state.statesFailed.filter(failedName => failedName !== 'insuranceBackPageFileName' && failedName !== 'insuranceBackPageFile');
                this.setState({ statesFailed });
            }
        }
    };

    handleFileDelete = (type) => {
        if (type === 'insuranceFrontPageFileName') {
            this.setState({
                insuranceFrontPageFileName: this.insurance && this.insurance.insuranceFrontPageFileName ? this.insurance.insuranceFrontPageFileName : '',
                insuranceFrontPageFile: null,
            })
        }
        if (type === 'insuranceBackPageFileName') {
            this.setState({
                insuranceBackPageFileName: this.insurance && this.insurance.insuranceBackPageFileName ? this.insurance.insuranceBackPageFileName : '',
                insuranceBackPageFile: null,
            })
        }
    }

    handleFileUpload = () => {
        if (this.state.insuranceFrontPageFile || this.state.insuranceBackPageFile) {
            uploadFiles(this.state)
                .then(res => {
                    const { statesFailed } = res;
                    if (statesFailed.length > 0) {
                        this.setState({
                            statesFailed: statesFailed
                        });
                        statesFailed.map(nameFailed => {
                            this.setState({
                                [nameFailed]: ''
                            })
                            return null;
                        });
                    } else {
                        this.submitInsuranceDetails();
                    }
                })
        }
        else {
            this.submitInsuranceDetails();
        }
    };

    loadInsuranceDetails = () => {
        getInsuranceDataById(this.state.insuranceId)
            .then((response) => {
                let insuranceDetails = response.data[0];
                let flag = true;
                this.state.insuranceProviders.map(item => {
                    if (item === insuranceDetails.insurance_provider) {
                        flag = false;
                    }
                    return null;
                })
                if (flag) {
                    insuranceDetails.insurance_provider2 = insuranceDetails.insurance_provider;
                    insuranceDetails.insurance_provider = "Other";
                }
                this.insurance = {
                    insuranceProv1: insuranceDetails.insurance_provider ? insuranceDetails.insurance_provider : "",
                    insuranceProv2: insuranceDetails.insurance_provider2 ? insuranceDetails.insurance_provider2 : "",
                    memberId: insuranceDetails.insured_member_id ? insuranceDetails.insured_member_id : "",
                    groupNumber: insuranceDetails.insured_group_number ? insuranceDetails.insured_group_number : "",
                    relation: insuranceDetails.relation_to_insured ? insuranceDetails.relation_to_insured : "",
                    insuredFirstName: insuranceDetails.insured_first_name ? insuranceDetails.insured_first_name : "",
                    insuredLastName: insuranceDetails.insured_last_name ? insuranceDetails.insured_last_name : "",
                    socialSecurityNumber: insuranceDetails.social_security_number ? insuranceDetails.social_security_number : "",
                    certifyNoInsurance: insuranceDetails.certify_no_insurance ? insuranceDetails.certify_no_insurance : false,
                    insuranceFrontPageFileName: insuranceDetails.images && insuranceDetails.images.insurance_front ? insuranceDetails.images.insurance_front : '',
                    insuranceBackPageFileName: insuranceDetails.images && insuranceDetails.images.insurance_back ? insuranceDetails.images.insurance_back : '',
                    note: insuranceDetails.note ? insuranceDetails.note : ""
                };
                this.setState(_.cloneDeep(this.insurance));
            });
    };

    handleSubmit = (e) => {
        let errors = [];
        if (!this.state.insuranceProv1 && !this.state.insuranceProv2) {
            errors.push('insuranceProvider');
        }
        if (/Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv1) || (this.state.insuranceProv1 === "Other" && /Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv2))) {
            if (!this.state.certifyNoInsurance) {
                errors.push('certifyNoInsurance');
            }
        } else if (this.state.insuranceProv1 !== "Self/Pay") {
            if (!this.state.memberId) {
                errors.push('memberId');
            }
        }
        if (this.state.socialSecurityNumber && !checkValidityLicense(this.state.socialSecurityNumber)) {
            errors.push('invalidSocialSecurityNumber');
        }
        if (!this.state.isActive && !this.state.reasonInactive) {
            errors.push('reasonInactive');
        }

        if (errors.length) {
            if (errors[0] === 'invalidSocialSecurityNumber') {
                Swal.fire({
                    title: 'Invalid Social Security Number',
                    text: `This looks like an invalid social security number.  It is required for using the ${this.state.insuranceProv1} option.  Please ensure it is entered correctly before you proceed, if you believe it to be correct please select allow it to continue.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Try again',
                    cancelButtonText: 'Allow it',
                    customClass: {
                        container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                        cancelButton: 'order-1',
                        confirmButton: 'order-2'
                    }
                }).then((result) => {
                    if (!result.isConfirmed) {
                        this.handleFileUpload();
                    }
                });
            } else {
                this.setState({ errors: errors });
            }
        } else {
            this.handleFileUpload();
        }
    };

    submitInsuranceDetails = () => {
        let insuranceDetails = {
            insuranceId: this.state.insuranceId,
            insuranceProv1: this.state.insuranceProv1,
            insuranceProv2: this.state.insuranceProv2,
            memberId: this.state.memberId,
            groupNumber: this.state.groupNumber,
            relation: this.state.relation,
            insuredFirstName: this.state.insuredFirstName,
            insuredLastName: this.state.insuredLastName,
            patientId: this.state.patientId,
            socialSecurityNumber: this.state.socialSecurityNumber,
            certifyNoInsurance: this.state.certifyNoInsurance,
            isActive: this.state.isActive,
            reasonInactive: !this.state.isActive ? this.state.reasonInactive : "",
            note: this.state.note
        }
        if (this.state.insuranceFrontPageFileName || this.state.insuranceBackPageFileName) {
            insuranceDetails.images = {}
        }
        if (this.state.insuranceFrontPageFileName) {
            insuranceDetails.images.frontImage = this.state.insuranceFrontPageFileName;
        }
        if (this.state.insuranceBackPageFileName) {
            insuranceDetails.images.backImage = this.state.insuranceBackPageFileName;
        }
        if (this.state.insuranceId) {
            updateInsurance(insuranceDetails)
                .then((response) => {
                    if (this.props.type === 'updateFromInsurance') {
                        this.props.context.componentParent.updateGridRow(this.props.nodeId, response.data);
                    } else {
                        this.props.context.componentParent.loadGridData();
                    }
                    this.handleClose();
                });
        } else {
            createInsurance(insuranceDetails)
                .then((response) => {
                    let params = {
                        _id: this.props.data._id,
                        patient_insurance_id: response.data._id
                    }
                    orderEasyUpdate(params).then(response => {
                        this.props.context.componentParent.loadGridData();
                        this.handleClose();
                    })
                });
        }
    }
    handleClose = () => {
        this.props.handleClose();
    };

    render() {
        let insuranceProviders = this.state.insuranceProviders;
        const { statesFailed } = this.state;
        const { t } = this.props;
        return (
            <div>
                <div className="row form-row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>Patient Name</label>
                            <input
                                type="text"
                                disabled
                                className="form-control"
                                name="patientName"
                                value={this.state.patientName}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Choose your Insurance Provider</label>
                        <Autocomplete
                            id="insurance-provider-field"
                            options={insuranceProviders}
                            value={this.state.insuranceProv1}
                            onChange={(event, newValue) => {
                                this.handleChange({ target: { value: newValue, name: "insuranceProv1" } })
                            }}
                            renderInput={(params) => <TextField {...params} label="Insurance Provider" variant="outlined" />}
                        />
                        <div className={this.hasError("insuranceProvider") ? "inline-errormsg" : "hidden"}>
                            <i className="fa fa-exclamation-circle" aria-hidden="true">
                                &nbsp;This field is required.
                            </i>
                        </div>
                    </div>
                    {
                        this.state.insuranceProv1 === "Other"
                        &&
                        <div className="form-group col-md-6">
                            <label>Insurance Provider (If Other)</label>
                            <input
                                className="form-control"
                                name="insuranceProv2"
                                value={this.state.insuranceProv2}
                                onChange={this.handleChange}
                            />
                        </div>
                    }
                </div>
                {
                    this.state.insuranceProv1 !== "Self/Pay"
                    &&
                    <div>
                        {
                            !(/Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv1) || (this.state.insuranceProv1 === "Other" && /Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv2)))
                            &&
                            <div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>
                                            Insurance Member ID
                                        </label>
                                        <input
                                            className="form-control"
                                            name="memberId"
                                            value={this.state.memberId}
                                            onChange={this.handleChange}
                                        />
                                        <div className={this.hasError("memberId") ? "inline-errormsg" : "hidden"}>
                                            <i className="fa fa-exclamation-circle" aria-hidden="true">
                                                &nbsp;This field is required.
                                            </i>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>
                                            Insurance Group Number
                                        </label>
                                        <input
                                            className="form-control"
                                            name="groupNumber"
                                            value={this.state.groupNumber}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Relation to Insured</label>
                                        <select
                                            className="select form-control"
                                            type="text"
                                            name="relation"
                                            value={this.state.relation}
                                            onChange={this.handleChange}
                                        >
                                            {
                                                relation.map((item) => {
                                                    return (
                                                        <option value={item.value} key={item.value}>
                                                            {item.desc}
                                                        </option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                {
                                    this.state.relation !== 'Self'
                                    &&
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label>What is the Name of The Insurance Holder?</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="insuredFirstName"
                                                value={this.state.insuredFirstName}
                                                onChange={this.handleChange}
                                            />
                                            <label className="home-page-label">
                                                First Name
                                            </label>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label></label>
                                            <input
                                                style={{ marginTop: "8px" }}
                                                className="form-control"
                                                type="text"
                                                name="insuredLastName"
                                                value={this.state.insuredLastName}
                                                onChange={this.handleChange}
                                            />
                                            <label className="home-page-label">
                                                Last Name
                                            </label>
                                        </div>
                                    </div>
                                }
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            Photo of the front of your health insurance
                                        </label>
                                        {
                                            this.insurance && this.insurance.insuranceFrontPageFileName
                                            &&
                                            <a
                                                className="upload-image-href pull-right"
                                                href={getPatientPhotoUrls(this.insurance.insuranceFrontPageFileName).urlLarge}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                                View File
                                            </a>
                                        }
                                        <FileBrowse
                                            handleFileChange={this.handleReadFile("insuranceFrontPageFileName")}
                                            fileDelete={() => this.handleFileDelete('insuranceFrontPageFileName')}
                                            file={this.state.insuranceFrontPageFile}
                                        />
                                        {
                                            statesFailed.includes('insuranceFrontPageFileName')
                                            &&
                                            <label
                                                className="inline-errormsg"
                                                style={{ width: "100%" }}
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;&nbsp;{t("Upload failed, please upload your .jpeg or .png image again")}
                                                </i>
                                            </label>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            Photo of the back of your health insurance
                                        </label>
                                        {
                                            this.insurance && this.insurance.insuranceBackPageFileName
                                            &&
                                            <a
                                                className="upload-image-href pull-right"
                                                href={getPatientPhotoUrls(this.insurance.insuranceBackPageFileName).urlLarge}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                                View File
                                            </a>
                                        }
                                        <FileBrowse
                                            handleFileChange={this.handleReadFile("insuranceBackPageFileName")}
                                            fileDelete={() => this.handleFileDelete('insuranceBackPageFileName')}
                                            file={this.state.insuranceBackPageFile}
                                        />
                                        {
                                            statesFailed.includes('insuranceBackPageFileName')
                                            &&
                                            <label
                                                className="inline-errormsg"
                                                style={{ width: "100%" }}
                                            >
                                                <i
                                                    className="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                >
                                                    &nbsp;&nbsp;{t("Upload failed, please upload your .jpeg or .png image again")}
                                                </i>
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (/Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv1) || (this.state.insuranceProv1 === "Other" && /Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(this.state.insuranceProv2)))
                            &&
                            <div className="row form-row">
                                <div className="col-12 mat-checkbox-custom form-group">
                                    <MenuItem onClick={this.toggleCheckedCertify}>
                                        <Checkbox checked={this.state.certifyNoInsurance} />
                                        <div style={{ whiteSpace: "break-spaces" }}>I certify that I do not have insurance</div>
                                    </MenuItem>
                                    <div
                                        className={
                                            this.hasError("certifyNoInsurance") ? "inline-errormsg" : "hidden"
                                        }
                                        style={{ width: "auto", fontSize: "12px" }}
                                    >
                                        <i className="fa fa-exclamation-circle" aria-hidden="true">
                                            &nbsp;You must check the Certify box if you want to use {this.state.insuranceProv1}
                                        </i>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="row">
                    <div className="form-group col-md-6">
                        <label>
                            Social Security Number
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="socialSecurityNumber"
                            value={this.state.socialSecurityNumber}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12">
                        <label>
                            Note
                        </label>
                        <textarea
                            className="form-control"
                            name="note"
                            value={this.state.note}
                            rows={3}
                            onChange={this.handleChange}
                        >
                        </textarea>
                    </div>
                </div>
                {
                    (getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin')
                    &&
                    <>
                        <div className="row form-row">
                            <div className="col-12  mat-checkbox-custom mb-2" style={{ maxWidth: "530px" }}>
                                <MenuItem onClick={this.toggleCheckedInactive}>
                                    <Checkbox checked={!this.state.isActive} />
                                    <div style={{ whiteSpace: "break-spaces" }}>Inactive</div>
                                </MenuItem>
                            </div>
                        </div>
                        {
                            !this.state.isActive
                            &&
                            <div className="row">
                                <div className="form-group col-12">
                                    <label>
                                        Reason for Inactive
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="reasonInactive"
                                        value={this.state.reasonInactive}
                                        onChange={this.handleChange}
                                    />
                                    <div className={this.hasError("reasonInactive") ? "inline-errormsg" : "hidden"}>
                                        <i className="fa fa-exclamation-circle" aria-hidden="true">
                                            &nbsp;This field is required.
                                        </i>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                <div className="row">
                    <div
                        className="col-12"
                        style={{
                            paddingTop: "12px",
                            borderTop: "1px solid rgba(0,0,0,.2",
                        }}
                    >
                        <Button
                            variant="primary"
                            style={{ float: "right", marginLeft: "10px" }}
                            onClick={this.handleSubmit}
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="secondary"
                            style={{ float: "right" }}
                            onClick={this.handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div >
        );
    }
}

export default withTranslation()(InsuranceDetails);
