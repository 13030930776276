import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from 'lodash';
import {
	searchOrdersMps,
} from "../../../../services/clinicPortalServices/orderSearchService";
import { bulkDownloadZipForPDF } from "../../../../services/clinicPortalServices/orderEditService";
import { serviceConstants, auditEnums } from "../../../../services/common/constants";
import { getUserRole, processCellForClipboard, defaultExcelExportParamsToUpperCase, getUserDetails } from "../../../../services/common/util";
import SearchMenu from "./searchMenu";
import toastr from "toastr";
import RequisitionPdfRenderer from "../../requisitions/clinicRequisitionGrid/requisitionPdfRenderer";
import PdfResultRenderer from "../../orders/clinicOrderGrid/pdfResultRenderer";
import InternalNoteCellRenderer from './internalNoteCellRenderer';
import EditBtnCellRenderer from "./editBtnCellRenderer";
import SymptomRenderer from "../../orders/clinicOrderGrid/symptomRenderer";
import { getCheckoutSessionIdForMpsPayment } from "../../../../services/clinicPortalServices/mpsPaymentServices";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import NoteBtnCellRenderer from "../../insurance/clinicInsuranceGrid/noteBtnCellRenderer";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import ImageBtnCellRenderer from "../../../common/imageBtnCellRenderer";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

class ClinicOrdersMPSGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isArchived: false,
			facilityIds: [],
			user_role: getUserRole(),
			userId: window.localStorage.getItem("USER_ID"),
			search_filters: {
				facility_id: [],
				from_date: moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'custom',
				filter_by: 'received_date',
				payment_status: 'all',
				filter_text: ''
			},
			columnDefs: [
				{
					headerName: "Action",
					cellRenderer: "editBtnCellRenderer",
					minWidth: 170,
				},
				{
					headerName: "Order Info",
					children: [
						{
							headerName: "Test",
							minWidth: 150,
							field: "test_id.name",
							columnGroupShow: 'open'
						},
						{
							headerName: "Test Type",
							minWidth: 150,
							field: "test_info.test_type",
							columnGroupShow: 'open'
						},
						{
							headerName: "Sample",
							minWidth: 150,
							field: "test_info.sample",
						},
						{
							headerName: "Result",
							field: "results.value",
							minWidth: 150,
							resizable: true,
							cellRenderer: "pdfResultRenderer",
						},
						{
							colId: "collected_date",
							headerName: "Specimen Collected Date",
							minWidth: 200,
							resizable: true,
							field: "test_info.collected",
							cellRenderer: function (params) {
								return params.data.test_info && params.data.test_info.collected ? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							colId: "received_date",
							headerName: "Received Date",
							minWidth: 200,
							resizable: true,
							field: "test_info.received",
							cellRenderer: function (params) {
								return params.data.test_info && params.data.test_info.received ? moment(params.data.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							colId: "released_date",
							headerName: "Released Date",
							minWidth: 200,
							resizable: true,
							field: "results.released",
							cellRenderer: function (params) {
								return params.data.results.released ? moment(params.data.results.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Diagnosis Code",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.diagnosis_code && params.data.diagnosis_code.length) {
									return params.data.diagnosis_code.join(', ');
								} else {
									return "";
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Requisition",
							minWidth: 150,
							field: "lab_order_id",
							columnGroupShow: 'open'
						},
						{
							headerName: "Requisition PDF",
							minWidth: 150,
							field: "requisition_pdf_path",
							cellRenderer: "requisitionPdfRenderer",
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Patient Symptoms",
							resizable: true,
							minWidth: 150,
							field: "symptoms",
							cellStyle: { textAlign: "center" },
							cellRenderer: "symptomRenderer",
						},
					]
				},
				{
					headerName: "Patient Info",
					children: [
						{
							headerName: "Patient Unique ID (MRN)",
							minWidth: 200,
							field: "patient_id.mrn",
							resizable: true,
						},
						{
							headerName: "First Name",
							minWidth: 200,
							resizable: true,
							field: "patient_id.first_name",
						},
						{
							headerName: "Last Name",
							minWidth: 200,
							resizable: true,
							field: "patient_id.last_name",
						},
						{
							colId: 'date_of_birth',
							headerName: "Date of Birth",
							minWidth: 150,
							resizable: true,
							field: "patient_id.date_of_birth",
							cellRenderer: function (params) {
								if (params.data.patient_id && params.data.patient_id.date_of_birth) {
									return moment(params.data.patient_id.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY");
								}
							},
						},
						{
							headerName: "Sex",
							minWidth: 150,
							resizable: true,
							field: "patient_id.gender",
							columnGroupShow: 'open'
						},
						{
							headerName: "Address 1",
							minWidth: 200,
							resizable: true,
							field: "patient_id.address.address1",
							columnGroupShow: 'open'
						},
						{
							headerName: "Address 2",
							minWidth: 200,
							resizable: true,
							field: "patient_id.address.address2",
							columnGroupShow: 'open'
						},
						{
							headerName: "City",
							minWidth: 200,
							resizable: true,
							field: "patient_id.address.city",
							columnGroupShow: 'open'
						},
						{
							headerName: "State",
							minWidth: 200,
							resizable: true,
							field: "patient_id.address.state",
							columnGroupShow: 'open'
						},
						{
							headerName: "ZIP",
							minWidth: 200,
							resizable: true,
							field: "patient_id.address.zip",
							columnGroupShow: 'open'
						},
						{
							headerName: "Email",
							minWidth: 180,
							resizable: true,
							field: "patient_id.email",
							columnGroupShow: 'open'
						},
						{
							headerName: "Cell Phone",
							minWidth: 180,
							resizable: true,
							field: "patient_id.mobile",
							columnGroupShow: 'open'
						},
						{
							headerName: "Internal Note",
							minWidth: 180,
							resizable: true,
							field: "patient_id.internal_note",
							cellRenderer: "internalNoteCellRenderer"
						}
					]
				},
				{
					headerName: "Facility Info",
					children: [
						{
							headerName: "Ordering Facility",
							minWidth: 150,
							resizable: true,
							field: "facility_source",
						},
						{
							headerName: "Ordering Provider Name",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.provider) {
									return (
										params.data.provider.first_name +
										" " +
										params.data.provider.last_name
									);
								} else {
									return "";
								}
							}
						},
						{
							headerName: "Ordering Provider NPI",
							minWidth: 150,
							resizable: true,
							field: "provider.npi"
						},
					]
				},
				{
					headerName: 'Insurance Information',
					children: [
						{
							headerName: 'YES/NO',
							resizable: true,
							minWidth: 100,
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id._id) {
									return "YES";
								} else {
									return "NO";
								}
							}
						},
						{
							headerName: 'Insurance Provider',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insurance_provider",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Member ID',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_member_id",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Group Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_group_number",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Relation to Insured',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.relation_to_insured",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Holder Name',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.insured_first_name) {
									return params.data.patient_insurance_id.insured_first_name + " " + params.data.patient_insurance_id.insured_last_name;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Social Security Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.social_security_number",
							columnGroupShow: 'open'
						},
						{
							headerName: "Insurance Front Image",
							field: "patient_insurance_id.images.insurance_front",
							minWidth: 200,
							sortable: true,
							resizable: true,
							cellRenderer: memo(params => {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.images && params.data.patient_insurance_id.images.insurance_front) {
									return <ImageBtnCellRenderer patient={params.data.patient_id} insurance={params.data.patient_insurance_id} type="insurance_front" />
								}
								return <div></div>
							}),
							columnGroupShow: 'open'
						},
						{
							headerName: "Insurance Back Image",
							field: "images.insurance_back",
							minWidth: 200,
							sortable: true,
							resizable: true,
							cellRenderer: memo(params => {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.images && params.data.patient_insurance_id.images.insurance_back) {
									return <ImageBtnCellRenderer patient={params.data.patient_id} insurance={params.data.patient_insurance_id} type="insurance_back" />

								}
								return <div></div>
							}),
							columnGroupShow: 'open'
						},
						{
							headerName: "Driver License",
							field: "patient_id.driver_license",
							minWidth: 200,
							sortable: true,
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Driver License State",
							field: "patient_id.driver_license_state",
							minWidth: 200,
							sortable: true,
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Driver License Image",
							field: "patient_id.driver_license_image",
							minWidth: 200,
							sortable: true,
							resizable: true,
							cellRenderer: memo(params => {
								if (params.data && params.data.patient_id && params.data.patient_id.driver_license_image) {
									return <ImageBtnCellRenderer patient={params.data.patient_id} insurance={{}} type="driver_license" />
								}
								return <div></div>
							}),
							columnGroupShow: 'open'
						},
						{
							headerName: "Note",
							minWidth: 90,
							resizable: true,
							cellRenderer: memo(params => {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.note) {
									return <NoteBtnCellRenderer data={params.data.patient_insurance_id} type="note" />
								}
								return <div></div>
							})
						},
					]
				},
				{
					headerName: 'Payment Information',
					children: [
						{
							headerName: 'Status',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.hasOwnProperty('due_amount')) {
									if (Number(params.data.mps_payment_id.due_amount) === 0) {
										return "Paid";
									} else {
										return "Outstanding";
									}
								} else {
									return "No entry";
								}
							},
						},
						{
							headerName: 'Records for Order',
							resizable: true,
							minWidth: 100,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.records && params.data.mps_payment_id.records.length) {
									return params.data.mps_payment_id.records.length;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Total Amount',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.total_amount) {
									return "$ " + params.data.mps_payment_id.total_amount;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Paid Amount',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.paid_amount) {
									return "$ " + params.data.mps_payment_id.paid_amount;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Due Amount',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.due_amount) {
									return "$ " + params.data.mps_payment_id.due_amount;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Date Paid',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.mps_payment_id && params.data.mps_payment_id.paid_time) {
									return moment(params.data.mps_payment_id.paid_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
								}
							},
							columnGroupShow: 'open'
						}
					]
				}
			],
			components: {
				pdfResultRenderer: PdfResultRenderer,
				requisitionPdfRenderer: RequisitionPdfRenderer,
				internalNoteCellRenderer: InternalNoteCellRenderer,
				editBtnCellRenderer: EditBtnCellRenderer,
				symptomRenderer: SymptomRenderer
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true,
			},
			apiData: [],
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			facilities: [],
			isEnabledBulkPayment: false,
			bulkPaymentIdsArray: [],
			bulkPaymentAmount: 0,
			selectedBulkPaymentPatientId: '',
			stripe: null,
			tests: [],
			resultValues: [],
			updateResultValues: false,
			bundleIds: [],
			allBundleIds: []
		};
		getAllTests({ type: "all" }).then(res => {
			if (res && res.data) {
				let allResultValues = [];
				let allBundleIds = [];
				res.data.map(test => {
					test.results.map(result => {
						allResultValues.push(result);
						return null;
					})
					if (test.is_bundle) {
						allBundleIds.push(test._id);
					}
					return null;
				})
				allResultValues.sort();
				this.allResultValues = allResultValues;
				this.setState({ tests: res.data, resultValues: allResultValues, updateResultValues: true, bundleIds: allBundleIds, allBundleIds: allBundleIds }, () => {
					this.loadFacilities(this.state.isArchived);
				})
			}
		})
	}

	componentDidMount() {
		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
			});
		}
	}

	loadFacilities = (isArchived) => {
		let defaultFilters = this.state.search_filters;
		let facilities = this.state.facilities;
		let facilityIds = [];

		let isAllFacilities = false;
		if (defaultFilters.facility_id && defaultFilters.facility_id.length > 0 && defaultFilters.facility_id.length === facilities.length && facilities.length > 0) {
			isAllFacilities = true;
		}

		getCompanyWithFacility(isArchived)
			.then((response) => {
				facilities = response.data;
				if (facilities.length > 0) {
					facilities.map(fac => {
						facilityIds.push(fac._id);
						return null;
					})
					defaultFilters.facility_id = facilityIds;
				} else {
					defaultFilters.facility_id = facilityIds;
				}

				if (!isAllFacilities && window.localStorage.getItem('FACILITY_ID') && JSON.parse(window.localStorage.getItem('FACILITY_ID')).length) {
					defaultFilters.facility_id = JSON.parse(window.localStorage.getItem('FACILITY_ID'));
				}
				this.setState({
					facilities: facilities,
					search_filters: defaultFilters,
					facilityIds: facilityIds
				}, () => {
					this.loadGridData();
				});
			});
	};

	onIsArchivedInformation = (event) => {
		this.setState({ isArchived: event.target.checked });
		this.loadFacilities(event.target.checked);
	}

	checkRealFacilities = (searchFilters) => {
		let realFacilities = [];
		searchFilters.facility_id.map(facilityId => {
			if (this.state.facilities.find(facility => facility._id === facilityId)) {
				realFacilities.push(facilityId);
			}
			return null;
		});
		return realFacilities;
	}

	loadGridData = () => {
		let searchFilters = this.state.search_filters;
		searchFilters.facility_id = this.checkRealFacilities(searchFilters);
		searchFilters.resultValues = this.state.resultValues;
		searchFilters.bundleIds = this.state.bundleIds;
		this.gridApi.showLoadingOverlay();
		searchOrdersMps(searchFilters)
			.then((response) => {
				this.setState({ apiData: response.data }, () => {
					this.filterDataByPaymentStatus();
				});
			});
	};

	filterDataByPaymentStatus = () => {
		let apiData = this.state.apiData;
		let rowData;
		switch (this.state.search_filters.payment_status) {
			case "all":
				rowData = apiData;
				break;
			case "outstanding":
				rowData = apiData.filter(item => {
					return item.mps_payment_id && item.mps_payment_id.due_amount && Number(item.mps_payment_id.due_amount) > 0
				})
				break;
			case "paid":
				rowData = apiData.filter(item => {
					return item.mps_payment_id && item.mps_payment_id.due_amount && Number(item.mps_payment_id.due_amount) === 0
				})
				break;
			case "no_entry":
				rowData = apiData.filter(item => {
					return !item.mps_payment_id
				})
				break;
			default:
				rowData = apiData;
				break;
		}
		this.setState({ rowData: rowData })
		if (!rowData.length) {
			this.gridApi.showNoRowsOverlay();
		} else {
			this.gridApi.hideOverlay();
		}
		this.sortByColumn(this.state.search_filters.filter_by, 'desc');
		this.gridApi.setQuickFilter(this.state.search_filters.filter_text);
	}

	handlePaymentStatusChange = (e) => {
		let searchFilters = this.state.search_filters;
		searchFilters.payment_status = e.target.value;
		this.setState({
			search_filters: searchFilters
		}, () => {
			this.filterDataByPaymentStatus();
		})
	}

	setDateRange = (filters) => {
		if (moment(filters.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(filters.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				filters.dateRange = 'today';
			} else if (moment(filters.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				filters.dateRange = 'week';
			} else if (moment(filters.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				filters.dateRange = 'month';
			} else {
				filters.dateRange = 'custom';
			}
		} else {
			filters.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		const filters = this.state.search_filters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(filters);
			this.setState({ search_filters: filters });
			this.loadGridData();
		}
	}

	handleFiltersChange = (e) => {
		let { facilityIds, facilities } = this.state;
		let filters = this.state.search_filters;
		switch (e.target.name) {
			case "filter_by": {
				filters.filter_by = e.target.value;
				break;
			}
			case "facility_id": {
				let facilityIdChanged = [], isAdded = false, facilitiesIn = [], facilityIdsTemp = [];
				let newFacilityIds = e.target.value;
				if (e.target.value.includes('all')) {
					if (filters.facility_id.length === facilityIds.length) {
						filters.facility_id = [];
					} else {
						filters.facility_id = _.cloneDeep(facilityIds);
					}
				} else {
					if (filters.facility_id.length > newFacilityIds.length) {
						facilityIdChanged = filters.facility_id.find(val => !newFacilityIds.includes(val));
					} else {
						facilityIdChanged = newFacilityIds.find(val => !filters.facility_id.includes(val));
						isAdded = true;
					}
					let facilityChanged = facilities.find(facility => facility._id === facilityIdChanged);
					facilityIdsTemp = _.cloneDeep(filters.facility_id);
					// If company is changed 
					if (facilityChanged.type === 'company') {
						let companyChanged = facilityChanged;
						facilities.forEach(fac => {
							if (fac.parent_id === companyChanged._id) {
								facilitiesIn.push(fac._id);
							}
						});
						let index = facilityIdsTemp.indexOf(companyChanged._id);
						if (facilitiesIn.length > 0) {
							// If company is selected
							if (isAdded) {
								// Add company Id
								facilityIdsTemp.push(companyChanged._id);
								// Add facility Ids
								facilitiesIn.forEach(facId => {
									if (!facilityIdsTemp.includes(facId)) {
										facilityIdsTemp.push(facId);
									}
								});
							} else { // If company is unselected
								// Remove company Id
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
								facilitiesIn.forEach(facId => {
									let index = facilityIdsTemp.indexOf(facId);
									if (index > -1) {
										facilityIdsTemp.splice(index, 1);
									}
								});
							}
						} else {
							isAdded ? facilityIdsTemp.push(companyChanged._id) : facilityIdsTemp.splice(index, 1);
						}
					} else { // If facility is changed
						let companyId = facilityChanged.parent_id;
						let facilitiesIn = [];
						facilities.forEach(fac => {
							if (fac.parent_id === companyId) {
								facilitiesIn.push(fac._id);
							}
						});
						// If facility Id is selected
						if (isAdded) {
							// Add facility Id
							facilityIdsTemp.push(facilityChanged._id);
							if (!filters.facility_id.includes(companyId)) {
								facilityIdsTemp.push(companyId);
							}
						} else { // If facility Id is unselected
							// Remove facility Id
							let index = facilityIdsTemp.indexOf(facilityChanged._id);
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
							// Remove company Id
							let facilitiesInFilters = [];
							facilitiesIn.forEach(fac => {
								if (facilityIdsTemp.includes(fac)) {
									facilitiesInFilters.push(fac);
								}
							});
							if (facilitiesInFilters.length === 0) {
								index = facilityIdsTemp.indexOf(companyId);
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
							}
						}
					}
					filters.facility_id = _.cloneDeep(facilityIdsTemp);
					window.localStorage.setItem('FACILITY_ID', JSON.stringify(filters.facility_id));
				}
				break;
			}
			default:
				break;
		}

		this.setState({ search_filters: filters });
		this.loadGridData();
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	onFilterTextChange = (e) => {
		let searchFilters = this.state.search_filters;
		searchFilters.filter_text = e.target.value;
		this.setState({
			search_filters: searchFilters
		})
		this.gridApi.setQuickFilter(searchFilters.filter_text);
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.MPSIncViewGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.MPSIncViewGridExported
		};
		createAudit(auditData);
	};

	onBtnExportZipForResults = () => {
		let pdfArray = [];
		this.gridApi.forEachNodeAfterFilter(node => {
			if (node.data.results && node.data.results.pdf_path) {
				pdfArray.push(node.data.results.pdf_path);
			}
		});
		if (pdfArray.length) {
			if (pdfArray.length > 1000) {
				toastr.warning("Maximum limit of 1000 reached.  Please reduce date range.");
			} else {
				const params = {
					pdfArray: pdfArray,
					userId: window.localStorage.getItem("USER_ID"),
					type: 'resultPDF'
				}
				boundActions.startBlockUILoading();
				bulkDownloadZipForPDF(params).then(response => {
					boundActions.endBlockUILoading();
					if (response.data && response.data.RESULT === "SUCCESS") {
						window.open(serviceConstants.HOST_NAME + response.data.path);
					}
				}).catch(error => {
					console.log(error);
				})
			}
		} else {
			toastr.warning("There is no filtered result");
		}
	}

	onBtnExportZipForRequisitions = () => {
		let pdfArray = [];
		this.gridApi.forEachNodeAfterFilter(node => {
			if (node.data.requisition_pdf_path) {
				pdfArray.push("/files/requisitionPDF/" + node.data.requisition_pdf_path);
			}
		});
		if (pdfArray.length) {
			if (pdfArray.length > 1000) {
				toastr.warning("Maximum limit of 1000 reached.  Please reduce date range.");
			} else {
				const params = {
					pdfArray: pdfArray,
					userId: window.localStorage.getItem("USER_ID"),
					type: 'requisitionPDF'
				}
				boundActions.startBlockUILoading();
				bulkDownloadZipForPDF(params).then(response => {
					boundActions.endBlockUILoading();
					if (response.data && response.data.RESULT === "SUCCESS") {
						window.open(serviceConstants.HOST_NAME + response.data.path);
					}
				}).catch(error => {
					console.log(error);
				})
			}
		} else {
			toastr.warning("There is no item has requisition pdf file");
		}
	}

	onPageSizeChanged = (e) => {
		this.pageSizeChange(e.target.value);
	};

	pageSizeChange = (pageSize) => {
		this.gridApi.paginationSetPageSize(Number(pageSize));
	}

	clearFilter = () => {
		let filters = this.state.search_filters;
		filters.facility_id = this.state.facilityIds;
		filters.from_date = moment().subtract(3, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.filter_by = 'received_date';

		window.localStorage.removeItem("FACILITY_ID");
		window.localStorage.removeItem("FROM_DATE_RANGE");
		window.localStorage.removeItem("TO_DATE_RANGE");

		this.setState({ search_filters: filters, resultValues: this.allResultValues, updateResultValues: true, bundleIds: this.state.allBundleIds })
		this.loadGridData();
	};

	updateDateRange = (dateRange) => {
		let filters = this.state.search_filters;
		if (filters.dateRange !== dateRange) {
			filters.dateRange = dateRange;
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					break;
				default:
					break;
			}
			this.setState({ search_filters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData();
			}
		}
	}

	sortByColumn = (type, sortType) => {
		this.gridColumnApi.applyColumnState({
			state: [
				{
					colId: type,
					sort: sortType,
				},
			],
			defaultState: { sort: null },
		});
	}

	handleEnableBulkPayment = () => {
		if (this.state.isEnabledBulkPayment) {
			this.setState({
				isEnabledBulkPayment: false,
				bulkPaymentIdsArray: [],
				bulkPaymentAmount: 0,
				selectedBulkPaymentPatientId: ''
			})
		} else {
			this.setState({ isEnabledBulkPayment: true });
		}
		this.gridApi.refreshCells();
	}

	processBulkPayment = () => {
		boundActions.startBlockUILoading();
		let firstName, lastName;
		let labOrderIds = [];
		this.state.apiData.map(item => {
			if (item.mps_payment_id && this.state.bulkPaymentIdsArray.includes(item.mps_payment_id._id)) {
				firstName = item.patient_id.first_name;
				lastName = item.patient_id.last_name;
				labOrderIds.push(item.lab_order_id);
			}
			return null;
		})
		let paymentInfo = {
			mps_payment_ids: this.state.bulkPaymentIdsArray,
			due_amount: this.state.bulkPaymentAmount,
			first_name: firstName,
			last_name: lastName,
			lab_order_id: labOrderIds[0],
			lab_order_ids: labOrderIds,
			from: "mpsView"
		}
		getCheckoutSessionIdForMpsPayment(paymentInfo).then(res => {
			if (res && res.sessionId) {
				this.state.stripe.redirectToCheckout({ sessionId: res.sessionId });
			}
		})
	}

	updateBundleIds = (bundleId) => {
		let bundleIds = this.state.bundleIds;
		if (bundleIds.includes(bundleId)) {
			bundleIds = bundleIds.filter(item => item !== bundleId);
		} else {
			bundleIds.push(bundleId);
		}
		this.setState({ bundleIds: bundleIds }, () => {
			this.loadGridData();
		})
	}

	render() {
		const { search_filters, facilities } = this.state;
		return (
			<div className="clinic-contain">
				<SearchMenu
					onIsArchivedInformation={(event) => { this.onIsArchivedInformation(event) }}
					isArchived={this.state.isArchived}
					facilities={facilities}
					facilityId={search_filters.facility_id}
					from_date={search_filters.from_date}
					to_date={search_filters.to_date}
					filter_by={search_filters.filter_by}
					payment_status={search_filters.payment_status}
					date_range={search_filters.dateRange}
					filter_text={search_filters.filter_text}
					updateDateRange={this.updateDateRange}
					onFilterTextChange={this.onFilterTextChange}
					handleFiltersChange={this.handleFiltersChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					handlePaymentStatusChange={this.handlePaymentStatusChange}
					clearFilter={this.clearFilter}
					onPageSizeChanged={this.onPageSizeChanged}
					onBtnExport={this.onBtnExport}
					onBtnExportZipForResults={this.onBtnExportZipForResults}
					onBtnExportZipForRequisitions={this.onBtnExportZipForRequisitions}
					user_role={this.state.user_role}
					isEnabledBulkPayment={this.state.isEnabledBulkPayment}
					bulkPaymentIdsArray={this.state.bulkPaymentIdsArray}
					bulkPaymentAmount={this.state.bulkPaymentAmount}
					handleEnableBulkPayment={this.handleEnableBulkPayment}
					processBulkPayment={this.processBulkPayment}
					tests={this.state.tests}
					resultValues={this.state.resultValues}
					updateResultValues={this.state.updateResultValues}
					bundleIds={this.state.bundleIds}
					updateBundleIds={this.updateBundleIds}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 150px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									processCellForClipboard={processCellForClipboard}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									defaultExcelExportParams={defaultExcelExportParamsToUpperCase}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicOrdersMPSGrid;
