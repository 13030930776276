import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class NoteCellRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false
		}
	}

	handleShow = () => {
		if ((this.props.data.notes && this.props.data.notes.length) || (this.props.data.payment && this.props.data.payment.notes && this.props.data.payment.notes.length)) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		let notes = [];
		if (this.props.data.notes && this.props.data.notes.length) {
			notes = this.props.data.notes;
		} else if (this.props.data.payment && this.props.data.payment.notes && this.props.data.payment.notes.length) {
			notes = this.props.data.payment.notes;
		}
		return (
			<div>
				<button onClick={this.handleShow} className="edit-order-btn">
					<span>{notes.length} notes</span>
				</button>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>{notes.length} notes</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								notes.length > 0
								&&
								notes.map((note, index) => {
									return <div className="row form-row history-row" key={index}>
										<div className="col-12 col-md-3 history-date">
											{moment(note.appliedDate).format("MM/DD/YYYY")}
										</div>
										<div className="col-12 col-md-9 history-description">
											{note.note}
										</div>
									</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
