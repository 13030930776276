import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { mergePatientData } from "../../../../services/clinicPortalServices/patientEditService";
import moment from "moment";
import { Checkbox, MenuItem } from "@mui/material";
import toastr from "toastr";
import Swal from "sweetalert2";
import {
	fetchPatientWithQuery
} from "../../../../services/clinicPortalServices/patientSearchService";

import { Card, CardHeader, CardContent, Avatar, Typography, IconButton } from "@mui/material";

export default class PatientDuplicatedDetails extends Component {
	constructor(props) {
		super(props);
		let patientDetails = props && props.data ? props.data : "";
		this.state = {
			show: false,
			patientIds: patientDetails.patientIds,
			patientArray: [],
			patientSelectedId: ""
		};
	}

	componentDidMount() {
		const { patientIds } = this.state;
		fetchPatientWithQuery({
			_id: patientIds
		}).then((data) => {
			this.setState({ patientArray: data.data });
		})
	}

	handleClose = () => {
		this.props.handleClose();
	};

	handlePatientSelect = (patientId) => {
		this.setState({ patientSelectedId: patientId });
	};

	handleMergePatients = () => {
		const { patientIds, patientSelectedId } = this.state;
		const mergePatientIds = patientIds.filter(item => item !== patientSelectedId);
		if (!patientSelectedId) {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'Main patient not choosed!',
				text: 'Choose the main patient and try again.',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
		}
		else if (mergePatientIds.length === 0) {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'No patients merged!',
				text: 'Choose patients that will be merged at least one.',
				icon: 'warning',
				confirmButtonText: 'Ok'
			});
		}
		else {
			const mergeDetails = {
				ids: mergePatientIds,
				selectedId: patientSelectedId
			}
			mergePatientData(mergeDetails).then(res => {
				toastr.success("Patients are merged!");
				this.props.handleClose();
				this.props.context.componentParent.loadGridData();
			})
		}
	}

	toggleRemoveMerge = (patientId) => {
		let { patientIds } = this.state;
		if (patientIds.includes(patientId)) {
			patientIds = patientIds.filter(item => item !== patientId);
		} else {
			patientIds.push(patientId);
		}
		this.setState({ patientIds });
	}

	render() {
		const { patientArray, patientSelectedId, patientIds } = this.state;
		return (
			<div className="row">
				<div className="col-12 text-center mb-3">
					<h4>Which patient would you like to keep?</h4>
				</div>
				{
					patientArray.map(patient => {
						return (
							<div className="col-12 col-md-6" key={patient._id}>
								<Card
									onClick={() => this.handlePatientSelect(patient._id)}
									style={{
										border: "1px solid #dbdad9",
										borderColor: patientSelectedId === patient._id ? "blue" : "#dbdad9",
										backgroundColor: (patientIds.includes(patient._id) || (patientSelectedId === patient._id)) ? "white" : "#8080805c"
									}}
								>
									<CardHeader
										avatar={
											<Avatar aria-label="recipe" className='patientAvatar' style={{ backgroundColor: '#0369b3' }}>
												{patient.first_name[0]}
											</Avatar>
										}
										action={
											<IconButton aria-label="settings" onClick={() => this.props.handleShowDetails(patient)}>
												<i className="fa fa-external-link-alt" aria-hidden="true">
												</i>
											</IconButton>
										}
										title={patient.first_name + ' ' + patient.last_name}
										subheader={"Last Updated: " + moment(patient.updatedAt).format("MM/DD/YYYY hh:mm A")}
									/>
									<CardContent>
										<Typography variant="body1" style={{ fontWeight: 'bold' }} component="h6">
											Patient ID
										</Typography>
										<Typography variant="body2" component="p">
											{patient._id}
										</Typography>
										<Typography variant="body1" component="h6" className="mt-2" style={{ fontWeight: 'bold' }}>
											Email Address
										</Typography>
										<Typography variant="body2" component="p">
											{patient.email ? patient.email : 'Undefined'}
										</Typography>
										<Typography variant="body1" component="h6" className="mt-2" style={{ fontWeight: 'bold' }}>
											Phone Number
										</Typography>
										<Typography variant="body2" component="p">
											{patient.mobile ? patient.mobile : 'Undefined'}
										</Typography>
										<Typography variant="body1" component="h6" className="mt-2" style={{ fontWeight: 'bold' }}>
											Birthday
										</Typography>
										<Typography variant="body2" component="p">
											{moment(patient.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY")}
										</Typography>
										<Typography variant="body1" component="h6" className="mt-2" style={{ fontWeight: 'bold' }}>
											MRN
										</Typography>
										<Typography variant="body2" component="p">
											{patient.mrn ? patient.mrn : 'Undefined'}
										</Typography>
									</CardContent>
								</Card>
								<MenuItem
									className="mb-3 pl-0" onClick={() => this.toggleRemoveMerge(patient._id)} disabled={patientSelectedId === patient._id}>
									<Checkbox checked={!(patientIds.includes(patient._id) || (patientSelectedId === patient._id))} ></Checkbox>
									Remove from merge
								</MenuItem>
							</div>
						);
					})
				}
				<div className="col-12"
					style={{
						paddingTop: "12px",
						marginTop: "12px",
						borderTop: "1px solid rgba(0,0,0,.2",
					}}
				>
					<Button
						variant="primary"
						style={{ float: "right", marginLeft: "10px" }}
						onClick={this.handleMergePatients}
					>
						Merge
					</Button>
					<Button
						variant="secondary"
						style={{ float: "right" }}
						onClick={this.handleClose}
					>
						Cancel
					</Button>
				</div>
			</div>
		);
	}
}
