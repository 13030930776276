import React, { Component } from "react";
import Barcode from 'react-barcode';
import { getUserAuthToken, popupCenter } from "../../../services/common/util";
import { serviceConstants } from "../../../services/common/constants";

export default class BarcodeRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="text-center" onClick={() => popupCenter(serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + this.props.data.test_info.sample + '.pdf?' + new Date().getTime() + '&token=' + getUserAuthToken(), this.props.data.test_info.sample, "800", "600")} style={{ cursor: "pointer" }}>
                <Barcode
                    value={this.props.data.test_info.sample}
                    format="CODE39"
                    width={1}
                    height={25}
                    marginTop={0}
                />
            </div>
        );
    }
}
