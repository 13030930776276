import { apiInterfaceForJson } from "../common/apiManager";

export const fetchLogisticNotifications = () => {
    return apiInterfaceForJson("/logisticNotification/v1/", "GET");
};

export const fetchLogisticNotificationsByDay = () => {
    return apiInterfaceForJson("/logisticNotification/v1/filterByDay", "GET");
};

export const changeLogisticNotification = (notificationDetails) => {
    return apiInterfaceForJson("/logisticNotification/v1/changeLogisticNotification", "POST", notificationDetails);
};