import { apiInterfaceForJson } from "../common/apiManager";

export const getAllInsuranceProviders = () => {
    return apiInterfaceForJson("/insuranceProvider/v1", "GET");
};

export const getInsuranceProviderDataById = (insuranceProviderId) => {
    return apiInterfaceForJson("/insuranceProvider/v1/" + insuranceProviderId, "GET");
};

export const createInsuranceProvider = (insuranceProviderDetails) => {
    return apiInterfaceForJson("/insuranceProvider/v1/", "POST", insuranceProviderDetails);
};

export const updateInsuranceProvider = (insuranceProviderDetails) => {
    return apiInterfaceForJson("/insuranceProvider/v1/", "PUT", insuranceProviderDetails);
};

export const deleteInsuranceProvider = (insuranceProviderDetails) => {
    return apiInterfaceForJson("/insuranceProvider/v1/delete", "POST", insuranceProviderDetails);
};