import React, { Component } from "react";
import { successPayment } from "../../../../services/clinicPortalServices/vipPaymentsServices";
import moment from 'moment';
import { serviceConstants } from "../../../../services/common/constants";

class VipPaymentSuccessPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vipPaymentDetails: null
        }
        this.init();
    };

    init = () => {
        const vipPaymentId = this.props.match.params.vipPaymentId;
        if (vipPaymentId) {
            successPayment({ _id: vipPaymentId }).then(response => {
                if (response.RESULT === 'SUCCESS') {
                    this.setState({
                        vipPaymentDetails: response.data
                    })
                } else {
                    this.props.history.push("/");
                }
            })
        }
    }

    render() {
        return (
            <div className="content login-content">
                <div className="container" style={{ textAlign: 'center', color: 'green', fontSize: "18px" }}>
                    <h1 style={{ textAlign: 'center', color: 'green' }}>Thank you for your VIP payment!</h1>
                    <br />
                    <p>You will receive a receipt to the email address you provided when making your payment.</p>
                    <br />
                    {
                        this.state.vipPaymentDetails
                        &&
                        <div className="vip-instruction-details">
                            <p>
                                You have chosen a VIP spot for {moment(this.state.vipPaymentDetails.date, "YYYYMMDD").format("MM/DD/YYYY")} in the {this.state.vipPaymentDetails.ampm === 'am' ? 'Morning' : 'Afternoon'}.
                                Please get to that location as soon as possible during that timeframe.  You will be responsible for making that appointment or calling the location of your choice to inform them you registered as a VIP.  All of our locations offer this service and will be able to see you in your scheduled timeframe.
                            </p>
                            <p>
                                Once you have been seen and had your swab, be sure to ask the location for your order or sample ID, you will need this to complete the VIP order in the next step.
                            </p>
                            <p>
                                Visit this Link:  <a href={serviceConstants.HOST_NAME + "/vipReserve/inputSample/" + this.state.vipPaymentDetails._id} style={{ textDecoration: "underline", color: "blue" }} rel="noreferrer" target="_blank">{serviceConstants.HOST_NAME + "/vipReserve/inputSample/" + this.state.vipPaymentDetails._id}</a>
                                <br />
                                Then enter the sample ID you were provided.
                            </p>
                            <p>
                                You may have also received a new email from us informing you a requistion was made at the location you visited.
                                <br />
                                That email also contains your sample ID.
                            </p>
                        </div>
                    }
                </div>
            </div >
        )
    }
}

export default VipPaymentSuccessPayment;

