import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import moment from "moment";
//service calls
import { getQuantPCData } from "../../../../services/limsPortalServices/quantStudioImportResultService";
import { getUserRole, dateComparator, getUserDetails } from "../../../../services/common/util";
import OrderReportsMenu from "./orderReportsMenu";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicPCPositiveReportGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isArchived: false,
			gridData: [],
			companies: [],
			chartInfo: {
				theme: 'ag-default',
				autoSize: true,
				data: [],
				series: [
					{
						type: 'line',
						xKey: 'batch_id',
						yKey: 'orf1ab_ct',
						stroke: '#0000ff',
						marker: {
							fill: '#0000ff',
							stroke: '#0000ff',
						},
					},
					{
						type: 'line',
						xKey: 'batch_id',
						yKey: 'n_gene_ct',
						stroke: '#ff6610',
						marker: {
							fill: '#ff6610',
							stroke: '#ff6610',
						},
					},
					{
						type: 'line',
						xKey: 'batch_id',
						yKey: 's_gene_ct',
						stroke: '#aaaaaa',
						marker: {
							fill: '#aaaaaa',
							stroke: '#aaaaaa',
						},
					},
				],
				axes: [
					{
						type: 'category',
						position: 'bottom',
					},
					{
						type: 'number',
						position: 'left',
					},
				],
			},
			facilityIds: [],
			user_role: getUserRole(),
			userId: window.localStorage.getItem("USER_ID"),
			searchFilters: {
				serialNumber: "",
				from_date: moment().startOf('month').format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'month',
			},
			serialNumbers: [],
			columnDefsCompany: [
				{
					headerName: 'Batch ID',
					minWidth: 210,
					field: "batch_id",
				},
				{
					headerName: "Name",
					field: "sample",
				},
				{
					headerName: "Well",
					field: "well",
				},
				{
					headerName: "Well #",
					field: "well_number",
				},
				{
					headerName: "ORF1ab Ct",
					field: "orf1ab_ct",
				},
				{
					headerName: "N gene Ct",
					field: "n_gene_ct",
				},
				{
					headerName: "S gene Ct",
					field: "s_gene_ct",
				},
				{
					headerName: "MS2 Ct",
					field: "ms2_ct",
				},
				{
					headerName: "ORF1ab",
					field: "orf1ab",
				},
				{
					headerName: "N gene",
					field: "n_gene",
				},
				{
					headerName: "S gene",
					field: "s_gene",
				},
				{
					headerName: "MS2",
					field: "ms2",
				},
				{
					headerName: "ORF1ab BaselineEnd",
					field: "orf1ab_baselineend",
				},
				{
					headerName: "N gene BaselineEnd",
					field: "n_gene_baselineend",
				},
				{
					headerName: "S gene BaselineEnd",
					field: "s_gene_baselineend",
				},
				{
					headerName: "MS2 BaselineEnd",
					field: "ms2_baselineend",
				},
				{
					headerName: "Status",
					field: "status",
				}
			],
			getDataPath: function (data) {
				return data.orgHierarchy;
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true,
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
		};
	}

	loadGridData = () => {
		let { searchFilters } = this.state;
		this.gridApi.showLoadingOverlay();
		getQuantPCData(searchFilters)
			.then((response) => {
				let serialNumbers = [];
				response.data.map(item => {
					if (item.serialNumber && !serialNumbers.includes(item.serialNumber)) {
						serialNumbers.push(item.serialNumber);
					}
					return null;
				})
				if (!searchFilters.serialNumber) {
					searchFilters.serialNumber = serialNumbers[0];
				}
				this.setState({ serialNumbers: serialNumbers, searchFilters: searchFilters }, () => {
					this.updateGridRowData(response.data);
				})
			})
	};

	updateGridRowData = (rows) => {
		let chartInfo = this.state.chartInfo;
		let rowData = [];
		rows.map(item => {
			if (item.serialNumber === this.state.searchFilters.serialNumber) {
				rowData.push(item);
			}
			return null;
		})
		let chartData = [];
		let average_orf1ab_ct = 0, average_n_gene_ct = 0, average_s_gene_ct = 0;
		let total_orf1ab_ct = 0, total_n_gene_ct = 0, total_s_gene_ct = 0;
		if (rowData.length) {
			chartData = rowData.map(item => {
				item.orf1ab_ct = Number(item.orf1ab_ct);
				item.n_gene_ct = Number(item.n_gene_ct);
				item.s_gene_ct = Number(item.s_gene_ct);
				total_orf1ab_ct += item.orf1ab_ct;
				total_n_gene_ct += item.n_gene_ct;
				total_s_gene_ct += item.s_gene_ct;
				return item;
			});
			average_orf1ab_ct = Math.round(total_orf1ab_ct / rowData.length * 100000000) / 100000000;
			average_n_gene_ct = Math.round(total_n_gene_ct / rowData.length * 100000000) / 100000000;
			average_s_gene_ct = Math.round(total_s_gene_ct / rowData.length * 100000000) / 100000000;
		}
		chartInfo.data = chartData;
		chartInfo.title = { text: "Average Values    ORF1ab Ct: " + average_orf1ab_ct.toString() + ", N gene Ct: " + average_n_gene_ct.toString() + ", S gene Ct: " + average_s_gene_ct.toString() };
		this.setState({ rowData: rowData, chartInfo: chartInfo })
		if (!rowData.length) {
			this.gridApi.showNoRowsOverlay();
		} else {
			this.gridApi.hideOverlay();
		}
	}

	updateDateRange = (dateRange) => {
		let filters = this.state.searchFilters;
		if (filters.dateRange !== dateRange) {
			filters.dateRange = dateRange;
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					break;
				default:
					break;
			}
			this.setState({ searchFilters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData();
			}
		}
	}

	setDateRange = (searchFilters) => {
		if (moment(searchFilters.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'today';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'week';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'month';
			} else {
				searchFilters.dateRange = 'custom';
			}
		} else {
			searchFilters.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		let filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(filters);
			this.setState({ searchFilters: filters });
			this.loadGridData();
		}
	}

	handleFiltersChange = (e) => {
		let filters = this.state.searchFilters;
		switch (e.target.name) {
			case "serialNumber": {
				filters.serialNumber = e.target.value;
				break;
			}
			default:
				break;
		}
		this.setState({ searchFilters: filters });
		this.loadGridData();
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	sortByColumn = (type, sortType) => {
		if (type === 'received_date') {
			type = 'test_info.received';
		}
		if (type === 'released_date') {
			type = 'results.released';
		}
		this.gridColumnApi.applyColumnState({
			state: [
				{
					colId: type,
					sort: sortType,
					comparator: dateComparator
				},
			],
			defaultState: { sort: null },
		});
	}

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.OrderTotalGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.OrderTotalGridExported
		};
		createAudit(auditData);
	};

	onPageSizeChanged = (e) => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	resetState = () => {
		this.clearFilter();
	};

	clearFilter = () => {
		let filters = this.state.searchFilters;
		filters.date_view = "daily";
		filters.filter_by = 'received_date';
		filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.dateRange = 'month';
		this.setState({ searchFilters: filters }, () => {
			this.loadGridData();
		});
	};

	render() {
		const { searchFilters } = this.state;
		return (
			<div className="clinic-contain">
				<OrderReportsMenu
					from_date={searchFilters.from_date}
					to_date={searchFilters.to_date}
					date_range={searchFilters.dateRange}
					serialNumber={searchFilters.serialNumber}
					handleFiltersChange={this.handleFiltersChange}
					handleDateFiltersChange={this.handleDateFiltersChange}
					updateDateRange={this.updateDateRange}
					clearFilter={this.clearFilter}
					onPageSizeChanged={this.onPageSizeChanged}
					resetState={this.resetState}
					onBtnExport={this.onBtnExport}
					serialNumbers={this.state.serialNumbers}
				/>
				<ThemeContext.Consumer>
					{({ themeName }) => (
						<>
							<div style={{ height: "300px" }}>
								<AgChartsReact options={themeName === "Light" ? this.state.chartInfo : { ...this.state.chartInfo, theme: 'ag-default-dark' }} />
							</div>
							<div
								style={{
									width: "100%",
									height: "100vh",
									padding: "15px",
								}}
							>
								<div
									id="myGrid"
									style={{
										height: "100%",
										width: "100%",
									}}
									className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
								>
									<AgGridReact
										rowData={this.state.rowData}
										groupDisplayType={'custom'}
										rowGroupPanelShow={'never'}
										columnDefs={this.state.columnDefsCompany}
										defaultColDef={this.state.defaultColDef}
										animateRows={true}
										onGridReady={this.onGridReady}
										pagination={true}
										paginationPageSize={20}
										paginationNumberFormatter={this.state.paginationNumberFormatter}
										excelStyles={this.state.excelStyles}
										context={this.state.context}
									/>
								</div>
							</div>
						</>
					)}
				</ThemeContext.Consumer>
			</div>
		);
	}
}

export default ClinicPCPositiveReportGrid;
