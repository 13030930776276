import React, { Component } from "react";
import { sendForgetPasswordEmail } from "../../../services/clinicPortalServices/loginService";
class UserPortalForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: "",
            isError: false,
            isSuccess: false
        };
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    sendPasswordRecoveryEmail = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        sendForgetPasswordEmail(this.state.email, window.location.origin)
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        loading: false,
                        isError: false,
                        isSuccess: true,
                    })
                } else {
                    this.setState({
                        isError: true,
                        loading: false,
                        isSuccess: false
                    });
                }
            });
    };

    render() {
        return (
            <div className="content login-content" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div
                                        className="col-md-12 col-lg-6 login-right"
                                        style={{ border: "none" }}
                                    >
                                        <h4>
                                            Welcome to American Gene Technologies Results Portal
                                            <sup>TM</sup>
                                        </h4>
                                        <br />
                                        <p style={{ fontWeight: "500" }}>
                                            Please enter your information to access your Dashboard.
                                        </p>
                                        <p>
                                            Access to the AGT Portal is restricted solely to
                                            authorized users and is monitored for administrative and
                                            security purpose by the AGT team. All users expressly
                                            consent to such monitoring at time of portal registration.
                                            Any use of this system must be in compliance with AGT
                                            policies, procedures and applicable laws. Unauthorized
                                            access or use of this system may result in portal
                                            termination and civil or criminal liability.
                                        </p>
                                        <p>&copy; 2023 American Gene Technologies</p>
                                    </div>
                                    <div className="col-md-12 col-lg-5 login-right">
                                        <form onSubmit={this.sendPasswordRecoveryEmail}>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    User Email{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            {this.state.isError && (
                                                <div
                                                    className=" btn-block "
                                                    style={{
                                                        color: "red",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    Invalid Email
                                                </div>
                                            )}
                                            {this.state.isSuccess && !this.state.loading && (
                                                <div>
                                                    <h3
                                                        className=" btn-block "
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        Email Sent
                                                    </h3>
                                                    <div className="mb-4">
                                                        An email has been sent to {this.state.email}. If this email address is registered to myresultnow.com, you'll receive instructions on how to set a new password.
                                                    </div>
                                                </div>
                                            )}
                                            <button
                                                className="btn btn-primary btn-block btn-lg login-btn"
                                                type="submit" disabled={this.state.loading}
                                            >
                                                {this.state.loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }}></i>}
                                                {this.state.loading && <span>Sending Recovery Email</span>}
                                                {!this.state.loading && !this.state.isSuccess && <span>Send Recovery Email</span>}
                                                {this.state.isSuccess && !this.state.loading && <span>Resend Recovery Email</span>}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserPortalForgetPassword;
