import { apiInterfaceForJson } from "../common/apiManager";

export const getAllTests = (params) => {
    return apiInterfaceForJson("/test/v1/getAll", "POST", params);
};

export const getAllTestsForManagementPage = (params) => {
    return apiInterfaceForJson("/test/v1/getAllTests", "POST", params);
}

export const getTestById = (id) => {
    return apiInterfaceForJson("/test/v1/" + id, "GET");
};

export const createTest = (details) => {
    return apiInterfaceForJson("/test/v1/", "POST", details);
};

export const updateTest = (details) => {
    return apiInterfaceForJson("/test/v1/", "PUT", details);
};

export const searchTests = (query) => {
    return apiInterfaceForJson("/test/v1/search", "POST", query);
};

export const deleteTest = (details) => {
    return apiInterfaceForJson("/test/v1/delete", "POST", details);
};
