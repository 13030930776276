import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
//service calls
import { fetchLogisticNotifications, changeLogisticNotification } from "../../../../services/clinicPortalServices/logisticNotificationService";
import SearchMenu from "./searchMenu";
import CheckboxRenderer from "./checkboxRenderer.jsx";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class LogisticNotificationGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			errors: [],
			columnDefs: [
				{
					headerName: "Person",
					minWidth: 250,
					resizable: true,
					field: "name",
					valueGetter: function addColumns(params) {
						if (params.data.name && params.data.phone) {
							return (
								params.data.name +
								" - " +
								params.data.phone
							);
						} else {
							return "";
						}
					},
				},
				{
					headerName: "Monday",
					minWidth: 80,
					field: 'monday',
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Tuesday",
					minWidth: 80,
					resizable: true,
					field: "tuesday",
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Wednesday",
					minWidth: 80,
					resizable: true,
					field: "wednesday",
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Thursday",
					minWidth: 80,
					resizable: true,
					field: "thursday",
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Friday",
					minWidth: 80,
					resizable: true,
					field: "friday",
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Saturday",
					minWidth: 80,
					resizable: true,
					field: "saturday",
					cellRenderer: "checkboxRenderer"
				},
				{
					headerName: "Sunday",
					minWidth: 80,
					resizable: true,
					field: "sunday",
					cellRenderer: "checkboxRenderer"
				}
			],
			context: { componentParent: this },
			components: {
				checkboxRenderer: CheckboxRenderer
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		fetchLogisticNotifications().then(res => {
			if (res && res.data && res.data.length) {
				this.setState({ rowData: res.data });
				this.gridApi.refreshCells();
				this.gridApi.hideOverlay();
			} else {
				this.setState({ rowData: [] });
				this.gridApi.showNoRowsOverlay();
			}
		});
	};

	checkItem = (_id, day, checked) => {
		const data = {
			_id: _id,
			day: day,
			checked: checked
		}
		changeLogisticNotification(data);
	}

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
		this.loadGridData();
	}

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
	};

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu />

				<div
					style={{
						width: "100%",
						height: "calc(100vh - 140px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									context={this.state.context}
									components={this.state.components}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default LogisticNotificationGrid;
