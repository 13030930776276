import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { TextField, Autocomplete } from "@mui/material";
import { ModalStyled } from '../../../../theme/customizedStyleComponents';

class BarcodeAndFind extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            emptyInput: false,
            sampleId: null,
            sampleText: '',
        };
        this._onDetected = this._onDetected.bind(this);
        this._toggle = this._toggle.bind(this);
    }

    handleSearchNew = () => {
        if (this.state.sampleText === '' && this.state.sampleId === null) {
            this.setState({ emptyInput: true })
        } else {
            this.setState({
                modal: false
            });
            if (this.state.sampleText === '') {
                this.props.handleChange(this.state.sampleId.sample);
            } else {
                this.props.handleChange(this.state.sampleText);
            }
        }
    };

    handleError = (err) => {
        console.error(err);
    };

    render() {
        return (
            <div>
                <button
                    className="btn btn-info button-info-grid"
                    type="button"
                    style={{ marginLeft: "0px", height: "42px", marginTop: "-6px", whiteSpace: "nowrap" }}
                    onClick={this._toggle}>
                    Scan Or Enter a Sample ID
                </button>
                <ModalStyled
                    show={this.state.modal}
                    onHide={this._toggle}
                    backdrop="static"
                >
                    <Modal.Header closeButton="true">
                        <Modal.Title>Scan Code 39 BarCode Or Enter SampleID</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: "15px" }}>

                        <div className='col-md-12 grid-buttons' style={{ marginBottom: "20px" }}>
                            <Autocomplete
                                id="box-demo"
                                options={this.props.sampleIds}
                                value={this.state.sampleId && this.state.sampleId.sample ? this.state.sampleId : null}
                                onChange={(event, newValue) => {
                                    this.setState({
                                        sampleId: newValue,
                                        emptyInput: false
                                    })
                                }}
                                getOptionLabel={(option) => option ? option.sample : ""}
                                renderInput={(params) => <TextField {...params} label="Enter a Sample Id" variant="outlined" />}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className='col-md-12 grid-buttons'>
                            <div className="target-barcode" style={{ width: "calc(100% - 35px)", height: "100%", right: "16px", top: "0" }} >

                            </div>
                            <BarcodeScannerComponent
                                onError={this.handleError}
                                onUpdate={(err, result) => {
                                    if (result) {
                                        this._onDetected(result.text);
                                    } else {
                                    };
                                }}
                            />
                        </div>
                        <div className='col-md-12'>
                            {this.state.emptyInput && <h4 className='text-center' style={{ marginTop: "15px", color: "red" }}>Please scan or enter the sample ID</h4>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleSearchNew}>
                            OK
                        </Button>
                    </Modal.Footer>
                </ModalStyled>
            </div>
        )
    }

    _toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
        if (!this.state.modal) {
            this.setState({ sampleId: null })
        }
    }

    _onDetected(result) {
        const format = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
        if (!format.test(result)) {
            this.setState({ sampleText: result, emptyInput: false });
            this.handleSearchNew();
        }
    }
}

export default BarcodeAndFind;