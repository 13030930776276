import React, { Component } from "react";
import { serviceConstants } from "../../../../services/common/constants";
import { getUserAuthToken } from "../../../../services/common/util";

export default class PdfResultRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfPath:
        props.data &&
          props.data.order_id &&
          props.data.order_id.results &&
          props.data.order_id.results.pdf_path &&
          props.data.order_id.results.pdf_path.length > 0
          ? `${serviceConstants.API_HOST_NAME}${props.data && props.data.order_id.results.pdf_path}`
          : "",
      result: props.data && props.data.order_id && props.data.order_id.results && props.data.order_id.results.value ? props.data.order_id.results.value : "",
    };
  }

  render() {
    return (
      <div>
        {this.state.pdfPath.length > 0 ? (
          <a href={this.state.pdfPath + '?' + new Date().getTime() + '&token=' + getUserAuthToken()} target="_blank" rel="noreferrer" className="pdf-link">
            <i className="fa fa-file-pdf-o"></i> {this.state.result}
          </a>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
