import { getUserRole, getUserID, convertRegularPickupTimesFromFront } from "../common/util";
import { apiInterfaceForJson } from "../common/apiManager";

export const fetchFacilitiesForOrders = () => {
    const userRole = getUserRole();
    if (userRole && /salesperson|customerserviceuser|salesadmin|agtsuperadmin|agtadmin|audit|labtech/.test(userRole.toLowerCase().trim())) {
        return apiInterfaceForJson("/facility/v1", "GET");
    } else {
        const user_id = getUserID();
        return apiInterfaceForJson("/user/v1/facilities", "POST", { user_id: user_id });
    }
};

export const getAllFacilityData = () => {
    return apiInterfaceForJson("/facility/v1/withArchived", "GET");
};

export const getFacilityData = () => {
    return apiInterfaceForJson("/facility/v1/search", "POST");
};

export const archiveFacility = (facilityId) => {
    return apiInterfaceForJson("/facility/v1/archiveFacility", "POST", {
        id: facilityId
    });
}

export const unArchiveFacility = (facilityId) => {
    return apiInterfaceForJson("/facility/v1/unArchiveFacility", "POST", {
        id: facilityId
    });
}

export const getFacilityDataById = (facilityId) => {
    return apiInterfaceForJson("/facility/v1/" + facilityId, "GET");
};

export const createFacility = (facilityDetails) => {
    let facilityInfo = {
        "address": {
            'address1': facilityDetails.address1,
            'address2': facilityDetails.address2,
            'city': facilityDetails.city,
            'state': facilityDetails.state,
            'zip': facilityDetails.zip,
            'country': facilityDetails.country
        },
        'code': facilityDetails.code,
        'name': facilityDetails.name,
        'isActive': true,
        'phone_no': facilityDetails.phoneNum,
        'contact_name': facilityDetails.contactName,
        'contact_email': facilityDetails.contactEmail,
        'insurance_contact_email': facilityDetails.insuranceContactEmail,
        'contact_mobile': facilityDetails.contactMobile,
        'fax_no': facilityDetails.faxNum,
        'email_notifications_enabled': facilityDetails.emailNotification,
        'environmental_monitoring_enabled': facilityDetails.environmentalMonitoring,
        'fax_type': facilityDetails.faxType,
        'insurance_only': facilityDetails.insurance_only,
        'first_inconclusive_release': facilityDetails.first_inconclusive_release,
        'invoice_processing': facilityDetails.invoice_processing,
        'diagnosis_code': facilityDetails.diagnosis_code,
        'test_type': facilityDetails.test_type,
        'collectors': facilityDetails.collectors,
    };
    if (facilityDetails.company_id) {
        facilityInfo.company_id = facilityDetails.company_id;
    }
    if (facilityDetails.regularPickupTimes) {
        facilityInfo.regularPickupTimes = convertRegularPickupTimesFromFront(facilityDetails.regularPickupTimes);
    }
    if (facilityDetails.lat) {
        facilityInfo.lat = facilityDetails.lat;
        facilityInfo.lng = facilityDetails.lng;
    }
    if (facilityDetails.testIds) {
        facilityInfo.testIds = facilityDetails.testIds;
    }
    return apiInterfaceForJson("/facility/v1/", "POST", facilityInfo);
};

export const updateFacility = (update) => {
    let updateFacilityInfo = {
        "address": {
            'address1': update.address1,
            'address2': update.address2,
            'city': update.city,
            'state': update.state,
            'zip': update.zip,
            'country': update.country
        },
        '_id': update.id,
        'code': update.code,
        'name': update.name,
        'isActive': true,
        'phone_no': update.phoneNum,
        'contact_name': update.contactName,
        'contact_email': update.contactEmail,
        'insurance_contact_email': update.insuranceContactEmail,
        'contact_mobile': update.contactMobile,
        'fax_no': update.faxNum,
        'email_notifications_enabled': update.emailNotification,
        'environmental_monitoring_enabled': update.environmentalMonitoring,
        'fax_type': update.faxType,
        'insurance_only': update.insurance_only,
        'first_inconclusive_release': update.first_inconclusive_release,
        'invoice_processing': update.invoice_processing,
        'diagnosis_code': update.diagnosis_code,
        'test_type': update.test_type,
        'collectors': update.collectors,
    };
    if (update.company_id) {
        updateFacilityInfo.company_id = update.company_id;
    }
    if (update.regularPickupTimes) {
        updateFacilityInfo.regularPickupTimes = convertRegularPickupTimesFromFront(update.regularPickupTimes);
    }
    if (update.lat) {
        updateFacilityInfo.lat = update.lat;
        updateFacilityInfo.lng = update.lng;
    }
    if (update.testIds) {
        updateFacilityInfo.testIds = update.testIds;
    }
    return apiInterfaceForJson("/facility/v1/", "PUT", updateFacilityInfo);
};

export const noticeFacility = (facilityIds) => {
    return apiInterfaceForJson("/facility/v1/notice", "POST", {
        facilityIds: facilityIds
    });
}

export const easyUpdateFacility = (params) => {
    return apiInterfaceForJson("/facility/v1/easyUpdate", "POST", params);
};

export const deleteFacility = (facilityDetails) => {
    return apiInterfaceForJson("/facility/v1/delete", "POST", facilityDetails);
};