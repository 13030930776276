import { apiInterfaceForJson } from "../common/apiManager";

export const getLocationData = () => {
    return apiInterfaceForJson("/location/v1/", "GET");
};

export const createLocation = (locationDetails) => {
    let locationInfo = {
        "address": {
            'address1': locationDetails.address1,
            'address2': locationDetails.address2,
            'city': locationDetails.city,
            'state': locationDetails.state,
            'zip': locationDetails.zip
        },
        'name': locationDetails.name,
        'image': locationDetails.image,
        'booking_link': locationDetails.booking_link,
        'contact_number': locationDetails.contact_number,
        'timings': locationDetails.timings,
        'facility_id': locationDetails.facility_id,
        'accept_insurance': locationDetails.accept_insurance,
        'exclude_register': locationDetails.exclude_register,
        'hide_list': locationDetails.hide_list,
        'paragraph_html': locationDetails.paragraph_html
    };
    if (locationDetails.lat) {
        locationInfo.lat = locationDetails.lat;
        locationInfo.lng = locationDetails.lng;
    }
    return apiInterfaceForJson("/location/v1/", "POST", locationInfo);
};

export const updateLocation = (locationDetails) => {
    let locationInfo = {
        "address": {
            'address1': locationDetails.address1,
            'address2': locationDetails.address2,
            'city': locationDetails.city,
            'state': locationDetails.state,
            'zip': locationDetails.zip
        },
        '_id': locationDetails.id,
        'name': locationDetails.name,
        'image': locationDetails.image,
        'booking_link': locationDetails.booking_link,
        'contact_number': locationDetails.contact_number,
        'timings': locationDetails.timings,
        'facility_id': locationDetails.facility_id,
        'accept_insurance': locationDetails.accept_insurance,
        'exclude_register': locationDetails.exclude_register,
        'hide_list': locationDetails.hide_list,
        'paragraph_html': locationDetails.paragraph_html,
        'paragraph_html_spanish': locationDetails.paragraph_html_spanish,
    };
    if (locationDetails.lat) {
        locationInfo.lat = locationDetails.lat;
        locationInfo.lng = locationDetails.lng;
    }
    return apiInterfaceForJson("/location/v1/", "PUT", locationInfo);
};

export const getLocationDataById = (locationId) => {
    return apiInterfaceForJson("/location/v1/" + locationId, "GET");
};

export const getSimilarLocations = (locationId) => {
    return apiInterfaceForJson("/location/v1/getSimilarLocations/" + locationId, "GET");
}

export const deleteLocation = (locationDetails) => {
    return apiInterfaceForJson("/location/v1/delete", "POST", locationDetails);
};