import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Checkbox, Select, InputLabel, FormControl, MenuItem } from "@mui/material";
import {
	noticeFacility,
	fetchFacilitiesForOrders
} from "../../../../services/clinicPortalServices/facilityServices";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class NoticeFacility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			errors: [],
			facilityId: []
		};
	}

	handleShow = () => {
		this.setState({ show: true });
		fetchFacilitiesForOrders().then(res => {
			this.setState({ facilities: res.data })
		})
	};

	handleClose = () => {
		this.setState({ show: false, facilityId: [] });
	};

	handleFiltersChange = (e) => {
		if (e.target.value.includes('all')) {
			if (this.props.facilityIds.length === this.state.facilityId.length) {
				this.setState({ facilityId: [] });
			} else {
				this.setState({ facilityId: this.props.facilityIds });
			}
		} else {
			this.setState({ facilityId: e.target.value })
		}
	}

	noticeViaEmail = () => {
		noticeFacility(this.state.facilityId)
			.then((res) => {
				if (res.status === 200) {
					let alertString;
					if (res.userCount) {
						if (this.state.facilityId.length === this.state.length) {
							alertString = "You have sent a notice to all users and facilities.";
						} else {
							let facilityNames = [];
							this.state.facilities.map(facility => {
								if (this.state.facilityId.includes(facility._id)) {
									facilityNames.push(facility.name);
								}
								return null;
							})
							let facilityNameString = facilityNames.join(', ');
							alertString = "You have just sent a notice to " + res.userCount + " users for the following facilities: (" + facilityNameString + ")";
						}
					} else {
						alertString = "This facilities user notifications have not been set up";
					}
					alert(alertString);
				}
				this.handleClose();
			});
	}

	render() {
		return (
			<div>
				<button
					className="btn btn-primary button-info-grid"
					onClick={() => this.handleShow()}
				>
					<i className="fas fa-bell"></i> Notify Facility Users of Releases
				</button>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Notify Facility Users of Releases</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row" style={{ marginBottom: "20px" }}>
							<div className="col-12">
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.state.facilities
										&&
										this.state.facilities.length
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.state.facilityId}
											onChange={this.handleFiltersChange}
											label="Select Facility"
											name="facilityId"
											multiple
											renderValue={(selected) => {
												if (selected.length === this.state.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.state.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.state.facilityId.length === this.state.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.state.facilityId.length === this.state.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.state.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.state.facilityId.includes(fac._id) ? true : false} />
															{fac.name}&nbsp;{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right", marginLeft: "10px" }}
									variant="primary"
									onClick={this.noticeViaEmail}
								>
									Notice via Email
								</Button>
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleClose}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
