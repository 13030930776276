import { apiInterfaceForJson } from "../common/apiManager";

export const getVipPaymentsData = () => {
    return apiInterfaceForJson("/vippayments/v1", "GET");
};

export const getVipPaymentDataById = (vipPaymentId) => {
    return apiInterfaceForJson("/vippayments/v1/getVipPaymentDataById", "POST", { vipPaymentId: vipPaymentId });
};

export const searchVipPayments = (query) => {
    return apiInterfaceForJson("/vippayments/v1/search", "POST", query);
}

export const createVipPayment = (vipPayment) => {
    return apiInterfaceForJson("/vippayments/v1/", "POST", vipPayment);
};

export const updateVipPayment = (vipPayment) => {
    return apiInterfaceForJson("/vippayments/v1/", "PUT", vipPayment);
};

export const deleteVipPayment = (vipPaymentDetails) => {
    let vipPaymentInfo = {
        '_id': vipPaymentDetails.id,
        'name': vipPaymentDetails.name
    };
    return apiInterfaceForJson("/vippayments/v1/deleteVipPayment", "POST", vipPaymentInfo);
};

export const getCheckoutSessionIdForVIP = (vipPaymentInfo) => {
    return apiInterfaceForJson("/vippayments/v1/getCheckoutSessionIdForVIP", "POST", vipPaymentInfo);
}

export const successPayment = (vipPaymentInfo) => {
    return apiInterfaceForJson("/vippayments/v1/successPayment", "POST", vipPaymentInfo);
}

export const submitSampleForVip = (info) => {
    return apiInterfaceForJson("/vippayments/v1/submitSampleForVip", "POST", info);
}

export const checkTime = (info) => {
    return apiInterfaceForJson("/vippayments/v1/checkTime", "POST", info);
}