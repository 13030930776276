import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Device } from "@twilio/voice-sdk";
import { getVoiceToken } from "../../../../services/clinicPortalServices/callingService";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";



export default class EditBtnCellRender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patientName: props.data.patient_id.first_name + ' ' + props.data.patient_id.last_name,
            patientMobile: props.data.patient_id.mobile.length > 10 ? props.data.patient_id.mobile : '+1' + props.data.patient_id.mobile,
            showModal: false,
            callState: 'Connecting',
            device: null,
            call: null,
            isAudioDev: false,
            soundWidth: "100px",
            soundColor: "#d1d1d1",
        }
    };

    componentDidUpdate() {
    };

    checkDev = async () => {
        await navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                // Check the connected devices
                let audioOuts = 0;
                let audioInputs = 0;

                devices.map(dev => {
                    if (dev.kind === "audioinput") {
                        audioInputs += 1;
                    }
                    if (dev.kind === "audiooutput") {
                        audioOuts += 1;
                    }
                    return null;
                })
                if (audioOuts > 0 && audioInputs > 0) {
                    this.setState({ isAudioDev: true });
                    return true;
                } else {
                    this.setState({ isAudioDev: false });
                    return false;
                }
            });
    };

    sendCallingPatient = async () => {
        this.setState({ showModal: true });
        if (this.checkDev()) {
            getVoiceToken().then(res => {
                let device = new Device(res.data, { debug: true });
                this.setState({ device: device }, () => {
                    this.state.device.on("registered", () => {
                        console.log('ready');
                        this.setState({ callState: "ready" });
                    }).on("error", (err) => {
                        this.setState({ callState: "error" })
                        console.log('error', err);
                    });
                });
            }).catch(err => {
                this.setState({ callState: "error" });
            })
        }
    }

    sendCall = async () => {
        if (this.state.device != null) {
            const call = await this.state.device.connect({ params: { To: this.state.patientMobile } });
            if (call) {
                this.setState({ call: call, callState: "onCall" }, () => {
                    const self = this;
                    this.state.call.on("volume", function (inputVolume, outputVolume) {
                        if (outputVolume === 0) {
                            if (inputVolume < 0.5) {
                                self.setState({ soundColor: '#d1d1d1', soundWidth: Math.floor(inputVolume * 100 + 100) + "px" })
                            } else if (inputVolume < 0.75) {
                                self.setState({ soundColor: '#9f9f9f', soundWidth: Math.floor(inputVolume * 100 + 100) + "px" })
                            }
                        } else {
                            if (outputVolume < 0.5) {
                                self.setState({ soundColor: '#d1d1d1', soundWidth: Math.floor(outputVolume * 100 + 100) + "px" })
                            } else if (outputVolume < 0.75) {
                                self.setState({ soundColor: '#9f9f9f', soundWidth: Math.floor(outputVolume * 100 + 100) + "px" })
                            }
                        }
                    })
                    this.state.call.on("disconnect", () => {
                        console.log('disconnect');
                        this.state.call.disconnect();
                        this.setState({ callState: "disconnect" });
                    }).on("cancel", () => {
                        console.log('cancel');
                        this.setState({ callState: "cancel" });
                    }).on("error", (err) => {
                        this.setState({ callState: "error" })
                        console.log('error', err);
                    });
                });
            }
        }
    }

    handleHangup = () => {
        if (this.state.call) {
            this.state.call.disconnect();
            this.state.device.disconnectAll();
        }
        this.setState({ showModal: false })
    }

    renderTooltipCall = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Call to patient
        </Tooltip>
    )

    handleClose = () => {
        if (this.state.device) {
            this.state.device.disconnectAll();
        }
        this.setState({ showModal: false });
    };

    render() {
        return (
            <div>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 50, hide: 100 }}
                    overlay={this.renderTooltipCall}
                >
                    <button className="edit-order-btn" onClick={this.sendCallingPatient}>
                        <i className="fa fa-phone" aria-hidden="true"></i>
                    </button>
                </OverlayTrigger>
                <ModalStyled
                    size="sm"
                    ria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Phone to {this.state.patientName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row text-center">
                            <h5 className="text-center mx-auto">{this.state.patientMobile}</h5>
                        </div>
                        {
                            !this.state.isAudioDev
                            &&
                            <h5 className="text-center mx-auto" style={{ color: "red" }}>The Audio and Mic device is not conncected. Please connect them and try call again</h5>

                        }
                        {
                            this.state.isAudioDev
                            &&
                            <div className="row text-center justify-content-center" style={{ alignItems: "center", maxHeight: "150px", height: "150px" }}>
                                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: this.state.soundWidth, height: this.state.soundWidth, background: this.state.soundColor, borderRadius: "50%" }}>
                                    <i className="fa fa-blender-phone fa-4x" aria-hidden="true" style={{ color: "white" }}></i>
                                </div>
                            </div>}
                        {
                            this.state.isAudioDev
                            &&
                            <div className="row justify-content-center">
                                {
                                    this.state.callState !== "onCall"
                                    &&
                                    <Button variant="success" onClick={this.sendCall}>
                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                    </Button>
                                }
                                {
                                    this.state.callState === "onCall"
                                    &&
                                    <Button variant="danger" onClick={this.handleHangup}>
                                        <i className="fa fa-phone-slash" aria-hidden="true"></i>
                                    </Button>
                                }
                            </div>
                        }
                    </Modal.Body>
                </ModalStyled>
            </div>
        );
    }
}