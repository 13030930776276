import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import FileBrowse from "../../../patientSignup/signup/patientPhotoUploadInfo/fileBrowse";
import { checkValidityLicense, getUserRole, uploadFiles } from "../../../../services/common/util";
import { fetchPatientInsuranceData, fetchPatientDataById } from "../../../../services/clinicPortalServices/patientSearchService";
import moment from 'moment';
import { relation } from "../../../../services/common/optionsData";
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, MenuItem, Autocomplete, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Swal from "sweetalert2";
import { createInsurance, updateInsurance, deleteInsurance } from "../../../../services/clinicPortalServices/patientInsuranceService";
import { getAllInsuranceProviders } from "../../../../services/clinicPortalServices/insuranceProviderSerivce";
import { triggerADTProcessForPatient, getPatientPhotoUrls } from "../../../../services/clinicPortalServices/patientEditService";
import { withTranslation } from 'react-i18next';
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class InsuranceInfoPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientId: props.data._id,
			insurances: [],
			removeInsuranceIds: [],
			errors: [],
			insuranceProviders: [],
			statesFailedObj: {}
		}
	}

	componentDidMount() {
		getAllInsuranceProviders().then(res => {
			this.setState({ insuranceProviders: ['Self/Pay', 'Other', ...res.data.filter(item => item.name !== "NO INSURANCE").map(item => { return item.name; })] });
		})
	}

	loadDataForModal = () => {
		fetchPatientInsuranceData(this.props.data._id)
			.then((response) => {
				if (response.data && response.data.length) {
					let insurances = response.data.map(item => {
						let flag = true;
						let provider1 = '', provider2 = '';
						provider1 = item.insurance_provider;
						this.state.insuranceProviders.map(insuranceItem => {
							if (insuranceItem === item.insurance_provider) {
								flag = false;
							}
							return null;
						})
						if (flag) {
							provider2 = item.insurance_provider;
							provider1 = "Other";
						}
						return {
							insuranceId: item._id ? item._id : '',
							provider1: provider1,
							provider2: provider2,
							memberId: item.insured_member_id ? item.insured_member_id : '',
							groupNumber: item.insured_group_number ? item.insured_group_number : '',
							relation: item.relation_to_insured ? item.relation_to_insured : '',
							firstName: item.insured_first_name ? item.insured_first_name : '',
							lastName: item.insured_last_name ? item.insured_last_name : '',
							socialSecurityNumber: item.social_security_number ? item.social_security_number : '',
							certifyNoInsurance: item.certify_no_insurance ? true : false,
							insuranceFrontPageFileName: item.images && item.images.insurance_front ? item.images.insurance_front : '',
							insuranceFrontPageFile: null,
							insuranceBackPageFileName: item.images && item.images.insurance_back ? item.images.insurance_back : '',
							insuranceBackPageFile: null,
							isActive: item.is_active,
							reasonInactive: item.reason_inactive ? item.reason_inactive : '',
							note: item.note ? item.note : "",
							is_incorrect: item.is_incorrect ? item.is_incorrect : false,
							incorrect_note: item.incorrect_note,
							is_fixed_incorrect: item.is_fixed_incorrect ? item.is_fixed_incorrect : false,
						}
					})
					this.insurances = insurances;
					this.setState({
						insurances: insurances
					})
				}
			});
	}

	hasError = (index, key) => {
		if (this.state.errors[index]) {
			return this.state.errors[index].indexOf(key) !== -1;
		}
		return false;
	}

	handleClose = () => {
		this.setState({
			insurances: [],
			removeInsuranceIds: [],
			errors: [],
			statesFailedObj: {}
		})
		this.props.handleClose();
	};

	toggleCheckedCertify = (index) => {
		let insurances = this.state.insurances;
		insurances.map((insurance, i) => {
			if (index === i) {
				insurance.certifyNoInsurance = !insurance.certifyNoInsurance;
			}
			return null;
		})
		this.setState({ insurances: insurances });
	}

	handleChange = (e, index) => {
		let insurances = this.state.insurances;
		insurances.map((insurance, i) => {
			if (index === i) {
				insurance[e.target.name] = e.target.value;
			}
			return null;
		})
		this.setState({ insurances: insurances });
	}

	handleReadFile = (type, index) => (file) => {
		let insurances = this.state.insurances;
		let { statesFailedObj } = this.state;
		insurances.map((insurance, i) => {
			if (index === i) {
				if (type === 'insuranceFrontPageFileName') {
					insurance.insuranceFrontPageFileName = file.name.substring(0, file.name.lastIndexOf(".")) + "_" + moment().format("YYYYMMDDHHmmss") + file.name.substring(file.name.lastIndexOf("."));
					insurance.insuranceFrontPageFile = file;

					if (statesFailedObj[i]?.includes('insuranceFrontPageFileName')) {
						const statesFailedTemp = statesFailedObj[i].filter(failedName => failedName !== 'insuranceFrontPageFileName' && failedName !== 'insuranceFrontPageFile');
						statesFailedObj[i] = statesFailedTemp;
						this.setState({ statesFailedObj });
					}

				}
				if (type === 'insuranceBackPageFileName') {
					insurance.insuranceBackPageFileName = file.name.substring(0, file.name.lastIndexOf(".")) + "_" + moment().format("YYYYMMDDHHmmss") + file.name.substring(file.name.lastIndexOf("."));
					insurance.insuranceBackPageFile = file;

					if (statesFailedObj[i]?.includes('insuranceBackPageFileName')) {
						const statesFailedTemp = statesFailedObj[i].filter(failedName => failedName !== 'insuranceBackPageFileName' && failedName !== 'insuranceBackPageFile');
						statesFailedObj[i] = statesFailedTemp;
						this.setState({ statesFailedObj });
					}
				}
			}
			return null;
		})
		this.setState({ insurances: insurances });
	};

	handleFileDelete = (type, index) => {
		let insurances = this.state.insurances;
		insurances.map((insurance, i) => {
			if (index === i) {
				if (type === 'insuranceFrontPageFileName') {
					insurance.insuranceFrontPageFileName = this.insurances[index] && this.insurances[index].insuranceFrontPageFileName ? this.insurances[index].insuranceFrontPageFileName : '';
					insurance.insuranceFrontPageFile = null;
				}
				if (type === 'insuranceBackPageFileName') {
					insurance.insuranceBackPageFileName = this.insurances[index] && this.insurances[index].insuranceBackPageFileName ? this.insurances[index].insuranceBackPageFileName : '';
					insurance.insuranceBackPageFile = null;
				}
			}
			return null;
		})
		this.setState({ insurances: insurances });
	}

	handleSave = () => {
		let errors = this.state.insurances.map(insurance => {
			return [];
		})
		let insurances = this.state.insurances;
		insurances.map((insurance, index) => {
			if (!insurance.provider1 && !insurance.provider2) {
				errors[index].push('insuranceProvider');
			}
			if (/Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider1) || (insurance.provider1 === 'Other' && /Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider2))) {
				if (!insurance.socialSecurityNumber) {
					errors[index].push('socialSecurityNumber');
				}
				if (!insurance.certifyNoInsurance) {
					errors[index].push('certifyNoInsurance');
				}
			} else if (insurance.provider1 !== "Self/Pay") {
				if (!insurance.memberId) {
					errors[index].push('memberId');
				}
			}
			if (insurance.socialSecurityNumber && !checkValidityLicense(insurance.socialSecurityNumber)) {
				errors[index].push('invalidSocialSecurityNumber');
			}
			if (!insurance.isActive && !insurance.reasonInactive) {
				errors[index].push('reasonInactive');
			}
			return null;
		})

		let errorHappened = false;
		let showSwal = false;
		errors.map(error => {
			if (error.length) {
				if (error.length === 1 && error[0] === 'invalidSocialSecurityNumber') {
					showSwal = true;
				} else {
					errorHappened = true;
				}
			}
			return null;
		})
		if (errorHappened) {
			this.setState({ errors: errors });
		} else {
			this.setState({ errors: [] });
			if (showSwal) {
				Swal.fire({
					title: 'Invalid Social Security Number',
					text: `This looks like an invalid social security number.  It is required for using this insurance option.  Please ensure it is entered correctly before you proceed, if you believe it to be correct please select allow it to continue.`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Try again',
					cancelButtonText: 'Allow it',
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
						cancelButton: 'order-1',
						confirmButton: 'order-2'
					}
				}).then((result) => {
					if (!result.isConfirmed) {
						this.updateInsurances();
					}
				});
			} else {
				this.updateInsurances();
			}
		}
	}

	updateInsurances = () => {
		this.state.removeInsuranceIds.map(removeInsuranceId => {
			deleteInsurance(removeInsuranceId);
			return null;
		})
		let insurances = this.state.insurances;
		if (insurances.length) {
			if (insurances.find(item => item.is_incorrect)) {
				Swal.fire({
					title: 'This record was marked as incorrect.',
					html: 'Did you correct the information?',
					icon: 'question',
					width: 800,
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: "Yes, it's fixed",
					confirmButtonColor: '#dc3741',
					denyButtonText: 'No, save without fixing',
					denyButtonColor: '#7066e0',
					cancelButtonText: "No, I'll do it now",
					allowOutsideClick: false,
					customClass: {
						cancelButton: 'order-1',
						denyButton: 'order-2 btn_info',
						confirmButton: 'order-3 btn_primary',
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					}
				}).then((result) => {
					if (result.isConfirmed) {
						insurances.map(item => {
							if (item.is_incorrect) {
								item.is_fixed_incorrect = true;
							}
							return null;
						})
						this.handleInsurances();
						triggerADTProcessForPatient({ patientId: this.state.patientId }).then(res => {
						})
					} else if (result.isDenied) {
						this.handleInsurances();
					} else {
						return;
					}
				});
			} else {
				this.handleInsurances();
			}
		} else {
			this.handleClose();
			if (this.props.hasOwnProperty('handleParentClose')) {
				this.props.handleParentClose();
			}
			fetchPatientDataById(this.props.data._id).then(res => {
				if (res && res.data && res.data[0]) {
					this.props.context.componentParent.updateGridRow(this.props.nodeId, res.data[0]);
				}
			})
		}
	}

	handleInsurances = async () => {
		let statesFailedObj = {};
		let { insurances } = this.state;
		for (let i = 0; i < insurances.length; i++) {
			const insurance = insurances[i];
			if (insurance.insuranceFrontPageFile || insurance.insuranceBackPageFile) {
				const uploadInsurancePromise = new Promise((resolve, reject) => {
					uploadFiles(insurance, true)
						.then(res => {
							const { statesFailed } = res;
							if (statesFailed.length > 0) {
								statesFailedObj[i] = statesFailed;
								statesFailed.map(nameFailed => {
									insurances[i][nameFailed] = '';
									return null;
								});
							}
							resolve(true);
						});
				});
				await uploadInsurancePromise;
			}
		}
		if (Object.keys(statesFailedObj).length > 0) {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: "Failed Uploading!",
				text: 'Some of your images did not upload correctly, please try again.',
				icon: "error"
			});
			this.setState({
				statesFailedObj,
				insurances
			});
		}
		else {
			insurances.map(insurance => {
				let insuranceDetails = {
					insuranceId: insurance.insuranceId,
					insuranceProv1: insurance.provider1,
					insuranceProv2: insurance.provider2,
					memberId: insurance.memberId,
					groupNumber: insurance.groupNumber,
					relation: insurance.relation,
					insuredFirstName: insurance.firstName,
					insuredLastName: insurance.lastName,
					socialSecurityNumber: insurance.socialSecurityNumber,
					certifyNoInsurance: insurance.certifyNoInsurance,
					patientId: this.state.patientId,
					isActive: insurance.isActive,
					reasonInactive: !insurance.isActive ? insurance.reasonInactive : '',
					note: insurance.note ? insurance.note : "",
					is_fixed_incorrect: insurance.is_fixed_incorrect ? true : false
				}
				if (insurance.insuranceFrontPageFileName || insurance.insuranceBackPageFileName) {
					insuranceDetails.images = {}
				}
				if (insurance.insuranceFrontPageFileName) {
					insuranceDetails.images.frontImage = insurance.insuranceFrontPageFileName;
				}
				if (insurance.insuranceBackPageFileName) {
					insuranceDetails.images.backImage = insurance.insuranceBackPageFileName;
				}
				if (insurance.insuranceId) {
					updateInsurance(insuranceDetails).then((response) => {
						this.handleClose();
						if (this.props.hasOwnProperty('handleParentClose')) {
							this.props.handleParentClose();
						}
						fetchPatientDataById(this.props.data._id).then(res => {
							if (res && res.data && res.data[0]) {
								this.props.context.componentParent.updateGridRow(this.props.nodeId, res.data[0]);
							}
						})
					});
				} else {
					createInsurance(insuranceDetails).then((response) => {
						this.handleClose();
						if (this.props.hasOwnProperty('handleParentClose')) {
							this.props.handleParentClose();
						}
						fetchPatientDataById(this.props.data._id).then(res => {
							if (res && res.data && res.data[0]) {
								this.props.context.componentParent.updateGridRow(this.props.nodeId, res.data[0]);
							}
						})
					});
				}
				return null;
			})
		}

	}

	addArrayItem = () => {
		let insurances = this.state.insurances;
		insurances.push({
			insuranceId: '',
			provider1: "",
			provider2: "",
			memberId: '',
			groupNumber: '',
			relation: '',
			firstName: '',
			lastName: '',
			socialSecurityNumber: '',
			certifyNoInsurance: false,
			insuranceFrontPageFileName: '',
			insuranceFrontPageFile: null,
			insuranceBackPageFileName: '',
			insuranceBackPageFile: null,
			isActive: true,
			reasonInactive: '',
			note: ""
		})
		this.setState({ insurances: insurances })
	}

	removeArrayItem = (e, index) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'Do you remove this insurance item?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				let insurances = this.state.insurances;
				let removeInsuranceIds = this.state.removeInsuranceIds;
				if (insurances[index].insuranceId) {
					removeInsuranceIds.push(insurances[index].insuranceId);
				}
				insurances.splice(index, 1);
				this.setState({
					insurances: insurances,
					removeInsuranceIds: removeInsuranceIds
				})
			}
		});
	}

	toggleCheckedInactive = (index) => {
		let { insurances } = this.state;
		insurances[index].isActive = !insurances[index].isActive;
		this.setState({ insurances: insurances });
	}

	render() {
		const { statesFailedObj } = this.state;
		const { t } = this.props;
		return (
			<ModalStyled
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.props.show}
				onHide={this.handleClose}
				onEnter={this.loadDataForModal}
				backdrop="static"
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Insurance Information</Modal.Title>
				</Modal.Header>
				<Modal.Body className="insurance-information-container">
					<div>
						<form>
							<div className="row form-row">
								<div className="col-12">
									<div className="row form-row">
										<div className="col-12">
											<div className="form-group">
												<h3>Insurance Information <i className="fa fa-plus-circle btn" onClick={() => this.addArrayItem()}></i></h3>
											</div>
										</div>
										{
											this.state.insurances.map((insurance, index) => {

												return <Accordion key={index} sx={{ width: "100%" }}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
													>
														<h4>
															Insurance {index + 1}
														</h4>
													</AccordionSummary>
													<AccordionDetails>
														<div className="row form-row">
															<div className="col-12 col-md-6">
																<div className="form-group">
																	<label>Insurance Provider <abbr className="text-danger">*</abbr></label>
																	<Autocomplete
																		id="insurance-provider-field"
																		options={this.state.insuranceProviders}
																		value={insurance.provider1}
																		onChange={(event, newValue) => {
																			this.handleChange({ target: { value: newValue, name: "provider1" } }, index)
																		}}
																		renderInput={(params) => <TextField {...params} label="Insurance Provider" variant="outlined" className="insurance-provider-input" />}
																	/>
																	<div className={this.hasError(index, "insuranceProvider") ? "inline-errormsg" : "hidden"}>
																		<i className="fa fa-exclamation-circle" aria-hidden="true">
																			&nbsp;This field is required.
																		</i>
																	</div>
																</div>
															</div>
															{
																insurance.provider1 === 'Other'
																&&
																<div className="col-12 col-md-6">
																	<div className="form-group">
																		<label>Insurance Provider (If Other) <abbr className="text-danger">*</abbr></label>
																		<input
																			name="provider2"
																			value={insurance.provider2}
																			className="form-control"
																			onChange={(e) => this.handleChange(e, index)}
																		/>
																	</div>
																</div>
															}
															{
																!(/Self\/Pay|Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider1) || (insurance.provider1 === "Other" && /Self\/Pay|Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider2)))
																&&
																<div className="col-12">
																	<div className="row form-row">
																		<div className="col-12 col-md-6">
																			<div className="form-group">
																				<label>Insurance Member ID <abbr className="text-danger">*</abbr></label>
																				<input
																					name="memberId"
																					value={insurance.memberId}
																					className="form-control"
																					onChange={(e) => this.handleChange(e, index)}
																				/>
																				<div className={this.hasError(index, "memberId") ? "inline-errormsg" : "hidden"}>
																					<i className="fa fa-exclamation-circle" aria-hidden="true">
																						&nbsp;This field is required.
																					</i>
																				</div>
																			</div>
																		</div>
																		<div className="col-12 col-md-6">
																			<div className="form-group">
																				<label>Insurance Group Number</label>
																				<input
																					name="groupNumber"
																					value={insurance.groupNumber}
																					className="form-control"
																					onChange={(e) => this.handleChange(e, index)}
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-md-6">
																			<div className="form-group">
																				<label>Relation to Insured</label>
																				<select
																					name="relation"
																					value={insurance.relation}
																					className="form-control"
																					onChange={(e) => this.handleChange(e, index)}
																				>
																					{
																						relation.map((item) => {
																							return (
																								<option value={item.value} key={item.value}>
																									{item.desc}
																								</option>
																							);
																						})
																					}
																				</select>
																			</div>
																		</div>
																		{
																			insurance.relation !== 'Self'
																			&&
																			<div className="col-12">
																				<div className="row">
																					<div className="col-12">
																						<label>What is the Name of The Insurance Holder?</label>
																					</div>
																					<div className="col-12 col-md-6">
																						<div className="form-group">
																							<input
																								name="firstName"
																								value={insurance.firstName}
																								className="form-control"
																								onChange={(e) => this.handleChange(e, index)}
																							/>
																							<label className="home-page-label">
																								First Name
																							</label>
																						</div>
																					</div>
																					<div className="col-12 col-md-6">
																						<div className="form-group">
																							<input
																								name="lastName"
																								value={insurance.lastName}
																								className="form-control"
																								onChange={(e) => this.handleChange(e, index)}
																							/>
																							<label className="home-page-label">
																								Last Name
																							</label>
																						</div>
																					</div>
																				</div>
																			</div>
																		}
																		<div className="col-12">
																			<div className="form-group">
																				<label>
																					Photo of the front of your health insurance
																				</label>
																				{
																					this.insurances && this.insurances[index] && this.insurances[index].insuranceFrontPageFileName
																					&&
																					<a
																						className="upload-image-href pull-right"
																						href={getPatientPhotoUrls(this.insurances[index].insuranceFrontPageFileName).urlLarge}
																						rel="noreferrer"
																						target="_blank"
																					>
																						<i className="fa fa-eye" aria-hidden="true"></i>{" "}
																						View File
																					</a>
																				}
																				<FileBrowse
																					handleFileChange={this.handleReadFile("insuranceFrontPageFileName", index)}
																					fileDelete={() => this.handleFileDelete('insuranceFrontPageFileName', index)}
																					file={insurance.insuranceFrontPageFile}
																				/>
																				{
																					statesFailedObj[index]?.includes('insuranceFrontPageFileName')
																					&&
																					<label
																						className="inline-errormsg"
																						style={{ width: "100%" }}
																					>
																						<i
																							className="fa fa-exclamation-circle"
																							aria-hidden="true"
																						>
																							&nbsp;&nbsp;{t("Upload failed, please upload your .jpeg or .png image again")}
																						</i>
																					</label>
																				}
																			</div>
																		</div>
																		<div className="col-12">
																			<div className="form-group">
																				<label>
																					Photo of the back of your health insurance
																				</label>
																				{
																					this.insurances && this.insurances[index] && this.insurances[index].insuranceBackPageFileName
																					&&
																					<a
																						className="upload-image-href pull-right"
																						href={getPatientPhotoUrls(this.insurances[index].insuranceBackPageFileName).urlLarge}
																						target="_blank"
																						rel="noreferrer"
																					>
																						<i className="fa fa-eye" aria-hidden="true"></i>{" "}
																						View File
																					</a>
																				}
																				<FileBrowse
																					handleFileChange={this.handleReadFile("insuranceBackPageFileName", index)}
																					fileDelete={() => this.handleFileDelete('insuranceBackPageFileName', index)}
																					file={insurance.insuranceBackPageFile}
																				/>
																				{
																					statesFailedObj[index]?.includes('insuranceBackPageFileName')
																					&&
																					<label
																						className="inline-errormsg"
																						style={{ width: "100%" }}
																					>
																						<i
																							className="fa fa-exclamation-circle"
																							aria-hidden="true"
																						>
																							&nbsp;&nbsp;{t("Upload failed, please upload your .jpeg or .png image again")}
																						</i>
																					</label>
																				}
																			</div>
																		</div>
																	</div>
																</div>
															}
															<div className="col-12">
																<div className="row">
																	<div className="col-md-6">
																		<div className="form-group">
																			<label>Social Security Number <abbr className="text-danger">*</abbr></label>
																			<input
																				name="socialSecurityNumber"
																				value={insurance.socialSecurityNumber}
																				className="form-control"
																				onChange={(e) => this.handleChange(e, index)}
																			/>
																			<div className={this.hasError(index, "socialSecurityNumber") ? "inline-errormsg" : "hidden"}>
																				<i className="fa fa-exclamation-circle" aria-hidden="true">
																					&nbsp;This field is required.
																				</i>
																			</div>
																			<div className={this.hasError(index, "invalidSocialSecurityNumber") ? "inline-errormsg" : "hidden"}>
																				<i className="fa fa-exclamation-circle" aria-hidden="true">
																					&nbsp;This field is invalid.
																				</i>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															{
																(/Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider1) || (insurance.provider1 === "Other" && /Cares Act \(Uninsured\)|HRSA COVID-19 Uninsured Program/.test(insurance.provider2)))
																&&
																<div className="col-12">
																	<div className="row form-row">
																		<div className="col-12 mat-checkbox-custom form-group">
																			<MenuItem onClick={() => this.toggleCheckedCertify(index)}>
																				<Checkbox checked={insurance.certifyNoInsurance} />
																				<div style={{ whiteSpace: "break-spaces" }}>I certify that I do not have insurance</div>
																			</MenuItem>
																			<div
																				className={
																					this.hasError(index, "certifyNoInsurance") ? "inline-errormsg" : "hidden"
																				}
																				style={{ width: "auto", fontSize: "12px" }}
																			>
																				<i className="fa fa-exclamation-circle" aria-hidden="true">
																					&nbsp;You must check the Certify box if you want to use {insurance.provider1}
																				</i>
																			</div>
																		</div>
																	</div>
																</div>
															}
														</div>
														<div className="row">
															<div className="form-group col-12">
																<label>
																	Note
																</label>
																<textarea
																	className="form-control"
																	name="note"
																	value={insurance.note}
																	rows={3}
																	onChange={(e) => this.handleChange(e, index)}
																>
																</textarea>
															</div>
														</div>
														{
															(getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin')
															&&
															<>
																<div className="row">
																	<div className="col-12  mat-checkbox-custom checkbox-inactive mb-2" style={{ maxWidth: "530px" }}>
																		<MenuItem onClick={(e) => this.toggleCheckedInactive(index)}>
																			<Checkbox checked={!insurance.isActive} />
																			<div style={{ whiteSpace: "break-spaces" }}>Inactive</div>
																		</MenuItem>
																	</div>
																</div>
																{
																	!insurance.isActive
																	&&
																	<div className="row">
																		<div className="form-group col-12">
																			<label>
																				Reason for Inactive
																			</label>
																			<input
																				className="form-control"
																				type="text"
																				name="reasonInactive"
																				value={insurance.reasonInactive}
																				onChange={(e) => this.handleChange(e, index)}
																			/>
																			<div className={this.hasError(index, "reasonInactive") ? "inline-errormsg" : "hidden"}>
																				<i className="fa fa-exclamation-circle" aria-hidden="true">
																					&nbsp;This field is required.
																				</i>
																			</div>
																		</div>
																	</div>
																}
															</>
														}
														<div className="row form-row">
															<div className="col-12">
																<h4 style={{ float: "right", cursor: "pointer" }} onClick={(e) => this.removeArrayItem(e, index)}>
																	Remove Insurance
																	<i className="fa fa-times-circle btn"></i>
																</h4>
															</div>
														</div>
													</AccordionDetails>
												</Accordion>
											})
										}
										{
											this.state.insurances.length === 0
											&&
											<div className="col-12">
												<div className="form-group">
													<h5>It doesn't have any insurance yet, please create insurance by clicking plus button</h5>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</form>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={this.handleClose}
					>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={this.handleSave}
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</ModalStyled>
		);
	}
}

export default withTranslation()(InsuranceInfoPopup);
