import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import EditBtnCellRenderer from "./editBtnCellRenderer";
import SymptomRenderer from "../../orders/clinicOrderGrid/symptomRenderer";
import TestResultRenderer from "./testResultRenderer";
import { getAllTestsForManagementPage } from "../../../../services/clinicPortalServices/testService";
import SearchMenu from "./searchMenu";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicTestGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: "Priority",
					minWidth: 100,
					resizable: true,
					field: "form_dropdown_priority",
				},
				{
					headerName: "Name",
					minWidth: 150,
					resizable: true,
					field: "name",
				},
				{
					headerName: "Is Bundle?",
					minWidth: 120,
					resizable: true,
					valueGetter: params => {
						if (params.data.is_bundle) {
							return "Yes";
						} else {
							return "No";
						}
					}
				},
				{
					headerName: "Hide from dropdown?",
					minWidth: 120,
					resizable: true,
					valueGetter: params => {
						if (params.data.hide_from_dropdown) {
							return "Yes"
						} else {
							return "No"
						}
					}
				},
				{
					headerName: "Allow as individual test of Panel only?",
					minWidth: 120,
					resizable: true,
					valueGetter: params => {
						if (params.data.not_allowed_single_test) {
							return "Yes"
						} else {
							return "No"
						}
					}
				},
				{
					headerName: "Code",
					minWidth: 150,
					resizable: true,
					field: "code",
				},
				{
					headerName: "Method Name",
					minWidth: 150,
					resizable: true,
					field: "method_name",
				},
				{
					headerName: "LOINC code",
					minWidth: 150,
					resizable: true,
					field: "loinc_code",
				},
				{
					headerName: "Machine",
					minWidth: 150,
					resizable: true,
					field: "machine",
				},
				{
					headerName: "Results",
					minWidth: 120,
					resizable: true,
					field: "results",
					cellRenderer: "testResultRenderer"
				},
				{
					headerName: "Sample Types",
					minWidth: 250,
					resizable: true,
					field: "sample_types",
				},
				{
					headerName: "Symptoms",
					minWidth: 150,
					resizable: true,
					field: "symptoms",
					cellRenderer: "symptomRenderer"
				},
				{
					headerName: "Diagnosis Codes (ICD-10)",
					minWidth: 150,
					resizable: true,
					field: "diagnosis_codes",
					valueGetter: (params) => {
						let codeArray = [];
						params.data.diagnosis_codes.map(diagnosis_code => {
							codeArray.push(diagnosis_code.code + " " + diagnosis_code.text);
							return null;
						})
						return codeArray.join(", ");
					}
				},
				{
					headerName: "Test ID",
					minWidth: 100,
					field: "_id",
					resizable: true,
				},
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
				symptomRenderer: SymptomRenderer,
				testResultRenderer: TestResultRenderer
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true
			},
			rowData: [],
			context: { componentParent: this }
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getAllTestsForManagementPage({ type: "all" })
			.then((response) => {
				this.setState({ rowData: response.data });
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
	};

	onBtExport = () => {
		this.gridApi.exportDataAsExcel({});
	};

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtExport={this.onBtExport}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicTestGrid;
