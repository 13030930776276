import { apiInterfaceForJson } from "../common/apiManager";

export const saveOrderSettings = (gridSetting) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/save", "POST", gridSetting);
};

export const saveViewDefault = (gridSetting) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/saveDefault", "POST", gridSetting);
};

export const getViewSettings = (params) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/find", "POST", params);
};

export const deleteGridViewService = (params) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/delete", "POST", params);
};

export const renameGridViewService = (params) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/rename", "POST", params);
};

export const updateGridViewService = (params) => {
    return apiInterfaceForJson("/viewsettings/v1/gridstate/update-gridview", "POST", params);
};