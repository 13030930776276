import React, { Component } from 'react';
import { IMG01 } from '../../clinicPortal/invoices/ClinicInvoiceView/img';
import { serviceConstants } from "../../../services/common/constants";
import { states, loaderPositionStyle } from "../../../services/common/optionsData";
import { TextField, MenuItem } from "@mui/material";
import { getCheckoutSessionId } from "../../../services/patientPortalServices/patientPaymentService";
import Swal from 'sweetalert2';
import { FadeLoader } from "react-spinners";

class PatientPayment extends Component {

	constructor(props) {
		super(props);
		this.state = {
			stripe: null,
			is_try_to_submit: false,
			patientName: "",
			companyName: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			showLoading: false,
		}
	}

	componentDidMount() {
		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
		} else {
			document.querySelector('#stripe-js').addEventListener('load', () => {
				// Create Stripe instance once Stripe.js loads
				this.setState({ stripe: window.Stripe(serviceConstants.STRIPE_API_PUBLIC_KEY) });
			});
		}
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	getCheckoutSession = () => {
		this.setState({
			is_try_to_submit: true
		})

		if (this.state.patientName && this.state.address1 && this.state.city && this.state.state && this.state.zip) {
			const data = {
				patient_name: this.state.patientName,
				company_name: this.state.companyName,
				address1: this.state.address1,
				address2: this.state.address2,
				city: this.state.city,
				state: this.state.state,
				zip: this.state.zip
			};
			this.setState({
				showLoading: true
			})
			getCheckoutSessionId(data).then(res => {
				if (res.RESULT === "SUCCESS") {
					this.state.stripe.redirectToCheckout({ sessionId: res.sessionId });
				} else {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: "Something went wrong!",
						icon: "error"
					})
				}
			})
		}
	}

	render() {
		const stateList = states.filter(item => item.value);
		return (
			<div>
				<div className="content login-content">
					<div className="container-fluid">
						<div className="row" style={{ fontFamily: "sans-serif" }}>
							<div className="col-lg-8 offset-lg-2">
								<div className="invoice-content" style={{ padding: "15px 30px", height: "calc(100% + 10px)" }}>
									<div className="invoice-item">
										<div className="row d-flex align-items-end">
											<div className="col-md-2">
												<img src={IMG01} alt="logo" style={{ maxHeight: "120px" }} />
											</div>
											<div className="col-md-4"></div>
											<div className="col-md-6 mt-1">
												<h1><b>MEDICAL STATEMENT</b></h1>
												<h3>SARS-Cov-2 RT-PCR Test</h3>
											</div>
										</div>
										<div className='row mt-3 d-flex align-items-center'>
											<div className="col-md-6">
												<div className="invoice-logo" style={{ fontSize: "18px" }}>
													<strong>Bill From</strong>
													<br />
													American Gene Technologies International Inc.
													<br />
													9713 Key West Ave.
													<br />
													5th Floor
													<br />
													Rockville, MD 20850
													<br />
													Attn: Billing Department
												</div>
											</div>
											<div className="col-md-6">
												<h2 className="text-danger">Balance Due: $165.00</h2>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6 form-group">
											<TextField
												label="Patient Name *"
												name="patientName"
												value={this.state.patientName}
												onChange={this.handleChange}
												error={this.state.is_try_to_submit && this.state.patientName === ""}
												helperText={this.state.is_try_to_submit && this.state.patientName === "" ? 'This field is required!' : ' '}
											/>
										</div>
										<div className="col-md-6 form-group">
											<TextField
												label="Company Name"
												name="companyName"
												value={this.state.companyName}
												onChange={this.handleChange}
											/>
										</div>
										<div className="col-md-12 mb-2">
											<h5>Patient Address</h5>
										</div>
										<div className="col-md-6 form-group">
											<TextField
												label="Address1 *"
												name="address1"
												value={this.state.address1}
												onChange={this.handleChange}
												error={this.state.is_try_to_submit && this.state.address1 === ""}
												helperText={this.state.is_try_to_submit && this.state.address1 === "" ? 'This field is required!' : ' '}
											/>
										</div>
										<div className="col-md-6 form-group">
											<TextField
												label="Address2"
												name="address2"
												value={this.state.address2}
												onChange={this.handleChange}
											/>
										</div>
										<div className="col-md-4 form-group">
											<TextField
												label="City *"
												name="city"
												value={this.state.city}
												onChange={this.handleChange}
												error={this.state.is_try_to_submit && this.state.city === ""}
												helperText={this.state.is_try_to_submit && this.state.city === "" ? 'This field is required!' : ' '}
											/>
										</div>
										<div className="col-md-4 form-group">
											<TextField
												select
												value={this.state.state}
												name="state"
												label="State *"
												onChange={this.handleChange}
												error={this.state.is_try_to_submit && this.state.state === ""}
												helperText={this.state.is_try_to_submit && this.state.state === "" ? 'This field is required!' : ' '}
											>
												{
													stateList.map(state => {
														return <MenuItem key={state.value} value={state.value}>
															{state.state}
														</MenuItem>
													})
												}
											</TextField>
										</div>
										<div className="col-md-4 form-group">
											<TextField
												label="Zip Code *"
												value={this.state.zip}
												name="zip"
												onChange={this.handleChange}
												error={this.state.is_try_to_submit && this.state.zip === ""}
												helperText={this.state.is_try_to_submit && this.state.zip === "" ? 'This field is required!' : ' '}
											/>
										</div>
										<div className="col-12 text-center" style={{ fontSize: "18px" }}>
											When clicking the "Pay $165.00" button you will be directed to provide your credit card information.
											<br />
											This payment is for a one time use only.  We do not store your credit card information on our website.
										</div>
									</div>
									<div className="row d-flex justify-content-center mt-5">
										<button className="btn btn-primary d-flex align-items-center justify-content-center" style={{ height: "60px", width: "150px", fontSize: "20px", fontWeight: "bold" }} onClick={this.getCheckoutSession} disabled={this.state.showLoading ? true : false}>
											Pay $165.00
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<FadeLoader loading={this.state.showLoading} height={12} width={5} radius={10} margin={2} css={loaderPositionStyle} color={"#42c0fb"} />
			</div>
		);
	}
}
export default PatientPayment;
