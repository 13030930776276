import React, { Component } from "react";

class HomeBookAppoinment extends Component {
  render() {
    const facheckStyle = {
      color: "#0E5087"
    }
    return (
      <section className="section section-banner" id="scroll-top">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
            </div>
            <div className="col-12 col-md-6">
              <div className="banner-wrapper">
                <div className="banner-header">
                  <h5>GET YOUR RESULTS IN 12 HOURS OR LESS</h5>
                  <h1>
                    Reserve Your VIP Spot
                    <br/>
                    <span>For $50, Get Your Result When You Need It</span>
                  </h1>
                  <p>
                    <i
                      className="fa fa-check"
                      style={facheckStyle}
                      aria-hidden="true"
                    ></i>
                    {"  "}
                    Enter Your Info
                    <br />
                    <i
                      className="fa fa-check"
                      style={facheckStyle}
                      aria-hidden="true"
                    ></i>
                    {"  "}
                    Choose A Location
                    <br />
                    <i
                      className="fa fa-check"
                      style={facheckStyle}
                      aria-hidden="true"
                    ></i>
                    {"  "}
                    Pay $50
                    <br />
                    <i
                      className="fa fa-check"
                      style={facheckStyle}
                      aria-hidden="true"
                    ></i>
                    {"  "}
                    Get Results Twice As Fast
                    <br />
                  </p>
                  <div className="btn-col">
                    <ul>
                      <li>
                        <a href="#reserve_now" className="btn btn-fill">
                          ENSURE SAME DAY RESULT
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeBookAppoinment;
