import React, { Component } from "react";
import {
    SubMenu,
    ControlledMenu
} from '@szhsin/react-menu';
import { Checkbox, MenuItem } from "@mui/material";
import _ from "lodash";

export default class TestResultFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuState: "closed",
            resultValues: []
        }
    }

    componentDidMount() {
        const self = this;
        document.onclick = function (e) {
            const target = e.target;
            if (!target.closest('.resultFilterMenu') && !target.closest('.resultFilterMenuButton')) {
                self.setState({ menuState: "closed" })
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resultValues !== this.props.resultValues && this.props.updateResultValues) {
            this.setState({ resultValues: this.props.resultValues });
        }
    }

    handleMenuClose = () => {
        let resultValues = this.state.resultValues;
        resultValues.sort();
        if (JSON.stringify(resultValues) !== JSON.stringify(this.props.resultValues)) {
            if (this.props.type === 'orderGrid') {
                let activeGridView = this.props.context.componentParent.state.activeGridView;
                activeGridView.search_filters.resultValues = resultValues;
                this.props.context.componentParent.setState({ activeGridView: _.cloneDeep(activeGridView), updateResultValues: false }, () => {
                    this.props.context.componentParent.loadGridData();
                });
            } else {
                this.props.context.componentParent.setState({ resultValues: _.cloneDeep(resultValues), updateResultValues: false }, () => {
                    this.props.context.componentParent.loadGridData();
                });
            }
        }
    };

    handleMenuButton = () => {
        if (this.state.menuState === "open") {
            this.setState({ menuState: "closed" });
        } else {
            this.setState({ menuState: "open" });
        }
    }

    clickTest = (test) => {
        let resultValues = this.state.resultValues;
        let testChecked = false;
        resultValues.map(resultValue => {
            if (test.results.includes(resultValue)) {
                testChecked = true;
            }
            return null;
        });
        if (testChecked) {
            resultValues = resultValues.filter(item => !test.results.includes(item));
        } else {
            test.results.map(result => {
                if (!resultValues.includes(result)) {
                    resultValues.push(result);
                }
                return null;
            })
        }
        this.setState({ resultValues: resultValues });
    }

    clickResult = (result) => {
        let resultValues = this.state.resultValues;
        if (!resultValues.includes(result)) {
            resultValues.push(result);
        } else {
            resultValues = resultValues.filter(item => item !== result);
        }
        this.setState({ resultValues: resultValues });
    }

    render() {
        return (
            <div className={this.props.type === 'orderGrid' ? "mt-1" : "mt-2 mb-3"}>
                <button className="btn btn-primary resultFilterMenuButton" onClick={this.handleMenuButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" viewBox="0 0 512 512"><path fill="currentColor" d="M0 64C0 46.3 14.3 32 32 32H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V266.8c-20.2 28.6-32 63.5-32 101.2c0 25.2 5.3 49.1 14.8 70.8C189.5 463.7 160.6 480 128 480c-53 0-96-43-96-96V96C14.3 96 0 81.7 0 64zM96 96v96h64V96H96zM512 368c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zm-76.7-43.3c-6.2-6.2-16.4-6.2-22.6 0L352 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z" /></svg> Test Result Filter
                </button>
                <ControlledMenu
                    state={this.state.menuState}
                    onClose={this.handleMenuClose}
                    className="resultFilterMenu"
                >
                    {
                        this.props.tests && this.props.tests.length > 0
                        &&
                        this.props.tests.map((test, testIndex) => {
                            if (!test.is_bundle) {
                                let subMenuDom = [];
                                test.results.map((result, resultIndex) => {
                                    subMenuDom.push(
                                        <div className="resultItem" key={resultIndex} onClick={() => this.clickResult(result)}>
                                            <Checkbox style={{ padding: "0px" }} checked={this.state.resultValues?.includes(result) ? true : false} /> {result}
                                        </div>
                                    )
                                    return null;
                                })
                                let testChecked = false;
                                this.state.resultValues?.map(resultValue => {
                                    if (test.results.includes(resultValue)) {
                                        testChecked = true;
                                    }
                                    return null;
                                });
                                return <SubMenu key={testIndex} label={() => {
                                    return <div style={{ width: "100%" }} className="submenu-item" onClick={() => this.clickTest(test)}><Checkbox style={{ padding: "0px" }} checked={testChecked} /> {test.name}</div>
                                }}>
                                    <MenuItem
                                        onClick={e => { e.keepOpen = true }}
                                        className="resultFilterSubMenuItem"
                                    >
                                        {subMenuDom}
                                    </MenuItem>
                                </SubMenu>
                            } else {
                                return <MenuItem
                                    key={testIndex}
                                    onClick={e => { e.keepOpen = true }}
                                    style={{ paddingLeft: "24px" }}
                                >
                                    <div style={{ width: "100%" }} onClick={() => this.props.updateBundleIds(test._id)}><Checkbox style={{ padding: "0px" }} checked={this.props.bundleIds.includes(test._id)} /> {test.name}</div>
                                </MenuItem>
                            }
                        })
                    }
                    <MenuItem
                        onClick={e => { this.setState({ menuState: "closed" }); this.handleMenuClose() }}
                        className="saveCloseMenu"
                    >
                        Save and Close
                    </MenuItem>
                </ControlledMenu>
            </div>
        );
    }
}
