import { apiInterfaceForJson } from "../common/apiManager";

export const fetchDashboardDetails = (patientInfo) => {
	return apiInterfaceForJson("/order/v1/patient/results", "POST", patientInfo);
}

export const patientCommunication = (patientData) => {
	return apiInterfaceForJson("/communication/v1/patientcommunication", "POST", patientData);
}

export const filterPatients = (patientInfo) => {
	return apiInterfaceForJson("/patient/v1/filter", "POST", patientInfo);
}
