import { apiInterfaceForJson } from "../common/apiManager";

export const fetchLabInventories = () => {
    return apiInterfaceForJson("/labInventory/v1/", "GET");
};

export const getLabInventoryDataById = (labInventoryId) => {
    return apiInterfaceForJson("/labInventory/v1/" + labInventoryId, "GET");
};

export const createLabInventory = (labInventoryDetails) => {
    return apiInterfaceForJson("/labInventory/v1/", "POST", labInventoryDetails);
};

export const updateLabInventory = (labInventoryDetails) => {
    return apiInterfaceForJson("/labInventory/v1/", "PUT", labInventoryDetails);
};

export const deleteLabInventory = (labInventoryDetails) => {
    return apiInterfaceForJson("/labInventory/v1/delete", "POST", labInventoryDetails);
};
