import React, { Component } from "react";
import { Checkbox } from "@mui/material";

export default class CheckboxRenderer extends Component {
    checkedHandler = (event) => {
        const colId = this.props.column.colId;
        const checked = event.target.checked;
        this.props.node.setDataValue(colId, checked);
        this.props.context.componentParent.checkItem(this.props.data._id, colId, checked);
    }

    render() {
        const colId = this.props.column.colId;
        return (
            <div className="text-center assign-actions">
                <Checkbox checked={this.props.data[colId]} onClick={this.checkedHandler} ></Checkbox>
            </div>
        );
    }
}
