import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { getRowColumnIndexForQuantPlate, getCellIndexForQuantPlate } from "../../../services/common/util";
import { quantPlateRows, quantPlateColumns, solanaPlateRows } from "../../../services/common/optionsData";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class SelectPlateWell extends Component {
    handleSelect = (cellIndex) => {
        let orderInfo = this.props.manualOrderInfo;
        let plateData = this.props.manualPlateData;
        const { row, column } = getRowColumnIndexForQuantPlate(cellIndex);
        let wellPosition = quantPlateRows[row] + quantPlateColumns[column];
        if (plateData.machine_type !== 'quant') {
            if (plateData.test_id.name && plateData.test_id.name.toLowerCase().includes("strep")) {
                wellPosition = (cellIndex + 1) + ", " + (cellIndex + 2);
            } else {
                wellPosition = cellIndex + 1;
            }
        }
        Swal.fire({
            title: "Place Sample ID " + orderInfo.orderSample + " in Well " + wellPosition + " of Plate " + plateData.name,
            text: orderInfo.patientName + "(" + orderInfo.patientBirthday + "), collected on " + orderInfo.orderCollected + " by " + orderInfo.collectorName + " from " + orderInfo.facilityName,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: 'Cancel',
            customClass: {
                container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                cancelButton: 'order-1',
                confirmButton: 'order-2',
            }
        }).then(result => {
            if (result.isConfirmed) {
                this.props.manualAccession(orderInfo, plateData, cellIndex);
                this.onHide();
            }
        })
    }

    onHide = () => {
        this.props.onHide();
    }

    render() {
        return <ModalStyled
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.props.show}
            onHide={this.onHide}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please Select Plate Well
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row">
                        {
                            this.props.manualPlateData && this.props.selectedMachine === 'quant'
                            &&
                            <div className="col-12">
                                <div className="plate-table big-cell d-flex justify-content-center">
                                    <table>
                                        <tbody>
                                            {
                                                quantPlateRows.map((row, index) => {
                                                    return <tr key={index}>
                                                        {
                                                            quantPlateColumns.map((column, i) => {
                                                                let cellIndex = null;
                                                                let className = '';
                                                                if (i >= 1 && index >= 1) {
                                                                    cellIndex = getCellIndexForQuantPlate(index - 1, i - 1);
                                                                }
                                                                if (this.props.manualPlateData.cells && this.props.manualPlateData.cells[cellIndex]) {
                                                                    className = 'assigned-cell'
                                                                }
                                                                if (i !== 0 && index !== 0) {
                                                                    className += ' cell'
                                                                } else {
                                                                    className += ' constant'
                                                                }
                                                                return <td key={i} className={className} onClick={!className.includes('assigned-cell') ? (() => this.handleSelect(cellIndex)) : null}>
                                                                    {(i === 0 && index !== 0) ? row : ""}
                                                                    {(i !== 0 && index === 0) ? column : ""}
                                                                    {(i !== 0 && index !== 0 && this.props.manualPlateData.cells && this.props.manualPlateData.cells[cellIndex] && this.props.manualPlateData.cells[cellIndex].is_control_spot ? this.props.manualPlateData.cells[cellIndex].control_spot_type : "")}
                                                                </td>
                                                            })
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            this.props.manualPlateData && this.props.selectedMachine === 'solana'
                            &&
                            <div className="col-12">
                                <div className="solana-plate-table d-flex justify-content-center">
                                    <table>
                                        <tbody>
                                            {
                                                solanaPlateRows.map((row, index) => {
                                                    let cellIndex = index;
                                                    let className = '';
                                                    if (this.props.manualPlateData.test_id.name && this.props.manualPlateData.test_id.name.toLowerCase().includes("strep")) {
                                                        if (!(cellIndex % 2)) {
                                                            if (this.props.manualPlateData.cells && this.props.manualPlateData.cells[cellIndex]) {
                                                                className = 'assigned-cell'
                                                            }
                                                            return <tr key={index}>
                                                                <td className={className} onClick={!className.includes('assigned-cell') ? (() => this.handleSelect(cellIndex)) : null}>
                                                                    ({row}, {Number(row) + 1}) {className.includes('assigned-cell') && this.props.manualPlateData.cells[cellIndex].orderId.test_info ? this.props.manualPlateData.cells[cellIndex].orderId.test_info.sample : ""}
                                                                </td>
                                                            </tr>
                                                        }
                                                    } else {
                                                        if (this.props.manualPlateData.cells && this.props.manualPlateData.cells[cellIndex]) {
                                                            className = 'assigned-cell'
                                                        }
                                                        return <tr key={index}>
                                                            <td className={className} onClick={!className.includes('assigned-cell') ? (() => this.handleSelect(cellIndex)) : null}>
                                                                ({row}) {className.includes('assigned-cell') && this.props.manualPlateData.cells[cellIndex].orderId.test_info ? this.props.manualPlateData.cells[cellIndex].orderId.test_info.sample : ""}
                                                            </td>
                                                        </tr>
                                                    }
                                                    return null;
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className="col-12 text-center mt-3">
                            <h2>
                                {this.props.manualPlateData && this.props.manualPlateData.name ? this.props.manualPlateData.name : ""}
                            </h2>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={this.onHide}
                >
                    Close
                </Button>
            </Modal.Footer>
        </ModalStyled>;
    }
}
