import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ClinicPortalLoginContainer from "../components/clinicPortal/login";
import ClinicDashboard from "../components/clinicPortal/dashboard/clinicDashboard";
import ClinicPortalLayout from "../layouts/ClinicPortalLayout";
import ClinicOrderGrid from "../components/clinicPortal/orders/clinicOrderGrid";
import ClinicRequisitionGrid from "../components/clinicPortal/requisitions/clinicRequisitionGrid";
import ClinicOrdersUrgency from "../components/clinicPortal/ordersUrgency/clinicOrdersUrgency";
import ClinicRequisitionNewGrid from "../components/clinicPortal/requisitionsNew/clinicRequisitionNewGrid";
import ClinicResultLinksGrid from "../components/clinicPortal/resultLinks/clinicResultLinksGrid";
// import ClinicFacilityCommunications from "../components/clinicPortal/communication/clinicFacilityCommunications";
// import ClinicCommunicationDetail from "../components/clinicPortal/communication/clinicCommunicationDetail";
import ClinicPatientGrid from "../components/clinicPortal/patients/clinicPatientGrid";
import ClinicPatientDuplicatedGrid from "../components/clinicPortal/patientsDuplicated/ClinicPatientDuplicatedGrid";
import ClinicInsuranceGrid from "../components/clinicPortal/insurance/clinicInsuranceGrid";
import ClinicInvoiceGrid from "../components/clinicPortal/invoices/ClinicInvoiceGrid";
import ClinicSelfPaymentGrid from "../components/clinicPortal/patientPayment/clinicSelfPaymentGrid";
import ClinicOrdersMPSGrid from "../components/clinicPortal/ordersMPSView/clinicOrdersMPSView";
import ClinicOrdersMPSOutstandingGrid from "../components/clinicPortal/ordersMPSView/clinicOrdersMPSOutstanding";
import ClinicOrdersMPSPaidGrid from "../components/clinicPortal/ordersMPSView/clinicOrdersMPSPaid";
import ClinicMPSCorrectedGrid from "../components/clinicPortal/ordersMPSView/clinicMPSCorrectedView";
import ClinicPatientPaymentGrid from "../components/clinicPortal/patientPayment/clinicPatientPaymentGrid";
import ClinicInsuranceProviderGrid from "../components/clinicPortal/insurance/clinicInsuranceProviderGrid";
import ClinicAuditGrid from "../components/clinicPortal/audit/clinicAuditGrid";
import ClinicBrooksLimsRecordGrid from "../components/clinicPortal/brooksLimsRecord/clinicBrooksLimsRecordGrid";
import ClinicStateReportsGrid from "../components/clinicPortal/reports/ClinicStateReportsGrid";
import ClinicDailyReportsGrid from "../components/clinicPortal/reports/ClinicDailyReportsGrid";
import ClinicFailedNotificationReportsGrid from "../components/clinicPortal/reports/ClinicFailedNotificationReportsGrid";
import ClinicOrderReportsGrid from "../components/clinicPortal/reports/ClinicOrderTotalReportsGrid";
import ClinicSelfCheckinReportsGrid from "../components/clinicPortal/reports/ClinicSelfCheckinReportsGrid";
import ClinicPCPositiveReportGrid from "../components/clinicPortal/reports/ClinicPCPositiveReportGrid";
import ClinicVipPaymentGrid from "../components/clinicPortal/vipPayment/clinicVipPaymentGrid";
import ClinicCompanyGrid from "../components/clinicPortal/company/clinicCompanyGrid";
import ClinicFacilityGrid from "../components/clinicPortal/facility/clinicFacilityGrid";
import ClinicFacilityTestManagementGrid from "../components/clinicPortal/facility/clinicFacilityTestManagementGrid";
import ClinicLocationGrid from "../components/clinicPortal/location/clinicLocationGrid";
import ClinicPhysicianGrid from "../components/clinicPortal/physician/clinicPhysicianGrid";
import ClinicUserGrid from "../components/clinicPortal/user/clinicUserGrid";
// import ClinicFacilityPickupTimeGrid from "../components/clinicPortal/facility/clinicFacilityPickupTimeGrid";
// import ClinicRouteTemplate from "../components/clinicPortal/driver/clinicRouteTemplate";
// import ClinicDriverAvailabilityGrid from "../components/clinicPortal/driver/clinicDriverAvailabilityGrid";
// import ClinicDriverVisualUpdate from "../components/clinicPortal/driver/clinicDriverVisualUpdate";
// import RealTimeMapView from "../components/clinicPortal/map/realTimeMapView";
// import ClinicFacilityInventoryGrid from "../components/clinicPortal/facilityInventory/clinicFacilityInventoryGrid";
// import ClinicDeliveryPickupOrderGrid from "../components/clinicPortal/pickup/clinicDeliveryPickupOrderGrid";
// import ClinicDriverShiftInfoGrid from "../components/clinicPortal/driver/clinicDriverShiftInfoGrid";
// import ClinicDriverGrid from "../components/clinicPortal/driver/clinicDriverGrid";
import ClinicNotificationTypeGrid from "../components/clinicPortal/notificationManagement/clinicNotificationTypeGrid";
import FacilityNotificationGrid from "../components/clinicPortal/notificationManagement/facilityNotificationGrid";
import LogisticNotificationGrid from "../components/clinicPortal/notificationManagement/logisticNotificationGrid";
import ClinicSuperAdminSettings from "../components/clinicPortal/superAdminSettings";
import ClinicVideoGrid from "../components/clinicPortal/video/clinicVideoGrid";
import ClinicTestGrid from "../components/clinicPortal/labManagement/clinicTestGrid";
import ClinicMaintenanceGrid from "../components/clinicPortal/labManagement/clinicMaintenanceGrid";
import ClinicInventoryGrid from "../components/clinicPortal/labManagement/clinicInventoryManagementGrid";

import ThemeProvider from "../theme/ThemeProvider";

class ClinicPortalRouter extends Component {
    render(props) {
        return <ThemeProvider>
            <ClinicPortalLayout>
                <Switch>
                    <Route path="/clinic/" exact component={ClinicPortalLoginContainer} />

                    <Route path="/clinic/dashboard" exact component={ClinicDashboard} />

                    <Route path="/clinic/orders" exact component={ClinicOrderGrid} />
                    <Route path="/clinic/requisitions/:type" exact component={ClinicRequisitionGrid} />
                    <Route path="/clinic/orderUrgency" exact component={ClinicOrdersUrgency} />
                    <Route path="/clinic/requestSampleCancel" exact component={ClinicRequisitionNewGrid} />
                    <Route path="/clinic/resultLinks" exact component={ClinicResultLinksGrid} />

                    {/* <Route path="/clinic/facilityCommunications" exact component={ClinicFacilityCommunications} />
                    <Route path="/clinic/communication/:communicationId" exact component={ClinicCommunicationDetail} /> */}

                    <Route path="/clinic/patients" exact component={ClinicPatientGrid} />
                    <Route path="/clinic/insurance" exact component={ClinicInsuranceGrid} />
                    <Route path="/clinic/patientsDuplicated" exact component={ClinicPatientDuplicatedGrid} />

                    <Route path="/clinic/invoices" exact component={ClinicInvoiceGrid} />
                    <Route path="/clinic/selfPayment" exact component={ClinicSelfPaymentGrid} />
                    <Route path="/clinic/mpsView" exact component={ClinicOrdersMPSGrid} />
                    <Route path="/clinic/mpsPayment/outstanding" exact component={ClinicOrdersMPSOutstandingGrid} />
                    <Route path="/clinic/mpsPayment/paid" exact component={ClinicOrdersMPSPaidGrid} />
                    <Route path="/clinic/mpsCorrectedView" exact component={ClinicMPSCorrectedGrid} />
                    <Route path="/clinic/patientPayment" exact component={ClinicPatientPaymentGrid} />
                    <Route path="/clinic/insuranceProvider" exact component={ClinicInsuranceProviderGrid} />

                    <Route path="/clinic/audit" exact component={ClinicAuditGrid} />
                    <Route path="/clinic/brooksLimsRecord" exact component={ClinicBrooksLimsRecordGrid} />
                    <Route path="/clinic/reports/stateReports" exact component={ClinicStateReportsGrid} />
                    <Route path="/clinic/reports/dailyReports" exact component={ClinicDailyReportsGrid} />
                    <Route path="/clinic/reports/failedNotificationReports" exact component={ClinicFailedNotificationReportsGrid} />
                    <Route path="/clinic/reports/graphOrderTotals" exact component={ClinicOrderReportsGrid} />
                    <Route path="/clinic/reports/graphSelfCheckIns" exact component={ClinicSelfCheckinReportsGrid} />
                    <Route path="/clinic/reports/pcPositiveReport" exact component={ClinicPCPositiveReportGrid} />

                    <Route path="/clinic/vipPayments" exact component={ClinicVipPaymentGrid} />

                    <Route path="/clinic/company" exact component={ClinicCompanyGrid} />
                    <Route path="/clinic/facility" exact component={ClinicFacilityGrid} />
                    <Route path="/clinic/facilityTestManagement" exact component={ClinicFacilityTestManagementGrid} />
                    <Route path="/clinic/location" exact component={ClinicLocationGrid} />
                    <Route path="/clinic/physician" exact component={ClinicPhysicianGrid} />
                    <Route path="/clinic/user" exact component={ClinicUserGrid} />


                    {/* <Route path="/clinic/facilityPickupTimes" exact component={ClinicFacilityPickupTimeGrid} />
                    <Route path="/clinic/routeTemplate" exact component={() => (<div className="clinic-contain"> <ClinicRouteTemplate /> </div>)} />
                    <Route path="/clinic/driverAvailability" exact component={ClinicDriverAvailabilityGrid} />
                    <Route path="/clinic/visualDriverView" exact component={ClinicDriverVisualUpdate} />
                    <Route path="/clinic/realTimeMapView" exact component={() => (<div className="clinic-contain"> <RealTimeMapView /></div>)} />
                    <Route path="/clinic/facilityInventory" exact component={ClinicFacilityInventoryGrid} />
                    <Route path="/clinic/deliveryPickupOrder" exact component={ClinicDeliveryPickupOrderGrid} />
                    <Route path="/clinic/driverShiftInfo" exact component={ClinicDriverShiftInfoGrid} />
                    <Route path="/clinic/driver" exact component={ClinicDriverGrid} /> */}

                    <Route path="/clinic/notificationType" exact component={ClinicNotificationTypeGrid} />
                    <Route path="/clinic/facilityNotificationManagement" exact component={FacilityNotificationGrid} />
                    <Route path="/clinic/logisticNotificationManagement" exact component={LogisticNotificationGrid} />

                    <Route path="/clinic/superAdminSettings" exact component={ClinicSuperAdminSettings} />

                    <Route path="/clinic/video" exact component={ClinicVideoGrid} />

                    <Route path="/clinic/test" exact component={ClinicTestGrid} />
                    <Route path="/clinic/maintenance" exact component={ClinicMaintenanceGrid} />
                    <Route path="/clinic/inventory" exact component={ClinicInventoryGrid} />
                </Switch>
            </ClinicPortalLayout>
        </ThemeProvider>
    }
}

export default ClinicPortalRouter;