import React, { Component } from "react";
import { successPayment } from "../../../services/patientPortalServices/paymentService";
import { successPaymentForMpsPayment } from "../../../services/clinicPortalServices/mpsPaymentServices";
import { getUserAuthToken, getPatientAuthToken } from "../../../services/common/util";

class PatientSuccessPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    componentDidMount() {
        let orderId = this.props.match.params.orderId;
        if (orderId.includes('mps_')) {
            let from = "patientPortal";
            if (orderId.split('mps_')[0] === "mpsView") {
                from = "mpsView";
            }
            const data = {
                ids: orderId.split('mps_')[1].split("___"),
                from: from
            }
            successPaymentForMpsPayment(data).then(res => {
                setTimeout(() => {
                    if (orderId.split('mps_')[0] === "mpsView") {
                        if (getUserAuthToken()) {
                            this.props.history.push("/clinic/mpsPayment/paid");
                        } else if (getPatientAuthToken()) {
                            this.props.history.push("/patientportal/dashboard");
                        }
                    } else {
                        if (getPatientAuthToken()) {
                            this.props.history.push("/patientportal/dashboard");
                        }
                    }
                }, 3000)
            })
        } else {
            let isFromSelfPaymentPage = false;
            if (orderId.includes("_self")) {
                orderId = orderId.split("_self")[0];
                isFromSelfPaymentPage = true;
            }
            successPayment({ _id: orderId }).then(response => {
                if (isFromSelfPaymentPage) {
                    setTimeout(() => {
                        this.props.history.push("/clinic/selfPayment");
                    }, 3000)
                } else {
                    setTimeout(() => {
                        if (getPatientAuthToken()) {
                            this.props.history.push("/patientportal/dashboard");
                        }
                    }, 5000)
                }
            })
        }
    }

    render() {
        return (
            <div className="content login-content">
                <div className="container" style={{ textAlign: 'center', color: 'green' }}>
                    <h1 style={{ textAlign: 'center', color: 'green' }}>Thank you for your payment!</h1>
                    <br />
                    <p>You will receive a receipt to the email address you provided when making your payment.</p>
                    <p>You will be redirected to your dashboard page automatically</p>
                </div>
            </div >
        )
    }
}

export default PatientSuccessPayment;

