import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class SampleTrackingRenderer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			logs: []
		}
	}

	handleShow = () => {
		if (!(this.props.data.results && this.props.data.results.value) && this.props.data.sample_tracking && this.props.data.sample_tracking.length && this.props.data.sample_tracking.filter(item => item.eventName !== 'PICKED_UP').length) {
			this.setState({ show: true });
		}
	};

	handleClose = () => {
		this.setState({ show: false, logs: [] });
	};

	render() {
		let displayText = "";
		if (this.props.data.results && this.props.data.results.value) {
			if (this.props.data.results.value === "In Testing") {
				displayText = this.props.data.results.value;
			} else {
				displayText = "Resulted";
			}
		} else if (this.props.data.sample_tracking && this.props.data.sample_tracking.length && this.props.data.sample_tracking.filter(item => item.eventName !== 'PICKED_UP').length) {
			displayText = "Update " + this.props.data.sample_tracking.filter(item => item.eventName !== 'PICKED_UP').length;
		}
		return (
			<div className="text-center">
				<button onClick={this.handleShow} className="edit-order-btn">
					{displayText}
				</button>
				<ModalStyled
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Sample Tracking Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							{
								this.props.data.sample_tracking && this.props.data.sample_tracking.filter(item => item.eventName !== 'PICKED_UP').length > 0
								&&
								this.props.data.sample_tracking.filter(item => item.eventName !== 'PICKED_UP').map((item, index) => {
									return <div className="row form-row history-row" key={index}>
										<div className="col-12 col-md-5 history-date">
											{moment(item.time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")}
										</div>
										<div className="col-12 col-md-7 history-description">
											{item.eventName}
										</div>
									</div>
								})
							}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
