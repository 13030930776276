import React, { Component } from "react";
import { Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import LocationDetails from "./locationDetails";
import { deleteLocation } from "../../../../services/clinicPortalServices/locationService";
import Swal from "sweetalert2";
import { getUserRole } from "../../../../services/common/util";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			locationId: props.data._id,
			errors: []
		};
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Location
		</Tooltip>
	);

	renderTooltipLocation = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Location
		</Tooltip>
	);

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this location!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				const locationInfo = {
					_id: this.state.locationId,
					name: this.props.data.name
				};
				deleteLocation(locationInfo)
					.then((response) => {
						this.props.context.componentParent.loadGridData();
					});
			}
		});
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	render() {
		return (
			<div>
				{!(/customerserviceuser/.test(getUserRole().toLowerCase())) &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-pen"></i>
						</button>
					</OverlayTrigger>}
				{/* {
					(getUserRole().toLowerCase() === 'agtsuperadmin' || getUserRole().toLowerCase() === 'agtadmin')
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipLocation}
					>
						<button onClick={this.handleDelete} className="edit-order-btn">
							<i className="fas fa-trash"></i>
						</button>
					</OverlayTrigger>

				} */}
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Location Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<LocationDetails
							locationId={this.state.locationId}
							handleClose={this.handleClose}
							context={this.props.context}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
