import React, { Component } from 'react'
import moment from "moment";
import PayCellButtonRenderer from "./payCellButtonRenderer";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";
import { getCheckoutSessionIdForMpsPayment } from '../../../../services/clinicPortalServices/mpsPaymentServices';

const boundActions = bindActions(actions, store);

class InsuranceData extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    payTotal = (totalDueAmount) => {
        boundActions.startBlockUILoading();
        let bulkPaymentIds = [], labOrderIds = [];
        this.props.insurance.map(insuranceItem => {
            if (Number(insuranceItem.payment.due_amount) > 0) {
                labOrderIds.push(insuranceItem.lab_order_id);
                if (insuranceItem.mps_payment_id && insuranceItem.mps_payment_id._id) {
                    bulkPaymentIds.push(insuranceItem.mps_payment_id._id);
                } else {
                    bulkPaymentIds.push(insuranceItem._id)
                }
            }
            return null;
        })
        let paymentInfo = {
            mps_payment_ids: bulkPaymentIds,
            due_amount: totalDueAmount,
            first_name: this.props.patientData.first_name,
            last_name: this.props.patientData.last_name,
            lab_order_id: labOrderIds[0],
            lab_order_ids: labOrderIds,
            email: this.props.patientData.email,
            from: "patientPortal"
        }
        getCheckoutSessionIdForMpsPayment(paymentInfo).then(response => {
            if (response && response.sessionId) {
                this.props.stripe.redirectToCheckout({ sessionId: response.sessionId });
            }
        })
    }

    render() {
        let totalDueAmount = 0;
        let dueAmountCount = 0;
        this.props.insurance.map(insuranceItem => {
            if (Number(insuranceItem.payment.due_amount) > 0) {
                dueAmountCount++;
            }
            totalDueAmount += Number(insuranceItem.payment.due_amount);
            return null;
        })
        return (
            <div className='insurance-data' style={{ overflowY: "auto" }}>
                {
                    this.props.insurance && this.props.insurance.length > 1 && Number(totalDueAmount) > 0 && dueAmountCount > 1
                    &&
                    <div className='d-flex align-items-center justify-content-center' style={{ padding: "10px 0px" }}><button className='btn btn-primary' style={{ fontSize: "20px" }} onClick={() => this.payTotal(totalDueAmount)}>Pay All At Once (${totalDueAmount})</button></div>
                }
                <table
                    id='insurance-table'
                    role="table"
                    style={{ width: "100%" }}
                >
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Test Name</th>
                            <th role="columnheader">Collection Date</th>
                            <th role="columnheader">Sample ID</th>
                            <th role="columnheader">Patient Name</th>
                            <th role="columnheader">Facility Name</th>
                            <th role="columnheader">Physician Name</th>
                            <th role="columnheader">Chart Number</th>
                            <th role="columnheader">Total Amount</th>
                            <th role="columnheader">Adjustment Amount</th>
                            <th role="columnheader">Paid</th>
                            <th role="columnheader">Balance Due</th>
                            <th role="columnheader">Due By</th>
                            <th role="columnheader">Pay Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.insurance.map((insuranceItem, index) => {
                                return (
                                    <tr role="row" key={index}>
                                        <td role="cell">{insuranceItem.test_id.name}</td>
                                        <td role="cell">{moment(insuranceItem.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY")}</td>
                                        <td role="cell">{insuranceItem.test_info.sample}</td>
                                        <td role="cell">{insuranceItem.patient_id.first_name} {insuranceItem.patient_id.last_name}</td>
                                        <td role="cell">{insuranceItem.facility_source}</td>
                                        <td role="cell">{insuranceItem.provider.first_name} {insuranceItem.provider.last_name}</td>
                                        <td role="cell">{insuranceItem.payment.chart_number}</td>
                                        <td role="cell">${insuranceItem.payment.total_amount}</td>
                                        <td role="cell">${insuranceItem.payment.adjustment_amount}</td>
                                        <td role="cell">${insuranceItem.payment.paid_amount}</td>
                                        <td role="cell">${insuranceItem.payment.due_amount}</td>
                                        <td role="cell">{insuranceItem.payment.due_date}</td>
                                        <td role="cell">
                                            <PayCellButtonRenderer
                                                data={insuranceItem}
                                                patientData={this.props.patientData}
                                                value={insuranceItem.payment.due_amount}
                                                stripe={this.props.stripe}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
export default InsuranceData;
