import { apiInterfaceForJson } from "../common/apiManager";
import { templateIds } from "../common/constants";

export const sendSupportRequest = (supportRequestDetails) => {
    let bodyTemplate = {
        subject: supportRequestDetails.subject,
        emailTxt: "Thank you for submitting a support request, we will look into it and get back to you as soon as possible. The following are the details of your request:<br/><br/>Name: " + supportRequestDetails.name + "<br/>Email: " + supportRequestDetails.email + "<br/>Facility Name: " + supportRequestDetails.facilityName + "<br/>Description: " + supportRequestDetails.description + "<br/><br/>Thanks<br/>The MyResultNow Team"
    }

    let emailDataTemplate = {
        toEmail: [supportRequestDetails.email, "creid@americangene.com"],
        subject: supportRequestDetails.subject,
        body: bodyTemplate,
        templateId: templateIds.htmlEmailWithLogo
    }

    if (supportRequestDetails.supportType === "Lab related request") {
        emailDataTemplate.toEmail = ["wduong@americangene.com"];
    } else {
        emailDataTemplate.toEmail = [supportRequestDetails.email, "creid@americangene.com", "webprince777@gmail.com"];
    }

    return apiInterfaceForJson("/notifications/v1/emailTemplate", "POST", emailDataTemplate);
}