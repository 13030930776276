import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { states } from "../../../../services/common/optionsData";
import {
	updatePhysician,
	createPhysician,
	getPhysicianDataByFilter
} from "../../../../services/clinicPortalServices/physicianServices";
import { getFacilityData } from "../../../../services/clinicPortalServices/facilityServices";
import { phoneNumberFormatter } from "../../../../services/common/util";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import SignatureImages from "./img";

export default class PhysicianDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			physicianId:
				this.props && this.props.physicianId ? this.props.physicianId : "",
			physicianNpi:
				this.props && this.props.physicianNpi ? this.props.physicianNpi : "",
			firstName: "",
			lastName: "",
			code: "",
			npi: "",
			mobile: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			country: "",
			facilityIds: [],
			errors: [],
			facilities: [],
			allFacilityIds: [],
			signature_image: "",
			arraySignatureImages: []
		};
	}

	componentDidMount() {
		if (this.state.physicianNpi !== "") {
			this.loadPhysicianDetails();
		}

		getFacilityData().then((response) => {
			this.setState({ facilities: response.data });
			let allFacilityIds = [];
			response.data.map(item => {
				allFacilityIds.push(item._id);
				return null;
			})
			this.setState({ allFacilityIds: allFacilityIds });
		});

		let arraySignatureImages = []
		Object.entries(SignatureImages).map(([key, image]) => {
			image['key'] = key;
			arraySignatureImages.push(image);
			return null;
		})
		arraySignatureImages.sort(function (a, b) {
			if (a.label > b.label) {
				return 1;
			} else {
				return -1;
			}
		})
		this.setState({ arraySignatureImages: arraySignatureImages });
	}

	loadPhysicianDetails = () => {
		let filter = { npi: this.state.physicianNpi }
		getPhysicianDataByFilter(filter)
			.then((response) => {
				let physicianDetails = response.data[0];
				this.setState({
					physicianId: physicianDetails && physicianDetails._id ? physicianDetails._id : "",
					firstName: physicianDetails && physicianDetails.first_name ? physicianDetails.first_name : "",
					lastName: physicianDetails && physicianDetails.last_name ? physicianDetails.last_name : "",
					code: physicianDetails && physicianDetails.code ? physicianDetails.code : "",
					npi: physicianDetails && physicianDetails.npi ? physicianDetails.npi : "",
					mobile: physicianDetails && physicianDetails.mobile ? physicianDetails.mobile : "",
					address1:
						physicianDetails && physicianDetails.address && physicianDetails.address.address1
							? physicianDetails.address.address1
							: "",
					address2:
						physicianDetails && physicianDetails.address && physicianDetails.address.address2
							? physicianDetails.address.address2
							: "",
					city:
						physicianDetails && physicianDetails.address && physicianDetails.address.city
							? physicianDetails.address.city
							: "",
					state:
						physicianDetails && physicianDetails.address && physicianDetails.address.state
							? physicianDetails.address.state
							: "",
					zip:
						physicianDetails && physicianDetails.address && physicianDetails.address.zip
							? physicianDetails.address.zip
							: "",
					country:
						physicianDetails && physicianDetails.address && physicianDetails.address.country
							? physicianDetails.address.country
							: "",
					facilityIds:
						physicianDetails && physicianDetails.facilityIds
							? physicianDetails.facilityIds
							: [],
					signature_image:
						physicianDetails && physicianDetails.signature_image ? physicianDetails.signature_image : ""
				});
			})
	};

	handleChange = (e) => {
		if (e.target.name === "mobile") {
			this.setState((prevState) => ({
				mobile: phoneNumberFormatter(e.target.value, prevState.mobile),
			}));
		} else {
			if (e.target.name === 'facilityIds') {
				if (e.target.value.includes('all')) {
					if (this.state.allFacilityIds.length === this.state.facilityIds.length) {
						this.setState({ facilityIds: [] });
					} else {
						this.setState({ facilityIds: this.state.allFacilityIds });
					}
				} else {
					this.setState({ facilityIds: e.target.value })
				}
			} else {
				this.setState({ [e.target.name]: e.target.value });
			}
		}
	};

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	updateAndCreatePhysician = () => {
		let errors = [];

		if (this.state.firstName === "") {
			errors.push("firstName");
		}
		if (this.state.lastName === "") {
			errors.push("lastName");
		}

		if (this.state.npi === "") {
			errors.push("npi");
		}

		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let physicianInfo = {
			id: this.state.physicianId,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			code: this.state.code,
			npi: this.state.npi,
			mobile: this.state.mobile,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			country: this.state.country,
			zip: this.state.zip,
			facilityIds: this.state.facilityIds,
		};

		if (this.state.signature_image) {
			physicianInfo.signature_image = this.state.signature_image;
		}

		if (this.state.physicianId !== "") {
			updatePhysician(physicianInfo)
				.then((response) => {
					this.props.context.componentParent.loadGridData();
					this.props.handleClose();
				})
		} else {
			createPhysician(physicianInfo)
				.then((response) => {
					this.props.context.componentParent.loadGridData();
					this.props.handleClose();
				})
		}
	};

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									First Name <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="firstName"
									value={this.state.firstName}
									onChange={this.handleChange}
									required
									className={this.hasError("firstName") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("firstName") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Last Name <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="lastName"
									value={this.state.lastName}
									onChange={this.handleChange}
									required
									className={this.hasError("lastName") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("lastName") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Code</label>
								<input
									type="text"
									name="code"
									value={this.state.code}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									NPI <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="npi"
									value={this.state.npi}
									onChange={this.handleChange}
									className={this.hasError("npi") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("npi") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>Facility</label>
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="facility-label">Select Facility</InputLabel>
									{
										this.state.facilities
										&&
										this.state.facilities.length
										&&
										<Select
											labelId="facility-label"
											id="facility-select"
											value={this.state.facilityIds}
											onChange={this.handleChange}
											label="Select Facility"
											name="facilityIds"
											multiple
											renderValue={(selected) => {
												if (selected.length === this.state.facilities.length) {
													return 'All Facilities';
												} else {
													let displayStrings = [];
													this.state.facilities.map(fac => {
														if (selected.includes(fac._id)) {
															displayStrings.push(fac.name);
														}
														return null;
													})
													return displayStrings.join(', ');
												}
											}}
										>
											<MenuItem className={this.state.facilityIds.length === this.state.facilities.length ? "Mui-selected" : ""} key="all" value="all">
												<Checkbox checked={this.state.facilityIds.length === this.state.facilities.length ? true : false} />
												All Facilities
											</MenuItem>
											{
												this.state.facilities.map((fac) => {
													return (
														<MenuItem
															key={fac._id}
															value={fac._id}
														>
															<Checkbox checked={this.state.facilityIds.includes(fac._id) ? true : false} />
															{fac.name}&nbsp;{
																fac.archived === "archived"
																&&
																<b>{" ( Archived )"}</b>
															}
														</MenuItem>
													);
												})
											}
										</Select>
									}
								</FormControl>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Phone Number</label>
								<input
									type="tel"
									name="mobile"
									value={this.state.mobile}
									onChange={this.handleChange}
									placeholder="(XXX) XXX-XXXX"
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address1</label>
								<input
									type="text"
									name="address1"
									value={this.state.address1}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address2</label>
								<input
									type="text"
									name="address2"
									value={this.state.address2}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>City</label>
								<input
									type="text"
									name="city"
									value={this.state.city}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>State</label>
								<select
									className="form-control select"
									name="state"
									value={this.state.state}
									onChange={this.handleChange}
								>
									{
										states.map((state) => {
											return (
												<option key={state.value} value={state.value}>
													{state.state}
												</option>
											);
										})
									}
								</select>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Zip Code</label>
								<input
									type="text"
									name="zip"
									value={this.state.zip}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>
									Signature Image
								</label>
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="image-logo-label">Select Signature Image</InputLabel>
									<Select
										labelId="image-logo-label"
										value={this.state.signature_image}
										onChange={this.handleChange}
										label="Select Signature Image"
										className="signature-image"
										name="signature_image"
									>
										{
											this.state.arraySignatureImages.map((image, index) => {
												return (
													<MenuItem
														key={image.key}
														value={image.key}
													>
														<img
															style={{ height: "70px", width: "280px", marginRight: "5px" }}
															src={image.path}
															alt="Physician Signature"
															className="signature-image"
														/>
														{image.label}
													</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
								<div className={this.hasError("image") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 modal-button-col">
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.updateAndCreatePhysician}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
