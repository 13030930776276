import React, { Component } from "react";
import { Route } from "react-router-dom";
import LIMSPortalHeader from "../components/common/header";
import Footer from "../components/footer";
import { isUserLoggedIn, getUserRole } from "../services/common/util";
import UniversalSearch from "../components/common/header/universalSearch";
import OrderOverview from "../components/common/header/orderOverview";
import { ThemeContext } from "../theme/ThemeProvider.jsx";

class LIMSPortalLayout extends Component {
    constructor(props) {
        super(props);
        if (!(isUserLoggedIn() && getUserRole())) {
            window.location.href = '/';
        }
    };
    render() {
        return <ThemeContext.Consumer>
            {({ themeName }) => (
                <div className={"root-div " + themeName}>
                    <Route render={(props) => <LIMSPortalHeader {...props} />} />
                    <div className="full-wrap lims-wrap">
                        {
                            getUserRole() && /^agtsuperadmin|^agtadmin|^labtech|^customerserviceuser/.test(getUserRole().toLowerCase())
                            &&
                            <div className="universal-mobile">
                                <UniversalSearch />
                                {
                                    getUserRole() && /^agtsuperadmin|^agtadmin|^labtech/.test(getUserRole().toLowerCase())
                                    &&
                                    <OrderOverview
                                        userRole={getUserRole()}
                                    />
                                }
                            </div>
                        }
                        {getUserRole() && this.props.children}
                    </div>
                    <Footer />
                </div>
            )}
        </ThemeContext.Consumer>
    }
}

export default LIMSPortalLayout;