import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import readXlsxFile from 'read-excel-file'
import moment from "moment";
import 'moment-timezone';
import toastr from 'toastr';
import Swal from "sweetalert2";
import { dateComparator, getUserDetails } from "../../../services/common/util";
import { importQuantResultDocument, retestQuantResults, releaseQuantResults, uploadQuantResultDocument } from "../../../services/limsPortalServices/quantStudioImportResultService";
import { importSolanaResultDocument, uploadSolanaResultDocument } from "../../../services/limsPortalServices/solanaImportResultService";
import SearchMenu from "./searchMenu";
import CheckboxRenderer from "./checkboxRenderer";
import { Stepper, Step, StepLabel } from "@mui/material";
import { createAudit } from "../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../services/common/constants";
import Papa from "papaparse";
import { ThemeContext } from "../../../theme/ThemeProvider";
import store from "../../../redux/store";
import actions from "../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

class LIMSImportResultsGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			gridName: "Default",
			pageSize: "",
			columnDefs: [
				{
					minWidth: 100,
					children: [
						{
							headerName: "Actions",
							minWidth: 100,
							field: 'checked',
							cellRenderer: CheckboxRenderer
						},
					]
				},
				{
					headerName: "Import Count",
					minWidth: 140,
					field: "import_count",
					valueGetter: params => {
						if (params.data.import_count === 0) {
							return "First";
						} else if (params.data.import_count === 1) {
							return "Second";
						} else if (params.data.import_count === 2) {
							return "Third";
						} else if (params.data.import_count === 3) {
							return "Fourth";
						} else if (params.data.import_count === 4) {
							return "Fifth";
						} else if (params.data.import_count === 5) {
							return "Sixth";
						} else if (params.data.import_count === 6) {
							return "Seventh";
						} else if (params.data.import_count === 7) {
							return "Eighth";
						} else if (params.data.import_count === 8) {
							return "Ninth";
						} else if (params.data.import_count === 9) {
							return "Tenth";
						}
					}
				},
				{
					headerName: "Result Info",
					children: [
						{
							headerName: "Sample",
							field: "sample"
						},
					]
				},
				{
					headerName: "Order Info",
					children: [
						{
							headerName: "Sample",
							field: "order_id.test_info.sample"
						},
						{
							headerName: "Patient",
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.order_id && params.data.order_id.patient_id && params.data.order_id.patient_id.first_name) {
									return (
										params.data.order_id.patient_id.first_name +
										" " +
										params.data.order_id.patient_id.last_name
									);
								} else {
									return "";
								}
							},
						},
						{
							headerName: "Physician",
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.order_id && params.data.order_id.provider) {
									return (
										params.data.order_id.provider.first_name +
										" " +
										params.data.order_id.provider.last_name
									);
								} else {
									return "";
								}
							},
						},
						{
							headerName: "Collected Date",
							field: "test_info.collected",
							minWidth: 200,
							resizable: true,
							valueGetter: function (params) {
								if (params.data.order_id && params.data.order_id.test_info) {
									return params.data.order_id.test_info && params.data.order_id.test_info.collected ? moment(params.data.order_id.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
								}
							},
							comparator: dateComparator,
						},
					]
				}
			],
			components: {
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			tempImportedData: [],
			import_count: 'all',
			result_value: 'all',
			steps: ['Review the grid after import', 'Process samples automatically', 'Please review remaining items'],
			activeStep: 0,
			documentType: ""
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		this.gridApi.showNoRowsOverlay();
		this.gridApi.hideOverlay();
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.LIMSResultImportGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.LIMSResultImportGridExported
		};
		createAudit(auditData);
	};

	onPageSizeChanged = () => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	resetState = () => {
		this.gridColumnApi.resetColumnState();
	};

	importResults = async (e) => {
		boundActions.startBlockUILoading();
		const file = e.target.files[0];
		const fileName = file.name;
		const userDetails = JSON.parse(getUserDetails());
		const userFullName = userDetails.first_name + ' ' + userDetails.last_name;
		let readFilePromise = new Promise((resolve, reject) => {
			if (fileName.includes(".xlsx")) {
				readXlsxFile(file).then((rows) => {
					resolve(rows);
				})
			} else if (fileName.includes(".csv")) {
				Papa.parse(file, {
					complete: function (results) {
						resolve(results.data);
					}
				})
			}
		})
		let rows = await readFilePromise;
		let documentType = "";
		let startDateTime;
		let serialNumber = "";
		if (rows.length) {
			if (rows[0] && rows[0][0] === "Operator" && rows[0][1] === "StartDateTime" && rows[1] && rows[1][1] && rows[2] && rows[2][0] === "TubeNr" && rows[2][1] === "Sample ID" && rows[2][2] === "Unique ID" && rows[2][3] === "Result" && rows[2][4] === "LotNumber" && rows[2][5] === "MethodFile" && rows[2][6] === "ExpDate" && rows[2][8] === "Name" && rows[2][9] === "Result" && rows[2][10] === "Name" && rows[2][11] === "Result" && rows[3] && rows[3][5] === "Strep Comp" && rows.length > 4) {
				documentType = "solana-strep";
				startDateTime = moment(rows[1][1]).format("YYYYMMDDHHmmss");
				rows.splice(0, 3);
			} else if (rows[0] && rows[0][0] === "Operator" && rows[0][1] === "StartDateTime" && rows[1] && rows[1][1] && rows[2] && rows[2][0] === "TubeNr" && rows[2][1] === "Sample ID" && rows[2][2] === "Unique ID" && rows[2][3] === "Result" && rows[2][4] === "LotNumber" && rows[2][5] === "MethodFile" && rows[2][6] === "ExpDate" && rows[2][8] === "Name" && rows[2][9] === "Result" && rows[2][10] === "Name" && rows[2][11] === "Result" && rows[3] && rows[3][5] === "RSV+hMPV" && rows.length > 4) {
				documentType = "solana-rsv";
				startDateTime = moment(rows[1][1]).format("YYYYMMDDHHmmss");
				rows.splice(0, 3);
			} else if (rows[0] && rows[0][0] === "Operator" && rows[0][1] === "StartDateTime" && rows[1] && rows[1][1] && rows[2] && rows[2][0] === "TubeNr" && rows[2][1] === "Sample ID" && rows[2][2] === "Unique ID" && rows[2][3] === "Result" && rows[2][4] === "LotNumber" && rows[2][5] === "MethodFile" && rows[2][6] === "ExpDate" && rows[2][8] === "Name" && rows[2][9] === "Result" && rows[2][10] === "Name" && rows[2][11] === "Result" && rows[3] && rows[3][5] === "Influenza" && rows.length > 4) {
				documentType = "solana-influenza";
				startDateTime = moment(rows[1][1]).format("YYYYMMDDHHmmss");
				rows.splice(0, 3);
			} else if (rows[0] && rows[0][0] === "Operator" && rows[0][1] === "StartDateTime" && rows[1] && rows[1][1] && rows[2] && rows[2][0] === "TubeNr" && rows[2][1] === "Sample ID" && rows[2][2] === "Unique ID" && rows[2][3] === "Result" && rows[2][4] === "LotNumber" && rows[2][5] === "MethodFile" && rows[2][6] === "ExpDate" && rows[2][8] === "Name" && rows[2][9] === "Result" && rows[3] && rows[3][5] === "SARS-CoV-2" && rows.length > 4) {
				documentType = "solana-covid";
				startDateTime = moment(rows[1][1]).format("YYYYMMDDHHmmss");
				rows.splice(0, 3);
			} else if (rows[0] && rows[0][0] === "Operator" && rows[0][1] === "StartDateTime" && rows[1] && rows[1][1] && rows[2] && rows[2][0] === "TubeNr" && rows[2][1] === "Sample ID" && rows[2][2] === "Unique ID" && rows[2][3] === "Result" && rows[2][4] === "LotNumber" && rows[2][5] === "MethodFile" && rows[2][6] === "ExpDate" && rows[2][8] === "Name" && rows[2][9] === "Result" && !rows[2][10] && !rows[2][11] && rows[3] && rows[3][5] === "RSV" && rows.length > 4) {
				documentType = "solana-rsv-only";
				startDateTime = moment(rows[1][1]).format("YYYYMMDDHHmmss");
				rows.splice(0, 3);
			} else {
				serialNumber = rows[6][1];
				rows.splice(0, 11);
				let index = null;
				rows.map((row, i) => {
					if ((row[0] && row[0].toString().startsWith("*See")) || (row[1] && row[1].toString().startsWith("*See"))) {
						index = i - 1;
					}
					return null;
				})
				if (index) {
					rows.splice(index, 7);
				}

				if (rows[0] && rows[0][0] === "Batch ID" && rows[0][1] === "Sample Name" && rows[0][2] === "Well" && rows[0][3] === "Well Number" && rows[0][4] === "Sample Type" && rows[0][5] === "Manual Threshold" && rows[0][6] === "MS2 Manual Threshold" && rows[0][7] === "Baseline Start" && rows[0][8] === "MS2 Baseline Start" && rows[0][9] === "ORF1ab Ct" && rows[0][10] === "N gene Ct" && rows[0][11] === "S gene Ct" && rows[0][12] === "MS2 Ct" && rows[0][13] === "ORF1ab" && rows[0][14] === "N gene" && rows[0][15] === "S gene" && rows[0][16] === "MS2" && rows[0][17] === "ORF1ab BaselineEnd" && rows[0][18] === "N gene BaselineEnd" && rows[0][19] === "S gene BaselineEnd" && rows[0][20] === "MS2 BaselineEnd" && rows[0][21] === "Status" && rows[0][22] === "Interpretive Result" && rows[0][23] === "Action*" && rows[0][24] === "Sample Comments" && rows.length > 1) {
					documentType = "quant-covid";
					rows.splice(0, 1);
				}
			}
		}
		this.setState({ documentType: documentType });
		if (documentType === 'quant-covid') {
			importQuantResultDocument({ rows: rows, fileName: fileName, userFullName: userFullName, serialNumber: serialNumber }).then(response => {
				boundActions.endBlockUILoading();
				if (response.data) {
					const rowData = response.data;
					if (response.message === 'bundle_sample_included') {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Bundle Sample ID Found",
							html: "The following sample IDs belong to a bundle, which means the incorrect sample ID was used.  Please correct spreadsheet and upload again.<br/>" + rowData.join("<br/>"),
							icon: "warning",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})
					} else if (Array.isArray(rowData)) {
						rowData.sort((a, b) => {
							if (a.status > b.status) {
								return 1;
							}
							if (a.status < b.status) {
								return -1;
							}
							if (["SARS-CoV-2 Inconclusive", "NA"].includes(a.interpretive_result) && !["SARS-CoV-2 Inconclusive", "NA"].includes(b.interpretive_result)) {
								return -1;
							}
							if (!["SARS-CoV-2 Inconclusive", "NA"].includes(a.interpretive_result) && ["SARS-CoV-2 Inconclusive", "NA"].includes(b.interpretive_result)) {
								return 1;
							}
							return 0;
						})
						rowData.map(row => {
							row.checked = true;
							if (row.order_id && row.order_id.sample_tracking && row.order_id.sample_tracking.length && row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length) {
								row.import_count = row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
							} else {
								row.import_count = 0;
							}
							return null;
						})
						let columnDefs = this.state.columnDefs;
						columnDefs[2].children = [
							{
								headerName: "Batch ID",
								minWidth: 150,
								field: "batch_id",
							},
							{
								headerName: "Sample Name",
								minWidth: 150,
								field: "sample",
							},
							{
								headerName: "Well",
								minWidth: 150,
								field: "well",
							},
							{
								headerName: "Well Number",
								minWidth: 150,
								field: "well_number",
								columnGroupShow: 'open'
							},
							{
								headerName: "Sample Type",
								minWidth: 180,
								field: "sample_type",
							},
							{
								headerName: "Manual Threshold",
								minWidth: 180,
								field: "manual_threshold",
								columnGroupShow: 'open'
							},
							{
								headerName: "MS2 Manual Threshold",
								minWidth: 180,
								field: "ms2_manual_threshold",
								columnGroupShow: 'open'
							},
							{
								headerName: "Baseline Start",
								minWidth: 180,
								field: "baseline_start",
								columnGroupShow: 'open'
							},
							{
								headerName: "MS2 Baseline Start",
								minWidth: 180,
								field: "ms2_baseline_start",
								columnGroupShow: 'open'
							},
							{
								headerName: "ORF1ab Ct",
								minWidth: 180,
								field: "orf1ab_ct",
								columnGroupShow: 'open'
							},
							{
								headerName: "N gene Ct",
								minWidth: 180,
								field: "n_gene_ct",
								columnGroupShow: 'open'
							},
							{
								headerName: "S gene Ct",
								minWidth: 180,
								field: "s_gene_ct",
								columnGroupShow: 'open'
							},
							{
								headerName: "MS2 Ct",
								minWidth: 180,
								field: "ms2_ct",
								columnGroupShow: 'open'
							},
							{
								headerName: "ORF1ab",
								minWidth: 180,
								field: "orf1ab",
								columnGroupShow: 'open'
							},
							{
								headerName: "N gene",
								minWidth: 180,
								field: "n_gene",
								columnGroupShow: 'open'
							},
							{
								headerName: "S gene",
								minWidth: 180,
								field: "s_gene",
								columnGroupShow: 'open'
							},
							{
								headerName: "MS2",
								minWidth: 180,
								field: "ms2",
								columnGroupShow: 'open'
							},
							{
								headerName: "ORF1ab BaselineEnd",
								minWidth: 180,
								field: "orf1ab_baselineend",
								columnGroupShow: 'open'
							},
							{
								headerName: "N gene BaselineEnd",
								minWidth: 180,
								field: "n_gene_baselineend",
								columnGroupShow: 'open'
							},
							{
								headerName: "S gene BaselineEnd",
								minWidth: 180,
								field: "s_gene_baselineend",
								columnGroupShow: 'open'
							},
							{
								headerName: "MS2 BaselineEnd",
								minWidth: 180,
								field: "ms2_baselineend",
								columnGroupShow: 'open'
							},
							{
								headerName: "Status",
								minWidth: 180,
								field: "status",
							},
							{
								headerName: "Interpretive Result",
								minWidth: 180,
								field: "interpretive_result",
							},
							{
								headerName: "Action*",
								minWidth: 180,
								field: "action",
								columnGroupShow: 'open'
							},
							{
								headerName: "Sample Comments",
								minWidth: 180,
								field: "sample_comments",
								columnGroupShow: 'open'
							},
						]
						this.setState({ rowData: rowData, tempImportedData: rowData, activeStep: 0, columnDefs: columnDefs });
						this.gridApi.setColumnDefs(columnDefs);
						let correctedSampleInfoContent = "";
						if (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length) {
							response.wrongSubBundleSampleIds.map((wrongSampleId, index) => {
								correctedSampleInfoContent += "<br/>" + wrongSampleId + " -> " + response.correctedSubBundleSampleIds[index];
								return null;
							})
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Imported Quant File",
							html: "You have just imported " + rowData.length + " records.  Please review the records to ensure the data is correct then click on Next to continue." + (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length ? ("<br/><br/>The following sample ids are corrected automatically" + correctedSampleInfoContent) : ""),
							icon: "success",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})

						let formData = new FormData();
						formData.append(
							"document",
							file,
							fileName
						)
						uploadQuantResultDocument(formData).then((success) => {
						});
					}
				}
			})
		} else if (documentType === 'solana-covid') {
			rows = rows.filter(row => row[0] && row[1]);
			rows.map(row => {
				if (row[1] && row[1].toString().includes("E+")) {
					row[1] = Number(row[1]).toString();
				}
				if (row[2] && row[2].toString().includes("E+")) {
					row[2] = Number(row[2]).toString();
				}
				return null;
			})
			importSolanaResultDocument({ rows: rows, fileName: fileName, userFullName: userFullName, startDateTime: startDateTime, documentType: documentType }).then(response => {
				boundActions.endBlockUILoading();
				if (response.data) {
					const rowData = response.data;
					if (response.message === 'bundle_sample_included') {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Bundle Sample ID Found",
							html: "The following sample IDs belong to a bundle, which means the incorrect sample ID was used.  Please correct spreadsheet and upload again.<br/>" + rowData.join("<br/>"),
							icon: "warning",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})
					} else if (Array.isArray(rowData)) {
						rowData.sort((a, b) => {
							if (a.status > b.status) {
								return 1;
							}
							if (a.status < b.status) {
								return -1;
							}
							if (["SARS-CoV-2 Inconclusive", "NA"].includes(a.interpretive_result) && !["SARS-CoV-2 Inconclusive", "NA"].includes(b.interpretive_result)) {
								return -1;
							}
							if (!["SARS-CoV-2 Inconclusive", "NA"].includes(a.interpretive_result) && ["SARS-CoV-2 Inconclusive", "NA"].includes(b.interpretive_result)) {
								return 1;
							}
							return 0;
						})
						rowData.map(row => {
							row.checked = true;
							if (row.order_id && row.order_id.sample_tracking && row.order_id.sample_tracking.length && row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length) {
								row.import_count = row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
							} else {
								row.import_count = 0;
							}
							return null;
						})
						let columnDefs = this.state.columnDefs;
						columnDefs[2].children = [
							{
								headerName: "StartDateTime",
								minWidth: 150,
								field: "start_date_time",
								valueGetter: params => {
									return moment(params.data.start_date_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
								}
							},
							{
								headerName: "TubeNr",
								minWidth: 100,
								field: "tube_nr",
							},
							{
								headerName: "Sample ID",
								minWidth: 150,
								field: "sample",
							},
							{
								headerName: "Unique ID",
								minWidth: 150,
								field: "unique_id",
							},
							{
								headerName: "Result",
								minWidth: 100,
								field: "result",
							},
							{
								headerName: "LotNumber",
								minWidth: 150,
								field: "lot_number",
								columnGroupShow: 'open'
							},
							{
								headerName: "MethodFile",
								minWidth: 150,
								field: "method_file",
								columnGroupShow: 'open'
							},
							{
								headerName: "ExpDate",
								minWidth: 150,
								field: "exp_date",
								columnGroupShow: 'open'
							},
							{
								headerName: "Interpretive Result",
								minWidth: 150,
								field: "interpretive_result",
							},
							{
								headerName: "Status",
								minWidth: 100,
								field: "status",
							},
						]
						this.setState({ rowData: rowData, tempImportedData: rowData, activeStep: 0, columnDefs: columnDefs });
						this.gridApi.setColumnDefs(columnDefs);
						let correctedSampleInfoContent = "";
						if (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length) {
							response.wrongSubBundleSampleIds.map((wrongSampleId, index) => {
								correctedSampleInfoContent += "<br/>" + wrongSampleId + " -> " + response.correctedSubBundleSampleIds[index];
								return null;
							})
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Imported Solana File For SARS-CoV-2 RT-HDA Results",
							html: "You have just imported " + rowData.length + " records.  Please review the records to ensure the data is correct then click on Next to continue." + (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length ? ("<br/><br/>The following sample ids are corrected automatically" + correctedSampleInfoContent) : ""),
							icon: "success",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})

						let formData = new FormData();
						formData.append(
							"document",
							file,
							fileName
						)
						uploadSolanaResultDocument(formData).then((success) => {
						});
					}
				}
			})
		} else if (documentType === 'solana-strep') {
			rows = rows.filter(row => row[0] && row[1]);
			rows.map(row => {
				if (row[1] && row[1].toString().includes("E+")) {
					row[1] = Number(row[1]).toString();
				}
				if (row[2] && row[2].toString().includes("E+")) {
					row[2] = Number(row[2]).toString();
				}
				return null;
			})
			importSolanaResultDocument({ rows: rows, fileName: fileName, userFullName: userFullName, startDateTime: startDateTime, documentType: documentType }).then(response => {
				boundActions.endBlockUILoading();
				if (response.data) {
					const rowData = response.data;
					if (response.message === 'bundle_sample_included') {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Bundle Sample ID Found",
							html: "The following sample IDs belong to a bundle, which means the incorrect sample ID was used.  Please correct spreadsheet and upload again.<br/>" + rowData.join("<br/>"),
							icon: "warning",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})
					} else if (Array.isArray(rowData)) {
						rowData.sort((a, b) => {
							if (a.status > b.status) {
								return 1;
							}
							if (a.status < b.status) {
								return -1;
							}
							return 0;
						})
						rowData.map(row => {
							row.checked = true;
							if (row.order_id && row.order_id.sample_tracking && row.order_id.sample_tracking.length && row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length) {
								row.import_count = row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
							} else {
								row.import_count = 0;
							}
							return null;
						})
						let columnDefs = this.state.columnDefs;
						columnDefs[2].children = [
							{
								headerName: "StartDateTime",
								minWidth: 150,
								field: "start_date_time",
								valueGetter: params => {
									return moment(params.data.start_date_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
								}
							},
							{
								headerName: "TubeNr",
								minWidth: 100,
								field: "tube_nr",
							},
							{
								headerName: "Sample ID",
								minWidth: 150,
								field: "sample",
							},
							{
								headerName: "Unique ID",
								minWidth: 150,
								field: "unique_id",
							},
							{
								headerName: "Result",
								minWidth: 100,
								field: "result",
							},
							{
								headerName: "LotNumber",
								minWidth: 150,
								field: "lot_number",
								columnGroupShow: 'open'
							},
							{
								headerName: "MethodFile",
								minWidth: 150,
								field: "method_file",
								columnGroupShow: 'open'
							},
							{
								headerName: "ExpDate",
								minWidth: 150,
								field: "exp_date",
								columnGroupShow: 'open'
							},
							{
								headerName: "Name",
								minWidth: 150,
								field: "name1",
							},
							{
								headerName: "Result",
								minWidth: 150,
								field: "result1",
							},
							{
								headerName: "Name",
								minWidth: 150,
								field: "name2",
							},
							{
								headerName: "Result",
								minWidth: 150,
								field: "result2",
							},
							{
								headerName: "Interpretive Result",
								minWidth: 150,
								field: "interpretive_result",
							},
							{
								headerName: "Status",
								minWidth: 100,
								field: "status",
							},
						]
						this.setState({ rowData: rowData, tempImportedData: rowData, activeStep: 0, columnDefs: columnDefs });
						this.gridApi.setColumnDefs(columnDefs);
						let correctedSampleInfoContent = "";
						if (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length) {
							response.wrongSubBundleSampleIds.map((wrongSampleId, index) => {
								correctedSampleInfoContent += "<br/>" + wrongSampleId + " -> " + response.correctedSubBundleSampleIds[index];
								return null;
							})
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Imported Solana File For Strep Comp Results",
							html: "You have just imported " + rowData.length + " records.  Please review the records to ensure the data is correct then click on Next to continue." + (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length ? ("<br/><br/>The following sample ids are corrected automatically" + correctedSampleInfoContent) : ""),
							icon: "success",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})

						let formData = new FormData();
						formData.append(
							"document",
							file,
							fileName
						)
						uploadSolanaResultDocument(formData).then((success) => {
						});
					}
				}
			})
		} else if (documentType === 'solana-rsv' || documentType === 'solana-rsv-only') {
			rows = rows.filter(row => row[0] && row[1]);
			rows.map(row => {
				if (row[1] && row[1].toString().includes("E+")) {
					row[1] = Number(row[1]).toString();
				}
				if (row[2] && row[2].toString().includes("E+")) {
					row[2] = Number(row[2]).toString();
				}
				return null;
			})
			importSolanaResultDocument({ rows: rows, fileName: fileName, userFullName: userFullName, startDateTime: startDateTime, documentType: documentType }).then(response => {
				boundActions.endBlockUILoading();
				if (response.data) {
					const rowData = response.data;
					if (response.message === 'bundle_sample_included') {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Bundle Sample ID Found",
							html: "The following sample IDs belong to a bundle, which means the incorrect sample ID was used.  Please correct spreadsheet and upload again.<br/>" + rowData.join("<br/>"),
							icon: "warning",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})
					} else if (Array.isArray(rowData)) {
						rowData.sort((a, b) => {
							if (a.status > b.status) {
								return 1;
							}
							if (a.status < b.status) {
								return -1;
							}
							return 0;
						})
						rowData.map(row => {
							row.checked = true;
							if (row.order_id && row.order_id.sample_tracking && row.order_id.sample_tracking.length && row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length) {
								row.import_count = row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
							} else {
								row.import_count = 0;
							}
							return null;
						})
						let columnDefs = this.state.columnDefs;
						if (documentType === 'solana-rsv') {
							columnDefs[2].children = [
								{
									headerName: "StartDateTime",
									minWidth: 150,
									field: "start_date_time",
									valueGetter: params => {
										return moment(params.data.start_date_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
									}
								},
								{
									headerName: "TubeNr",
									minWidth: 100,
									field: "tube_nr",
								},
								{
									headerName: "Sample ID",
									minWidth: 150,
									field: "sample",
								},
								{
									headerName: "Unique ID",
									minWidth: 150,
									field: "unique_id",
								},
								{
									headerName: "Result",
									minWidth: 100,
									field: "result",
								},
								{
									headerName: "LotNumber",
									minWidth: 150,
									field: "lot_number",
									columnGroupShow: 'open'
								},
								{
									headerName: "MethodFile",
									minWidth: 150,
									field: "method_file",
									columnGroupShow: 'open'
								},
								{
									headerName: "ExpDate",
									minWidth: 150,
									field: "exp_date",
									columnGroupShow: 'open'
								},
								{
									headerName: "Name",
									minWidth: 150,
									field: "name1",
								},
								{
									headerName: "Result",
									minWidth: 150,
									field: "result1",
								},
								{
									headerName: "Name",
									minWidth: 150,
									field: "name2",
								},
								{
									headerName: "Result",
									minWidth: 150,
									field: "result2",
								},
								{
									headerName: "Interpretive Result",
									minWidth: 150,
									field: "interpretive_result",
								},
								{
									headerName: "Status",
									minWidth: 100,
									field: "status",
								},
							]
						} else {
							columnDefs[2].children = [
								{
									headerName: "StartDateTime",
									minWidth: 150,
									field: "start_date_time",
									valueGetter: params => {
										return moment(params.data.start_date_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
									}
								},
								{
									headerName: "TubeNr",
									minWidth: 100,
									field: "tube_nr",
								},
								{
									headerName: "Sample ID",
									minWidth: 150,
									field: "sample",
								},
								{
									headerName: "Unique ID",
									minWidth: 150,
									field: "unique_id",
								},
								{
									headerName: "Result",
									minWidth: 100,
									field: "result",
								},
								{
									headerName: "LotNumber",
									minWidth: 150,
									field: "lot_number",
									columnGroupShow: 'open'
								},
								{
									headerName: "MethodFile",
									minWidth: 150,
									field: "method_file",
									columnGroupShow: 'open'
								},
								{
									headerName: "ExpDate",
									minWidth: 150,
									field: "exp_date",
									columnGroupShow: 'open'
								},
								{
									headerName: "Name",
									minWidth: 150,
									field: "name1",
								},
								{
									headerName: "Result",
									minWidth: 150,
									field: "result1",
								},
								{
									headerName: "Interpretive Result",
									minWidth: 150,
									field: "interpretive_result",
								},
								{
									headerName: "Status",
									minWidth: 100,
									field: "status",
								},
							]
						}
						this.setState({ rowData: rowData, tempImportedData: rowData, activeStep: 0, columnDefs: columnDefs });
						this.gridApi.setColumnDefs(columnDefs);
						let correctedSampleInfoContent = "";
						if (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length) {
							response.wrongSubBundleSampleIds.map((wrongSampleId, index) => {
								correctedSampleInfoContent += "<br/>" + wrongSampleId + " -> " + response.correctedSubBundleSampleIds[index];
								return null;
							})
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Imported Solana File For " + (documentType === 'solana-rsv' ? "RSV + hMPV" : "RSV") + " Results",
							html: "You have just imported " + rowData.length + " records.  Please review the records to ensure the data is correct then click on Next to continue." + (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length ? ("<br/><br/>The following sample ids are corrected automatically" + correctedSampleInfoContent) : ""),
							icon: "success",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})

						let formData = new FormData();
						formData.append(
							"document",
							file,
							fileName
						)
						uploadSolanaResultDocument(formData).then((success) => {
						});
					}
				}
			})
		} else if (documentType === 'solana-influenza') {
			rows = rows.filter(row => row[0] && row[1]);
			rows.map(row => {
				if (row[1] && row[1].toString().includes("E+")) {
					row[1] = Number(row[1]).toString();
				}
				if (row[2] && row[2].toString().includes("E+")) {
					row[2] = Number(row[2]).toString();
				}
				return null;
			})
			importSolanaResultDocument({ rows: rows, fileName: fileName, userFullName: userFullName, startDateTime: startDateTime, documentType: documentType }).then(response => {
				boundActions.endBlockUILoading();
				if (response.data) {
					const rowData = response.data;
					if (response.message === 'bundle_sample_included') {
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Bundle Sample ID Found",
							html: "The following sample IDs belong to a bundle, which means the incorrect sample ID was used.  Please correct spreadsheet and upload again.<br/>" + rowData.join("<br/>"),
							icon: "warning",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})
					} else if (Array.isArray(rowData)) {
						rowData.sort((a, b) => {
							if (a.status > b.status) {
								return 1;
							}
							if (a.status < b.status) {
								return -1;
							}
							return 0;
						})
						rowData.map(row => {
							row.checked = true;
							if (row.order_id && row.order_id.sample_tracking && row.order_id.sample_tracking.length && row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length) {
								row.import_count = row.order_id.sample_tracking.filter(item => item.eventName.includes('IMPORT')).length;
							} else {
								row.import_count = 0;
							}
							return null;
						})
						let columnDefs = this.state.columnDefs;
						columnDefs[2].children = [
							{
								headerName: "StartDateTime",
								minWidth: 150,
								field: "start_date_time",
								valueGetter: params => {
									return moment(params.data.start_date_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
								}
							},
							{
								headerName: "TubeNr",
								minWidth: 100,
								field: "tube_nr",
							},
							{
								headerName: "Sample ID",
								minWidth: 150,
								field: "sample",
							},
							{
								headerName: "Unique ID",
								minWidth: 150,
								field: "unique_id",
							},
							{
								headerName: "Result",
								minWidth: 100,
								field: "result",
							},
							{
								headerName: "LotNumber",
								minWidth: 150,
								field: "lot_number",
								columnGroupShow: 'open'
							},
							{
								headerName: "MethodFile",
								minWidth: 150,
								field: "method_file",
								columnGroupShow: 'open'
							},
							{
								headerName: "ExpDate",
								minWidth: 150,
								field: "exp_date",
								columnGroupShow: 'open'
							},
							{
								headerName: "Name",
								minWidth: 150,
								field: "name1",
							},
							{
								headerName: "Result",
								minWidth: 150,
								field: "result1",
							},
							{
								headerName: "Name",
								minWidth: 150,
								field: "name2",
							},
							{
								headerName: "Result",
								minWidth: 150,
								field: "result2",
							},
							{
								headerName: "Interpretive Result",
								minWidth: 150,
								field: "interpretive_result",
							},
							{
								headerName: "Status",
								minWidth: 100,
								field: "status",
							},
						]
						this.setState({ rowData: rowData, tempImportedData: rowData, activeStep: 0, columnDefs: columnDefs });
						this.gridApi.setColumnDefs(columnDefs);
						let correctedSampleInfoContent = "";
						if (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length) {
							response.wrongSubBundleSampleIds.map((wrongSampleId, index) => {
								correctedSampleInfoContent += "<br/>" + wrongSampleId + " -> " + response.correctedSubBundleSampleIds[index];
								return null;
							})
						}
						Swal.fire({
							customClass: {
								container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
							},
							title: "Imported Solana File For Influenza A+B Results",
							html: "You have just imported " + rowData.length + " records.  Please review the records to ensure the data is correct then click on Next to continue." + (response.wrongSubBundleSampleIds && response.wrongSubBundleSampleIds.length ? ("<br/><br/>The following sample ids are corrected automatically" + correctedSampleInfoContent) : ""),
							icon: "success",
							confirmButtonText: 'Ok',
							allowOutsideClick: false
						})

						let formData = new FormData();
						formData.append(
							"document",
							file,
							fileName
						)
						uploadSolanaResultDocument(formData).then((success) => {
						});
					}
				}
			})
		} else {
			boundActions.endBlockUILoading();
			toastr.warning("Document format is not correct");
		}
		document.getElementById("import_result_file").value = "";
	}

	handleFiltersChange = (event) => {
		if (event.target.name === 'result_value') {
			this.setState({
				result_value: event.target.value
			});
			if (this.state.tempImportedData.length) {
				let rowData;
				if (event.target.value === 'valid') {
					rowData = this.state.tempImportedData.filter(item => item.status.toLowerCase() === 'valid' && !["SARS-CoV-2 Inconclusive", "NA"].includes(item.interpretive_result));
				} else if (event.target.value === 'invalid') {
					rowData = this.state.tempImportedData.filter(item => item.status.toLowerCase() === 'invalid' || ["SARS-CoV-2 Inconclusive", "NA"].includes(item.interpretive_result));
				} else if (event.target.value === 'inconclusive') {
					// rowData = this.state.tempImportedData.filter(item => ["SARS-CoV-2 Inconclusive", "NA"].includes(item.interpretive_result));
				} else {
					rowData = this.state.tempImportedData;
				}
				if (this.state.import_count === 'first') {
					rowData = rowData.filter(item => item.import_count === 0);
				} else if (this.state.import_count === 'second') {
					rowData = rowData.filter(item => item.import_count === 1);
				}
				this.setState({ rowData: rowData });
			}
		} else {
			this.setState({
				import_count: event.target.value
			})
			if (this.state.tempImportedData.length) {
				let rowData;
				if (event.target.value === 'first') {
					rowData = this.state.tempImportedData.filter(item => item.import_count === 0);
				} else if (event.target.value === 'second') {
					rowData = this.state.tempImportedData.filter(item => item.import_count === 1);
				} else {
					rowData = this.state.tempImportedData;
				}
				if (this.state.result_value === 'valid') {
					rowData = rowData.filter(item => item.status.toLowerCase() === 'valid' && !["SARS-CoV-2 Inconclusive", "NA"].includes(item.interpretive_result));
				} else if (this.state.result_value === 'invalid') {
					rowData = rowData.filter(item => item.status.toLowerCase() === 'invalid' || ["SARS-CoV-2 Inconclusive", "NA"].includes(item.interpretive_result));
				}
				this.setState({ rowData: rowData });
			}
		}
	}

	checkItem = (resultId, checked) => {
		let rowData = this.state.rowData;
		rowData.map(row => {
			if (row._id === resultId) {
				row.checked = checked;
			}
			return null;
		})
		this.setState({ rowData: rowData });
		this.gridApi.refreshCells();
	}

	selectAll = () => {
		let rowData = this.state.rowData;
		let checked = false;
		rowData.map(row => {
			if (row.checked === false) {
				checked = true;
			}
			return null;
		})
		rowData.map(row => {
			row.checked = checked;
			return null;
		})
		this.setState({ rowData: rowData });
		this.gridApi.refreshCells();
	}

	removeItem = (resultId) => {
		this.setState({ rowData: this.state.rowData.filter(item => item._id !== resultId) });
	}

	reTestChecked = () => {
		let results = [];
		this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
			let item = rowNode.data;
			if (item.checked) {
				results.push(item);
			}
		})
		this.retestResultsAction(results);
	}

	retestResultsAction = (results, type) => {
		const resultIds = results.map(result => {
			return result._id;
		})
		retestQuantResults({ results }).then(res => {
			if (res.data && res.data.RESULT === "SUCCESS") {
				this.setState({
					tempImportedData: this.state.tempImportedData.filter(item => !resultIds.includes(item._id)),
					rowData: this.state.rowData.filter(item => !resultIds.includes(item._id)),
				}, () => {
					this.gridApi.refreshCells();
					if (type !== 'no_action') {
						if (type !== 'automatic') {
							this.showPopup()
						} else {
							this.handleNext();
						}
					}
				});
			}
		})
	}

	releaseChecked = () => {
		let results = [];
		this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
			let item = rowNode.data;
			if (item.checked) {
				results.push(item);
			}
		})
		this.releaseResultsAction(results);
	}

	releaseResultsAction = (results, type) => {
		const resultIds = results.map(result => {
			return result._id;
		})
		releaseQuantResults({ results }).then(res => {
			if (res.data && res.data.RESULT === "SUCCESS") {
				this.setState({
					tempImportedData: this.state.tempImportedData.filter(item => !resultIds.includes(item._id)),
					rowData: this.state.rowData.filter(item => !resultIds.includes(item._id)),
				}, () => {
					this.gridApi.refreshCells();
					if (type !== 'no_action') {
						if (type !== 'automatic') {
							this.showPopup()
						} else {
							this.handleNext();
						}
					}
				});
			}
		})
	}

	showPopup = () => {
		if (this.state.tempImportedData.length) {
			this.setState({
				result_value: 'all',
				import_count: 'all',
				rowData: this.state.tempImportedData
			})
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'Kind Reminder',
				icon: 'info',
				html: `There are still items on the grid, you must take action on all of them.`,
				confirmButtonText: 'Ok',
			})
		} else {
			Swal.fire({
				customClass: {
					container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
				},
				title: 'All Imported Results Complete',
				icon: 'success',
				confirmButtonText: 'Ok',
			})
		}
	}

	handleNext = () => {
		let activeStep = this.state.activeStep;
		let allData = this.state.tempImportedData;
		let result_value = this.state.result_value;
		let import_count = this.state.import_count;
		let rowData = [];
		switch (activeStep) {
			case 0:
				let releaseData = [];
				let retestData = [];
				let validData = allData.filter(item => item.status.toLowerCase() === "valid" && !["NA", "SARS-CoV-2 Inconclusive"].includes(item.interpretive_result) && item.order_id);
				let firstInconclusiveData = allData.filter(item => (item.status.toLowerCase() !== "valid" || ["NA", "SARS-CoV-2 Inconclusive"].includes(item.interpretive_result)) && item.import_count === 0 && item.order_id);
				let secondInconclusiveData = allData.filter(item => (item.status.toLowerCase() !== "valid" || ["NA", "SARS-CoV-2 Inconclusive"].includes(item.interpretive_result)) && item.import_count === 1 && item.order_id);
				let controlSpotData = allData.filter(item => !item.order_id && item.sample.length < 6);

				releaseData = [...validData, ...secondInconclusiveData, ...controlSpotData];
				firstInconclusiveData.map(item => {
					if (item.order_id && item.order_id.facility_id && item.order_id.facility_id.first_inconclusive_release) {
						releaseData.push(item);
					} else {
						retestData.push(item);
					}
					return null;
				})
				let sampleReleaseLength = releaseData.length - controlSpotData.length;
				let controlDataLength = controlSpotData.length;
				let retestDataLength = retestData.length;
				this.setState({
					activeStep: activeStep + 1,
					rowData: retestData
				})
				let confirmButtonText = "";
				if (releaseData.length || retestData.length) {
					if (releaseData.length && !retestData.length) {
						confirmButtonText = "Release";
					} else if (!releaseData.length && retestData.length) {
						confirmButtonText = "Print / Send back to accessioning";
					} else {
						confirmButtonText = "Release / Print / Send back to accessioning";
					}
				} else {
					confirmButtonText = "Next";
				}
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: "Processing Samples Automatically",
					icon: "info",
					text: "We are going to release " + sampleReleaseLength + " items and " + controlDataLength + " controls, and send back " + retestDataLength + " to accessioning.",
					confirmButtonText: confirmButtonText,
					allowOutsideClick: false
				}).then((result) => {
					if (result.isConfirmed) {
						if (releaseData.length || retestData.length) {
							if (releaseData.length && retestData.length) {
								this.releaseResultsAction(releaseData, 'no_action');
								this.retestResultsAction(retestData, 'automatic');
								this.onBtnExport();
							} else {
								if (releaseData.length) {
									this.releaseResultsAction(releaseData, 'automatic');
								}
								if (retestData.length) {
									this.retestResultsAction(retestData, 'automatic');
									this.onBtnExport();
								}
							}
						} else {
							this.handleNext();
						}
					}
				})
				break;
			case 1:
				if (allData.length) {
					rowData = allData;
					result_value = 'all';
					import_count = 'all';
					this.setState({
						activeStep: activeStep + 1,
						rowData: rowData,
						result_value: result_value,
						import_count: import_count
					})
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: "Please review any remaining items and take action manually on them",
						icon: "info",
						confirmButtonText: "Ok",
						allowOutsideClick: false
					}).then((result) => {
					})
				} else {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: "All Samples Have Been Released!",
						icon: "success",
						confirmButtonText: "Ok",
						allowOutsideClick: false
					}).then((result) => {
						this.setState({
							tempImportedData: [],
							rowData: [],
							activeStep: 0,
							result_value: 'all',
							import_count: 'all'
						})
					})
				}
				break;
			case 2:
				Swal.fire({
					title: "Do you want to finish importing now?",
					text: "You will lose all remaining items",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Yes",
					cancelButtonText: "Cancel",
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
						cancelButton: 'order-1',
						confirmButton: 'order-2'
					}
				}).then(result => {
					if (result.isConfirmed) {
						this.setState({
							tempImportedData: [],
							rowData: [],
							activeStep: 0,
							result_value: 'all',
							import_count: 'all'
						})
					}
				})
				break;
			default:
				break;
		}
	}

	importFile = () => {
		document.getElementById("import_result_file").click();
	}

	render() {
		return (
			<div className="lims-container">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					onPageSizeChanged={this.onPageSizeChanged}
					resetState={this.resetState}
					onBtnExport={this.onBtnExport}
					selectAll={this.selectAll}
					checkItem={this.checkItem}
					importing={store.getState().blockUILoading}
					importResults={this.importResults}
					rowData={this.state.rowData}
					handleFiltersChange={this.handleFiltersChange}
					result_value={this.state.result_value}
					import_count={this.state.import_count}
					activeStep={this.state.activeStep}
					tempImportedData={this.state.tempImportedData}
					importFile={this.importFile}
				/>
				{
					this.state.tempImportedData.length > 0
					&&
					<div style={{ padding: "15px" }}>
						<Stepper activeStep={this.state.activeStep}>
							{
								this.state.steps.map((label, index) => {
									const stepProps = {};
									const labelProps = {};
									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>{label}</StepLabel>
										</Step>
									);
								})
							}
						</Stepper>
						<div className="row mt-3">
							<div className="col-12 d-flex justify-content-end">
								{
									this.state.rowData.length > 0 && this.state.activeStep === 2
									&&
									<button
										className="btn btn-info button-info-grid"
										onClick={() => this.selectAll()}
									>
										{" "}
										<i className="fa fa-check"></i> {this.state.rowData.filter(item => !item.checked).length ? "Select All" : "Deselect All"}
									</button>
								}
								{
									this.state.activeStep === 2
									&&
									<button
										className="btn btn-info button-info-grid"
										disabled={this.state.rowData.filter(item => item.checked).length ? false : true}
										onClick={this.reTestChecked}
									>
										<i className="fa fa-repeat" aria-hidden="true"></i> ReTest Checked
									</button>
								}
								{
									this.state.activeStep === 2
									&&
									<button
										className="btn btn-primary button-info-grid"
										disabled={this.state.rowData.filter(item => item.checked).length ? false : true}
										onClick={this.releaseChecked}
									>
										{" "}
										<i className="fa fa-arrow-right"></i> Release Checked
									</button>
								}
								<button
									className="btn btn-info button-info-grid"
									onClick={this.handleNext}
								>
									{" "}
									<i className="fa fa-arrow-right"></i> {this.state.activeStep === '2' ? "Finish" : "Next"}
								</button>
							</div>
						</div>
					</div>
				}
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 350px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									overlayNoRowsTemplate={this.state.tempImportedData.length > 0 ? "Imported items are still left, but no items to show" : "No Items To Show"}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div >
		);
	}
}

export default LIMSImportResultsGrid;
