import React, { Component } from "react";
import { Button, Tooltip } from "react-bootstrap";
import { states } from "../../../../services/common/optionsData";
import { serviceConstants } from "../../../../services/common/constants";
import { phoneNumberFormatter, getFullAddress } from "../../../../services/common/util";
import {
	createLocation,
	updateLocation,
	getLocationDataById,
} from "../../../../services/clinicPortalServices/locationService";
import { getFacilityData } from "../../../../services/clinicPortalServices/facilityServices";
import { FormControl, InputLabel, MenuItem, Select, Checkbox } from "@mui/material";
import LogoImages from "../../../appointmentBooking/home/maplist/img";
import DraftEditor from "./draftEditor";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import Geocode from "react-geocode";

// Geocode.setApiKey(serviceConstants.GOOGLE_API_KEY_FOR_GEOCODING);


export default class LocationDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: this.props.show,
			id: "",
			image: "",
			name: "",
			booking_link: "",
			lat: "",
			lng: "",
			contact_number: "",
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			timings: "",
			facility_id: null,
			locationId: this.props && this.props.locationId ? this.props.locationId : "",
			errors: [],
			facilities: [],
			acceptInsurance: false,
			excludeRegister: false,
			hideList: false,
			paragraphHtml: "",
			paragraphHtmlSpanish: "",
			arrayLogoImages: []
		};
		this.editorRef = React.createRef();
		this.editorRefSpanish = React.createRef();
	}

	componentDidMount() {
		getFacilityData().then((response) => {
			this.setState({ facilities: response.data });
			if (this.state.locationId !== "") {
				this.loadLocationDetails();
			}
		});
		let arrayLogoImages = [];
		Object.entries(LogoImages).map(([key, image]) => {
			image['key'] = key;
			arrayLogoImages.push(image);
			return null;
		})
		arrayLogoImages.sort(function (a, b) {
			if (a.label > b.label) {
				return 1;
			} else {
				return -1;
			}
		});
		this.setState({ arrayLogoImages: arrayLogoImages });
	}

	loadLocationDetails = () => {
		getLocationDataById(this.state.locationId)
			.then((response) => {
				let locationDetails = response.data[0];
				this.setState({
					id: locationDetails ? locationDetails._id : "",
					image: locationDetails ? locationDetails.image : "",
					name: locationDetails ? locationDetails.name : "",
					booking_link: locationDetails ? locationDetails.booking_link : "",
					contact_number: locationDetails ? locationDetails.contact_number : "",
					address1: locationDetails && locationDetails.address ? locationDetails.address.address1 : "",
					address2: locationDetails && locationDetails.address ? locationDetails.address.address2 : "",
					city: locationDetails && locationDetails.address ? locationDetails.address.city : "",
					state: locationDetails && locationDetails.address ? locationDetails.address.state : "",
					zip: locationDetails && locationDetails.address ? locationDetails.address.zip : "",
					timings: locationDetails ? locationDetails.timings.join(",") : "",
					facility_id: locationDetails && locationDetails.facility_id && locationDetails.facility_id._id ? locationDetails.facility_id._id : null,
					acceptInsurance: locationDetails && locationDetails.accept_insurance ? locationDetails.accept_insurance : false,
					excludeRegister: locationDetails && locationDetails.exclude_register ? locationDetails.exclude_register : false,
					hideList: locationDetails && locationDetails.hide_list ? locationDetails.hide_list : false,
					paragraphHtml: locationDetails && locationDetails.paragraph_html ? locationDetails.paragraph_html : "",
					paragraphHtmlSpanish: locationDetails && locationDetails.paragraph_html_spanish ? locationDetails.paragraph_html_spanish : ""
				});
			});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleChange = (e) => {
		const target = e.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		if (name === "contact_number") {
			this.setState((prevState) => ({
				contact_number: phoneNumberFormatter(value, prevState.contact_number),
			}));
		} else {
			this.setState({
				[name]: value,
			});
		}
	};

	toggle = (type) => {
		if (type === 'excludeRegister') {
			this.setState({ excludeRegister: !this.state.excludeRegister });
		} else if (type === 'acceptInsurance') {
			this.setState({ acceptInsurance: !this.state.acceptInsurance });
		} else if (type === 'hideList') {
			this.setState({ hideList: !this.state.hideList });
		}
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	};

	onEditorStateChange = (editorState) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			paragraphHtml: html
		})
	};

	onEditorStateChangeSpanish = (editorState) => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.setState({
			paragraphHtmlSpanish: html
		})
	};

	updateAndCreateLocation = (e) => {
		e.preventDefault();
		let errors = [];

		if (!this.state.image) {
			errors.push("image");
		}
		if (!this.state.name) {
			errors.push("name");
		}
		if (!this.state.facility_id) {
			errors.push("facility_id");
		}
		if (!this.state.address1) {
			errors.push("address1");
		}
		if (!this.state.city) {
			errors.push("city");
		}
		if (!this.state.state) {
			errors.push("state");
		}
		if (!this.state.zip) {
			errors.push("zip");
		}
		this.setState({ errors: errors });
		if (errors.length > 0) {
			return false;
		}

		let locationInfo = {
			id: this.state.id,
			image: this.state.image,
			name: this.state.name,
			booking_link: this.state.booking_link,
			contact_number: this.state.contact_number,
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
			timings: this.state.timings ? this.state.timings.split(',') : "",
			facility_id: this.state.facility_id,
			accept_insurance: this.state.acceptInsurance,
			exclude_register: this.state.excludeRegister,
			paragraph_html: this.state.paragraphHtml,
			paragraph_html_spanish: this.state.paragraphHtmlSpanish,
			hide_list: this.state.hideList
		};
		if (this.editorRef.current.state.editorState) {
			locationInfo.paragraph_html = draftToHtml(convertToRaw(this.editorRef.current.state.editorState.getCurrentContent()))
		}
		if (this.editorRefSpanish.current.state.editorState) {
			locationInfo.paragraph_html_spanish = draftToHtml(convertToRaw(this.editorRefSpanish.current.state.editorState.getCurrentContent()))
		}
		const addressObject = {
			address1: this.state.address1,
			address2: this.state.address2,
			city: this.state.city,
			state: this.state.state,
			zip: this.state.zip,
		}
		// Geocode.fromAddress(getFullAddress(addressObject)).then((response) => {
		// 	const { lat, lng } = response.results[0].geometry.location;
		// 	locationInfo.lat = lat;
		// 	locationInfo.lng = lng;
			if (this.state.locationId !== "") {
				updateLocation(locationInfo)
					.then((response) => {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose()
					});
			} else {
				createLocation(locationInfo)
					.then((response) => {
						this.props.context.componentParent.loadGridData();
						this.props.handleClose()
					});
			}
		// })
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Location
		</Tooltip>
	);

	render() {
		return (
			<div>
				<form>
					<div className="row form-row">
						<div className="col-12">
							<div className="form-group">
								<label>
									Image Logo <span className="text-danger"> *</span>{" "}
								</label>
								<FormControl
									variant="outlined"
									style={{ width: "100%", marginTop: "5px" }}
								>
									<InputLabel id="image-logo-label">Select Image Logo</InputLabel>
									<Select
										labelId="image-logo-label"
										value={this.state.image}
										onChange={this.handleChange}
										label="Select Image Logo"
										name="image"
									>
										{
											this.state.arrayLogoImages.map((image, index) => {
												return (
													<MenuItem
														key={image.key}
														value={image.key}
													>
														<img
															style={{ height: "70px", width: "70px", marginRight: "5px" }}
															src={image.path}
															alt="Logo"
														/>
														{image.label}
													</MenuItem>
												)
											})
										}
									</Select>
								</FormControl>
								<div className={this.hasError("image") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>
									Name <span className="text-danger"> *</span>{" "}
								</label>
								<input
									type="text"
									name="name"
									value={this.state.name}
									onChange={this.handleChange}
									required
									className={this.hasError("name") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("name") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Facility <span className="text-danger"> *</span>{" "}</label>
								<select
									className={this.hasError("facility_id") ? "form-control is-invalid select" : "form-control"}
									name="facility_id"
									value={this.state.facility_id}
									onChange={this.handleChange}
								>
									<option key={""} value={""}>
										Please Select
									</option>
									{
										this.state.facilities.map((facility) => {
											return (
												<option key={facility._id} value={facility._id}>
													{facility.name} {facility.archived === "archived" ? " ( Archived ) " : ""}
												</option>
											);
										})
									}
								</select>
								<div className={this.hasError("facility_id") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<label>Open Hours</label>
								<input
									type="text"
									name="timings"
									value={this.state.timings}
									onChange={this.handleChange}
									className="form-control"
								/>
								<label style={{ fontSize: "13px", color: "#ee344e" }}>
									Connect timings with comma
								</label>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Contact Number</label>
								<input
									type="tel"
									name="contact_number"
									value={this.state.contact_number}
									onChange={this.handleChange}
									placeholder="(XXX) XXX-XXXX"
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Website Link</label>
								<input
									type="text"
									name="booking_link"
									value={this.state.booking_link}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address1 <span className="text-danger"> *</span>{" "}</label>
								<input
									type="text"
									name="address1"
									value={this.state.address1}
									onChange={this.handleChange}
									className={this.hasError("address1") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("address1") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="form-group">
								<label>Address2</label>
								<input
									type="text"
									name="address2"
									value={this.state.address2}
									onChange={this.handleChange}
									className="form-control"
								/>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>City <span className="text-danger"> *</span>{" "}</label>
								<input
									type="text"
									name="city"
									value={this.state.city}
									onChange={this.handleChange}
									className={this.hasError("city") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("city") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>State <span className="text-danger"> *</span>{" "}</label>
								<select
									name="state"
									value={this.state.state}
									onChange={this.handleChange}
									className={this.hasError("state") ? "form-control is-invalid select" : "form-control"}
								>
									{
										states.map((state) => {
											return <option key={state.value} value={state.value}>{state.state}</option>;
										})
									}
								</select>
								<div className={this.hasError("state") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<label>Zip Code <span className="text-danger"> *</span>{" "}</label>
								<input
									type="text"
									name="zip"
									value={this.state.zip}
									onChange={this.handleChange}
									className={this.hasError("zip") ? "form-control is-invalid" : "form-control"}
								/>
								<div className={this.hasError("zip") ? "inline-errormsg" : "hidden"}>
									<i className="fa fa-exclamation-circle" aria-hidden="true">
										&nbsp;This field is required.
									</i>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-4">
							<div className="form-group">
								<MenuItem onClick={() => this.toggle('excludeRegister')}>
									<Checkbox checked={this.state.excludeRegister} />
									<div style={{ whiteSpace: "break-spaces" }}>Exclude Self Check-in</div>
								</MenuItem>
							</div>
						</div>
						{
							!this.state.excludeRegister
							&&
							<div className="col-12 col-md-4">
								<div className="form-group">
									<MenuItem onClick={() => this.toggle('acceptInsurance')}>
										<Checkbox checked={this.state.acceptInsurance} />
										<div style={{ whiteSpace: "break-spaces" }}>Accepts Insurance</div>
									</MenuItem>
								</div>
							</div>
						}
						<div className="col-12 col-md-4">
							<div className="form-group">
								<MenuItem onClick={() => this.toggle('hideList')}>
									<Checkbox checked={this.state.hideList} />
									<div style={{ whiteSpace: "break-spaces" }}>Exclude from website lists but allow Self Check-in</div>
								</MenuItem>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group paragraph-editor-form">
								<label>Paragraph Text</label>
								<DraftEditor
									className="paragraph-editor"
									onEditorStateChange={this.onEditorStateChange}
									ref={this.editorRef}
									initHtml={this.state.paragraphHtml}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group paragraph-editor-form">
								<label>Paragraph Text (Spanish)</label>
								<DraftEditor
									className="paragraph-editor"
									onEditorStateChange={this.onEditorStateChangeSpanish}
									ref={this.editorRefSpanish}
									initHtml={this.state.paragraphHtmlSpanish}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-12 modal-button-col"
						>
							<Button
								style={{ float: "right", marginLeft: "10px" }}
								variant="primary"
								onClick={this.updateAndCreateLocation}
							>
								Save Changes
							</Button>
							<Button
								style={{ float: "right" }}
								variant="secondary"
								onClick={this.props.handleClose}
							>
								Close
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
