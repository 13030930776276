import React from "react";
import { createRoot } from 'react-dom/client';

import AppRouter from "./approuter";

// boostrap
import "bootstrap/dist/css/bootstrap.min.css";
// toastr
import 'toastr/build/toastr.min.css';
//fontawesome
import "../node_modules/font-awesome/css/font-awesome.min.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './i18n/config';

// block ui css
import "@availity/block-ui/dist/index.css";

//react menu for test result filter
import '@szhsin/react-menu/dist/index.css';

// ag grid react
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';

import "./assets/css/all.css";
import "./assets/css/all.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/style.css";

import "./assets/css/customStyle.scss";
import "./assets/css/lims-style.scss";
import "./assets/css/darkStyle.scss";

import { serviceConstants } from './services/common/constants.js';
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey(serviceConstants.AG_GRID_LICENSE_KEY);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppRouter />);

if (module.hot) {
	// enables hot module replacement if plugin is installed
	module.hot.accept();
}
