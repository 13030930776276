import React, { Component } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class QrScanReader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientId: ""
		};
	}

	handleError = (err) => {
		console.error(err);
	};

	render() {
		return (
			<div>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.props.show}
					onHide={this.props.hideQrScannerHandler}
					id="scanQRcodeModal"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Scan QR Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.props.show ? (
							<div>
								<BarcodeScannerComponent
									onError={this.handleError}
									onUpdate={(err, result) => {
										if (result) {
											this.props.onQrCodeScanHandler(result.text);
										} else {
										};
									}}
								/>
							</div>
						) : null}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="secondary"
							onClick={this.props.hideQrScannerHandler}
						>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}

export default QrScanReader;
