import { apiInterfaceForJson } from "../common/apiManager";

export const fetchDrivers = () => {
    return apiInterfaceForJson("/driver/v1/", "GET");
};

export const searchDrivers = (query) => {
    return apiInterfaceForJson("/driver/v1/search", "POST", query);
};

export const getDriverDataById = (driverId) => {
    return apiInterfaceForJson("/driver/v1/" + driverId, "GET");
};

export const createDriver = (driverDetails) => {
    let driverInfo = {
        'driver_user_name': driverDetails.driverUserName,
        'first_name': driverDetails.firstName,
        'last_name': driverDetails.lastName,
        'email': driverDetails.email,
        'mobile': driverDetails.mobile,
        'password': driverDetails.password,
        'color': driverDetails.color
    };
    return apiInterfaceForJson("/driver/v1/", "POST", { driver: driverInfo, link: window.location.origin });
};

export const updateDriver = (driverDetails) => {
    let updateDriverInfo = {
        '_id': driverDetails.id,
        'driver_user_name': driverDetails.driverUserName,
        'first_name': driverDetails.firstName,
        'last_name': driverDetails.lastName,
        'email': driverDetails.email,
        'mobile': driverDetails.mobile,
        'color': driverDetails.color
    };
    if (driverDetails.shift_working_days) {
        updateDriverInfo.shift_working_days = driverDetails.shift_working_days;
    }
    return apiInterfaceForJson("/driver/v1/update", "POST", updateDriverInfo);
};

export const handleShift = (data) => {
    return apiInterfaceForJson("/driver/v1/handleShift", "POST", data);
};

export const updateShift = (data) => {
    return apiInterfaceForJson("/driver/v1/updateShift", "POST", data);
};

export const getShiftDetails = (data) => {
    return apiInterfaceForJson("/driver/v1/getShiftDetails", "POST", data);
};

export const getDriversForRealMap = () => {
    return apiInterfaceForJson("/driver/v1/getDriversForRealMap", "POST");
}

export const deleteDriver = (driverDetails) => {
    return apiInterfaceForJson("/driver/v1/delete", "POST", driverDetails);
};
