import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { fetchPatientQrResponse } from "../../../../services/patientSignupServices/patientSignupFormService";
import SocialShare from "./../../../socialShare";
import { logout } from "../../../../services/patientPortalServices/loginService";
import { easyUpdatePatient } from "../../../../services/clinicPortalServices/patientEditService";
import { Checkbox, MenuItem } from "@mui/material";
import toastr from "toastr";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

class DashboardSidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			qrData: '',
			isExpand: false,
			patientData: this.props.patientData,
			getSampleTrackingNotification: this.props.patientData ? this.props.patientData.get_sample_tracking_notification : true,
		};

	}

	componentDidMount() {
		fetchPatientQrResponse(this.props.patientData._id).then((response) => {
			this.setState({ qrData: response.data });
		});
	}

	expandQR = () => {
		this.setState({ isExpand: true });
	}

	handleClose = () => {
		this.setState({ isExpand: false });
	}

	toggleGetSampleTrackingNotification = () => {
		let getSampleTrackingNotification = this.state.getSampleTrackingNotification;
		getSampleTrackingNotification = !getSampleTrackingNotification
		this.setState({ getSampleTrackingNotification: getSampleTrackingNotification });
		if (this.state.patientData) {
			const patientDetails = {
				_id: this.state.patientData._id,
				get_sample_tracking_notification: getSampleTrackingNotification,
			}
			easyUpdatePatient(patientDetails).then(res => {
				toastr.success('Updated successfully!');
			})
		}
	}

	render() {

		return (
			<div className="profile-sidebar">
				<div className="widget-profile pro-widget-content">
					{
						this.props.patientData &&
						<div className="profile-info-widget">
							<div className="profile-det-info">
								<h3 className="patient-sampleId">MRN (PID): {this.props.patientData._id}</h3>
								{this.state.qrData &&
									<>
										<img className="qr-Image" src={this.state.qrData} onClick={this.expandQR} alt="QR" />
										<h3 className="text-info qr-expandbtn" onClick={this.expandQR} style={{ cursor: "pointer" }}>CLICK TO EXPAND</h3>
									</>}
								<h3 className="patient-name">{this.props.patientData.userName}</h3>
								<div className="patient-details">
									<h5>
										<i className="fas fa-birthday-cake"></i>
										{this.props.patientData.ageDetails}
									</h5>
									<h5 className="mb-0">
										<i className="fas fa-map-marker-alt"></i>
										{this.props.patientData.address.address1}
										<br /> {this.props.patientData.address.address2}
									</h5>
								</div>
							</div>

						</div>
					}
				</div>
				<div className="dashboard-widget">
					<nav className="dashboard-menu">
						<ul>
							<li className="dashboardItem active">
								<Link to="/patientportal/dashboard">
									<i className="fas fa-columns"></i>
									<span>Dashboard</span>
								</Link>
							</li>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
								onClick={this.props.showCommunicator}
							>
								<i className="fas fa-envelope" style={{ padding: "0.8rem" }}></i>
								<span>Contact Us</span>
							</MenuItem>
							<MenuItem
								className="receiveTrackBtn"
								style={{ paddingLeft: "8px" }}
								onClick={this.toggleGetSampleTrackingNotification}
							>
								<Checkbox checked={this.state.getSampleTrackingNotification} />
								Receive Sample Tracking Notification
							</MenuItem>
							<li className="dashboardLogout">
								<div className="py-3" onClick={logout} style={{ cursor: "pointer", fontSize: 16, paddingLeft: "20px" }}>
									<i className="fas fa-sign-out-alt"></i>
									<span className="ml-2">Logout</span>
								</div>
							</li>
							<li className="dashboardFooter">
								<div
									style={{
										textAlign: "center",
										padding: "50px 20px 0px 20px",
										textTransform: "none",
									}}
								>
									<span style={{ fontWeight: "bold", fontSize: "large" }}>
										PLEASE CONSIDER SHARING
									</span>
									<p style={{ paddingTop: "20px" }}>
										Results to your device in less than 24 hours!
									</p>
									<p>
										If you are satisfied with the ease and speed of getting your
										results,please consider sharing our services.
									</p>
									<b>(NOT YOUR RESULTS)</b>
									<p> Let others know, keep people safe!</p>
								</div>
								<SocialShare />
							</li>
						</ul>
					</nav>
				</div>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.isExpand}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						QR Code Viewer
					</Modal.Header>
					<Modal.Body style={{ textAlign: "center" }}>
						{this.state.qrData && <img src={this.state.qrData} style={{ width: "500px", heigh: "500px", margin: 'auto' }} alt="QR" />}
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
};

export default DashboardSidebar;
