import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import {
	updateOrderManualAction,
	fetchOrderFaxData
} from "../../../../services/clinicPortalServices/orderEditService";
import {
	notifyFacilityViaEmail,
	notifyFacilityViaSMS
} from "../../../../services/clinicPortalServices/reportService";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			orderId: props.data._id,
			manual_action: props.data.manual_action
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleOrderEditChanges = () => {
		const editParams = {
			orderId: this.state.orderId,
			manualAction: this.state.manual_action
		};
		updateOrderManualAction(editParams)
			.then((data) => {
				this.handleClose();
				// call refresh grid function
				if (this.props.context && this.props.context.componentParent) {
					this.props.context.componentParent.loadGridData();
				}
			})

	};

	handleFax = () => {
		fetchOrderFaxData(this.state.orderId)
			.then((response) => {
				alert(response.message);
			})
	};

	handleEmail = () => {
		if (this.props.data.facility_id.contact_email) {
			let apiData = {
				lab_order_id: this.props.data.lab_order_id,
				email: this.props.data.facility_id.contact_email
			}
			notifyFacilityViaEmail(apiData)
				.then((response) => {
					if (response.status === 200) {
						const editParams = {
							orderId: this.state.orderId,
							manualAction: 'Contacted Physician Via Email'
						};
						updateOrderManualAction(editParams)
							.then((data) => {
								this.handleClose();
								// call refresh grid function
								if (this.props.context && this.props.context.componentParent) {
									this.props.context.componentParent.loadGridData();
								}
							})
					}
				})
		} else {
			alert('That facility contact email is not provided yet');
		}
	}

	handleSMS = () => {
		if (this.props.data.facility_id.phone_no) {
			let apiData = {
				lab_order_id: this.props.data.lab_order_id,
				phone_no: this.props.data.facility_id.phone_no
			}
			notifyFacilityViaSMS(apiData)
				.then((response) => {
					if (response.status === 200) {
						const editParams = {
							orderId: this.state.orderId,
							manualAction: 'Contacted Physician Via SMS'
						};
						updateOrderManualAction(editParams)
							.then((data) => {
								this.handleClose();
								// call refresh grid function
								if (this.props.context && this.props.context.componentParent) {
									this.props.context.componentParent.loadGridData();
								}
							})
					}
				})
		} else {
			alert('That facility phone number is not provided yet');
		}
	}

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Order
		</Tooltip>
	);

	renderTooltipFax = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Fax Order
		</Tooltip>
	);

	renderTooltipEmail = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Email to Facility
		</Tooltip>
	);

	renderTooltipSMS = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Send SMS to Facility
		</Tooltip>
	);

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEdit}
				>
					<button onClick={this.handleShow} className="edit-order-btn">
						<i className="fas fa-pen"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipFax}
				>
					<button className="fax-button" onClick={this.handleFax}>
						<i className="fa fa-fax" aria-hidden="true"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEmail}
				>
					<button className="edit-order-btn" onClick={this.handleEmail}>
						<i className="fa fa-envelope" aria-hidden="true"></i>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipSMS}
				>
					<button className="edit-order-btn" onClick={this.handleSMS}>
						<i className="fa fa-sms" aria-hidden="true"></i>
					</button>
				</OverlayTrigger>

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Order Action</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12">
									<div className="form-group">
										<label>Action</label>
										<input
											type="text"
											className="form-control"
											name="manual_action"
											value={this.state.manual_action}
											onChange={this.handleChange}
										/>
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={this.handleOrderEditChanges}>
							Save Changes
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
