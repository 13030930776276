import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import LabInventoryDetails from "./labInventoryDetails";
import { Autocomplete, TextField } from "@mui/material";
import { getUserFullName } from "../../../../services/common/util";
import moment from "moment";
import { updateLabInventory } from "../../../../services/clinicPortalServices/labInventoryService";

export default class AddInventory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			showReceivedDeliveryPopup: false,
			selectedInventory: null,
			deliveredQuantity: "",
			note: "",
			loading: false,
		};
	}

	handleShow = () => {
		this.setState({ show: true });
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	handleShowReceivedDeliveryPopup = () => {
		this.setState({ showReceivedDeliveryPopup: true });
	}

	handleCloseReceivedDeliveryPopup = () => {
		this.setState({
			showReceivedDeliveryPopup: false,
			selectedInventory: null,
			deliveredQuantity: "",
			note: ""
		});
	}

	saveReceivedDeliveryPopup = () => {
		this.setState({ loading: true })
		let tracking_history = this.state.selectedInventory.tracking_history;
		tracking_history.push({
			offset: Number(this.state.deliveredQuantity),
			user_name: getUserFullName(),
			time: moment().format("YYYYMMDDHHmmss"),
			note: this.state.note,
		})
		let labInventory = {
			_id: this.state.selectedInventory._id,
			name: this.state.selectedInventory.name,
			tracking_history: tracking_history,
			current_amount: Number(this.state.selectedInventory.current_amount) + Number(this.state.deliveredQuantity),
			ordered_amount: "",
			ordered_date: "",
			ordered_vendor_index: ""
		}
		updateLabInventory(labInventory).then(res => {
			this.handleCloseReceivedDeliveryPopup();
			this.props.context.componentParent.loadGridData();
		})
	}

	render() {
		return (
			<div>
				<button
					className="btn btn-primary button-info-grid"
					onClick={() => this.handleShow()}
				>
					<i className="fas fa-user-plus"></i> Add Inventory
				</button>
				<button
					className="btn btn-primary button-info-grid"
					onClick={() => this.handleShowReceivedDeliveryPopup()}
				>
					<i className="fas fa-truck"></i> Received Delivery
				</button>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Add Inventory Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<LabInventoryDetails
							context={this.props.context}
							handleClose={this.handleClose}
						/>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showReceivedDeliveryPopup}
					onHide={this.handleCloseReceivedDeliveryPopup}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Receive Delivery</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="form-group col-sm-12">
								<Autocomplete
									options={this.props.context.componentParent.state.rowData}
									value={this.state.selectedInventory}
									onChange={(event, newValue) => {
										this.setState({
											selectedInventory: newValue
										})
									}}
									getOptionLabel={(option) => option ? option.name : ""}
									renderInput={(params) => <TextField {...params} label="Inventory" variant="outlined" />}
								/>
							</div>
							<div className="form-group col-sm-12">
								<TextField
									label="Delivered Quantity"
									variant="outlined"
									type="number"
									value={this.state.deliveredQuantity}
									onChange={(e) => {
										this.setState({
											deliveredQuantity: e.target.value
										})
									}}
								/>
							</div>
							<div className="form-group col-sm-12">
								<TextField
									label="Note"
									variant="outlined"
									value={this.state.note}
									onChange={(e) => {
										this.setState({
											note: e.target.value
										})
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div
								className="col-12"
								style={{
									paddingTop: "10px",
									borderTop: "1px solid rgba(0,0,0,.2",
								}}
							>
								<Button
									style={{ float: "right", marginLeft: "10px" }}
									variant="primary"
									onClick={this.saveReceivedDeliveryPopup}
									disabled={(this.state.deliveredQuantity && this.state.selectedInventory && !this.state.loading) ? false : true}
								>
									Save Changes
								</Button>
								<Button
									style={{ float: "right" }}
									variant="secondary"
									onClick={this.handleCloseReceivedDeliveryPopup}
								>
									Close
								</Button>
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
