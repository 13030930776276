import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { getUserDetails, isUserLoggedIn } from "./services/common/util";
import io from 'socket.io-client';
import Swal from "sweetalert2";
import { serviceConstants } from "./services/common/constants";
import { getSettings } from "./services/clinicPortalServices/settingService";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide } from '@mui/material';
import AGT_MRN_LOGO_MOBILE from "./assets/images/results/agt-mrn-logo-mobile.png";
import ClinicPortalRouter from './routers/ClinicPortalRouter.jsx';
import LIMSPortalRouter from './routers/LIMSPortalRouter.jsx';
import CommonRouter from './routers/CommonRouter.jsx';
import IdleTimer from 'react-idle-timer';
import { logout } from "./services/clinicPortalServices/loginService";
import BlockUi from '@availity/block-ui';
import { FadeLoader } from "react-spinners";
import { loaderPositionStyle } from './services/common/optionsData';
import { Connect } from "redux-zero/react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let myTimeout = null;

class AppContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isTriggered: false,
      showOverlay: false
    }
    this.idleTimer = null;
    this.handleOverlaySubmit = this.handleOverlaySubmit.bind(this);
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  handleOnAction = (event) => {
    clearInterval(myTimeout)
    this.idleTimer.reset();
  }

  handleOnIdle = (event) => {
    this.setState({ showOverlay: true });
    myTimeout = setTimeout(() => {
      logout();
    }, 1000 * 60 * 5);
  }

  handleOverlaySubmit = (event) => {
    this.setState({ showOverlay: false });
    this.idleTimer.reset();
  }

  handleClose = () => {
    window.localStorage.setItem('isNotificationEnabled', false);
    this.setState({ open: false });
  };

  handleAllow = () => {
    window.localStorage.setItem('isNotificationEnabled', true);
    this.setState({ open: false });
    window.Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        console.log('permission_granted');
      }
    });
  };

  triggerNotification = (objData) => {
    // windows notification
    window.Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        new window.Notification(objData.title, {
          body: objData.text,
          icon: "https://www.myresultnow.com/public_files/mrn-square-logo.png"
        });
      }
    });
    // browser notification
    if (objData.target !== 'superadmin' && objData.target !== 'lab') {
      Swal.fire({
        customClass: {
          container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
        },
        title: objData.title,
        text: objData.text,
        icon: 'info',
        confirmButtonText: 'Ok'
      });
    }
  };

  componentDidMount() {
    // enable windows notifications
    if (!window.localStorage.getItem('isNotificationEnabled')) {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notifications");
      } else if (window.Notification.permission !== "granted") {
        if (window.Notification.permission !== "denied") {
          if (!this.state.open && !this.state.isTriggered && !window.localStorage.getItem('isNotificationEnabled')) {
            this.setState({ open: true, isTriggered: true });
          }
        } else {
          console.log("Browser notifications are denied");
        }
      }
    }

    // config socket
    const isLoggedIn = window.localStorage.getItem('USER_ID');
    let socketConfigOptions = { query: "userId=" + isLoggedIn, transports: ['websocket', 'polling'] };
    let socketUrl = `${serviceConstants.API_HOST_NAME}`.replace("http", "ws");
    if (serviceConstants.API_HOST_NAME.includes('api')) {
      socketConfigOptions.path = '/api/socket.io';
      socketUrl = `${serviceConstants.HOST_NAME}`.replace("https", "wss")
    }
    socketConfigOptions.secure = true;
    let socket = io.connect(socketUrl, socketConfigOptions);
    window.socket = socket;
    socket.on('setting_maintenance_socket', (responseData) => {
      let objData = JSON.parse(responseData);
      let userName = '';
      if (getUserDetails()) {
        userName = JSON.parse(getUserDetails()).user_name;
      }
      if ((userName !== 'creid' && userName !== 'spmobileprods') || objData.type !== "state") {
        if (objData.type === "state") {
          window.location.href = objData.state ? "/comingSoon" : "/";
        } else {
          // windows notification
          if (!("Notification" in window)) {
            alert("This browser does not support desktop notifications");
          } else if (window.Notification.permission === "granted") {
            this.triggerNotification(objData);
          } else if (window.Notification.permission !== "denied" && !window.localStorage.getItem('isNotificationEnabled')) {
            window.Notification.requestPermission().then(function (permission) {
              if (permission === "granted") {
                this.triggerNotification(objData);
              }
            });
          }
        }
      }
    });
    socket.on('logout', (responseData) => {
      window.localStorage.clear();
      window.location.href = "/";
    })
  }

  render() {
    let userName = '';
    if (getUserDetails()) {
      userName = JSON.parse(getUserDetails()).user_name;
    }
    if (this.props) {
      const pathName = this.props.location.pathname;
      const url = pathName.split("/")[1];
      if (userName !== 'creid' && userName !== 'spmobileprods') {
        getSettings().then(response => {
          if (response && response.data && response.data.state && url !== 'comingSoon') {
            window.location.href = "/comingSoon";
          }
        })
      }

      return (
        <Connect mapToProps={({ blockUILoading }) => ({ blockUILoading })}>
          {({ blockUILoading }) => {
            return <div>
              <Dialog
                open={this.state.open}
                TransitionComponent={Transition}
                sx={{ bottom: "unset" }}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <img src={AGT_MRN_LOGO_MOBILE} style={{ width: '100px', float: 'left', marginRight: '24px' }} alt="Mobile Logo" />
                  <DialogContentText id="alert-dialog-slide-description">
                    We would like to show you notifications for our latest news and updates
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '0 24px 24px 24px' }}>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={this.handleAllow} color="primary">
                    Allow
                  </Button>
                </DialogActions>
              </Dialog>
              <BlockUi tag="div" blocking={blockUILoading} loader={<FadeLoader height={12} width={5} radius={10} margin={2} css={loaderPositionStyle} color={"#42c0fb"} />}>
                <Router>
                  <Switch>
                    <Route path="/clinic/*" component={ClinicPortalRouter} />
                    <Route path="/lims/*" component={LIMSPortalRouter} />
                    <Route path="/" component={CommonRouter} />
                  </Switch>
                </Router>
              </BlockUi>
              {
                isUserLoggedIn()
                &&
                <>
                  <div id="myNav" className="idle-overlay" style={{ width: this.state.showOverlay ? "100%" : "0%" }}>
                    <div className="overlay-content">
                      <p>Are You Still There ?</p>
                      <button type="button" className="btn btn-danger" onClick={this.handleOverlaySubmit}>Yes</button>
                    </div>
                  </div>

                  <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 30}
                    onAction={this.handleOnAction}
                    onIdle={this.handleOnIdle}
                  />
                </>
              }
            </div>
          }}
        </Connect>
      )
    }
    return null;
  }
};

export default AppContainer;
