import React, { Component } from "react";
import { serviceConstants } from "../../../../services/common/constants";
import { getUserAuthToken } from "../../../../services/common/util";

export default class PdfResultRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfPath:
        props.data && props.data.results &&
          props.data.results.pdf_path &&
          props.data &&
          props.data.results.pdf_path.length > 0
          ? `${serviceConstants.API_HOST_NAME}${props.data && props.data.results.pdf_path}`
          : "",
      result: props.data && props.data.results && props.data.results.value ? props.data.results.value : "",
    };
  }

  render() {
    return (
      <div>
        {
          this.state.pdfPath.length > 0
            ?
            (
              <a href={this.state.pdfPath + '?' + new Date().getTime() + '&token=' + getUserAuthToken()} target="_blank" rel="noreferrer" className="pdf-link">
                <i className="fa fa-file-pdf-o"></i> {this.state.result}
              </a>
            )
            :
            (
              <div>{this.props.data.is_cancel ? "Cancelled Requisition" : this.state.result}</div>
            )
        }
      </div>
    );
  }
}
