import { apiInterfaceForJson } from "../common/apiManager";
import { serviceConstants } from "../common/constants";
import { getUserAuthToken, getUserID } from "../common/util";

export const authenticateAndFetchUserDetails = (username, password) => {
	return apiInterfaceForJson("/user/v1/authenticate", "POST", {
		user_name: username,
		password: password,
	});
};

export const authenticateSecurityNumberAuthentication = (userId, securityNumber) => {
	return apiInterfaceForJson("/user/v1/twoFactorAuthentication", "POST", {
		userId: userId,
		securityNumber: securityNumber,
	});
};

export const sendForgetPasswordEmail = (email, link) => {
	return apiInterfaceForJson("/user/v1/sendForgetPasswordEmail", "POST", {
		email: email,
		link: link,
	});
};

export const resetPassowrd = (userId, forgetPasswordKey, password) => {
	return apiInterfaceForJson("/user/v1/resetPassword", "POST", {
		userId: userId,
		forgetPasswordKey: forgetPasswordKey,
		password: password
	});
};

export const createPassowrd = (userId, createPasswordKey, password) => {
	return apiInterfaceForJson("/user/v1/createPassword", "POST", {
		userId: userId,
		createPasswordKey: createPasswordKey,
		password: password
	});
};

export const logout = (path) => {
	let token = getUserAuthToken();
	window.localStorage.clear()
	return fetch(`${serviceConstants.API_HOST_NAME}/user/v1/logout`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
	}).then((response) => {
		if (path) {
			window.location.href = '/lims';
		} else {
			window.location.href = '/clinic';
		}
		return response.json();
	});
};

export const removeUserDevice = () => {
	let token = getUserAuthToken();
	let userDeviceInfo = {
		'_id': getUserID(),
		'device_id': '',
	};
	window.localStorage.clear();
	return fetch(`${serviceConstants.API_HOST_NAME}/user/v1/updateDevice`, {
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + token
		},
		body: JSON.stringify(userDeviceInfo)
	});
};

