import React, { Component } from "react";
import Header from "../components/header";
import Footer from "../components/footer";

class CommonLayout extends Component {
    render() {
        return <div className="root-div">
            <Header />
            <div className="full-wrap">
                {this.props.children}
            </div>
            <Footer />
        </div>
    }
}

export default CommonLayout;