import moment from "moment";
import React, { Component, memo } from "react";
import { Modal, Button } from "react-bootstrap";
import { dateComparator, getUserRole } from "../../../../services/common/util";
import { AgGridReact } from "ag-grid-react";
import OrderEditBtnCellRenderer from "../../orders/clinicOrderGrid/editBtnCellRenderer";
import RequisitionEditBtnCellRenderer from "../../requisitions/clinicRequisitionGrid/editBtnCellRenderer";
import PdfResultRenderer from "../../orders/clinicOrderGrid/pdfResultRenderer";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { fetchPatientDataById, fetchPatientExpandableData } from "../../../../services/clinicPortalServices/patientSearchService";
import ImageBtnCellRenderer from "../../../common/imageBtnCellRenderer";

export default class PatientViewPopup extends Component {
    constructor(props) {
        super(props);
        const context = props.context;
        this.state = {
            patientDetails: {},
            defaultColDef: {
                flex: 1,
                filter: true,
                sortable: true,
                resizable: true
            },
            colDefsForOrder: [
                {
                    headerName: "Actions",
                    minWidth: 150,
                    maxWidth: 250,
                    cellStyle: { textAlign: "center" },
                    cellRenderer: memo(params => {
                        if (params.data.results && params.data.results.value) {
                            return <OrderEditBtnCellRenderer
                                params={params}
                                data={params.data}
                                context={context}
                                type={"patientViewPopup"}
                            />;
                        } else {
                            return <RequisitionEditBtnCellRenderer
                                params={params}
                                data={params.data}
                                context={context}
                                type={"patientViewPopup"}
                            />;
                        }
                    }),
                    resizable: true,
                    hide: /audit/.test(getUserRole().toLowerCase()) ? true : false
                },
                {
                    headerName: "Requisition",
                    field: "lab_order_id",
                    resizable: true,
                },
                {
                    headerName: "Test",
                    field: "test_id.name",
                    resizable: true,
                },
                {
                    headerName: "Test Type",
                    field: "test_info.test_type",
                    resizable: true,
                },
                {
                    headerName: "Sample",
                    resizable: true,
                    field: "test_info.sample",
                },
                {
                    headerName: "Result",
                    resizable: true,
                    field: "results.value",
                    cellRenderer: "pdfResultRenderer",
                },
                {
                    headerName: "Specimen Collected Date",
                    field: "test_info.collected",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function (params) {
                        return params.data.test_info && params.data.test_info.collected ? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Physician",
                    minWidth: 120,
                    resizable: true,
                    valueGetter: function addColumns(params) {
                        if (params.data.provider) {
                            return (params.data.provider.first_name + " " + params.data.provider.last_name);
                        } else {
                            return "";
                        }
                    },
                },
                {
                    headerName: "Received Date",
                    field: "test_info.received",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function (params) {
                        return params.data.test_info && params.data.test_info.received
                            ? moment(
                                params.data.test_info.received,
                                "YYYYMMDDHHmmss"
                            ).format("MM/DD/YYYY hh:mm A")
                            : "";
                    },
                    comparator: dateComparator
                },
                {
                    headerName: "Released Date",
                    field: "results.released",
                    minWidth: 150,
                    resizable: true,
                    valueGetter: function (params) {
                        return params.data.results && params.data.results.released
                            ? moment(
                                params.data.results.released,
                                "YYYYMMDDHHmmss"
                            ).format("MM/DD/YYYY hh:mm A")
                            : "";
                    },
                    comparator: dateComparator
                },
            ],
            colDefsForInsurance: [
                {
                    headerName: 'Insurance Provider',
                    resizable: true,
                    minWidth: 150,
                    field: "insurance_provider",
                },
                {
                    headerName: 'Insurance Member ID',
                    resizable: true,
                    minWidth: 150,
                    field: "insured_member_id",
                },
                {
                    headerName: 'Insurance Group Number',
                    resizable: true,
                    minWidth: 150,
                    field: "insured_group_number",
                },
                {
                    headerName: 'Relation to Insured',
                    resizable: true,
                    minWidth: 150,
                    field: "relation_to_insured",
                },
                {
                    headerName: 'Insurance Holder Name',
                    resizable: true,
                    minWidth: 150,
                    field: "insured_first_name",
                    valueGetter: function addColumns(params) {
                        if (params.data && params.data.insured_first_name) {
                            return params.data.insured_first_name + " " + params.data.insured_last_name;
                        }
                    },
                },
                {
                    headerName: 'Social Security Number',
                    resizable: true,
                    minWidth: 150,
                    field: "social_security_number",
                },
                {
                    headerName: 'Active',
                    resizable: true,
                    minWidth: 150,
                    valueGetter: function addColumns(params) {
                        if (params.data && params.data.is_active) {
                            return "Active";
                        } else {
                            return "Inactive"
                        }
                    },
                },
                {
                    headerName: 'Reason',
                    resizable: true,
                    minWidth: 150,
                    valueGetter: function addColumns(params) {
                        if (params.data && !params.data.is_active && params.data.reason_inactive) {
                            return params.data.reason_inactive;
                        }
                    },
                },
                {
                    headerName: "Insurance Front Image",
                    field: "images.insurance_front",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo(params => {
                        if (params.data && params.data.images && params.data.images.insurance_front) {
                            return <ImageBtnCellRenderer insurance={params.data} patient={params.data.patient} type='insurance_front' />
                        }
                        return <div></div>
                    })
                },
                {
                    headerName: "Insurance Back Image",
                    field: "images.insurance_back",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo(params => {
                        if (params.data && params.data.images && params.data.images.insurance_back) {
                            return <ImageBtnCellRenderer insurance={params.data} patient={params.data.patient} type='insurance_back' />
                        }
                        return <div></div>
                    })
                },
                {
                    headerName: "Driver License Image",
                    field: "patient.driver_license_image",
                    minWidth: 200,
                    sortable: true,
                    resizable: true,
                    cellRenderer: memo(params => {
                        if (params.data && params.data.patient && params.data.patient.driver_license_image) {
                            return <ImageBtnCellRenderer patient={params.data.patient} insurance={{}} type='driver_license' />
                        }
                        return <div></div>
                    })
                },
            ],
            rowDataForOrder: [],
            rowDataForInsurance: [],
            components: {
                OrderEditBtnCellRenderer: OrderEditBtnCellRenderer,
                requisitionEditBtnCellRenderer: RequisitionEditBtnCellRenderer,
                pdfResultRenderer: PdfResultRenderer
            },
            paginationNumberFormatter: function (params) {
                return "[" + params.value.toLocaleString() + "]";
            },
            context: { componentParent: this }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && this.props.show !== prevProps.show) {
            fetchPatientDataById(this.props.data._id).then((res) => {
                if (res.data && res.data.length && res.data[0]) {
                    this.setState({ patientDetails: res.data[0], rowDataForInsurance: res.data[0].patient_insurance_ids });
                }
            })
            fetchPatientExpandableData(this.props.data._id).then((response) => {
                this.setState({ rowDataForOrder: response.data });
            }).catch((error) => {
            });
        }
    }

    render() {
        return (
            <ModalStyled
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Patient Full Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.patientDetails && this.state.patientDetails._id
                        &&
                        <div>
                            <div className="row">
                                <h4 className="col-12">Patient Information</h4>
                                <div className="col-md-6">
                                    <div><b>First Name:</b> {this.state.patientDetails.first_name}</div>
                                    <div><b>Last Name:</b> {this.state.patientDetails.last_name}</div>
                                    <div><b>Address 1:</b> {this.state.patientDetails.address?.address1}</div>
                                    <div><b>Address 2:</b> {this.state.patientDetails.address?.address2}</div>
                                    <div><b>City:</b> {this.state.patientDetails.address?.city}</div>
                                    <div><b>State:</b> {this.state.patientDetails.address?.state}</div>
                                    <div><b>Zip Code:</b> {this.state.patientDetails.address?.zip}</div>
                                    <div><b>Email Address:</b> {this.state.patientDetails.email}</div>
                                    <div><b>Phone #:</b> {this.state.patientDetails.mobile}</div>
                                    <div><b>Driver License:</b> {this.state.patientDetails.driver_license}</div>
                                    <div><b>Internal Note:</b> {this.state.patientDetails.internal_note}</div>
                                </div>
                                <div className="col-md-6">
                                    <div><b>MRN:</b> {this.state.patientDetails.mrn}</div>
                                    <div><b>Date of Birth:</b> {moment(this.state.patientDetails.date_of_birth).format("MM/DD/YYYY")}</div>
                                    <div><b>Gender:</b> {this.state.patientDetails.gender}</div>
                                    <div><b>Patient System ID:</b> {this.state.patientDetails._id}</div>
                                    <div><b>Associated Facility:</b> {this.state.patientDetails.facility_id?.name}</div>
                                    <div><b>Ethnicity:</b> {this.state.patientDetails.ethnicity}</div>
                                    <div><b>Race:</b> {this.state.patientDetails.race}</div>
                                    <div><b>Pregnancy:</b> {this.state.patientDetails.is_pregnant ? "Yes" : "No"}</div>
                                    <div><b>Passport:</b> {this.state.patientDetails.passport}</div>
                                    <div><b>Custom Info:</b> {this.state.patientDetails.custom_info}</div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <h4 className="col-12">Patient Orders</h4>
                                {
                                    this.state.rowDataForOrder && this.state.rowDataForOrder.length > 0
                                    &&
                                    <ThemeContext.Consumer>
                                        {({ themeName }) => (
                                            <div className="col-12">
                                                <div
                                                    id="currentListGrid"
                                                    style={{
                                                        height: "250px",
                                                        width: "100%",
                                                        marginBottom: "20px"
                                                    }}
                                                    className={themeName === "Light" ? "ag-theme-alpine col-12" : "ag-theme-alpine-dark col-12"}
                                                >
                                                    <AgGridReact
                                                        columnDefs={this.state.colDefsForOrder}
                                                        defaultColDef={this.state.defaultColDef}
                                                        masterDetail={true}
                                                        rowData={this.state.rowDataForOrder}
                                                        pagination={true}
                                                        paginationPageSize={20}
                                                        paginationNumberFormatter={this.state.paginationNumberFormatter}
                                                        context={this.state.context}
                                                        components={this.state.components}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </ThemeContext.Consumer>
                                }
                            </div>
                            <hr />
                            <div className="row">
                                <h4 className="col-12">Patient Insurance</h4>
                                {
                                    this.state.rowDataForInsurance && this.state.rowDataForInsurance.length > 0
                                    &&
                                    <ThemeContext.Consumer>
                                        {({ themeName }) => (
                                            <div className="col-12">
                                                <div
                                                    id="currentListGrid"
                                                    style={{
                                                        height: "250px",
                                                        width: "100%",
                                                        marginBottom: "20px"
                                                    }}
                                                    className={themeName === "Light" ? "ag-theme-alpine col-12" : "ag-theme-alpine-dark col-12"}
                                                >
                                                    <AgGridReact
                                                        columnDefs={this.state.colDefsForInsurance}
                                                        defaultColDef={this.state.defaultColDef}
                                                        masterDetail={true}
                                                        rowData={this.state.rowDataForInsurance}
                                                        pagination={true}
                                                        paginationPageSize={20}
                                                        paginationNumberFormatter={this.state.paginationNumberFormatter}
                                                        context={this.state.context}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </ThemeContext.Consumer>
                                }
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.props.handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalStyled >
        );
    }
}
