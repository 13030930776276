import React, { Component } from "react";
import { TextField } from "@mui/material";
import { getVipPaymentDataById, submitSampleForVip } from "../../../../services/clinicPortalServices/vipPaymentsServices";
import { sendEmailTemplate } from "../../../../services/common/service";
import { templateIds } from "../../../../services/common/constants";
import moment from 'moment';
import toastr from 'toastr';
import Swal from "sweetalert2";

class InputSampleForVIP extends Component {
  constructor(props) {
    super(props);
    const vipPaymentId = props.match.params.vipPaymentId;
    this.state = {
      errors: [],
      vipPaymentId: vipPaymentId,
      name: "",
      dateOfBirth: "",
      email: "",
      mobile: "",
      sample: "",
      disableForm: false,
    }

    this.getVipPaymentData(vipPaymentId);
  }

  getVipPaymentData = (vipPaymentId) => {
    getVipPaymentDataById(vipPaymentId).then(response => {
      if (response.data) {
        const details = response.data;
        if (details.sample) {
          this.setState({
            sample: details.sample,
            disableForm: true
          })
        }
        this.setState({
          name: details.first_name + " " + details.last_name,
          dateOfBirth: moment(details.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY"),
          email: details.email,
          mobile: details.mobile,
          date: moment(details.date, "YYYYMMDD").format("MM/DD/YYYY") + " " + details.ampm.toUpperCase()
        })
      } else {
        this.props.history.push("/");
      }
    })
  }

  hasError = (key) => {
    return this.state.errors.indexOf(key) !== -1;
  };

  handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    this.setState({ [key]: value });
  };

  submitSampleId = () => {
    if (!this.state.sample) {
      this.setState({ errors: ['sample'] });
    } else {
      this.setState({ errors: [] });
      const data = {
        id: this.state.vipPaymentId,
        sample: this.state.sample
      }
      submitSampleForVip(data).then(response => {
        if (response.RESULT === 'SUCCESS') {
          Swal.fire({
            customClass: {
              container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
            },
            title: 'Success!',
            icon: 'success',
            html: `We have found your record and updated your order to VIP Status!`,
            confirmButtonText: 'OK',
          }).then((result) => {
            this.getVipPaymentData(this.state.vipPaymentId);
          })
        } else {
          if (response.message === "Sample_Not_Found") {
            Swal.fire({
              title: 'No Sample Matched',
              html: `You provided: <b>` + this.state.sample + `</b><br/>It couldn't be found.  Double check the "Requisition Creation" email we sent you with your sample ID.
              <br/>If you believe it was correct,  click Report.`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Report',
              cancelButtonText: 'Try Again',
              customClass: {
                container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
                cancelButton: 'order-1',
                confirmButton: 'order-2'
              }
            }).then((result) => {
              if (result.isConfirmed) {
                let bodyTemplate = {
                  subject: "VIP Help!",
                  name: this.state.name,
                  email: this.state.email,
                  mobile: this.state.mobile,
                  date_of_birth: this.state.dateOfBirth,
                  date: this.state.date,
                  sample: this.state.sample.toString(),
                  emailTxt: "Hey Gerald,<br/><br/>A VIP patient has tried entering their sample ID, but did not match. Please see the info below.<br/><br/>Name: " + this.state.name + "<br/>Date Of Birth: " + this.state.dateOfBirth + "<br/>Email: " + this.state.email + "<br/>Mobile: " + this.state.mobile + "<br/>Chosen Time: " + this.state.date + "<br/><br/>Sample ID provided: " + this.state.sample.toString() + "<br/><br/>Thanks,<br/>The MyResultNow Team"
                }
                const emailDataTemplate = {
                  toEmail: "wduong@americangene.com",
                  subject: "VIP Help!",
                  body: bodyTemplate,
                  templateId: templateIds.htmlEmailWithLogo
                }
                sendEmailTemplate(emailDataTemplate).then(response => {
                  toastr.success("Reported Successfully");
                })
              }
            })
          }
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className="content login-content">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-md-9">
                <div className="card row-bg-color ">
                  <div className="card-body">
                    <div className="card-name">
                      <h2 className="card-title">VIP Confirmation - Sample ID Entry</h2>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="card-info">
                          Please enter your sample ID to confirm you had your swab and to enable VIP status on this specific test you just had at the location you visited.  By clicking the link in the email, this information should match what you entered.
                        </p>
                        <p className="card-info">
                          Name: {this.state.name}
                        </p>
                        <p className="card-info">
                          Date of Birth: {this.state.dateOfBirth}
                        </p>
                        <p className="card-info">
                          Email: {this.state.email}
                        </p>
                        <p className="card-info">
                          Mobile: {this.state.mobile}
                        </p>
                        <p className="card-info">
                          Chosen Time: {this.state.date}
                        </p>
                        <br />
                        <p className="card-info">
                          {
                            this.state.disableForm ? "You already submitted sample ID on this form." : 'Please enter the sample ID in the form below and click "Submit Sample ID".'
                          }
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          label="Sample ID"
                          variant="outlined"
                          value={this.state.sample}
                          name="sample"
                          onChange={this.handleChange}
                          style={{ backgroundColor: "white" }}
                          disabled={this.state.disableForm}
                        />
                        <div
                          className={this.hasError("sample") ? "inline-errormsg" : "hidden"}
                        >
                          <i className="fa fa-exclamation-circle" aria-hidden="true">
                            &nbsp;This field is required.
                          </i>
                        </div>
                      </div>
                    </div>
                    <div className="row next-button btn-patientinfo-next">
                      <div className="col-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.submitSampleId}
                          style={{ height: "50px" }}
                          disabled={this.state.disableForm}
                        >
                          Submit Sample ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InputSampleForVIP;
