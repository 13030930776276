import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import MaintenanceDetails from "./maintenanceDetails";
import Swal from "sweetalert2";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";
import { deleteMaintenanceSlot } from "../../../../services/clinicPortalServices/maintenanceSlotService";
import { getUserRole } from "../../../../services/common/util";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		};
	}

	handleShow = () => {
		this.setState({ show: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	};

	handleDelete = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this maintenance slot!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it',
			customClass: {
				container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
				cancelButton: 'order-1',
				confirmButton: 'order-2'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				deleteMaintenanceSlot({ _id: this.props.data._id, name: this.props.data.item_name }).then(res => {
					this.props.context.componentParent.loadGridData();
				})
			}
		});
	};

	handleClose = () => {
		this.setState({ show: false });
	};

	renderTooltipEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Update Maintenance
		</Tooltip>
	);

	renderTooltipDelete = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Delete Maintenance
		</Tooltip>
	);

	render() {
		return (
			<div>
				<div>
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipEdit}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fas fa-pen"></i>
						</button>
					</OverlayTrigger>
					{
						getUserRole() && getUserRole() !== "LabTech" && !this.props.data.completed_time
						&&
						<OverlayTrigger
							placement="top"
							delay={{ show: 50, hide: 100 }}
							overlay={this.renderTooltipDelete}
						>
							<button onClick={this.handleDelete} className="edit-order-btn">
								<i className="fas fa-trash"></i>
							</button>
						</OverlayTrigger>
					}
				</div>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					dialogClassName="removeTabIndexModal"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.data.completed_time ? "View Maintenance" : "Complete Maintenance"}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<MaintenanceDetails
							context={this.props.context}
							handleClose={this.handleClose}
							maintenanceEventDetails={this.props.data.maintenanceEvent_id}
							maintenanceSlotDetails={this.props.data}
							type={"slot"}
						/>
					</Modal.Body>
				</ModalStyled>
			</div>
		);
	}
}
