import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
import SearchMenu from "./searchMenu";
import { dateComparator, defaultExcelExportParams, getUserDetails } from "../../../../services/common/util";
//service calls
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import moment from "moment";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { getBrooksLimsRecordData } from "../../../../services/clinicPortalServices/brooksLimsRecordService";

class ClinicBrooksLimsRecordGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchFilters: {
				from_date: moment().startOf('week').format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
			},
			columnDefs: [
				{
					headerName: "Lab Order Id",
					minWidth: 200,
					field: "lab_order_id",
				},
				{
					headerName: "Test Name",
					minWidth: 200,
					field: "test_info.test_name",
				},
				{
					headerName: "Sample",
					minWidth: 200,
					field: "test_info.sample",
				},
				{
					headerName: "Test Type",
					minWidth: 200,
					field: "test_info.test_type",
				},
				{
					headerName: "Status",
					minWidth: 200,
					valueGetter: params => {
						if (params.data.result.released) {
							return "Released";
						} else {
							if (params.data.is_deleted) {
								return "Deleted";
							} else {
								return params.data.status;
							}
						}
					},
				},
				{
					headerName: "Collected Time",
					minWidth: 200,
					valueGetter: params => {
						if (params.data.test_info.collected) {
							return moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Received Time",
					minWidth: 200,
					valueGetter: params => {
						if (params.data.test_info.received) {
							return moment(params.data.test_info.received, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Released Time",
					minWidth: 200,
					valueGetter: params => {
						if (params.data.result.released) {
							return moment(params.data.result.released, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A");
						}
					},
					comparator: dateComparator
				},
				{
					headerName: "Patient Name",
					minWidth: 200,
					valueGetter: params => {
						return params.data.patient.first_name + " " + params.data.patient.last_name
					}
				},
				{
					headerName: "Patient Date of Birth",
					minWidth: 200,
					field: "patient.date_of_birth",
					comparator: dateComparator
				},
				{
					headerName: "Patient Gender",
					minWidth: 200,
					field: "patient.gender",
				},
				{
					headerName: "Patient Email",
					minWidth: 200,
					field: "patient.email",
				},
				{
					headerName: "Patient Phone Number",
					minWidth: 200,
					field: "patient.phone_number",
				},
				{
					headerName: "Patient mrn",
					minWidth: 200,
					field: "patient.mrn",
				},
				{
					headerName: "Patient Address",
					minWidth: 200,
					field: "patient.address",
				},
				{
					headerName: "Facility",
					minWidth: 200,
					field: "facility"
				},
				{
					headerName: "Physician Name",
					minWidth: 200,
					valueGetter: params => {
						return params.data.physician.first_name + " " + params.data.physician.last_name
					}
				},
				{
					headerName: "Physician NPI",
					minWidth: 200,
					field: "physician.npi"
				},
				{
					headerName: "Collected By",
					minWidth: 200,
					field: "test_info.collectedBy"
				},
				{
					headerName: "Received By",
					minWidth: 200,
					field: "test_info.receivedBy"
				},
				{
					headerName: "Released By",
					minWidth: 200,
					field: "result.releasedBy"
				},
			],

			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true,
			},
			rowData: [],
		};
	}
	getRowHeight = (params) => {
		if (params.data.update_count && params.data.update_count > 0) {
			return params.api.getSizesForCurrentTheme().rowHeight * (params.data.update_count + 1);
		} else {
			return false;
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		this.gridApi.showLoadingOverlay();
		const filter = {
			createdAt: {
				$gte: this.state.searchFilters.from_date,
				$lt: this.state.searchFilters.to_date,
			}
		}
		getBrooksLimsRecordData(filter)
			.then((response) => {
				let data = response.data;
				this.setState({ rowData: data });
				if (!data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			})
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	clearFilter = () => {
		let filters = this.state.searchFilters;
		filters.from_date = moment().subtract(7, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		filters.dateRange = 'week';
		this.setState({ searchFilters: filters });

		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
		this.loadGridData();
	};

	handleDateFiltersChange = (dateTime, type) => {
		const filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setState({ searchFilters: filters });
			this.loadGridData();
		}
	}

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.BrooksLIMSRecordGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.BrooksLIMSRecordGridExported
		};
		createAudit(auditData);
	};

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu
					handleDateFiltersChange={this.handleDateFiltersChange}
					from_date={this.state.searchFilters.from_date}
					to_date={this.state.searchFilters.to_date}
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									getRowHeight={this.getRowHeight}
									defaultExcelExportParams={defaultExcelExportParams}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicBrooksLimsRecordGrid;
