import React, { Component } from "react";
import { serviceConstants } from "../../../../services/common/constants";
import { getUserAuthToken } from "../../../../services/common/util";

export default class RequisitionPdfRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfPath: props.data && props.data.requisition_pdf_path
          ? `${serviceConstants.API_HOST_NAME}/files/requisitionPDF/${props.data.requisition_pdf_path}`
          : "",
    };
  }

  render() {
    return (
      <div>
        {this.state.pdfPath.length > 0 ? (
          <a href={this.state.pdfPath + '?' + new Date().getTime() + '&token=' + getUserAuthToken()} target="_blank" rel="noreferrer" className="pdf-link">
            <i className="fa fa-file-pdf-o"></i> {this.props.data.requisition_pdf_path}
          </a>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
