import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import 'moment-timezone';
import readXlsxFile from 'read-excel-file';

import EditBtnCellRenderer from "./editBtnCellRenderer";
import RequisitionPdfRenderer from "./requisitionPdfRenderer";
import CreateRequisition from "./createRequisition";

//service calls
import {
	getRequisitions,
} from "../../../../services/clinicPortalServices/orderSearchService";
import { bulkDownloadZipForPDF } from "../../../../services/clinicPortalServices/orderEditService";
import { fetchFacilitiesForOrders } from "../../../../services/clinicPortalServices/facilityServices";
import { updatePatientData } from "../../../../services/clinicPortalServices/patientEditService";
import { patientSignup } from "../../../../services/patientSignupServices/patientSignupFormService";
import { saveRequisitionChanges, createRequisitionPDF, notifyGrubbsUploaded, notifyBrowserGrubbsUploaded } from "../../../../services/clinicPortalServices/requisitionService";
import { serviceConstants, auditEnums } from "../../../../services/common/constants";
import { getUserRole, processCellForClipboard, defaultExcelExportParams, abortApiCalls, releaseApiCalls, getUserDetails, getUserAuthToken, isAllowedReducedGridView } from "../../../../services/common/util";
import RequisitionSearchMenu from "./requisitionSearchMenu";
import PatientBtnCellRenderer from "../../orders/clinicOrderGrid/patientBtnCellRenderer";
import { getViewSettings, saveViewDefault } from "../../../../services/clinicPortalServices/userViewSettings";
import { settingConstants } from "../../../../services/common/optionsData";
import toastr from "toastr";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import Swal from "sweetalert2";
import _ from 'lodash';
import SampleTrackingCellRenderer from "../../orders/clinicOrderGrid/sampleTrackingCellRenderer";
import { getPlateList } from "../../../../services/limsPortalServices/plateService";
import CancelReasonComponent from "../../requisitionsNew/clinicRequisitionNewGrid/CancelReasonComponent";
import SymptomRenderer from "../../orders/clinicOrderGrid/symptomRenderer";
import NoticeHistoryCellRenderer from "../../orders/clinicOrderGrid/noticeHistoryCellRenderer";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { getAllTests } from "../../../../services/clinicPortalServices/testService";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import store from "../../../../redux/store";
import actions from "../../../../redux/actions";
import { bindActions } from "redux-zero/utils";

const boundActions = bindActions(actions, store);

export const RequisitionGridContext = React.createContext();

class ClinicRequisitionGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isArchived: false,
			isCancelled: false,
			isReducedGridView: true,
			user_role: getUserRole(),
			searchFilters: {
				facility_id: [],
				from_date: moment().format("YYYY-MM-DD") + 'T00:00',
				to_date: moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00',
				dateRange: 'today'
			},
			gridName: "Default",
			pageSize: "",
			columnDefs: [
				{
					headerName: "Actions",
					minWidth: 100,
					maxWidth: 100,
					cellStyle: { textAlign: "center" },
					cellRenderer: "editBtnCellRenderer",
				},
				{
					headerName: 'Requisition Information',
					children: [
						{
							headerName: "Requisition",
							minWidth: 50,
							resizable: true,
							field: "lab_order_id"
						},
						{
							headerName: "Patient Name",
							minWidth: 50,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.patient_id) {
									return (
										params.data.patient_id.first_name +
										" " +
										params.data.patient_id.last_name
									);
								} else {
									return "";
								}
							},
							cellRenderer: 'patientBtnCellRenderer'
						},
						{
							headerName: "DOB",
							minWidth: 150,
							valueGetter: function addColumns(params) {
								return params.data.patient_id && params.data.patient_id.date_of_birth
									? moment(
										params.data.patient_id.date_of_birth,
										"YYYY-MM-DD"
									).format("MM/DD/YYYY")
									: "";
							},
							field: "patient_id.date_of_birth",
							columnGroupShow: 'open',
							resizable: true,
						},
						{
							headerName: "MRN",
							minWidth: 150,
							field: "patient_id.mrn",
							columnGroupShow: 'open',
							resizable: true,
						},
						{
							headerName: "Test",
							minWidth: 150,
							field: "test_id.name",
							resizable: true,
						},
						{
							headerName: "Test Type",
							minWidth: 150,
							field: "test_info.test_type",
							columnGroupShow: 'open',
							resizable: true,
						},
						{
							headerName: "Sample ID",
							minWidth: 180,
							field: "test_info.sample",
							cellRenderer: memo(params => {
								if (params.data.test_info && params.data.test_info.sample) {
									const url = serviceConstants.API_HOST_NAME + "/files/barcodeImagePDF/" + params.data.test_info.sample + ".pdf?" + new Date().getTime().toString() + "&token=" + getUserAuthToken();
									return <div>{params.data.test_info.sample}&nbsp;&nbsp;<a href={url} target='_blank' rel="noreferrer" style={{ fontSize: "20px", cursor: "pointer" }}><i className='fa fa-barcode'></i></a></div>;
								}
								return <div></div>
							}),
							resizable: true
						},
						{
							headerName: "Driver License",
							minWidth: 150,
							field: "patient_id.driver_license",
							resizable: true
						},
						{
							headerName: "Requisition PDF",
							minWidth: 150,
							field: "requisition_pdf_path",
							cellRenderer: "requisitionPdfRenderer",
							resizable: true
						},
						{
							headerName: "Facility",
							minWidth: 150,
							resizable: true,
							field: "facility_id.name",
						},
						{
							headerName: "Physician",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.provider) {
									return (
										params.data.provider.first_name +
										" " +
										params.data.provider.last_name
									);
								} else {
									return "";
								}
							},
						},
						{
							colId: "collected_date",
							headerName: "Specimen Collected Date",
							minWidth: 200,
							resizable: true,
							field: "test_info.collected",
							cellRenderer: function (params) {
								return params.data.test_info && params.data.test_info.collected ? moment(params.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A") : "";
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Collector Name",
							field: "test_info.collector_name",
							minWidth: 200,
							resizable: true,
							columnGroupShow: 'open'
						},
						{
							headerName: "Diagnosis Code",
							minWidth: 150,
							resizable: true,
							valueGetter: function addColumns(params) {
								if (params.data.diagnosis_code && params.data.diagnosis_code.length) {
									return params.data.diagnosis_code.join(', ');
								} else {
									return "";
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: "Passport",
							minWidth: 150,
							resizable: true,
							field: "patient_id.passport",
							columnGroupShow: 'open'
						},
						{
							headerName: "Is HL7?",
							resizable: true,
							valueGetter: function addColumns(params) {
								return params.data.is_HL7_requisition ? "YES" : "";
							},
						},
						{
							headerName: "Is VIP Order?",
							resizable: true,
							valueGetter: function addColumns(params) {
								return params.data.is_vip_order ? "YES" : "NO";
							},
						},
						{
							headerName: "Sample Tracking",
							resizable: true,
							cellRenderer: "sampleTrackingCellRenderer",
							valueGetter: params => {
								if (params.data.sample_tracking && params.data.sample_tracking.length) {
									let eventName = params.data.sample_tracking[params.data.sample_tracking.length - 1].eventName;
									if (eventName === "IMPORT - FOR RETEST") {
										if (["Positive SARS-CoV-2", "SARS-CoV-2 POSITIVE"].includes(params.data.sample_tracking[params.data.sample_tracking.length - 1].result)) {
											eventName += " (Positive)"
										} else {
											eventName += " (Inconclusive / Invalid)"
										}
									}
									return eventName;
								} else {
									return "No Info"
								}
							}
						},
						{
							headerName: "Patient Symptoms",
							resizable: true,
							minWidth: 150,
							field: "symptoms",
							cellStyle: { textAlign: "center" },
							cellRenderer: "symptomRenderer",
						},
						{
							headerName: "Order Notice History",
							resizable: true,
							minWidth: 150,
							field: "noticeHistory",
							cellStyle: { textAlign: "center" },
							cellRenderer: "noticeHistoryCellRenderer",
							valueGetter: params => {
								if (params.data.noticeHistory && params.data.noticeHistory.length) {
									return params.data.noticeHistory.length + " Log" + (params.data.noticeHistory.length > 1 ? "s" : "");
								} else {
									return "No History";
								}
							}
						},
						{
							headerName: "Is Cancelled?",
							resizable: true,
							field: "is_cancel",
							valueGetter: function addColumns(params) {
								return params.data.is_cancel ? "YES" : "NO";
							},
						},
						{
							headerName: "Reason for cancellation",
							resizable: true,
							field: "cancel_reason",
							cellStyle: { textAlign: "center" },
							cellRenderer: "CancelReasonComponent",
						}
					]
				},
				{
					headerName: 'Insurance Information',
					children: [
						{
							headerName: 'YES/NO',
							resizable: true,
							minWidth: 100,
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id._id) {
									return "YES";
								} else {
									return "NO";
								}
							}
						},
						{
							headerName: 'Insurance Provider',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insurance_provider",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Member ID',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_member_id",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Group Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.insured_group_number",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Relation to Insured',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.relation_to_insured",
							columnGroupShow: 'open'
						},
						{
							headerName: 'Insurance Holder Name',
							resizable: true,
							minWidth: 150,
							valueGetter: function addColumns(params) {
								if (params.data.patient_insurance_id && params.data.patient_insurance_id.insured_first_name) {
									return params.data.patient_insurance_id.insured_first_name + " " + params.data.patient_insurance_id.insured_last_name;
								}
							},
							columnGroupShow: 'open'
						},
						{
							headerName: 'Social Security Number',
							resizable: true,
							minWidth: 150,
							field: "patient_insurance_id.social_security_number",
							columnGroupShow: 'open'
						},
					]
				}
			],
			components: {
				editBtnCellRenderer: EditBtnCellRenderer,
				requisitionPdfRenderer: RequisitionPdfRenderer,
				patientBtnCellRenderer: PatientBtnCellRenderer,
				sampleTrackingCellRenderer: SampleTrackingCellRenderer,
				CancelReasonComponent: CancelReasonComponent,
				symptomRenderer: SymptomRenderer,
				noticeHistoryCellRenderer: NoticeHistoryCellRenderer,
			},
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
				resizable: true,
			},
			rowClassRules: {
				'cancelled-requisition': function (params) {
					return params.data.is_cancel;
				},
				'passed-24hours-requisition': function (params) {
					return !params.data.is_cancel && params.data.passed_24hours && !(params.data.sample_tracking && params.data.sample_tracking.filter(item => item.eventName === "ACCESSIONED").length);
				},
			},
			rowData: [],
			context: { componentParent: this },
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			facilities: [],
			allFacilities: [],
			showCreateRequisition: false,
			plateList: [],
			tests: [],
			testIds: [],
			allSingleTests: []
		};

		getPlateList().then(res => {
			if (res && res.data) {
				this.setState({ plateList: res.data })
			}
		})
		getAllTests({ type: "all" }).then(res => {
			if (res && res.data) {
				let testIds = res.data.map(test => {
					return test._id;
				})
				this.testIds = testIds;
				this.setState({ tests: res.data, testIds: testIds }, () => {
					this.loadFacilities(this.state.isArchived, this.state.isCancelled);
					this.loadAllFacilities();
				})
			}
		})
		getAllTests({ type: "single" }).then(res => {
			this.setState({ allSingleTests: res.data });
		})
	}

	loadAllFacilities = () => {
		fetchFacilitiesForOrders().then((res) => {
			this.setState({ allFacilities: res.data });
		})
	}
	loadFacilities = (isArchived, isCancelled) => {
		let filters = this.state.searchFilters;
		let facilities = this.state.facilities;
		let showCancelled = isCancelled;
		let isAllFacilities = false;
		if (filters.facility_id.length > 0 && filters.facility_id.length === facilities.length && facilities.length > 0) {
			isAllFacilities = true;
		}

		if (this.props.match.params.type !== "view") {
			if (this.props.match.params.type === 'cancelled') {
				showCancelled = true;
				filters.from_date = moment().subtract(2, "days").format("YYYY-MM-DD") + 'T00:00';
				filters.to_date = moment().subtract(1, "days").format("YYYY-MM-DD") + 'T00:00';
			} else {
				filters.from_date = moment().subtract(1, "days").format("YYYY-MM-DD") + 'T00:00';
				filters.to_date = moment().format("YYYY-MM-DD") + 'T00:00';
			}
		} else {
			if (window.localStorage.getItem('FROM_DATE_RANGE')) {
				filters.from_date = window.localStorage.getItem('FROM_DATE_RANGE');
			}
			if (window.localStorage.getItem('TO_DATE_RANGE')) {
				filters.to_date = window.localStorage.getItem('TO_DATE_RANGE');
			}
		}

		getCompanyWithFacility(isArchived)
			.then((response) => {
				let facilityIds = [];
				facilities = response.data;
				if (facilities.length > 0) {
					facilities.map(fac => {
						facilityIds.push(fac._id);
						return null;
					})
					this.facilityIds = facilityIds;
					filters.facility_id = facilityIds;
				} else {
					filters.facility_id = facilities[0]._id;
				}
				if (!isAllFacilities && window.localStorage.getItem('FACILITY_ID') && JSON.parse(window.localStorage.getItem('FACILITY_ID')).length) {
					filters.facility_id = JSON.parse(window.localStorage.getItem('FACILITY_ID'))
				}
				this.setDateRange(filters);
				this.setState({ searchFilters: filters, facilities: facilities, isCancelled: showCancelled }, () => {
					this.loadGridData(showCancelled);
				});
			})
	};

	updateDateRange = (dateRange) => {
		let filters = this.state.searchFilters;
		if (filters.dateRange !== dateRange) {
			switch (dateRange) {
				case 'today':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'week':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('week').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				case 'month':
					filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
					filters.from_date = moment().startOf('month').format("YYYY-MM-DD") + 'T00:00';
					filters.dateRange = dateRange;
					break;
				default:
					break;
			}
			this.setState({ searchFilters: filters });
			if (dateRange !== 'custom') {
				this.loadGridData(this.state.isCancelled);
			}
		}

	}

	setDateRange = (searchFilters) => {
		if (moment(searchFilters.to_date).format("YYYY-MM-DD") === moment().add(1, "days").format("YYYY-MM-DD")) {
			if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'today';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('week').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'week';
			} else if (moment(searchFilters.from_date).format("YYYY-MM-DD") === moment().startOf('month').format("YYYY-MM-DD")) {
				searchFilters.dateRange = 'month';
			} else {
				searchFilters.dateRange = 'custom';
			}
		} else {
			searchFilters.dateRange = 'custom';
		}
	}

	handleDateFiltersChange = (dateTime, type) => {
		let filters = this.state.searchFilters;
		let filterIsValid = true;
		if (type === 'from_date') {
			if (moment(filters.to_date).isAfter(dateTime)) {
				filters.from_date = dateTime;
				window.localStorage.setItem('FROM_DATE_RANGE', filters.from_date);
				window.localStorage.setItem('TO_DATE_RANGE', filters.to_date);
			} else {
				filterIsValid = false;
			}
		} else {
			if (moment(dateTime).isAfter(filters.from_date)) {
				filters.to_date = dateTime;
				window.localStorage.setItem('FROM_DATE_RANGE', filters.from_date);
				window.localStorage.setItem('TO_DATE_RANGE', filters.to_date);
			} else {
				filterIsValid = false;
			}
		}
		if (filterIsValid) {
			this.setDateRange(filters);
			this.setState({ searchFilters: filters });
			this.loadGridData(this.state.isCancelled);
		}
	}

	handleFiltersChange = (e) => {
		let filters = this.state.searchFilters;
		switch (e.target.name) {
			case "facility_id": {
				let { facilities } = this.state;
				let facilityIdChanged = [], isAdded = false, facilitiesIn = [], facilityIdsTemp = [];
				let newFacilityIds = e.target.value;
				if (e.target.value.includes('all')) {
					if (filters.facility_id.length === facilities.length) {
						filters.facility_id = [];
					} else {
						filters.facility_id = _.cloneDeep(this.facilityIds);
					}
				} else {
					if (filters.facility_id.length > newFacilityIds.length) {
						facilityIdChanged = filters.facility_id.find(val => !newFacilityIds.includes(val));
					} else {
						facilityIdChanged = newFacilityIds.find(val => !filters.facility_id.includes(val));
						isAdded = true;
					}
					let facilityChanged = facilities.find(facility => facility._id === facilityIdChanged);
					facilityIdsTemp = _.cloneDeep(filters.facility_id);
					// If company is changed 
					if (facilityChanged.type === 'company') {
						let companyChanged = facilityChanged;
						facilities.forEach(fac => {
							if (fac.parent_id === companyChanged._id) {
								facilitiesIn.push(fac._id);
							}
						});
						let index = facilityIdsTemp.indexOf(companyChanged._id);
						if (facilitiesIn.length > 0) {
							// If company is selected
							if (isAdded) {
								// Add company Id
								facilityIdsTemp.push(companyChanged._id);
								// Add facility Ids
								facilitiesIn.forEach(facId => {
									if (!facilityIdsTemp.includes(facId)) {
										facilityIdsTemp.push(facId);
									}
								});
							} else { // If company is unselected
								// Remove company Id
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
								facilitiesIn.forEach(facId => {
									let index = facilityIdsTemp.indexOf(facId);
									if (index > -1) {
										facilityIdsTemp.splice(index, 1);
									}
								});
							}
						} else {
							isAdded ? facilityIdsTemp.push(companyChanged._id) : facilityIdsTemp.splice(index, 1);
						}
					} else { // If facility is changed
						let companyId = facilityChanged.parent_id;
						let facilitiesIn = [];
						facilities.forEach(fac => {
							if (fac.parent_id === companyId) {
								facilitiesIn.push(fac._id);
							}
						});
						// If facility Id is selected
						if (isAdded) {
							// Add facility Id
							facilityIdsTemp.push(facilityChanged._id);
							if (!filters.facility_id.includes(companyId)) {
								facilityIdsTemp.push(companyId);
							}
						} else { // If facility Id is unselected
							// Remove facility Id
							let index = facilityIdsTemp.indexOf(facilityChanged._id);
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
							// Remove company Id
							let facilitiesInFilters = [];
							facilitiesIn.forEach(fac => {
								if (facilityIdsTemp.includes(fac)) {
									facilitiesInFilters.push(fac);
								}
							});
							if (facilitiesInFilters.length === 0) {
								index = facilityIdsTemp.indexOf(companyId);
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
							}
						}
					}
					filters.facility_id = _.cloneDeep(facilityIdsTemp);
				}
				window.localStorage.setItem('FACILITY_ID', JSON.stringify(filters.facility_id));
				this.setState({ searchFilters: filters }, () => {
					this.loadGridData(this.state.isCancelled);
				});
				break;
			}
			case "testIds": {
				let testIds = this.state.testIds;
				if (e.target.value.includes('all')) {
					if (testIds.length === this.state.tests.length) {
						testIds = [];
					} else {
						testIds = _.cloneDeep(this.testIds);
					}
				} else {
					testIds = e.target.value;
				}
				this.setState({ testIds: testIds }, () => {
					this.loadGridData(this.state.isCancelled);
				});
				break;
			}
			default: break;
		}
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridSchema();
	};

	checkRealFacilities = (searchFilters) => {
		let realFacilities = [];
		searchFilters.facility_id.map(facilityId => {
			if (this.state.facilities.find(facility => facility._id === facilityId)) {
				realFacilities.push(facilityId);
			}
			return null;
		});
		return realFacilities;
	}

	loadGridData = (isCancelled) => {
		abortApiCalls();
		releaseApiCalls();
		this.gridApi.showLoadingOverlay();
		let searchFilters = this.state.searchFilters;
		searchFilters.facility_id = this.checkRealFacilities(searchFilters);
		searchFilters.is_cancel = isCancelled;
		searchFilters.testIds = this.state.testIds;
		getRequisitions(searchFilters)
			.then((response) => {
				this.setState({ rowData: response.data });
				if (!response.data.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			})
	};

	onIsArchivedInformation = (event) => {
		this.setState({ isArchived: event.target.checked });
		this.loadFacilities(event.target.checked, this.state.isCancelled);
	};

	onIsReducedGridView = (event) => {
		this.setState({ isReducedGridView: event.target.checked });
		this.loadFacilities(this.state.isArchived);
	}

	onIsCancelledInformation = (event) => {
		this.setState({ isCancelled: event.target.checked });
		this.loadFacilities(this.state.isArchived, event.target.checked);
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	createRequisition = () => {
		if (this.state.facilities && this.state.facilities.length && this.state.facilities.filter(item => item.archived !== 'archived').length) {
			this.setState({ showCreateRequisition: true }, () => {
				let elements = document.getElementsByClassName("removeTabIndexModal");
				if (elements.length) {
					for (let i = 0; i < elements.length; i++) {
						let dialog = elements[i];
						dialog.parentNode.removeAttribute('tabindex');
					}
				}
			});
		} else {
			toastr.info("You need to have active facilities to make requisition.");
		}
	}

	handleCloseCreateRequisition = () => {
		this.setState({ showCreateRequisition: false });
	}

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.RequisitionGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.RequisitionGridExported
		};
		createAudit(auditData);
	};

	onBtnExportZipForRequisitions = () => {
		let pdfArray = [];
		this.gridApi.forEachNodeAfterFilter(node => {
			if (node.data.requisition_pdf_path) {
				pdfArray.push("/files/requisitionPDF/" + node.data.requisition_pdf_path);
			}
		});
		if (pdfArray.length) {
			if (pdfArray.length > 1000) {
				toastr.warning("Maximum limit of 1000 reached.  Please reduce date range.");
			} else {
				const params = {
					pdfArray: pdfArray,
					userId: window.localStorage.getItem("USER_ID"),
					type: 'requisitionPDF'
				}
				boundActions.startBlockUILoading();
				bulkDownloadZipForPDF(params).then(response => {
					boundActions.endBlockUILoading();
					if (response.data && response.data.RESULT === "SUCCESS") {
						window.open(serviceConstants.HOST_NAME + response.data.path);
					}
				}).catch(error => {
					boundActions.endBlockUILoading();
					console.log(error);
				})
			}
		} else {
			toastr.warning("There is no item has requisition pdf file");
		}
	}

	importNovaRequisitions = (e) => {
		boundActions.startBlockUILoading();
		readXlsxFile(e.target.files[0]).then((rows) => {
			if (rows[0][0] === "Collection Date" && rows[0][1] === "Collection Time" && rows[0][2] === "Sample ID" && rows[0][3] === "First Name" && rows[0][4] === "Last Name" && rows[0][5] === "Date of Birth" && rows[0][6] === "Sex" && rows[0][7] === "Race" && rows[0][8] === "Ethnicity" && rows[0][9] === "Phone Number" && rows[0][10] === "Email" && rows[0][11] === "Address_1" && rows[0][12] === "Address_2" && rows[0][13] === "City" && rows[0][14] === "State" && rows[0][15] === "ZIP" && rows[0][16] === "Do you have insurance?" && rows[0][17] === "Health Insurance Provider Name" && rows[0][18] === "Policy Number" && rows[0][19] === "Group Number" && rows[0][20] === "Driver_license" && rows[0][21] === "Parent / Guardian Name" && rows[0][22] === "Facility" && rows.length > 1) {
				for (let i = 1; i < rows.length; i++) {
					const facilitySource = this.state.allFacilities.find(facItem =>
						facItem.name === rows[i][22]
					);
					if (facilitySource) {
						const sample = rows[i][2];
						this.setState({ sample: sample });
						let patientInfo = {
							firstName: rows[i][3],
							lastName: rows[i][4],
							email: rows[i][10],
							phone: rows[i][9],
							sex: rows[i][6] ? (["M", "F", "U"].includes(rows[i][6].charAt(0).toUpperCase()) ? rows[i][6].charAt(0).toUpperCase() : "U") : "U",
							dob: moment.tz(rows[i][5], 'Europe/London').format("YYYY-MM-DD"),
							ethnicity: ["Not Hispanic or Latino", "Hispanic or Latino"].includes(rows[i][8]) ? rows[i][8] : "",
							race: ["White", "Black or African American", "American Indian or Alaska Native", "Asian", "Native Hawaiian or Other Pacific Islander", "Other"].includes(rows[i][7]) ? rows[i][7] : null,
							address1: rows[i][11],
							address2: rows[i][12],
							city: rows[i][13],
							state: rows[i][14],
							zip: rows[i][15],
							symptoms: [],
							facilityId: facilitySource._id,
							driverLicense: rows[i][20],
							notify: false
						}

						if (rows[i][16] && rows[i][16].toLowerCase() === "yes") {
							patientInfo.insuranceProv1 = rows[i][17];
							patientInfo.insuredFirstName = rows[i][21] ? rows[i][21].split(' ')[0] : '';
							patientInfo.insuredLastName = rows[i][21] ? rows[i][21].split(' ')[1] : '';
							patientInfo.groupNumber = rows[i][19];
							patientInfo.memberId = rows[i][18];
							patientInfo.relation = "";
						}
						patientSignup(patientInfo).then((response) => {
							if (response.data && response.data.length) {
								const patient = response.data[0];
								const patientId = patient._id;
								const patientInsuranceId = response.data[1] ? response.data[1]._id : "";
								const collectedDate = moment.tz(rows[i][0], 'Europe/London').format("YYYYMMDD") + moment((rows[i][1].split("M")[0]) + "M", "hh:mm A").format("HHmmss");
								const testType = "Nasopharyngeal Swab";
								const diagnosisCode = ['Z11.52'];
								let reqInfo = {
									providerFirstName: 'Taha',
									providerLastName: 'Tokhi',
									providerNPI: '1508536426',
									testInfoCode: "SARS-CoV-2",
									testInfoDescription: "Rt-PCR Test",
									testType: testType,
									diagnosisCode: diagnosisCode,
									sample: sample,
									collectedDate: collectedDate,
									collectorName: 'Taha Tokhi',
									patientId: patientId,
									patientInsuranceId: patientInsuranceId,
									facilityId: facilitySource._id,
									orderDate: moment().format("YYYYMMDDHHmmss"),
									facilityOrderId: sample,
									facilitySource: facilitySource.name,
									requistionPDFPath: sample + '-' + collectedDate + '.pdf',
									importNova: true,
									testId: "6333a8a8e136fdfe8a0c36c9",
									orderType: "single"
								}
								saveRequisitionChanges(reqInfo).then(res => {
									const pdfParams = {
										patientName: rows[i][3] + ' ' + rows[i][4],
										address1: rows[i][11] ? rows[i][11].toString() : "",
										address2: rows[i][12] ? rows[i][12] : "",
										city: rows[i][13] ? rows[i][13].toString() : "",
										state: rows[i][14] ? rows[i][14].toString() : "",
										zip: rows[i][15] ? rows[i][15].toString() : "",
										phoneNumber: rows[i][9] ? rows[i][9].toString() : "",
										email: rows[i][10] ? rows[i][10].toString() : "",
										dateOfBirth: moment.tz(rows[i][5], 'Europe/London').format("MM/DD/YYYY"),
										gender: rows[i][6] ? rows[i][6].charAt(0).toUpperCase() : "U",
										sample: sample.toString(),
										physicianName: 'Taha Tokhi',
										facilityName: facilitySource.name,
										providerNPI: "1508536426",
										facilityAddress1: facilitySource.address.address1,
										facilityAddress2: facilitySource.address.address2,
										facilityCity: facilitySource.address.city,
										facilityState: facilitySource.address.state,
										facilityZip: facilitySource.address.zip,
										facilityPhone: facilitySource.phone_no,
										testType: testType,
										collectedDate: moment(collectedDate, "YYYYMMDDHHmmss").format('MM/DD/YYYY'),
										collectedTime: moment(collectedDate, "YYYYMMDDHHmmss").format('hh:mm A'),
										signature_image: "Taha-Tokhi-Signature.png",
										requisition_pdf_path: sample.toString() + '-' + collectedDate + '.pdf',
										diagnosisCode: diagnosisCode,
										symptoms: [],
										insuranceProvider: rows[i][17] ? rows[i][17].toString() : "",
										insuranceMemberId: rows[i][18] ? rows[i][18].toString() : "",
										insuranceGroupNumber: rows[i][19] ? rows[i][19].toString() : "",
										insuranceRelationToInsured: "",
										insuranceHolderName: rows[i][21] ? rows[i][21] : '',
										insuranceDriverLicense: rows[i][20] ? rows[i][20].toString() : "",
										lab_order_id: res.data.lab_order_id,
										testId: "6333a8a8e136fdfe8a0c36c9",
										mrn: patient.mrn
									};
									createRequisitionPDF(pdfParams).then((res) => {
										const count = rows.length - 1;
										if (i === count) {
											boundActions.endBlockUILoading();
											this.loadGridData(this.state.isCancelled);
											Swal.fire({
												customClass: {
													container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
												},
												title: count + ' requisitions are successfully imported',
												icon: 'success',
												confirmButtonText: 'Ok'
											})
										}
									})
								})
							}
						})
					}
				}
			} else {
				boundActions.endBlockUILoading();
				Swal.fire({
					customClass: {
						container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
					},
					title: 'Document Format Error',
					text: "The Format of document is wrong, Please input the right Exel file and try again",
					icon: 'warning',
					confirmButtonText: 'Close'
				}).then((result) => {
					return;
				})
			}
		})
		document.getElementById('import_requisitions').value = "";
	}

	importGrubbs = (e) => {
		readXlsxFile(e.target.files[0]).then((rows) => {
			if (rows[0][0] === 'Date' && rows[0][1] === 'Start Time' && rows[0][2] === 'RT-PCR Test ID' && rows[0][3] === 'First Name' && rows[0][4] === 'Last Name' && rows[0][5] === 'Gender' && rows[0][6] === 'Are you pregnant?' && rows[0][7] === 'Date of Birth' && rows[0][8] === 'Email' && (rows[0][9] && rows[0][9].toLowerCase().includes('phone')) && rows[0][10] === 'Address' && rows[0][11] === 'Address line 2' && rows[0][12] === 'City' && rows[0][13] === 'State' && rows[0][14] === 'Zip' && rows.length > 1) {
				let isValid = true;
				let isOld = false;
				let isFuture = false;
				let isNotSame = true;
				for (let i = 1; i < rows.length; i++) {
					if (!(rows[i][0] && rows[i][1] && rows[i][2] && rows[i][3] && rows[i][4] && rows[i][5] && rows[i][7] && rows[i][9] && rows[i][10] && rows[i][12] && rows[i][13])) {
						isValid = false;
					}
				}
				for (let i = 1; i < rows.length; i++) {
					// check collected date is less than 3 days before
					const limitDay = moment().subtract(3, 'days').format("YYYYMMDD");
					if (moment.tz(rows[i][0], 'Europe/London').format("YYYYMMDD") < limitDay) {
						isOld = true;
					}
				}
				for (let i = 1; i < rows.length; i++) {
					// check birthday to not allow future date
					const limitDay = moment().format("YYYYMMDD");
					if (moment.tz(rows[i][7], 'Europe/London').format("YYYYMMDD") >= limitDay) {
						isFuture = true;
					}
				}
				for (let i = 1; i < rows.length; i++) {
					// check birthday is not same for all patients
					if (moment.tz(rows[i][7], 'Europe/London').format("YYYYMMDD") !== moment.tz(rows[1][7], 'Europe/London').format("YYYYMMDD")) {
						isNotSame = false;
					}
				}
				if (isValid && !isOld && !isFuture && !isNotSame) {
					boundActions.startBlockUILoading();
					for (let i = 1; i < rows.length; i++) {
						const sample = rows[i][2];
						this.setState({ sample: sample });
						const editParams = {
							firstName: rows[i][3],
							lastName: rows[i][4],
							dob: moment.tz(rows[i][7], 'Europe/London').format("YYYY-MM-DD"),
							gender: rows[i][5] ? (["M", "F", "U"].includes(rows[i][5].charAt(0).toUpperCase()) ? rows[i][5].charAt(0).toUpperCase() : "U") : "U",
							email: rows[i][8] ? rows[i][8] : "",
							mobile: rows[i][9] ? rows[i][9].toString() : "",
							address1: rows[i][10],
							address2: rows[i][11] ? rows[i][11] : "",
							city: rows[i][12],
							state: rows[i][13],
							zip: rows[i][14] ? rows[i][14].toString() : "20850",
							country: "US",
							is_pregnant: rows[i][6] && rows[i][6].toLowerCase() === 'yes' ? true : false
						};
						updatePatientData(editParams).then((response) => {
							if (response.data) {
								// create requisition
								const collectedDate = moment.tz(rows[i][0], 'Europe/London').format("YYYYMMDD") + moment((rows[i][1].split("M")[0]) + "M", "hh:mm A").format("HHmmss");
								const providerFirstName = "Michael";
								const providerLastName = "Kim";
								const providerNPI = "1386649028";
								const facilityName = "Grubbs Pharmacy";
								const facilityId = "605d5808177b981d99677d0e";
								const facilityPhone = "2025033262";
								const facilityAddress1 = "326 East Capitol St NE";
								const facilityAddress2 = "";
								const facilityCity = "Washington";
								const facilityZip = "20003";
								const facilityState = "DC";
								const testType = "Nasal Swab";
								const providerSignatureImage = "";
								const patientId = response.data._id;
								const patientMRN = response.data.mrn;
								const reqInfo = {
									providerFirstName: providerFirstName,
									providerLastName: providerLastName,
									providerNPI: providerNPI,
									testInfoCode: "SARS-CoV-2",
									testInfoDescription: "Rt-PCR Test",
									testType: testType,
									diagnosisCode: ['Z11.52'],
									sample: sample,
									collectedDate: collectedDate,
									collectorName: providerFirstName + " " + providerLastName,
									patientId: patientId,
									facilityId: facilityId,
									orderDate: moment().format("YYYYMMDDHHmmss"),
									facilityOrderId: sample,
									facilitySource: facilityName,
									requistionPDFPath: sample + '-' + collectedDate + '.pdf',
									testId: "6333a8a8e136fdfe8a0c36c9",
									orderType: "single"
								};
								saveRequisitionChanges(reqInfo).then((response) => {
									const createPDFData = {
										patientName: rows[i][3] + ' ' + rows[i][4],
										address1: rows[i][10],
										address2: rows[i][11] ? rows[i][11] : "",
										city: rows[i][12],
										state: rows[i][13],
										zip: rows[i][14] ? rows[i][14].toString() : "20850",
										phoneNumber: rows[i][9] ? rows[i][9].toString() : "",
										email: rows[i][8] ? rows[i][8] : "",
										dateOfBirth: moment.tz(rows[i][7], 'Europe/London').format("MM/DD/YYYY"),
										gender: rows[i][5] ? rows[i][5].charAt(0).toUpperCase() : "U",
										sample: sample,
										physicianName: providerFirstName + " " + providerLastName,
										facilityName: facilityName,
										providerNPI: providerNPI,
										facilityAddress1: facilityAddress1,
										facilityAddress2: facilityAddress2,
										facilityCity: facilityCity,
										facilityState: facilityState,
										facilityZip: facilityZip,
										facilityPhone: facilityPhone,
										testType: testType,
										collectedDate: moment(collectedDate, "YYYYMMDDHHmmss").format('MM/DD/YYYY'),
										collectedTime: moment(collectedDate, "YYYYMMDDHHmmss").format('hh:mm A'),
										signature_image: providerSignatureImage,
										requisition_pdf_path: sample + '-' + collectedDate + '.pdf',
										diagnosisCode: ['Z11.52'],
										symptoms: [],
										lab_order_id: response.data.lab_order_id,
										testId: "6333a8a8e136fdfe8a0c36c9",
										mrn: patientMRN
									};
									createRequisitionPDF(createPDFData).then((response) => {
										if (i === rows.length - 1) {
											boundActions.endBlockUILoading();
											this.loadGridData(this.state.isCancelled);
										}
									})
								})
							}
						})
					}
					notifyGrubbsUploaded();
					notifyBrowserGrubbsUploaded();
				} else {
					if (!isValid) {
						toastr.warning("There is missing information on the spreadsheet, please revise and upload again.");
					}
					if (isOld) {
						toastr.warning("We noticed the collection date is older than three days old.  This may be due to the collection date not being in the date format in the spreadsheet.  Please correct and try uploading again.");
					}
					if (isFuture) {
						toastr.warning("There is an error with the formatting of the birthdays or the birthday is in the future.  Please review the spreadsheet, make correction and try importing again please.");
					}
					if (isNotSame) {
						toastr.warning("There is an error with the formatting of the birthdays.  Please review the spreadsheet, make correction and try importing again please.");
					}
				}
			} else {
				toastr.warning("Please select proper file to import requisitions");
			}
		})
		document.getElementById('import_grubbs').value = "";
	}

	onPageSizeChanged = () => {
		let value = document.getElementById("page-size").value;
		this.gridApi.paginationSetPageSize(Number(value));
	};

	loadGridSchema = () => {
		let userId = window.localStorage.getItem("USER_ID");
		let params = {
			user_id: userId,
			type: settingConstants.GRID,
			page: settingConstants.REQUISITIONS
		}
		getViewSettings(params)
			.then((response) => {
				const columnState =
					response.data && response.data.length > 0 &&
					response.data[0].grid_views.find((item) => {
						return item.name === this.state.gridName;
					}).columns;
				if (columnState) {
					this.gridColumnApi.applyColumnState({
						state: columnState,
						applyOrder: true,
					});
				} else {
					this.gridColumnApi.resetColumnState();
				}

				const pageSize =
					response.data && response.data.length > 0 &&
					response.data[0].grid_views.find((item) => {
						return item.name === this.state.gridName;
					}).page_size;
				document.getElementById("page-size").value =
					pageSize && pageSize > 0 ? pageSize : 20;
				this.onPageSizeChanged();
			})
	};

	saveState = () => {
		let userId = window.localStorage.getItem("USER_ID");
		const columnState = this.gridColumnApi.getColumnState();
		let pageSize = document.getElementById("page-size").value;
		let saveObject = {
			user_id: userId,
			type: settingConstants.GRID,
			page: settingConstants.REQUISITIONS,
			grid_views: [
				{
					name: this.state.gridName,
					page_size: pageSize,
					columns: columnState,
				},
			],
		};
		saveViewDefault(saveObject)
			.then(() => {
				toastr.success("Saved successfully.");
			})
	};

	resetState = () => {
		this.gridColumnApi.resetColumnState();
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";

		const filters = this.state.searchFilters;

		filters.from_date = moment().format("YYYY-MM-DD") + 'T00:00';
		filters.to_date = moment().add(1, "days").format("YYYY-MM-DD") + 'T00:00';
		if (this.state.facilities.length > 1) {
			filters.facility_id = this.facilityIds;
		} else {
			filters.facility_id = this.state.facilities[0]._id;
		}
		window.localStorage.removeItem("FACILITY_ID");
		window.localStorage.removeItem("FROM_DATE_RANGE");
		window.localStorage.removeItem("TO_DATE_RANGE");

		this.setState({ searchFilters: filters, testIds: this.testIds });
		this.loadGridData(this.state.isCancelled);
		this.setDateRange(filters);
	};

	render() {
		let columnDefs = this.state.columnDefs;
		const { isReducedGridView } = this.state;
		if (/audit/.test(getUserRole().toLowerCase())) {
			columnDefs = columnDefs.filter(item => item.headerName !== "Actions");
		}
		columnDefs = /salesperson|salesadmin/.test(getUserRole().toLowerCase()) ? columnDefs[1].children.filter(x => /Patient Name|Sample ID|Facility|Physician|Collected Date|Collector Name/.test(x.headerName)) : columnDefs;

		if (isReducedGridView && isAllowedReducedGridView('requisition')) {
			columnDefs = [columnDefs[0], ...columnDefs[1]?.children.filter(x => /Requisition|Patient Name|Test|Sample|Requisition PDF|Facility| Physician|Specimen Collected Date/.test(x.headerName) && !/Sample Tracking/.test(x.headerName))];
			columnDefs[columnDefs.length - 1]['resizable'] = false;
		}

		return (
			<div className="clinic-contain">
				<RequisitionGridContext.Provider
					value={{ isReducedGridView, onIsReducedGridView: this.onIsReducedGridView }}
				>
					<RequisitionSearchMenu
						onIsArchivedInformation={(event) => { this.onIsArchivedInformation(event) }}
						onIsCancelledInformation={(event) => { this.onIsCancelledInformation(event) }}
						isArchived={this.state.isArchived}
						isCancelled={this.state.isCancelled}
						facilityId={this.state.searchFilters.facility_id}
						handleFiltersChange={this.handleFiltersChange}
						handleDateFiltersChange={this.handleDateFiltersChange}
						updateDateRange={this.updateDateRange}
						facilities={this.state.facilities}
						from_date={this.state.searchFilters.from_date}
						to_date={this.state.searchFilters.to_date}
						date_range={this.state.searchFilters.dateRange}
						onFilterTextChange={this.onFilterTextChange}
						clearFilter={this.clearFilter}
						onPageSizeChanged={this.onPageSizeChanged}
						saveState={this.saveState}
						resetState={this.resetState}
						onBtnExport={this.onBtnExport}
						createRequisition={this.createRequisition}
						user_role={this.state.user_role}
						onBtnExportZipForRequisitions={this.onBtnExportZipForRequisitions}
						importGrubbs={this.importGrubbs}
						importNovaRequisitions={this.importNovaRequisitions}
						tests={this.state.tests}
						testIds={this.state.testIds}
					/>
				</RequisitionGridContext.Provider>
				<CreateRequisition
					show={this.state.showCreateRequisition}
					handleClose={this.handleCloseCreateRequisition}
					context={this.state.context}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 350px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									components={this.state.components}
									pagination={true}
									paginationPageSize={20}
									paginationNumberFormatter={this.state.paginationNumberFormatter}
									excelStyles={this.state.excelStyles}
									context={this.state.context}
									processCellForClipboard={processCellForClipboard}
									defaultExcelExportParams={defaultExcelExportParams}
									rowClassRules={this.state.rowClassRules}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicRequisitionGrid;
