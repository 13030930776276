import React, { Component } from "react";
import { TextField, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel, InputAdornment, IconButton } from "@mui/material";
import { dailyReportsTypes } from "../../../../services/common/optionsData";
import ReportsBreadcrumb from "./dailyReportsBreadcrumb";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import EventIcon from "@mui/icons-material/Event";

class ReportsSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from_date: this.props.from_date,
			to_date: this.props.to_date,
			pickerOpened: false
		};
	}

	render() {
		return (
			<div>
				<ReportsBreadcrumb
					onPageSizeChanged={this.props.onPageSizeChanged}
					saveState={this.props.saveState}
					resetState={this.props.resetState}
					onBtnExport={this.props.onBtnExport}
				/>
				<div className="row mt-3" style={{ padding: "12px" }}>
					<div className="col-md-10">
						<div className="row">
							<div className="col-md-6 pl-4">
								<FormControl component="fieldset">
									<FormLabel component="legend" style={{ marginBottom: "0px" }}>
										Type
									</FormLabel>
									<RadioGroup
										aria-label="result"
										name="type"
										value={this.props.reportType}
										onChange={this.props.handleFiltersChange}
										style={{ flexDirection: "row" }}
									>
										{
											dailyReportsTypes.map((res) => {
												return (
													<FormControlLabel
														key={res.code}
														value={res.code}
														control={<Radio />}
														label={res.value}
													/>
												);
											})
										}
									</RadioGroup>
								</FormControl>
								<TextField
									label="Current Grid Search"
									variant="outlined"
									className="filter-search-bar mb-3"
									id="reset-form"
									InputLabelProps={{
										shrink: true,
									}}
									type="string"
									margin="dense"
									onChange={this.props.onFilterTextChange}
								/>
							</div>
							<div className="col-md-6">
								<div className="row" style={{ marginTop: "-15px" }}>
									<div className="col-md-12">
										<FormLabel component="legend" style={{ marginBottom: "10px" }}>
											Date Range
										</FormLabel>
									</div>
									<div className="dateRangeBar">
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-3" + (this.props.date_range === 'today' ? " active" : "")}
											onClick={() => this.props.updateDateRange('today')}
										>
											Today
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-3" + (this.props.date_range === 'week' ? " active" : "")}
											onClick={() => this.props.updateDateRange('week')}
										>
											Week
										</button>
										<button
											className={"btn btn-info dateRangeBtn mx-1 mb-3" + (this.props.date_range === 'month' ? " active" : "")}
											onClick={() => this.props.updateDateRange('month')}
										>
											Month
										</button>
										<button
											className={"btn btn-info dateRangeBtn ml-1 mb-3" + (this.props.date_range === 'custom' ? " active" : "")}
										>
											Custom
										</button>
									</div>
									<div className="dateRangeBar dateRangeBarPickers">
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.from_date : this.props.from_date}
											onChange={(dateTime) => this.setState({ from_date: dateTime })}
											onOpen={() => this.setState({ from_date: this.props.from_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'from_date')}
											label="From Date"
											renderInput={props => <TextField
												{...props}
												className="ml-1 mr-2 mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
										<MobileDateTimePicker
											value={this.state.pickerOpened ? this.state.to_date : this.props.to_date}
											onChange={(dateTime) => this.setState({ to_date: dateTime })}
											onOpen={() => this.setState({ to_date: this.props.to_date, pickerOpened: true })}
											onClose={() => this.setState({ pickerOpened: false })}
											onAccept={(dateTime) => this.props.handleDateFiltersChange(dateTime.$d, 'to_date')}
											label="To Date"
											renderInput={props => <TextField
												{...props}
												className="mt-2"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-2 text-right">
						<span>
							<button
								className="btn btn-primary"
								onClick={() => this.props.clearFilter()}
							>
								<i className="fa fa-times" aria-hidden="true"></i> Clear Filters
							</button>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default ReportsSearchMenu;
