import React, { Component } from "react";
import { AgGridReact } from 'ag-grid-react';
//service calls
import { getAllData } from "../../../../services/patientPortalServices/patientPaymentService";
import SearchMenu from "./searchMenu";
import moment from 'moment';
import { dateComparator, getFullAddress, getUserDetails } from "../../../../services/common/util";
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../../services/common/constants";
import { ThemeContext } from "../../../../theme/ThemeProvider";

class ClinicPatientPaymentGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			columnDefs: [
				{
					headerName: "Patient Info",
					children: [
						{
							headerName: "Patient Name",
							minWidth: 120,
							field: "patient_name",
							resizable: true,
						},
						{
							headerName: "Company Name",
							minWidth: 120,
							field: "company_name",
							resizable: true,
						},
						{
							headerName: "Address",
							minWidth: 200,
							field: "company_name",
							resizable: true,
							valueGetter: function (params) {
								return getFullAddress(params.data);
							}
						},
					]
				},
				{
					headerName: "Payment Info",
					children: [
						{
							headerName: "Is Paid?",
							minWidth: 150,
							field: "is_paid",
							resizable: true,
							valueGetter: function (params) {
								if (params.data.is_paid) {
									return "YES";
								} else {
									return "NO";
								}
							}
						},
						{
							headerName: "Amount",
							minWidth: 150,
							field: "amount",
							resizable: true,
							valueGetter: function (params) {
								if (params.data.amount) {
									return "$" + params.data.amount
								}
							}
						},
						{
							headerName: "Paid Time",
							minWidth: 150,
							field: "paid_time",
							resizable: true,
							valueGetter: function (params) {
								if (params.data.paid_time) {
									return moment(params.data.paid_time, "YYYYMMDDHHmmss").format("MM/DD/YYYY hh:mm A")
								}
							},
							comparator: dateComparator
						},
					]
				},
				{
					headerName: "Card Holder Info",
					children: [
						{
							headerName: "Name",
							minWidth: 120,
							field: "card_holder_info.name",
							resizable: true,
						},
						{
							headerName: "Email",
							minWidth: 120,
							field: "card_holder_info.email",
							resizable: true,
						},
					]
				},
			],
			defaultColDef: {
				flex: 1,
				filter: true,
				sortable: true,
			},
			rowData: [],
			context: { componentParent: this }
		};
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.loadGridData();
	};

	loadGridData = () => {
		getAllData()
			.then((response) => {
				this.setState({ rowData: response.data });
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onBtnExport = () => {
		this.gridApi.exportDataAsExcel({});
		const userData = JSON.parse(getUserDetails());
		const auditData = {
			identifier: auditEnums.IDENTIFIERS.ExportRecord,
			event_type: auditEnums.EVENTTYPES.PatientPaymentGridExported,
			user_id: userData._id,
			user_name: userData.user_name + " (" + userData.role + ")",
			update_string: auditEnums.EVENTTYPES.PatientPaymentGridExported
		};
		createAudit(auditData);
	};

	render() {
		return (
			<div className="clinic-contain">
				<SearchMenu
					onFilterTextChange={this.onFilterTextChange}
					onBtnExport={this.onBtnExport}
				/>
				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<ThemeContext.Consumer>
						{({ themeName }) => (
							<div
								id="myGrid"
								style={{
									height: "100%",
									width: "100%",
								}}
								className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
							>
								<AgGridReact
									columnDefs={this.state.columnDefs}
									defaultColDef={this.state.defaultColDef}
									masterDetail={true}
									onGridReady={this.onGridReady}
									rowData={this.state.rowData}
									pagination={true}
									paginationAutoPageSize={true}
									components={this.state.components}
									context={this.state.context}
								/>
							</div>
						)}
					</ThemeContext.Consumer>
				</div>
			</div>
		);
	}
}

export default ClinicPatientPaymentGrid;
