import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { authenticateAndFetchUserDetails } from "../../../services/patientPortalServices/loginService";
import { logout } from "../../../services/clinicPortalServices/loginService";
import { logoutDriver } from "../../../services/driverPortalServices/loginService";
import { isUserLoggedIn, getPatientAuthToken, getDriverAuthToken, isValidEmail, setCookie } from "../../../services/common/util";
import { fetchDashboardDetails } from "../../../services/patientPortalServices/dashboardService";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import moment from "moment";
import Swal from "sweetalert2";
import { createAudit } from "../../../services/clinicPortalServices/auditService";
import { auditEnums } from "../../../services/common/constants";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

class PatientPortalLoginContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			patientsData: [],
			setPatientId: '',
			usedPatient: null,
			dob: null,
			contactInfo: "",
			id: "",
			isAuthenticationfailed: "UNKNOWN",
			loading: false,
		};
		if (getPatientAuthToken()) {
			this.props.history.push('/patientportal/dashboard')
		}
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleDateChange = (date) => {
		this.setState({ dob: date });
	}

	handleLogin = (e) => {
		e.preventDefault();
		let loginInfo = {};
		this.setState({ loading: true });
		if (isValidEmail(this.state.contactInfo)) {
			loginInfo = {
				email: this.state.contactInfo,
				date_of_birth: moment(this.state.dob).format('YYYY-MM-DD'),
			};
		} else {
			loginInfo = {
				mobile: this.state.contactInfo,
				date_of_birth: moment(this.state.dob).format('YYYY-MM-DD'),
			};
		}
		authenticateAndFetchUserDetails(loginInfo)
			.then((res) => {
				if (!res.data) {
					Swal.fire({
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal'
						},
						title: "Having Trouble Logging In?",
						text: `Seems our record is different from yours.\n
						You can use either your email or mobile number.\n
						Please try again with a different method or click below.`,
						icon: 'warning',
						showCloseButton: true,
						confirmButtonText: 'Get Support To Access Your Results',
					}).then((result) => {
						if (result.isConfirmed) {
							this.props.history.push("/patientportal-issue");
						}
					});
					this.setState({
						isAuthenticationfailed: "YES",
						loading: false
					});
					return;
				}
				this.setState({
					isAuthenticationfailed: "NO",
					loading: false
				});
				if (isUserLoggedIn()) {
					logout();
				} else if (getDriverAuthToken())
					logoutDriver();

				if (res.data && res.data.patients.length > 0) {
					let patientsData = res.data.patients;
					let patientIds = [];
					patientsData.map(patient => {
						patient.userName = patient.first_name + ' ' + patient.last_name;
						patient.ageDetails = moment(patient.date_of_birth, "YYYY/MM/DD").format("Do MMM YYYY") + ", " + moment().diff(moment(patient.date_of_birth, "YYYY/MM/DD"), "years") + " years";
						patient.results = [];
						patientIds.push(patient._id);
						return null;
					});
					if (res.data.patients.length > 1) {
						window.localStorage.setItem("PATIENT-AUTH-TOKEN", res.data.token);
						setCookie();
						const patientInfo = { patient_id: { "$in": patientIds } };
						fetchDashboardDetails(patientInfo).then((data) => {
							if (data.data != null && data.data.length > 0) {
								data.data.map(item => {
									patientsData.map(patient => {
										if (patient._id === item.patient_id._id) {
											if (item.createdAt) {
												item.dateTime = moment.tz(item.createdAt, "America/New_York").format("YYYYMMDDHHmmss");
											} else {
												item.dateTime = moment(item.test_info.collected, "YYYYMMDDHHmmss").format("YYYYMMDDHHmmss");
											}
											if (item.results && item.results.pdf_path) {
												patient.results.push(item);
											} else if (item.sample_tracking && item.sample_tracking.length) {
												patient.results.push(item);
											}
										}
										return null;
									})
									return null;
								});
								patientsData.map(patient => {
									patient.results.sort((a, b) => b.dateTime - a.dateTime);
									if (patient.results[0]) {
										let status = "Created";
										if (patient.results[0].results && patient.results[0].results.value) {
											status = "Completed";
										} else if (patient.results[0].sample_tracking && patient.results[0].sample_tracking.length) {
											if (patient.results[0].sample_tracking.filter(item => item.eventName.includes("IMPORT")).length) {
												status = "Imported";
											} else if (patient.results[0].sample_tracking.filter(item => item.eventName === "ACCESSIONED").length) {
												status = "Accessioned";
											}
										}
										patient.dropdownText = patient.first_name + " " + patient.last_name + " ( Sample ID: " + patient.results[0].test_info.sample + ", Order Date: " + moment(patient.results[0].dateTime, "YYYYMMDDHHmmss").format("MMM DD YYYY") + ", Status: " + status + ")";
										patient.lastTestDate = patient.results[0].dateTime;
									} else {
										patient.dropdownText = patient.first_name + " " + patient.last_name + " ( No Result Available )";
										patient.lastTestDate = ""
									}
									return null
								})
								patientsData.sort((a, b) => b.lastTestDate - a.lastTestDate);
							}
							this.setState({
								patientsData: patientsData,
								usedPatient: patientsData[0],
								setPatientId: patientsData[0]._id,
								showModal: true,
							});
							window.localStorage.setItem("MATCHED_PATIENTS_DATA", JSON.stringify(patientsData[0]));
						});
					} else {
						const patient = patientsData[0];
						const auditData = {
							identifier: auditEnums.IDENTIFIERS.PatientRecord,
							event_type: auditEnums.EVENTTYPES.PatientLogIn,
							user_id: patient._id,
							user_name: patient.first_name + " " + patient.last_name + " (Patient)",
							record_id: patient._id,
							update_string: patient.first_name + " " + patient.last_name + " logged in to the patient portal"
						}
						window.localStorage.setItem("PATIENT-AUTH-TOKEN", res.data.token);
						setCookie();
						createAudit(auditData).then(res => {
							window.localStorage.setItem("MATCHED_PATIENTS_DATA", JSON.stringify(patientsData[0]));
							this.props.history.push("/patientportal/dashboard");
						})
					}
				}
			})

	};

	handleChoosePatient = (e) => {
		this.setState({
			setPatientId: e.target.value
		});
	};

	handlSetPatient = () => {
		const patient = this.state.patientsData.find(patientItem => {
			return patientItem._id === this.state.setPatientId;
		});
		this.setState({
			usedPatient: patient,
			showModal: false,
		});

		const auditData = {
			identifier: auditEnums.IDENTIFIERS.PatientRecord,
			event_type: auditEnums.EVENTTYPES.PatientLogIn,
			user_id: patient._id,
			user_name: patient.first_name + " " + patient.last_name + " (Patient)",
			record_id: patient._id,
			update_string: patient.first_name + " " + patient.last_name + " logged in to the patient portal"
		}
		createAudit(auditData).then(res => {
			window.localStorage.setItem("MATCHED_PATIENTS_DATA", JSON.stringify(patient));
			this.props.history.push("/patientportal/dashboard");
		})
	};

	render() {
		const url = window.location.pathname.split("/")[1];
		return (
			<div className="content login-content" >
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-8 offset-md-2">
							<div className="account-content patient-signup">
								<div className="row align-items-center justify-content-center">
									{
										url === 'patientportal'
										&&
										<div
											className="col-md-12 col-lg-6 login-right"
											style={{ border: "none" }}
										>
											<h4>
												Welcome to American Gene Technologies Results Portal
												<sup>TM</sup>
											</h4>
											<br />
											<p style={{ fontWeight: "500" }}>
												Please enter your information to access your Dashboard.
											</p>
											<p>
												Access to the AGT Portal is restricted solely to
												authorized users and is monitored for administrative and
												security purpose by the AGT team. All users expressly
												consent to such monitoring at time of portal registration.
												Any use of this system must be in compliance with AGT
												policies, procedures and applicable laws. Unauthorized
												access or use of this system may result in portal
												termination and civil or criminal liability.
											</p>
											<p>&copy; 2023 American Gene Technologies</p>
											<p style={{ fontWeight: "500" }}>
												<Link to="/terms-and-conditions" target="_blank">Terms and conditions</Link>
											</p>
										</div>
									}
									{
										url === 'result-validation'
										&&
										<div
											className="col-md-12 col-lg-6 login-right"
											style={{ border: "none" }}
										>
											<h4>
												MyResultNow Result Validation
											</h4>
											<br />
											<p style={{ fontWeight: "500" }}>
												Please enter the information of the individual who provided you with their result PDF into the form fields.
											</p>
											<p>
												Once you have validated access to the patient portal to view and authenticate the results you will be presented with the identical PDF that you were just presented with.  The most recent result should automatically be displayed to you.  In some cases browser permisions prevent the display of the PDF, in this case please use the download link to view on your device.
											</p>
											<p>
												Access to the AGT Portal is restricted solely to authorized users and is monitored for administrative and security purpose by the AGT team. All users expressly consent to such monitoring at time of portal registration. Any use of this system must be in compliance with AGT policies, procedures and applicable laws. Unauthorized access or use of this system may result in portal termination and civil or criminal liability.
											</p>
											<p>&copy; 2023 American Gene Technologies</p>
											<p style={{ fontWeight: "500" }}>
												<Link to="/terms-and-conditions" target="_blank">Terms and conditions</Link>
											</p>
										</div>
									}
									<div className="col-md-12 col-lg-5 login-right">
										<form onSubmit={this.handleLogin}>
											<div className="form-group">
												<label className="font-weight-bold">
													Email or Mobile #{" "}
													<span className="text-danger">*</span>
												</label>
												<input
													type="text"
													name="contactInfo"
													value={this.state.contactInfo}
													onChange={this.handleChange}
													className="form-control"
													required
												/>
												<span style={{ fontSize: "13px" }}>
													Email: username@example.com <br /> Mobile #:
													2223334444
												</span>
											</div>
											<div className="form-group">
												<label className="font-weight-bold">
													Patient Date Of Birth <span className="text-danger">*</span>
												</label>
												<MobileDatePicker
													value={this.state.dob}
													onChange={date => this.handleDateChange(date.$d)}
													maxDate={new Date()}
													renderInput={props => <TextField
														{...props}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton edge="end">
																		<EventIcon />
																	</IconButton>
																</InputAdornment>
															)
														}}
													/>}
													required
												/>
												<span style={{ fontSize: "13px" }}>
													Date Of Birth: MM/DD/YYYY (05/25/1984)
												</span>
											</div>

											<button
												className="btn btn-primary btn-block btn-lg login-btn"
												type="submit" disabled={this.state.loading}
											>
												{this.state.loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }}></i>}
												{this.state.loading && <span>Authenticating Please wait</span>}
												{!this.state.loading && <span>Login</span>}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-center video-section mt-5">
						<div className="text-center" style={{ maxWidth: 800, marginBottom: 60 }}>
							<div style={{ fontWeight: 600, fontSize: "1.55rem", marginBottom: 20 }}>YOUR COVID TEST IS ADVANCING MEDICAL RESEARCH</div>
							<div style={{ fontSize: "1.2rem", marginBottom: 20 }}>Discover how your COVID test being processed at MyResultNow is helping fund gene therapy research at American Gene Technologies to potentially cure HIV.</div>
							<div className="d-flex justify-content-center">
								<div style={{ maxWidth: 700, width: "100%" }}>
									<iframe style={{ boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.42)", aspectRatio: "16 / 9", width: "100%" }} src="https://www.youtube-nocookie.com/embed/IZpv0iU8TdY?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showModal}
					backdrop="static"
				>
					<Modal.Header>
						<Modal.Title>
							Choose One Record Below
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div
							className="row"
							style={{ padding: "10px" }}
						>
							<div
								className="col-12"
								style={{ marginBottom: "15px" }}
							>
								Verify with the dropdown which record you are wanting to view.
								The test date is in the dropdown so you can easily choose the most recent.
							</div>
							<div
								className="col-12"
							>
								<select
									required
									type="text"
									name="patientUser"
									value={this.state.setPatientId}
									onChange={this.handleChoosePatient}
									className="form-control"
								>
									{
										this.state.patientsData
										&&
										this.state.patientsData.map((patientItem) => {
											return (
												<option
													key={patientItem._id}
													value={patientItem._id}
												>
													{patientItem.dropdownText}
												</option>
											)
										})
									}
								</select>
							</div>
						</div>

					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={this.handlSetPatient}>
							Ok
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}

export default PatientPortalLoginContainer;
