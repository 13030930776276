import { apiInterfaceForJson } from "../common/apiManager";
import moment from "moment";

export const fetchPatientDataById = (patientId) => {
	return apiInterfaceForJson("/patient/v1/" + patientId, "GET");
};

export const fetchPatientWithQuery = (data) => {
	return apiInterfaceForJson("/patient/v1/search", "POST", data, window.apiCallController.signal);
};

export const fetchPatientDuplicatedData = (facilityId) => {
	return apiInterfaceForJson("/patient/v1/searchDuplicated", "POST", { facility_id: facilityId });
};

export const dismissPatientDuplicatedData = (params) => {
	return apiInterfaceForJson("/patient/v1/dismissDuplicated", "POST", params);
};

export const fetchPatientReports = (filters) => {
	let from_date = moment(filters.from_date).format("YYYY-MM-DD") + "T00:00:00.000Z";
	let to_date = moment(filters.to_date).format("YYYY-MM-DD") + "T23:59:59.000Z";

	let filterParams = {
		facility_id: filters.facility_id,
		from_date: from_date,
		to_date: to_date
	};
	return apiInterfaceForJson("/patient/v1/searchReports", "POST", filterParams);
};

export const fetchPatientExpandableData = (patientId) => {
	return apiInterfaceForJson("/order/v1/search", "POST", { patient_id: patientId, order_type: { $ne: "sub_bundle" } });
};

export const fetchPatientInsuranceData = (patientId) => {
	return apiInterfaceForJson("/patientinsurance/v1/search", "POST", { patient: patientId });
};
