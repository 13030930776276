import { templateIds } from "../common/constants";
import { apiInterfaceForJson } from "../common/apiManager";
import moment from 'moment';

export const fetchInventories = () => {
    return apiInterfaceForJson("/inventory/v1/", "GET");
};

export const getInventoryDataById = (inventoryId) => {
    return apiInterfaceForJson("/inventory/v1/" + inventoryId, "GET");
};

export const getReadyAssignKitInventories = () => {
    return apiInterfaceForJson("/inventory/v1/search", "POST", {
        kit_status: "READY_TO_ASSIGN",
        preparing_kit_count: {
            $gt: 0
        }
    });
};

export const createInventory = (inventoryDetails) => {
    let inventoryInfo = {
        'facility_id': inventoryDetails.facility_id,
        'max_kit_count': inventoryDetails.max_kit_count,
        'min_kit_count': inventoryDetails.min_kit_count,
        'current_kit_count': inventoryDetails.current_kit_count,
        'preparing_kit_count': inventoryDetails.preparing_kit_count,
    };
    return apiInterfaceForJson("/inventory/v1/", "POST", inventoryInfo);
};

export const updateInventory = (inventoryDetails) => {
    return apiInterfaceForJson("/inventory/v1/", "PUT", inventoryDetails);
};

export const deleteInventory = (inventoryDetails) => {
    return apiInterfaceForJson("/inventory/v1/delete", "POST", inventoryDetails);
};

export const sendSMSNotificationAfterRequestKit = (mobileNumber, userName, facilityName, kitCount) => {
    const body = userName + ' from ' + facilityName + ' has requested ' + kitCount + ' kits at ' + moment().format("MM/DD/YYYY hh:mm A") + '. \nPlease get kits ready and visit MyResultNow to update the status.';
    let messageData = {
        toMobile: mobileNumber,
        body: body
    };
    return apiInterfaceForJson("/notifications/v1/message", "POST", messageData);
};

export const sendBrowserNotificationAfterRequestKit = (userName, facilityName, kitCount) => {
    const body = userName + ' from ' + facilityName + ' has requested ' + kitCount + ' kits at ' + moment().format("MM/DD/YYYY hh:mm A") + '. \nPlease get kits ready.';
    const title = 'Facility has requested kits';
    let messageData = {
        title: title,
        text: body,
        target: 'superadmin'
    };
    return apiInterfaceForJson("/notifications/v1/browser", "POST", messageData);
};

export const sendEmailNotificationAfterRequestKit = (emails, userName, facilityName, kitCount) => {
    let bodyTemplate = {
        subject: facilityName + " has requested Kits",
        emailTxt: userName + " from " + facilityName + " has requested " + kitCount + " kits at " + moment().format("MM/DD/YYYY hh:mm A") + "<br/><br/>Please get kits ready and visit MyResultNow to update the status."
    }
    const emailDataTemplate = {
        toEmail: emails,
        subject: facilityName + " has requested Kits",
        body: bodyTemplate,
        templateId: templateIds.htmlEmailWithLogo
    }

    return apiInterfaceForJson("/notifications/v1/emailTemplate", "POST", emailDataTemplate);
};
