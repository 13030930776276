import { apiInterfaceForJson } from "../common/apiManager";
import { publicIpv4 } from 'public-ip';

export const getAuditData = (filter) => {
    return apiInterfaceForJson("/audit/v1/search", "POST", filter);
};

export const createAudit = (data) => {
    return publicIpv4().then(IPv4 => {
        if (IPv4) {
            data.ip_address = IPv4;
            return apiInterfaceForJson("/audit/v1", "POST", data);
        }
    })
}
