const data = {
    "FARRAGUT_MEDICAL": {
        label: "FARRAGUT MEDICAL LOGO",
        path: "../images/bookingmaplist/farragut-medical.jpg"
    },
    "FAST_TRACK_URGENT_CARE": {
        label: "FAST TRACK URGENT CARE LOGO",
        path: "../images/bookingmaplist/fast-track-urgent-care.jpg"
    },
    "GRUBB_PHARMACY": {
        label: "GRUBB PHARMACY LOGO",
        path: "../images/bookingmaplist/grubb-pharmacy.jpg"
    },
    "SHADY_GROVE": {
        label: "SHADY GROVE LOGO",
        path: "../images/bookingmaplist/shady-grove.jpg"
    },
    "PILL_PLUS": {
        label: "PILL PLUS LOGO",
        path: "../images/bookingmaplist/pill-plus.jpg"
    },
    "PASSPORT_HEALTH_SILVER_SPRING": {
        label: "PASSPORT HEALTH SILVER SPRING LOGO",
        path: "../images/bookingmaplist/passport-health-silver-spring.jpg"
    },
    "ALPHA_PEOPLES_DRUG": {
        label: "ALPHA PEOPLES DRUG LOGO",
        path: "../images/bookingmaplist/alpha-peoples-drugs.jpg"
    },
    "PASSPORT_HEALTH_ALEXANDRIA": {
        label: "PASSPORT HEALTH ALEXANDRIA LOGO",
        path: "../images/bookingmaplist/passport-health-alexandria.jpg"
    },
    "PASSPORT_HEALTH_BETHESDA": {
        label: "PASSPORT HEALTH BETHESDA LOGO",
        path: "../images/bookingmaplist/passport-health-bethesda.jpg"
    },
    "PASSPORT_HEALTH_DC": {
        label: "PASSPORT HEALTH DC LOGO",
        path: "../images/bookingmaplist/passport-health-dc.jpg"
    },
    "PASSPORT_HEALTH_RESTON": {
        label: "PASSPORT HEALTH RESTON LOGO",
        path: "../images/bookingmaplist/passport-health-reston.jpg"
    },
    "PASSPORT_HEALTH_USA_TIMONIUM": {
        label: "PASSPORT HEALTH USA TIMONIUM LOGO",
        path: "../images/bookingmaplist/passport-health-usa-timonium.png"
    },
    "PASSPORT_HEALTH_USA_COLUMBIA": {
        label: "PASSPORT HEALTH USA COLUMBIA LOGO",
        path: "../images/bookingmaplist/passport-health-usa-columbia.png"
    },
    "PASSPORT_HEALTH_USA_BALTIMORE": {
        label: "PASSPORT HEALTH USA BALTIMORE LOGO",
        path: "../images/bookingmaplist/passport-health-usa-baltimore.png"
    },
    "PASSPORT_HEALTH_USA_ANNAPOLIS": {
        label: "PASSPORT HEALTH USA ANNAPOLIS LOGO",
        path: "../images/bookingmaplist/passport-health-usa-annapolis.png"
    },
    "DM_COVID_TEST": {
        label: "DM COVID LOGO",
        path: "../images/bookingmaplist/dm-covid-test.jpg"
    },
    "ELAINE_ELLIS": {
        label: "ELAINE ELLIS LOGO",
        path: "../images/bookingmaplist/elaine-ellis-c-o-h.jpg"
    },
    "LJ_MEDICAL": {
        label: "LJ MEDICAL LOGO",
        path: "../images/bookingmaplist/lj-medical.jpg"
    },
    "Medical_Access_Germantown": {
        label: "MEDICAL ACCESS GERMANTOWN LOGO",
        path: "../images/bookingmaplist/medical-access-germantown.png"
    },
    "The_Wellness_NPS": {
        label: "The Wellness NPS",
        path: "../images/bookingmaplist/the-wellness-nps-logo.png"
    },
    "PETRO_HEALTH_LLC": {
        label: "PETRO HEALTH LLC LOGO",
        path: "../images/bookingmaplist/petro-health-llc-logo.jpg"
    },
    "3RDROOM": {
        label: "3RDROOM",
        path: "../images/bookingmaplist/3RDroom-logo.png"
    },
    "LAUREL_PHARMACY": {
        label: "LAUREL PHARMACY",
        path: "../images/bookingmaplist/laurel-pharmacy-logo.png"
    },
    "PEACH_TREE_HOUSE": {
        label: "PEACH TREE HOUSE",
        path: "../images/bookingmaplist/peach-tree-house-logo.png"
    },
    "AGT": {
        label: "AGT",
        path: "../images/bookingmaplist/agt-logo-square.jpg"
    },
    "NOVA_PRIMARY_URGENT_CARE": {
        label: "NOVA PRIMARY URGENT CARE",
        path: "../images/bookingmaplist/nova-prime-urgent-care-logo.jpg"
    },
    "FAIR_FAX_URGENT_CARE": {
        label: "FAIR FAX URGENT CARE",
        path: "../images/bookingmaplist/fair-fax-urgent-care.jpg"
    },
    "MCLEAN_MEDICAL_CENTER": {
        label: "MCLEAN MEDICAL CENTER",
        path: "../images/bookingmaplist/mclean-medical-center-logo.jpg"
    },
    "BETHESDA_PEDIATRICS": {
        label: "BETHESDA PEDIATRICS",
        path: "../images/bookingmaplist/Bethesda-Pediatrics-Logo.png"
    },
    "FAMILY_HEALTHH_CONNECTIONS": {
        label: "FAMILY HEALTHH CONNECTIONS",
        path: "../images/bookingmaplist/family-health-connections-logo.png"
    },
    "LOMACK_LOCATION": {
        label: "LOMACK LOCATION",
        path: "../images/bookingmaplist/lomack-location-logo.jpg"
    },
    "EPIC_CLINIC": {
        label: "EPIC CLINIC",
        path: "../images/bookingmaplist/epic-clinic-logo.jpg"
    },
    "TMC_LOCATION": {
        label: "TMC LOCATION",
        path: "../images/bookingmaplist/tmc-location-logo.png"
    },
    "MEDICAL_ACCESS_ALEXANDRIA": {
        label: "MEDICAL ACCESS ALEXANDRIA",
        path: "../images/bookingmaplist/medical-access-alexandria-logo.png"
    },
    "MEDICAL_ACCESS_BELTSVILLE": {
        label: "MEDICAL ACCESS BELTSVILLE",
        path: "../images/bookingmaplist/medical-access-beltsville.png"
    },
    "MEDICAL_ACCESS_ROCKVILLE": {
        label: "MEDICAL ACCESS ROCKVILLE",
        path: "../images/bookingmaplist/medical-access-rockville-logo.png"
    },
    "MEDICAL_ACCESS_WOODBRIDGE": {
        label: "MEDICAL ACCESS WOODBRIDGE",
        path: "../images/bookingmaplist/medical-access-woodbridge-logo.png"
    },
    "VICTORIA_MEDICAL_CENTER": {
        label: "VICTORIA MEDICAL CENTER",
        path: "../images/bookingmaplist/victoria-medical-center-logo.png"
    },
    "QUALIT_CARE_PHARMACY_BALTIMORE": {
        label: "QUALIT CARE PHARMACY BALTIMORE",
        path: "../images/bookingmaplist/qualit-care-pharmacy-baltimore.png"
    },
    "DEFAULT_LOGO": {
        label: "DEFAULT LOGO",
        path: "../images/bookingmaplist/default-company-location-logo.png"
    },
    "VIGINIA_PEDICACTRICS_LOGO": {
        label: "VIGINIA PEDICACTRICS LOGO",
        path: "../images/bookingmaplist/viginia-pedicactris-logo.jpg"
    },
    "WONDER_YEARS_LOGO": {
        label: "WONDER YEARS LOGO",
        path: "../images/bookingmaplist/wonder-years-logo-150.png"
    },
    "HEALTHPLEX PEDIATRICS ASSOCIATION ALEXANDRIA": {
        label: "HEALTHPLEX_PEDIATRICS_ASSOCIATION_ALEXANDRIA",
        path: "../images/bookingmaplist/healthplex-pediatrics-association-alexandria.png"
    },
    "ALOE WELLNESS": {
        label: "ALOE_WELLNESS",
        path: "../images/bookingmaplist/Aloe-Wellness.png"
    },
    "RFOM NURSE PARCTITIONER CLINIC": {
        label: "RFOM_NURSE_PARCTITIONER_CLINIC",
        path: "../images/bookingmaplist/RFOM-Nurse-Practitioner-Clinic.png"
    },
    "CITY WORLD FAMILY CLINIC": {
        label: "CITY_WORLD_FAMILY_CLINIC",
        path: "../images/bookingmaplist/city-world-family-clinic.png"
    },
    "WEST END PEDS": {
        label: "WEST_END_PEDS",
        path: "../images/bookingmaplist/west-end-peds-logo.png"
    },
    "BIO TRENDS": {
        label: "BIO_TRENDS",
        path: "../images/bookingmaplist/bio-trends-location-logo.png"
    },
    "REVERED MEDICAL": {
        label: "REVERED_MEDICAL",
        path: "../images/bookingmaplist/revered-medical-location-logo.png"
    },
    "MAIN STREET PHARMACY": {
        label: "MAIN_STREET_PHARMACY",
        path: "../images/bookingmaplist/main-street-pharmacy-location.png"
    },
    "HIRSCH PEDIATRICS": {
        label: "HIRSCH_PEDIATRICS",
        path: "../images/bookingmaplist/hirsch-pediatrics.png"
    },
    "ARLINGTON WALK IN CLINIC": {
        label: "ARLINGTON_WALK_IN_CLINIC",
        path: "../images/bookingmaplist/Arlington-Walk-In-Clinic-location.png"
    },
    "CORDIAL HEALTH CARE": {
        label: "CORDIAL_HEALTH_CARE",
        path: "../images/bookingmaplist/Cordial-Health-Care.png"
    },
    "QUICK COVID TEST LLC": {
        label: "QUICK_COVID_TEST_LLC",
        path: "../images/bookingmaplist/Quick-COVID-Test-LLC-thumb.png"
    },
    "KIDZ_DOC": {
        label: "KIDZ DOC",
        path: "../images/bookingmaplist/kidz-doc-logo.png"
    },
    "ONE_TENT_HEALTH": {
        label: "ONE TENT HEALTH",
        path: "../images/bookingmaplist/one-tent-health-logo.png"
    },
    "TRUE_VINE": {
        label: "TRUE VINE",
        path: "../images/bookingmaplist/true-vine-logo.png"
    },
    "TRUE_VINE_HEALTHCARE": {
        label: "TRUE VINE HEALTHCARE",
        path: "../images/bookingmaplist/true-vine-healthcare.png"
    },
    "PREMIER_CHOICE": {
        label: "PREMIER CHOICE",
        path: "../images/bookingmaplist/premier-choice-icon.png"
    },
};
export default data;