import React, { Component } from "react";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from 'moment';
import { getMpsPaymentDataById } from "../../../../services/clinicPortalServices/mpsPaymentServices";
import PatientDetails from "../../patients/clinicPatientGrid/patientDetails";
import InsuranceDetails from "../../insurance/clinicInsuranceGrid/insuranceDetails";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class EditBtnCellRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			patientName: props.data.patient_id.first_name + " " + props.data.patient_id.last_name,
			orderDate: moment(props.data.test_info.collected, "YYYYMMDDHHmmss").format("MM/DD/YYYY"),
			orderFacility: props.data.facility_source,
			showPaymentInfo: false,
			showPatientPopup: false,
			showInsurancePopup: false,
			mode: props.data.mps_payment_id ? "update" : "create",
			errors: [],
			chart_number: "",
			location: "",
			total_amount: 0,
			paid_amount: 0,
			due_amount: 0,
			status: "",
			notes: [],
			records: [],
		};
	}

	handleShow = () => {
		this.setState({ showPaymentInfo: true });
		getMpsPaymentDataById(this.props.data.mps_payment_id._id).then(res => {
			if (res && res.data) {
				this.setState({
					chart_number: res.data.chart_number,
					location: res.data.location,
					total_amount: res.data.total_amount,
					paid_amount: res.data.paid_amount,
					due_amount: res.data.due_amount,
					status: Number(res.data.due_amount) > 0 ? "Outstanding" : "Paid",
					notes: res.data.notes,
					records: res.data.records,
					paid_from: res.data.paid_from,
					card_holder_info: res.data.card_holder_info
				})
			}
		})
	}

	handleShowPatientPopup = () => {
		this.setState({ showPatientPopup: true }, () => {
			let elements = document.getElementsByClassName("removeTabIndexModal");
			if (elements.length) {
				for (let i = 0; i < elements.length; i++) {
					let dialog = elements[i];
					dialog.parentNode.removeAttribute('tabindex');
				}
			}
		});
	}

	handleShowInsurancePopup = () => {
		this.setState({ showInsurancePopup: true });
	}

	handleClose = () => {
		this.setState({
			showPaymentInfo: false,
			showPatientPopup: false,
			showInsurancePopup: false,
			errors: [],
			chart_number: "",
			location: "",
			total_amount: "",
			paid_amount: "",
			due_amount: "",
			status: "",
			notes: [],
			records: []
		});
	};

	renderTooltipPaymentInfo = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Payment Info
		</Tooltip>
	)

	renderTooltipPatientEdit = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Patient
		</Tooltip>
	)

	renderTooltipEditInsurance = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Edit Insurance
		</Tooltip>
	)

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipPatientEdit}
				>
					<button onClick={this.handleShowPatientPopup} className="edit-order-btn">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="18px" style={{ marginTop: "-3px" }}>
							<path d="M223.1 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 223.1 256zM274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512h286.4c-1.246-5.531-1.43-11.31-.2832-17.04l14.28-71.41c1.943-9.723 6.676-18.56 13.68-25.56l45.72-45.72C363.3 322.4 321.2 304 274.7 304zM371.4 420.6c-2.514 2.512-4.227 5.715-4.924 9.203l-14.28 71.41c-1.258 6.289 4.293 11.84 10.59 10.59l71.42-14.29c3.482-.6992 6.682-2.406 9.195-4.922l125.3-125.3l-72.01-72.01L371.4 420.6zM629.5 255.7l-21.1-21.11c-14.06-14.06-36.85-14.06-50.91 0l-38.13 38.14l72.01 72.01l38.13-38.13C643.5 292.5 643.5 269.7 629.5 255.7z" />
						</svg>
					</button>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipEditInsurance}
				>
					<button onClick={this.handleShowInsurancePopup} className="edit-order-btn">
						<i className="fas fa-address-card"></i>
					</button>
				</OverlayTrigger>
				{
					this.props.data.mps_payment_id && Number(this.props.data.mps_payment_id.due_amount) === 0
					&&
					<OverlayTrigger
						placement="top"
						delay={{ show: 50, hide: 100 }}
						overlay={this.renderTooltipPaymentInfo}
					>
						<button onClick={() => this.handleShow()} className="edit-order-btn">
							<i className="fas fa-info-circle"></i>
						</button>
					</OverlayTrigger>
				}

				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPaymentInfo}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>
							Payment Info
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<label>Total Amount</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={"$ " + this.state.total_amount}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Paid From</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.paid_from === 'mpsView' ? "MPS Page" : (this.state.paid_from === 'Outside of MRN' ? "Outside of MRN" : "Patient Portal")}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Email</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.email ? this.state.card_holder_info.email : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Name</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.name ? this.state.card_holder_info.name : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder Country</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.country ? this.state.card_holder_info.country : ""}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Card Holder ZIP Code</label>
									<input
										type="text"
										className="form-control"
										disabled
										value={this.state.card_holder_info && this.state.card_holder_info.zip ? this.state.card_holder_info.zip : ""}
									/>
								</div>
							</div>
							<div className="col-12 mt-3">
								<label>
									Notes
								</label>
							</div>
							<div className="col-12">
								{
									this.state.notes.length > 0
									&&
									this.state.notes.map((note, index) => {
										return (
											<div className="row" key={index}>
												<div className="col-md-8">
													<div className="form-group">
														<input
															type="text"
															placeholder="Note"
															value={note.note}
															name="note"
															className="form-control"
															disabled
														/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group">
														<input
															value={note.applied_date}
															className="form-control"
															disabled
														/>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showPatientPopup}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton>
						<Modal.Title>Patient Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientDetails
							data={this.props.data.patient_id}
							order={this.props.data}
							context={this.props.context}
							handleClose={this.handleClose}
							type='mpsGrid'
						/>
					</Modal.Body>
				</ModalStyled>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.showInsurancePopup}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Edit Insurance Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InsuranceDetails
							data={this.props.data}
							context={this.props.context}
							handleClose={this.handleClose}
							type="updateFromInsuranceBilling"
						/>
					</Modal.Body>
				</ModalStyled>
			</div >
		);
	}
}
