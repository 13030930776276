import { apiInterfaceForJson } from "../common/apiManager";

export const fetchVideos = () => {
    return apiInterfaceForJson("/video/v1/", "GET");
};

export const searchVideo = (filterParams) => {
    return apiInterfaceForJson("/video/v1/search", "POST", filterParams);
};


export const getVideoDataById = (videoId) => {
    return apiInterfaceForJson("/video/v1/" + videoId, "GET");
};

export const createVideo = (videoDetails) => {
    return apiInterfaceForJson("/video/v1/", "POST", videoDetails);
};

export const updateVideo = (videoDetails) => {
    return apiInterfaceForJson("/video/v1/", "PUT", videoDetails);
};
