import React, { Component } from "react";
import { createPassowrd } from "../../../services/clinicPortalServices/loginService";
import { isUserLoggedIn, getPatientAuthToken, getDriverAuthToken } from "../../../services/common/util";
import { logout } from "../../../services/clinicPortalServices/loginService";
import { logoutDriver } from "../../../services/driverPortalServices/loginService";
import { logoutPatient } from "../../../services/patientPortalServices/loginService";

class UserPortalCreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            password: "",
            confirmPassword: "",
            isError: false,
            isSuccess: false,
            isEqual: false
        };
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, isEqual: false, loading: false });
    };

    createNewPassword = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({ isEqual: true, loading: false })
            return;
        }
        const userId = this.props.match.params.userId;
        const createPasswordkey = this.props.match.params.createPasswordKey;

        createPassowrd(userId, createPasswordkey, this.state.password)
            .then((res) => {
                this.setState({
                    loading: false,
                    isSuccess: true
                });
                if (isUserLoggedIn())
                    logout();
                if (getDriverAuthToken())
                    logoutDriver();
                if (getPatientAuthToken())
                    logoutPatient();
                this.props.history.push("/clinic");
            })
    };

    render() {
        return (
            <div className="content login-content" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div
                                        className="col-md-12 col-lg-6 login-right"
                                        style={{ border: "none" }}
                                    >
                                        <h4>
                                            Welcome to American Gene Technologies Results Portal
                                            <sup>TM</sup>
                                        </h4>
                                        <br />
                                        <p style={{ fontWeight: "500" }}>
                                            Please enter your information to access your Dashboard.
                                        </p>
                                        <p>
                                            Access to the AGT Portal is restricted solely to
                                            authorized users and is monitored for administrative and
                                            security purpose by the AGT team. All users expressly
                                            consent to such monitoring at time of portal registration.
                                            Any use of this system must be in compliance with AGT
                                            policies, procedures and applicable laws. Unauthorized
                                            access or use of this system may result in portal
                                            termination and civil or criminal liability.
                                        </p>
                                        <p>&copy; 2023 American Gene Technologies</p>
                                    </div>
                                    <div className="col-md-12 col-lg-5 login-right">
                                        <form onSubmit={this.createNewPassword}>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Create a new Password{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-bold">
                                                    Confirm Password Again{" "}
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={this.state.confirmPassword}
                                                    onChange={this.handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                            {
                                                this.state.isError && <div style={{ color: "red" }}>Invalid Credientials</div>
                                            }
                                            {
                                                this.state.isEqual && <div style={{ color: "red" }}>Password Does Not Matched</div>
                                            }
                                            <button
                                                className="btn btn-primary btn-block btn-lg login-btn"
                                                type="submit" disabled={this.state.loading}
                                            >
                                                {this.state.loading && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }}></i>}
                                                {this.state.loading && <span>Reseting Password ...</span>}
                                                {!this.state.loading && !this.state.isSuccess && <span>Reset Password</span>}
                                                {this.state.isSuccess && !this.state.loading && <span>Password Reset Successfully</span>}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserPortalCreatePassword;
