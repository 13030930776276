import React, { Component } from "react";
import { Modal, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ModalStyled } from "../../../../theme/customizedStyleComponents";

export default class CancelReasonComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
		}
	}

	handleShow = () => {
		this.setState({ show: true });
	}

	handleClose = () => {
		this.setState({ show: false });
	}

	renderTooltipEmail = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Reason for cancellation
		</Tooltip>
	);

	render() {
		return (
			<div>
				{
					this.props.data.is_cancel &&
					<OverlayTrigger
						placement="top"
						delay={{ show: 100, hide: 400 }}
						overlay={this.renderTooltipEmail}
					>
						<button onClick={this.handleShow} className="edit-order-btn">
							<i className="fa fa-file-text" aria-hidden="true"></i>
						</button>
					</OverlayTrigger>
				}


				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Reason for cancellation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12">
									{this.props.data.cancel_reason}
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		)
	}
}