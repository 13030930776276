import React, { Component, memo } from "react";
import { AgGridReact } from 'ag-grid-react';
import moment from "moment";
import SearchMenu from "./searchMenu";

import {
	fetchPatientWithQuery,
} from "../../../../services/clinicPortalServices/patientSearchService";
import { getUserRole, getFullAddress, abortApiCalls, releaseApiCalls, processCellForClipboard, defaultExcelExportParams, getUserDetails } from "../../../../services/common/util";
import { auditEnums } from "../../../../services/common/constants";
import { getCompanyWithFacility } from "../../../../services/clinicPortalServices/companyServices";
import _ from 'lodash';
import { createAudit } from "../../../../services/clinicPortalServices/auditService";
import { getPlateList } from "../../../../services/limsPortalServices/plateService";
import { ThemeContext } from "../../../../theme/ThemeProvider";
import { revertPatientsForCorrection } from "../../../../services/clinicPortalServices/mpsPaymentServices";
import Swal from "sweetalert2";
import toastr from "toastr";

class ClinicPatientGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isArchived: false,
			user_role: getUserRole(),
			searchFilters: {
				facility_id: [],
				type: "fixed_incorrect"
			},
			showQrScanner: false,
			scannedPatientId: "",
			patientDetails: {},
			gridName: "Default",
			columnDefs: [
				{
					headerName: "What's corrected?",
					minWidth: 220,
					valueGetter: params => {
						if (params.data.is_fixed_incorrect && (params.data.patient_insurance_ids.filter(item => item.is_fixed_incorrect).length > 0)) {
							return "Patient and Insurance Both";
						} else if (params.data.is_fixed_incorrect) {
							return "Patient is corrected";
						} else if (params.data.patient_insurance_ids.filter(item => item.is_fixed_incorrect).length > 0) {
							return "Insurance is corrected";
						}
					},
					resizable: true,
				},
				{
					headerName: "Patient ID",
					field: "_id",
					minWidth: 220,
					resizable: true,
				},
				{
					headerName: "First Name",
					field: "first_name",
					minWidth: 200,
					resizable: true,
				},
				{
					headerName: "Last Name",
					field: "last_name",
					minWidth: 150,
					resizable: true,
				},
				{
					colId: 'date_of_birth',
					headerName: "Date Of Birth",
					field: "date_of_birth",
					minWidth: 150,
					maxWidth: 150,
					sortable: true,
					cellRenderer: function (params) {
						return params.data.date_of_birth ? moment(params.data.date_of_birth, "YYYY-MM-DD").format("MM/DD/YYYY") : "";
					},
				},
				{
					headerName: "Gender",
					field: "gender",
					minWidth: 100,
					maxWidth: 100,
				},
				{
					headerName: "MRN",
					field: "mrn",
					minWidth: 150,
					resizable: true,
					sortable: true
				},
				{
					headerName: "Email",
					field: "email",
					minWidth: 150,
					resizable: true,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-envelope"></i> {params.data.email ? params.data.email : ""}</span>
					})
				},
				{
					headerName: "Phone",
					field: "mobile",
					minWidth: 170,
					maxWidth: 170,
					cellRenderer: memo(params => {
						return <span><i className="fas fa-phone-alt"></i> {params.data.mobile ? params.data.mobile : ""}</span>
					})
				},
				{
					headerName: "Associated Facility",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.facility_id && params.data.facility_id.name) {
							return params.data.facility_id.name;
						}
					},
				},
				{
					headerName: "Address",
					minWidth: 200,
					resizable: true,
					valueGetter: function addColumns(params) {
						if (params.data.address && params.data.address.address1) {
							return getFullAddress(params.data.address);
						} else {
							return "";
						}
					},
					cellRenderer: memo(params => {
						return <span><i className="fas fa-map-marker-alt"></i> {params.value ? params.value : ""}</span>
					})
				},
				{
					headerName: "Facility",
					minWidth: 170,
					resizable: true,
					sortable: true,
					valueGetter: function (params) {
						if (params.data.facility_id && params.data.facility_id.name) {
							return params.data.facility_id.name;
						}
					},
				},
				{
					headerName: "MRN Registered",
					minWidth: 170,
					resizable: true,
					sortable: true,
					valueGetter: function (params) {
						if (params.data.website_sign_up) {
							return "YES";
						} else {
							return "NO";
						}
					},
				},
				{
					headerName: "Location Chosen",
					minWidth: 170,
					resizable: true,
					sortable: true,
					valueGetter: function (params) {
						if (params.data.website_sign_up && params.data.location_id && params.data.location_id.name) {
							return params.data.location_id.name;
						}
					},
				},
				{
					colId: "created_at",
					headerName: "Time Created",
					field: "createdAt",
					minWidth: 170,
					resizable: true,
					sortable: true,
					cellRenderer: function (params) {
						return params.data.createdAt ? moment.tz(params.data.createdAt, "America/New_York").format("MM/DD/YYYY hh:mm A") : "";
					},
				},
				{
					headerName: "Drivers License",
					field: "driver_license",
					minWidth: 200,
					sortable: true,
					resizable: true
				},
				{
					headerName: "OLD MRN",
					field: "old_mrn",
					minWidth: 150,
					resizable: true,
					sortable: true
				},
				{
					headerName: "Insurance Provider",
					field: "patient_insurance_id.insurance_provider",
					minWidth: 150,
					sortable: true,
					resizable: true
				},
				{
					headerName: "Insurance Member ID",
					field: "patient_insurance_id.insured_member_id",
					minWidth: 200,
					sortable: true,
					resizable: true
				},
				{
					headerName: "Insurance Group Number",
					field: "patient_insurance_id.insured_group_number",
					minWidth: 200,
					sortable: true,
					resizable: true
				},
				{
					headerName: "Relation to insured",
					field: "patient_insurance_id.relation_to_insured",
					minWidth: 200,
					sortable: true,
					resizable: true
				},
				{
					headerName: "Insurance Holder Name",
					minWidth: 200,
					sortable: true,
					resizable: true,
					valueGetter: function (params) {
						return (params.data && params.data.patient_insurance_id && params.data.patient_insurance_id.insured_first_name) ? params.data.patient_insurance_id.insured_first_name + " " + params.data.patient_insurance_id.insured_last_name : "";
					},
				},
				{
					headerName: "Social Security Number",
					field: "patient_insurance_id.social_security_number",
					minWidth: 200,
					sortable: true,
					resizable: true
				},
			],
			paginationNumberFormatter: function (params) {
				return "[" + params.value.toLocaleString() + "]";
			},
			defaultColDef: {
				flex: 1,
				filter: true,
				enableRowGroup: true,
				enablePivot: true,
				enableValue: true,
				sortable: true
			},
			rowGroupPanelShow: "always",
			pivotPanelShow: "always",
			excelStyles: [
				{
					id: "header",
					interior: {
						color: "#aaaaaa",
						pattern: "Solid",
					},
				},
				{
					id: "body",
					interior: {
						color: "#dddddd",
						pattern: "Solid",
					},
				},
			],
			rowData: [],
			expandableRowData: [],
			facilities: [],
			context: { componentParent: this }
		};
		this.loadFacilities(this.state.isArchived);
		getPlateList().then(res => {
			if (res && res.data) {
				this.setState({ plateList: res.data })
			}
		})
	}

	loadFacilities = (isArchived) => {
		let filters = this.state.searchFilters;
		let facilities = this.state.facilities;

		let isAllFacilities = false;
		if (filters.facility_id.length > 0 && filters.facility_id.length === facilities.length && facilities.length > 0) {
			isAllFacilities = true;
		}

		getCompanyWithFacility(isArchived)
			.then((response) => {
				let facilityIds = [];
				facilities = response.data;
				if (facilities.length > 0) {
					facilities.map(fac => {
						facilityIds.push(fac._id);
						return null;
					})
					filters.facility_id = facilityIds;
					this.facilityIds = facilityIds;
				} else {
					filters.facility_id = facilities[0]._id;
				}
				if (!isAllFacilities && window.localStorage.getItem('FACILITY_ID') && JSON.parse(window.localStorage.getItem('FACILITY_ID')).length) {
					filters.facility_id = JSON.parse(window.localStorage.getItem('FACILITY_ID'));
				}
				this.setState({ searchFilters: filters, facilities }, () => {
					this.loadGridData();
				});
			});
	};

	onIsArchivedInformation = (event) => {
		this.setState({ isArchived: event.target.checked });
		this.loadFacilities(event.target.checked);
	}

	handleFiltersChange = (e) => {
		let filters = this.state.searchFilters;
		if (e.target.name === 'facility_id') {
			let { facilities } = this.state;
			let facilityIdChanged = [], isAdded = false, facilitiesIn = [], facilityIdsTemp = [];
			let newFacilityIds = e.target.value;

			if (e.target.value.includes('all')) {
				if (filters.facility_id.length === facilities.length) {
					filters.facility_id = [];
				} else {
					filters.facility_id = _.cloneDeep(this.facilityIds);
				}
			} else {
				if (filters.facility_id.length > newFacilityIds.length) {
					facilityIdChanged = filters.facility_id.find(val => !newFacilityIds.includes(val));
				} else {
					facilityIdChanged = newFacilityIds.find(val => !filters.facility_id.includes(val));
					isAdded = true;
				}
				let facilityChanged = facilities.find(facility => facility._id === facilityIdChanged);
				facilityIdsTemp = _.cloneDeep(filters.facility_id);
				// If company is changed 
				if (facilityChanged.type === 'company') {
					let companyChanged = facilityChanged;
					facilities.forEach(fac => {
						if (fac.parent_id === companyChanged._id) {
							facilitiesIn.push(fac._id);
						}
					});
					let index = facilityIdsTemp.indexOf(companyChanged._id);
					if (facilitiesIn.length > 0) {
						// If company is selected
						if (isAdded) {
							// Add company Id
							facilityIdsTemp.push(companyChanged._id);
							// Add facility Ids
							facilitiesIn.forEach(facId => {
								if (!facilityIdsTemp.includes(facId)) {
									facilityIdsTemp.push(facId);
								}
							});
						} else { // If company is unselected
							// Remove company Id
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
							facilitiesIn.forEach(facId => {
								let index = facilityIdsTemp.indexOf(facId);
								if (index > -1) {
									facilityIdsTemp.splice(index, 1);
								}
							});
						}
					} else {
						isAdded ? facilityIdsTemp.push(companyChanged._id) : facilityIdsTemp.splice(index, 1);
					}
				} else { // If facility is changed
					let companyId = facilityChanged.parent_id;
					let facilitiesIn = [];
					facilities.forEach(fac => {
						if (fac.parent_id === companyId) {
							facilitiesIn.push(fac._id);
						}
					});
					// If facility Id is selected
					if (isAdded) {
						// Add facility Id
						facilityIdsTemp.push(facilityChanged._id);
						if (!filters.facility_id.includes(companyId)) {
							facilityIdsTemp.push(companyId);
						}
					} else { // If facility Id is unselected
						// Remove facility Id
						let index = facilityIdsTemp.indexOf(facilityChanged._id);
						if (index > -1) {
							facilityIdsTemp.splice(index, 1);
						}
						// Remove company Id
						let facilitiesInFilters = [];
						facilitiesIn.forEach(fac => {
							if (facilityIdsTemp.includes(fac)) {
								facilitiesInFilters.push(fac);
							}
						});
						if (facilitiesInFilters.length === 0) {
							index = facilityIdsTemp.indexOf(companyId);
							if (index > -1) {
								facilityIdsTemp.splice(index, 1);
							}
						}
					}
				}
				filters.facility_id = _.cloneDeep(facilityIdsTemp);
			}
			window.localStorage.setItem('FACILITY_ID', JSON.stringify(filters.facility_id));
		} else {
			filters['type'] = e.target.value;
		}
		this.setState({ searchFilters: filters });
		this.loadGridData();
	};

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
	};

	checkRealFacilities = (searchFilters) => {
		let realFacilities = [];
		searchFilters.facility_id.map(facilityId => {
			if (this.state.facilities.find(facility => facility._id === facilityId && facility.type === 'facility')) {
				realFacilities.push(facilityId);
			}
			return null;
		});
		return realFacilities;
	}

	loadGridData = () => {
		abortApiCalls();
		releaseApiCalls();
		this.gridApi.showLoadingOverlay();
		let facilityId = this.checkRealFacilities(this.state.searchFilters);
		fetchPatientWithQuery({ facility_id: facilityId, isForPatientGrid: true, isForMPS: true, type: this.state.searchFilters.type })
			.then((data) => {
				let rowData = [];
				data.data.map(item => {
					if (item.is_fixed_incorrect) {
						if (item.patient_insurance_ids.find(insurance => insurance.is_fixed_incorrect)) {
							item.patient_insurance_ids.map(insurance => {
								if (insurance.is_fixed_incorrect) {
									item.patient_insurance_id = insurance;
									rowData.push(_.cloneDeep(item));
								}
								return null;
							})
						} else {
							item.patient_insurance_id = item.patient_insurance_ids[0];
							rowData.push(_.cloneDeep(item));
						}
					} else {
						if (item.patient_insurance_ids.find(insurance => insurance.is_fixed_incorrect)) {
							item.patient_insurance_ids.map(insurance => {
								if (insurance.is_fixed_incorrect) {
									item.patient_insurance_id = insurance;
									rowData.push(_.cloneDeep(item));
								}
								return null;
							})
						}
					}
					return null;
				})
				this.setState({ rowData: rowData });
				if (!rowData.length) {
					this.gridApi.showNoRowsOverlay();
				} else {
					this.gridApi.hideOverlay();
				}
			});
	};

	onFilterTextChange = (e) => {
		this.gridApi.setQuickFilter(e.target.value);
	};

	onBtnExport = () => {
		if (this.state.rowData && this.state.rowData.length) {
			this.gridApi.exportDataAsExcel({});
			const userData = JSON.parse(getUserDetails());
			if (userData && userData.role) {
				if (userData.role === "MPSInsurance") {
					Swal.fire({
						title: 'Patient Correction Grid Exported',
						text: 'You just downloaded the corrected patients.  Please confirm the spreadsheet is populated.  Once you click remove, these records that are visible to you now will be permanently removed from this grid.',
						icon: 'info',
						showCancelButton: true,
						confirmButtonText: 'Remove',
						cancelButtonText: 'Keep',
						customClass: {
							container: window.localStorage.getItem('appTheme') === 'Dark' && /clinic|lims/.test(window.location.pathname.split("/")[1]) && 'dark-swal',
							cancelButton: 'order-1',
							confirmButton: 'order-2'
						},
						allowOutsideClick: false
					}).then((result) => {
						if (result.isConfirmed) {
							let patientInsuranceIds = [];
							let patientIds = this.state.rowData.map(patient => {
								if (patient.patient_insurance_id && patient.patient_insurance_id._id) {
									patientInsuranceIds.push(patient.patient_insurance_id._id)
								}
								return patient._id;
							});
							if ((patientIds && patientIds.length) || (patientInsuranceIds && patientInsuranceIds.length)) {
								revertPatientsForCorrection({ patientIds: patientIds, patientInsuranceIds: patientInsuranceIds }).then(res => {
									this.loadGridData();
								})
							}
						}
					})
				}
				const auditData = {
					identifier: auditEnums.IDENTIFIERS.ExportRecord,
					event_type: auditEnums.EVENTTYPES.CorretedPatientGridForMPSExported,
					user_id: userData._id,
					user_name: userData.user_name + " (" + userData.role + ")",
					update_string: auditEnums.EVENTTYPES.CorretedPatientGridForMPSExported
				};
				createAudit(auditData);
			}
		} else {
			toastr.warning("Grid doesn't have data to export.")
		}
	};

	clearFilter = () => {
		this.gridApi.setFilterModel(null);
		this.gridApi.setQuickFilter(null);
		document.getElementById("reset-form").value = "";
		const filters = this.state.searchFilters;
		if (["agtsuperadmin", "agtadmin", "labtech"].includes(getUserRole().toLowerCase())) {
			filters.facility_id = [];
		} else {
			if (this.state.facilities.length > 1) {
				filters.facility_id = this.facilityIds;
			} else {
				filters.facility_id = this.state.facilities[0]._id;
			}
		}
		filters.type = 'all';
		window.localStorage.removeItem("FACILITY_ID");
		this.setState({ searchFilters: filters });
		this.loadGridData();
	};

	render() {
		let columnDefs = /audit/.test(getUserRole().toLowerCase()) ? this.state.columnDefs.filter(x => x.headerName !== 'Actions') : this.state.columnDefs;
		if (/facilityuser|facilityadmin/.test(getUserRole().toLowerCase()))
			columnDefs = columnDefs.filter(x => x.headerName !== 'Internal Note');
		return (
			<div className="clinic-contain">
				<SearchMenu
					onIsArchivedInformation={(event) => { this.onIsArchivedInformation(event) }}
					isArchived={this.state.isArchived}
					facilityId={this.state.searchFilters.facility_id}
					type={this.state.searchFilters.type}
					handleFiltersChange={this.handleFiltersChange}
					facilities={this.state.facilities}
					onFilterTextChange={this.onFilterTextChange}
					clearFilter={this.clearFilter}
					onBtnExport={this.onBtnExport}
					user_role={this.state.user_role}
				/>

				<div
					style={{
						width: "100%",
						height: "calc(100vh - 250px)",
						padding: "15px",
					}}
				>
					<div
						id="myGrid"
						style={{
							height: "100%",
							width: "100%",
						}}
						className="ag-theme-alpine"
					>
						<ThemeContext.Consumer>
							{({ themeName }) => (
								<div
									id="myGrid"
									style={{
										height: "100%",
										width: "100%",
									}}
									className={themeName === "Light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
								>
									<AgGridReact
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										onGridReady={this.onGridReady}
										rowData={this.state.rowData}
										pagination={true}
										paginationPageSize={20}
										paginationNumberFormatter={this.state.paginationNumberFormatter}
										excelStyles={this.state.excelStyles}
										context={this.state.context}
										overlayNoRowsTemplate={this.state.searchFilters.facility_id.length === 0 ? "No facility has been selected, please choose a facility" : "No Rows To Show"}
										defaultExcelExportParams={defaultExcelExportParams}
										processCellForClipboard={processCellForClipboard}
									/>
								</div>
							)}
						</ThemeContext.Consumer>
					</div>
				</div>
			</div>
		);
	}
}

export default ClinicPatientGrid;
