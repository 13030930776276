import React, { Component } from "react";
import { Modal, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import toastr from 'toastr';
import { isValidEmail, getUserDetails } from "../../../services/common/util";
import { sendSupportRequest } from "../../../services/appointmentBookingServices/emailService";
import MessageQuestions from "../../Icons/messageQuestions";
import { fetchFacilitiesForOrders } from "../../../services/clinicPortalServices/facilityServices";
import { submitCommunication } from "../../../services/clinicPortalServices/communicationService";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

export default class SupportPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			supportType: "",
			supportName: "",
			supportEmail: "",
			supportFacilityName: "",
			supportSubject: "",
			supportDescription: "",
			errors: [],
			supportTypeList: [
				'Lab related request',
				'Website issue',
			],
			facilities: []
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	}

	componentDidMount() {
		fetchFacilitiesForOrders().then(res => {
			let facilities = [];
			let supportFacilityName = res.data?.length > 0 ? res.data[0].name : '';
			res.data.map((facilityItem) => {
				facilities.push(facilityItem);
				return null;
			})
			this.setState({ facilities: facilities, supportFacilityName: supportFacilityName });
		})
	}

	handleShow = () => {
		this.setState({ show: true });
		if (getUserDetails() && JSON.parse(getUserDetails())) {
			const userDetails = JSON.parse(getUserDetails());
			this.setState({
				supportName: userDetails.first_name + ' ' + userDetails.last_name,
				supportEmail: userDetails.email,
				supportType: 'Lab related request',
				supportFacilityName: userDetails.facilityIds && userDetails.facilityIds.length ? userDetails.facilityIds[0].name : this.state.facilities[0].name,
				supportSubject: "Lab - ",
				supportDescription: "",
				errors: []
			});
		}
	};

	handleClose = () => {
		this.setState({
			show: false
		});
	};

	handleChange = (e) => {
		let stateVal = e.target.value;
		if (e.target.name === "supportSubject") {
			if (this.state.supportType === 'Lab related request') {
				if (e.target.value.length < 5) {
					stateVal = "Lab - ";
				} else {
					stateVal = e.target.value;
				}
			} else {
				if (e.target.value.length < 13) {
					stateVal = "IT Support - ";
				} else {
					stateVal = e.target.value;
				}
			}
		}
		if (e.target.name === "supportType") {
			if (e.target.value === "Lab related request") {
				const subjectval = "Lab - " + this.state.supportSubject.split('IT Support - ')[1];
				this.setState({ supportSubject: subjectval });
			} else {
				const subjectval = "IT Support - " + this.state.supportSubject.split('Lab - ')[1];
				this.setState({ supportSubject: subjectval });
			}
		}
		this.setState({ [e.target.name]: stateVal });
		if (e.target.value) {
			this.setState({ errors: this.state.errors.filter(item => item !== e.target.name) })
		}
	}

	renderTooltipSupport = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Send Support Request
		</Tooltip>
	);

	sendRequest = () => {
		let errors = [];
		if (!this.state.supportName) {
			errors.push("supportName");
		}
		if (!this.state.supportEmail) {
			errors.push("supportEmail");
		}
		if (this.state.supportEmail && !isValidEmail(this.state.supportEmail)) {
			errors.push("supportEmailNotValid");
		}
		if (!this.state.supportType) {
			errors.push("supportType");
		}
		if (!this.state.supportFacilityName) {
			errors.push("supportFacilityName");
		}
		if (!this.state.supportSubject) {
			errors.push("supportSubject");
		}
		if (!this.state.supportDescription) {
			errors.push("supportDescription");
		}
		if (errors.length) {
			this.setState({ errors: errors });
		} else {
			const supportRequestDetails = {
				name: this.state.supportName,
				email: this.state.supportEmail,
				supportType: this.state.supportType,
				facilityName: this.state.supportFacilityName,
				subject: this.state.supportSubject,
				description: this.state.supportDescription.replaceAll("\n", "<br/>")
			}
			const comment = {
				subject: this.state.supportSubject,
				message: this.state.supportDescription,
				facility: this.state.supportFacilityName,
			}
			submitCommunication(comment);
			sendSupportRequest(supportRequestDetails).then(response => {
				this.handleClose();
				toastr.success('Successfully requested!')
			}).catch(error => {
				console.log(error);
			})
		}
	}

	render() {
		return (
			<div>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipSupport}
				>
					<button
						className="p-0 support-request-btn header-icon"
						onClick={this.handleShow}
					>
						<MessageQuestions width={35} height={25} color="#0369b3" />
					</button>
				</OverlayTrigger>
				<ModalStyled
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title>Send Support Request</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form>
							<div className="row form-row">
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Name <abbr className="text-danger">*</abbr></label>
										<input
											type="text"
											name="supportName"
											value={this.state.supportName}
											onChange={this.handleChange}
											className="form-control"
										/>
										<div className={this.hasError("supportName") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<label>Email <abbr className="text-danger">*</abbr></label>
										<input
											type="text"
											name="supportEmail"
											value={this.state.supportEmail}
											onChange={this.handleChange}
											className="form-control"
										/>
										<div className={this.hasError("supportEmail") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
										<div className={this.hasError("supportEmailNotValid") ? "inline-errormsg" : "hidden"} style={{ width: "200px" }}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This email is not valid</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label>What type of support do you need? <abbr className="text-danger">*</abbr></label>
										<select
											type="text"
											name="supportType"
											value={this.state.supportType}
											onChange={this.handleChange}
											className="form-control"
										>
											{
												this.state.supportTypeList.map((typeItem, index) => {
													return (
														<option value={typeItem} key={index}>{typeItem}</option>
													)
												})
											}
										</select>
										<div className={this.hasError("supportType") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label>Facility Name <abbr className="text-danger">*</abbr></label>
										<select
											type="text"
											name="supportFacilityName"
											value={this.state.supportFacilityName}
											onChange={this.handleChange}
											className="form-control"
										>
											{
												this.state.facilities.map((fac, index) => {
													return (
														<option value={fac.name} key={index}>{fac.name} {fac.archived === "archived" ? " ( Archived ) " : ""}</option>
													)
												})
											}
										</select>
										<div className={this.hasError("supportFacilityName") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label>Subject <abbr className="text-danger">*</abbr></label>
										<input
											type="text"
											name="supportSubject"
											value={this.state.supportSubject}
											onChange={this.handleChange}
											className="form-control"
										/>
										<div className={this.hasError("supportSubject") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
								<div className="col-12">
									<div className="form-group">
										<label>Describe Issue <abbr className="text-danger">*</abbr></label>
										<textarea
											rows={5}
											name="supportDescription"
											value={this.state.supportDescription}
											onChange={this.handleChange}
											className="form-control"
										/>
										<div className={this.hasError("supportDescription") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true"> &nbsp;This field is required</i>
										</div>
									</div>
								</div>
							</div>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={() => this.sendRequest()}>
							Send Request
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
