import { apiInterfaceForJson } from "../common/apiManager";

export const getMpsPaymentDataById = (id) => {
    return apiInterfaceForJson("/mpsPayment/v1/" + id, "GET");
};

export const createMpsPayment = (data) => {
    return apiInterfaceForJson("/mpsPayment/v1/", "POST", data);
};

export const updateMpsPayment = (data) => {
    return apiInterfaceForJson("/mpsPayment/v1/", "PUT", data);
};

export const getCheckoutSessionIdForMpsPayment = (details) => {
    return apiInterfaceForJson("/mpsPayment/v1/getCheckoutSessionId", "POST", details);
}

export const successPaymentForMpsPayment = (details) => {
    return apiInterfaceForJson("/mpsPayment/v1/successPayment", "POST", details);
}

export const getMPSPaymentData = (info) => {
    return apiInterfaceForJson("/mpsPayment/v1/getMPSPaymentData", "POST", info);
}

export const revertPatientsForCorrection = (data) => {
    return apiInterfaceForJson("/patient/v1/revertPatientsForCorrection", "POST", data);
} 