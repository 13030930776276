import { Light } from './schemes/LightTheme';
import { Dark } from './schemes/DarkTheme';

export function themeCreator(theme) {
  return themeMap[theme];
}

const themeMap = {
  Dark,
  Light,
};
