import React, { Component } from "react";
import $ from 'jquery';
// import { Badge } from "@mui/material"
import { isUserLoggedIn, getUserRole, getUserDetails } from "../../../services/common/util";
// import { unreadCommunications } from "../../../services/clinicPortalServices/communicationService";
import { Link } from "react-router-dom";

class ClinicSidebar extends Component {
    constructor(props) {
        super(props);
        let userName = '';
        if (getUserDetails()) {
            const userDetails = JSON.parse(getUserDetails());
            userName = userDetails.user_name;
        }
        this.state = {
            subMenu: '',
            // newCommunications: 0,
            userName: userName,
        }
    }

    socketConnect = () => {
        if (window.socket) {
            // window.socket.on('updateReadCommunication', (responseData) => {
            //     this.getUnreadCommunications();
            // });
        }
    };

    componentDidMount() {
        // this.getUnreadCommunications();
        setTimeout(() => {
            this.socketConnect();
        }, 500)

        $('.groupMenu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).removeClass('active')
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').addClass('active');
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').removeClass('active');
            }
        });
        // $('#groupMenu ul.submenu').addClass('active').trigger('click');

        if (/requestsamplecancel$|orderurgency$|orders$|resultlinks$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'orders' });
        }
        if (this.props.location.pathname.includes('requisitions/')) {
            this.setState({ subMenu: 'orders' });
        }
        if (/facilitycommunications$|patientcommunications$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'communication' });
        }
        if (/patients$|insurance$|patientsduplicated$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'patientmenu' });
        }
        if (/invoices$|insuranceprovider$|mpsview$|mpscorrectedview$|patientpayment$|selfpayment$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'billing' });
        }
        if (/mpspayment$/.test(this.props.location.pathname.split('/').at(-2).toLowerCase())) {
            this.setState({ subMenu: 'billing' });
        }
        if (/statereports$|dailyreports$|failednotificationreports$|graphordertotals$|graphselfcheckins$|audit$|brooksLimsRecord$|pcpositivereport$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'reports' });
        }

        if (/facility$|facilitytestmanagement$|physician$|user$|location$|company$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'facilitymenu' });
        }
        if (/facilityinventory$|facilitypickuptimes$|driver$|driveravailability$|drivershiftinfo$|deliverypickuporder$|realtimemapview$|visualdriverview$|routetemplate$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'logisticmenu' });
        }
        if (/notificationtype$|facilitynotificationmanagement$|logisticnotificationmanagement$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'notificationmenu' });
        }
        if (/superadminsettings$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'adminmenu' });
        }
        if (/test$|maintenance$|inventory$/.test(this.props.location.pathname.split('/').at(-1).toLowerCase())) {
            this.setState({ subMenu: 'labmanagementmenu' });
        }
    }
    // getUnreadCommunications = () => {
    //     if (isUserLoggedIn()) {
    //         unreadCommunications().then(res => {
    //             if (res.data) {
    //                 this.setState({ newCommunications: res.data.length });
    //             }
    //         })
    //     }
    // }
    render() {
        const { location } = this.props
        let pathname = location.pathname
        return (
            <div
                className="clinic-sidebar"
                id="sidebar"
            >
                <nav className="clinicboard-menu">
                    <ul className="clinicMenu">
                        {
                            getUserRole().toLowerCase() !== 'mpsinsurance'
                                ?
                                <>
                                    {
                                        getUserRole() && /^agtsuperadmin|^agtadmin/.test(getUserRole().toLowerCase()) &&
                                        <li className={pathname.split('/').at(-1) === 'dashboard' ? "groupMenu active" : "groupMenu"}>
                                            <Link to="/clinic/dashboard">
                                                <i className="fas fa-columns mr-2"></i>
                                                <span className="manuLabel">Dashboard</span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        !/accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#orders" className={(this.state.subMenu === 'orders') ? "subdrop" : ""}>
                                                <i className="fab fa-first-order mr-2"></i>
                                                <span className="manuLabel">Orders</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'orders') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'orders' ? "active" : ""}>
                                                    <Link to="/clinic/orders">
                                                        Orders
                                                    </Link>
                                                </li>
                                                <li className={pathname.includes('requisitions/') ? "active" : ""}>
                                                    <Link to="/clinic/requisitions/view">
                                                        Requisitions
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'orderUrgency' ? "active" : ""}>
                                                    <Link to="/clinic/orderUrgency">
                                                        SARS-CoV-2 Orders of Urgency
                                                    </Link>
                                                </li>
                                                {
                                                    !/salesperson|salesadmin|facilityuser|facilityadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'requestSampleCancel' ? "active" : ""}>
                                                        <Link to="/clinic/requestSampleCancel">
                                                            Request & Cancel Samples
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    getUserRole() && /^agtsuperadmin|^agtadmin|customerserviceuser/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'resultLinks' ? "active" : ""}>
                                                        <Link to="/clinic/resultLinks">
                                                            Result Links
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {/* {
                                        getUserRole() && /^agtsuperadmin|^agtadmin|facilityadmin|facilityuser|labtech/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'facilityCommunications' ? "groupMenu active" : "groupMenu"}>
                                            <Link to="/clinic/facilityCommunications">
                                                <Badge badgeContent={this.state.newCommunications} color="secondary" className="mr-2" overlap="rectangular">
                                                    <i className="fas fa-comments"></i>
                                                </Badge>
                                                <span className="manuLabel">Communication</span>

                                            </Link>
                                        </li>
                                    } */}
                                    {
                                        !/salesperson|salesadmin|accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#patientmenu" className={(this.state.subMenu === 'patientmenu') ? "subdrop" : ""}>
                                                <i className="fas fa-procedures mr-2"></i>
                                                <span className="manuLabel">Patient Menu</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'patientmenu') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'patients' ? "active" : ""}>
                                                    <Link to="/clinic/patients">
                                                        Patients
                                                    </Link>
                                                </li>
                                                {
                                                    !/labtech/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'insurance' ? "active" : ""}>
                                                        <Link to="/clinic/insurance">
                                                            Patient Insurance
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/labtech|customerserviceuser/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'patientsDuplicated' ? "active" : ""}>
                                                        <Link to="/clinic/patientsDuplicated">
                                                            Possible Patient Duplicates
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /facilityadmin/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'user' ? "active groupMenu" : "groupMenu"}>
                                            <Link to="/clinic/user">
                                                <i className="fas fa-user mr-2"></i>
                                                <span className="manuLabel">User</span>
                                            </Link>
                                        </li>
                                    }
                                    {/* {
                                        getUserRole() && /facilityadmin|facilityuser/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'deliveryPickupOrder' ? "active groupMenu" : "groupMenu"}>
                                            <Link to="/clinic/deliveryPickupOrder">
                                                <i className="fas fa-ambulance mr-2"></i>
                                                <span className="manuLabel">Delivery & Pickup Orders</span>
                                            </Link>
                                        </li>
                                    } */}
                                    {
                                        getUserRole() && /^agtsuperadmin|^agtadmin|^accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#billing" className={(this.state.subMenu === 'billing') ? "subdrop" : ""}>
                                                <i className="fas fa-file-invoice-dollar mr-2"></i>
                                                <span className="manuLabel">Billing</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'billing') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'invoices' ? "active" : ""}>
                                                    <Link to="/clinic/invoices">
                                                        Invoices
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'selfPayment' ? "active" : ""}>
                                                    <Link to="/clinic/selfPayment">
                                                        Self/Pay Payments
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'mpsView' ? "active" : ""}>
                                                    <Link to="/clinic/mpsView">
                                                        Insurance Payments (MPS)
                                                    </Link>
                                                </li>
                                                <li className={(pathname.split('/').at(-2) === 'mpsPayment' && pathname.split('/').at(-1) === 'outstanding') ? "active" : ""}>
                                                    <Link to="/clinic/mpsPayment/outstanding">
                                                        MPS Outstanding
                                                    </Link>
                                                </li>
                                                <li className={(pathname.split('/').at(-2) === 'mpsPayment' && pathname.split('/').at(-1) === 'paid') ? "active" : ""}>
                                                    <Link to="/clinic/mpsPayment/paid">
                                                        MPS Paid
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'mpsCorrectedView' ? "active" : ""}>
                                                    <Link to="/clinic/mpsCorrectedView">
                                                        Corrected Records for MPS
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'patientPayment' ? "active" : ""}>
                                                    <Link to="/clinic/patientPayment">
                                                        Patient $165 Payment
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'insuranceProvider' ? "active" : ""}>
                                                    <Link to="/clinic/insuranceProvider">
                                                        Insurance Provider
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {
                                        !/labtech|facilityadmin|facilityuser|accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#reports" className={(this.state.subMenu === 'reports') ? "subdrop" : ""}>
                                                <i className="fas fa-file-medical-alt mr-2"></i>
                                                <span className="manuLabel">Audit & Reports</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'reports') ? "submenu active" : "submenu"}>
                                                {
                                                    !/salesperson|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'audit' ? "active" : ""}>
                                                        <Link to="/clinic/audit">
                                                            Audit Log
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'brooksLimsRecord' ? "active" : ""}>
                                                        <Link to="/clinic/brooksLimsRecord">
                                                            Brooks LIMS Records
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'stateReports' ? "active" : ""}>
                                                        <Link to="/clinic/reports/stateReports">
                                                            State Reports
                                                        </Link>
                                                    </li>
                                                }
                                                <li className={pathname.split('/').at(-1) === 'dailyReports' ? "active" : ""}>
                                                    <Link to="/clinic/reports/dailyReports">
                                                        End of Day Reports
                                                    </Link>
                                                </li>
                                                {
                                                    !/salesperson|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'failedNotificationReports' ? "active" : ""}>
                                                        <Link to="/clinic/reports/failedNotificationReports">
                                                            Failed SMS/Email Reports
                                                        </Link>
                                                    </li>
                                                }
                                                <li className={pathname.split('/').at(-1) === 'graphOrderTotals' ? "active" : ""}>
                                                    <Link to="/clinic/reports/graphOrderTotals">
                                                        Order Totals Graph
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'graphSelfCheckIns' ? "active" : ""}>
                                                    <Link to="/clinic/reports/graphSelfCheckIns">
                                                        Self Check-In Graph
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'pcPositiveReport' ? "active" : ""}>
                                                    <Link to="/clinic/reports/pcPositiveReport">
                                                        PC Positive Reports
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {
                                        !/labtech|facilityadmin|facilityuser|accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#facilitymenu" className={(this.state.subMenu === 'facilitymenu') ? "subdrop" : ""} >
                                                <i className="fas fa-clinic-medical mr-2"></i>
                                                <span className="manuLabel">Facility Management</span>
                                                <span className="menu-arrow">
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'facilitymenu') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'company' ? "active" : ""}>
                                                    <Link to="/clinic/company">
                                                        Company
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'facility' ? "active" : ""}>
                                                    <Link to="/clinic/facility">
                                                        Facility
                                                    </Link>
                                                </li>
                                                {
                                                    /agtsuperadmin|agtadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'facilityTestManagement' ? "active" : ""}>
                                                        <Link to="/clinic/facilityTestManagement">
                                                            Patient Test Result Access
                                                        </Link>
                                                    </li>
                                                }
                                                <li className={pathname.split('/').at(-1) === 'location' ? "active" : ""}>
                                                    <Link to="/clinic/location">
                                                        Location
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'physician' ? "active" : ""}>
                                                    <Link to="/clinic/physician">
                                                        Physician
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'user' ? "active" : ""}>
                                                    <Link to="/clinic/user">
                                                        User
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {/* {
                                        !/labtech|customerserviceuser|facilityadmin|facilityuser|accountant/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#logisticmenu" className={(this.state.subMenu === 'logisticmenu') ? "subdrop" : ""}>
                                                <i className="fas fa-users-cog mr-2"></i>
                                                <span className="manuLabel">Logistic Management</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'logisticmenu') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'facilityPickupTimes' ? "active" : ""}>
                                                    <Link to="/clinic/facilityPickupTimes">
                                                        Facility Pickup Times
                                                    </Link>
                                                </li>
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'routeTemplate' ? "active" : ""}>
                                                        <Link to="/clinic/routeTemplate">
                                                            Route Templates
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'driverAvailability' ? "active" : ""}>
                                                        <Link to="/clinic/driverAvailability">
                                                            Driver Availability
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'visualDriverView' ? "active" : ""}>
                                                        <Link to="/clinic/visualDriverView">
                                                            Visual Driver Updates
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'realTimeMapView' ? "active" : ""}>
                                                        <Link to="/clinic/realTimeMapView">
                                                            Real-Time Map View
                                                        </Link>
                                                    </li>
                                                }
                                                <li className={pathname.split('/').at(-1) === 'facilityInventory' ? "active" : ""}>
                                                    <Link to="/clinic/facilityInventory">
                                                        Facility Inventory
                                                    </Link>
                                                </li>
                                                {
                                                    !/audit/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'deliveryPickupOrder' ? "active" : ""}>
                                                        <Link to="/clinic/deliveryPickupOrder">
                                                            Delivery & Pickup Orders
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'driverShiftInfo' ? "active" : ""}>
                                                        <Link to="/clinic/driverShiftInfo">
                                                            Driver Shift Information
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !/salesperson|audit|salesadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'driver' ? "active" : ""}>
                                                        <Link to="/clinic/driver">
                                                            Driver
                                                        </Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    } */}
                                    {
                                        /agtsuperadmin|agtadmin/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#notificationmenu" className={(this.state.subMenu === 'notificationmenu') ? "subdrop" : ""}>
                                                <i className="fas fa-bell mr-2"></i>
                                                <span className="manuLabel">Notification Management</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'notificationmenu') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'notificationType' ? "active" : ""}>
                                                    <Link to="/clinic/notificationType">
                                                        Notification Types
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'facilityNotificationManagement' ? "active" : ""}>
                                                    <Link to="/clinic/facilityNotificationManagement">
                                                        Facility Notification Management
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'logisticNotificationManagement' ? "active" : ""}>
                                                    <Link to="/clinic/logisticNotificationManagement">
                                                        Logistic Notification Management
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /facilityadmin/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'facilityNotificationManagement' ? "active groupMenu" : "groupMenu"}>
                                            <Link to="/clinic/facilityNotificationManagement">
                                                <i className="fas fa-bell mr-2"></i>
                                                <span className="manuLabel">Notification Management</span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /facilityadmin/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'facilityTestManagement' ? "active groupMenu" : "groupMenu"}>
                                            <Link to="/clinic/facilityTestManagement">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" className="mr-2" viewBox="0 0 576 512"><path fill="currentColor" d="M48 0C21.5 0 0 21.5 0 48V256H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v64H144c8.8 0 16 7.2 16 16s-7.2 16-16 16H0v80c0 26.5 21.5 48 48 48H265.9c-6.3-10.2-9.9-22.2-9.9-35.1c0-46.9 25.8-87.8 64-109.2V271.8 48c0-26.5-21.5-48-48-48H48zM152 64h16c8.8 0 16 7.2 16 16v24h24c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H184v24c0 8.8-7.2 16-16 16H152c-8.8 0-16-7.2-16-16V152H112c-8.8 0-16-7.2-16-16V120c0-8.8 7.2-16 16-16h24V80c0-8.8 7.2-16 16-16zM512 272c0-44.2-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80s80-35.8 80-80zM288 477.1c0 19.3 15.6 34.9 34.9 34.9H541.1c19.3 0 34.9-15.6 34.9-34.9c0-51.4-41.7-93.1-93.1-93.1H381.1c-51.4 0-93.1 41.7-93.1 93.1z" /></svg>
                                                <span className="manuLabel">Patient Test Result Access</span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /^agtsuperadmin|^agtadmin/.test(getUserRole().toLowerCase()) && (this.state.userName === 'creid' || this.state.userName === 'spmobileprods')
                                        &&
                                        <li className="groupMenu">
                                            <a href="#adminmenu" className={(this.state.subMenu === 'adminmenu') ? "subdrop" : ""}>
                                                <i className="fas fa-user-lock mr-2"></i>
                                                <span className="manuLabel">Admin</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'adminmenu') ? "submenu active" : "submenu"}>
                                                <li className={pathname.split('/').at(-1) === 'superAdminSettings' ? "active" : ""}>
                                                    <Link to="/clinic/superAdminSettings">
                                                        Super Admin Settings
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /agtsuperadmin|agtadmin/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className={pathname.split('/').at(-1) === 'video' ? "active groupMenu" : "groupMenu"}>
                                            <Link to="/clinic/video">
                                                <i className="fas fa-video-camera mr-2" aria-hidden="true"></i>
                                                <span className="manuLabel">Video Management</span>
                                            </Link>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /^agtsuperadmin|^agtadmin|^labtech/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <a href="#labmanagementmenu" className={(this.state.subMenu === 'labmanagementmenu') ? "subdrop" : ""}>
                                                <i className="fas fa-microscope mr-2"></i>
                                                <span className="manuLabel">Lab Management</span>
                                                <span className="menu-arrow" >
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </a>
                                            <ul className={(this.state.subMenu === 'labmanagementmenu') ? "submenu active" : "submenu"}>
                                                {
                                                    getUserRole() && /^agtsuperadmin|^agtadmin/.test(getUserRole().toLowerCase())
                                                    &&
                                                    <li className={pathname.split('/').at(-1) === 'test' ? "active" : ""}>
                                                        <Link to="/clinic/test">
                                                            Lab Test Management
                                                        </Link>
                                                    </li>
                                                }
                                                <li className={pathname.split('/').at(-1) === 'maintenance' ? "active" : ""}>
                                                    <Link to="/clinic/maintenance">
                                                        Maintenance Management
                                                    </Link>
                                                </li>
                                                <li className={pathname.split('/').at(-1) === 'inventory' ? "active" : ""}>
                                                    <Link to="/clinic/inventory">
                                                        Inventory Management
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    {
                                        getUserRole() && /agtsuperadmin|agtadmin|labtech/.test(getUserRole().toLowerCase())
                                        &&
                                        <li className="groupMenu">
                                            <Link to="/lims/accessioning">
                                                <i className="fas fa-flask mr-2"></i>
                                                <span className="manuLabel">LIMS</span>
                                            </Link>
                                        </li>
                                    }
                                </>
                                :
                                <>
                                    <li className={pathname.split('/').at(-1) === 'mpsView' ? "active" : ""}>
                                        <Link to="/clinic/mpsView">
                                            Insurance Payments (MPS)
                                        </Link>
                                    </li>
                                    <li className={(pathname.split('/').at(-2) === 'mpsPayment' && pathname.split('/').at(-1) === 'outstanding') ? "active" : ""}>
                                        <Link to="/clinic/mpsPayment/outstanding">
                                            MPS Outstanding
                                        </Link>
                                    </li>
                                    <li className={(pathname.split('/').at(-2) === 'mpsPayment' && pathname.split('/').at(-1) === 'paid') ? "active" : ""}>
                                        <Link to="/clinic/mpsPayment/paid">
                                            MPS Paid
                                        </Link>
                                    </li>
                                    <li className={pathname.split('/').at(-1) === 'mpsCorrectedView' ? "active" : ""}>
                                        <Link to="/clinic/mpsCorrectedView">
                                            Corrected Records for MPS
                                        </Link>
                                    </li>
                                    <li className={pathname.split('/').at(-1) === 'insuranceProvider' ? "active" : ""}>
                                        <Link to="/clinic/insuranceProvider">
                                            Insurance Provider
                                        </Link>
                                    </li>
                                </>
                        }
                    </ul>
                </nav>
            </div>
        )
    }
}

export default ClinicSidebar;
