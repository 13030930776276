import React, { Component } from "react";
import { Modal, Tooltip, Button, OverlayTrigger } from "react-bootstrap";
import { FormControl, InputLabel, Select, MenuItem, TextField, IconButton, InputAdornment } from "@mui/material";
import { fetchFacilitiesForOrders } from "../../../services/clinicPortalServices/facilityServices";
import { fetchInventories, updateInventory } from "../../../services/clinicPortalServices/inventoryService";
import { createDeliveryPickupOrder, sendNotificationAfterRequestPickup, sendBrowserNotificationAfterRequestPickup } from "../../../services/clinicPortalServices/deliveryPickupOrderServices";
import moment from 'moment';
import toastr from 'toastr';
import { MobileTimePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import { fetchLogisticNotificationsByDay } from "../../../services/clinicPortalServices/logisticNotificationService";
import { ModalStyled } from "../../../theme/customizedStyleComponents";

const pickupTimeSlots = [
	{
		label: "10:30 AM to 2:00 PM",
		value: "103000-140000"
	},
	{
		label: "3:00 PM to 8:00 PM",
		value: "150000-200000"
	}
];

const weekendPickupTimeSlots = [
	{
		label: "2:00 PM to 5:00 PM",
		value: "140000-170000"
	}
]

export default class RequestPickup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			requestPickupFacility: "",
			requestPickupTime: "",
			requestEarliestArrivalTime: "",
			facilities: [],
			inventories: [],
			errors: [],
			pickupTimeSlots: pickupTimeSlots,
			start: moment().format("YYYY-MM-DDTHH:") + "00",
			end: moment().add(1, "hours").format("YYYY-MM-DDTHH:") + "00"
		};
	}

	hasError = (key) => {
		return this.state.errors.indexOf(key) !== -1;
	}

	componentDidMount() {
		fetchFacilitiesForOrders().then(response => {
			this.setState({ facilities: response.data.filter(item => item.archived !== 'archived') });
		})

		fetchInventories().then(response => {
			this.setState({ inventories: response.data });
		})
	}

	handleShow = () => {
		if (this.state.facilities && this.state.facilities.length) {
			this.setState({ show: true }, () => {
				let elements = document.getElementsByClassName("removeTabIndexModal");
				if (elements.length) {
					for (let i = 0; i < elements.length; i++) {
						let dialog = elements[i];
						dialog.parentNode.removeAttribute('tabindex');
					}
				}
			});
			if (moment().day() === 0 || moment().day() === 6) {
				if (Number(moment().format("HH")) < 13) {
					this.setState({
						pickupTimeSlots: weekendPickupTimeSlots
					})
				} else {
					this.setState({
						pickupTimeSlots: []
					})
				}
			} else {
				if (Number(moment().format("HH")) >= 10 && Number(moment().format("HHmm")) < 1600) {
					this.setState({
						pickupTimeSlots: [pickupTimeSlots[1]]
					})
				} else if (Number(moment().format("HHmm")) >= 1600) {
					this.setState({
						pickupTimeSlots: []
					})
				} else {
					this.setState({
						pickupTimeSlots: pickupTimeSlots
					})
				}
			}
		} else {
			toastr.info("You need to have active facilities to request pickup.");
		}
	};

	handleClose = () => {
		this.setState({
			show: false,
			requestPickupFacility: "",
			requestPickupTime: "",
			requestEarliestArrivalTime: "",
			start: moment().format("YYYY-MM-DDTHH:") + "00",
			end: moment().add(1, "hours").format("YYYY-MM-DDTHH:") + "00"
		});
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.value) {
			this.setState({ errors: this.state.errors.filter(item => item !== e.target.name) })
		}
	}

	handleTimeChange = (dateTime, type) => {
		let isOk = false;
		if (type === 'start') {
			isOk = moment(dateTime).isBefore(this.state.end) || moment(dateTime).isSame(this.state.end)
		} else {
			isOk = moment(this.state.start).isBefore(dateTime) || moment(this.state.start).isSame(dateTime)
		}
		if (isOk) {
			this.setState({ [type]: dateTime });
		} else {
			toastr.warning("Please select time correctly")
		}
	}

	renderTooltipPickUp = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Request Driver Pickup
		</Tooltip>
	);

	completeRequestPickup = () => {
		let errors = [];
		if (!this.state.requestPickupTime && !["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) {
			errors.push('requestPickupTime');
		}
		if (!this.state.requestPickupFacility) {
			errors.push('requestPickupFacility');
		}
		if (!this.state.start && ["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) {
			errors.push('start');
		}
		if (!this.state.end && ["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) {
			errors.push('end');
		}
		if (!this.state.requestEarliestArrivalTime) {
			errors.push('requestEarliestArrivalTime');
		}
		if (errors.length) {
			this.setState({ errors: errors });
			return;
		} else {
			this.setState({ errors: [] });
		}

		let start_time, end_time;
		if (!["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) {
			start_time = moment().format("YYYYMMDD") + this.state.requestPickupTime.split('-')[0];
			end_time = moment().format("YYYYMMDD") + this.state.requestPickupTime.split('-')[1];
		} else {
			start_time = moment(this.state.start).format("YYYYMMDDHHmmss");
			end_time = moment(this.state.end).format("YYYYMMDDHHmmss");
		}
		const earliest_arrival_time = moment().format("YYYYMMDD") + moment(this.state.requestEarliestArrivalTime, "Hmmss").format("HHmmss");
		const type = "Requested";
		const facility_id = this.state.requestPickupFacility._id;

		let deliveryPickupOrderInfo = {
			facility_id: facility_id,
			start_time: start_time,
			end_time: end_time,
			earliest_arrival_time: earliest_arrival_time,
			type: type,
			isCompleted: false
		}
		this.state.inventories.map(inventory => {
			if (inventory.facility_id._id === facility_id && inventory.kit_status === 'READY_TO_ASSIGN' && inventory.preparing_kit_count > 0) {
				deliveryPickupOrderInfo.kit_count = inventory.preparing_kit_count;
				deliveryPickupOrderInfo.inventory_id = inventory._id;
			}
			return null;
		})
		createDeliveryPickupOrder(deliveryPickupOrderInfo).then(res => {
			this.handleClose();
			toastr.success("Pickup is requested successfully, thanks");
			const facilityName = this.state.facilities.filter(item => item._id === facility_id)[0].name;
			const requestPickupTimeString = moment(start_time, "YYYYMMDDHHmmss").format("hh:mm A") + ' - ' + moment(end_time, "YYYYMMDDHHmmss").format("hh:mm A");
			const earliestArrivalTime = moment(earliest_arrival_time, "YYYYMMDDHHmmss").format("hh:mm A");
			fetchLogisticNotificationsByDay().then(res => {
				if (res?.data?.length > 0) {
					const superAdminMobiles = res.data;
					superAdminMobiles.map(superAdminMobile => {
						sendNotificationAfterRequestPickup(superAdminMobile, facilityName, requestPickupTimeString, earliestArrivalTime);
						return null;
					})
				}
			});

			sendBrowserNotificationAfterRequestPickup(facilityName, requestPickupTimeString);
			if (deliveryPickupOrderInfo.kit_count) {
				const inventoryData = {
					_id: deliveryPickupOrderInfo.inventory_id,
					facility_id: facility_id,
					kit_status: "ASSIGNED"
				};
				updateInventory(inventoryData).then(res => {
					toastr.success("Kits are assigned to this request");
				});
			}
		})
	}

	render() {
		let selectDom = [];
		if ((this.state.requestPickupTime && !["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) || (this.state.start && this.state.end && ["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role))) {
			let time, startTime, endTime;
			if (["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) {
				startTime = moment(this.state.start).format("HHmm") + "00";
				endTime = moment(this.state.end).format("HHmm") + "00";
				time = Number(startTime);
			} else {
				this.state.pickupTimeSlots.map((slot, key) => {
					if (slot.value === this.state.requestPickupTime) {
						startTime = this.state.requestPickupTime.split('-')[0];
						endTime = this.state.requestPickupTime.split('-')[1];
						time = Number(startTime);
					}
					return null;
				})
			}
			while (time <= endTime) {
				selectDom.push(<MenuItem key={time} value={time}>{moment(time, "Hmmss").format('hh:mm A')}</MenuItem>);
				if (time.toString().includes('0000')) {
					time += 3000;
				} else {
					time += 7000;
				}
			}
		}
		return (
			<div>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 50, hide: 100 }}
					overlay={this.renderTooltipPickUp}
				>
					<i
						className="fa fa-ambulance fa-2x header-icon"
						aria-hidden="true"
						onClick={this.handleShow}
					>
					</i>
				</OverlayTrigger>
				<ModalStyled
					centered
					size="md"
					show={this.state.show}
					onHide={this.handleClose}
					backdrop="static"
					dialogClassName="removeTabIndexModal"
				>
					<Modal.Header closeButton="true">
						<Modal.Title>Request Pick UP</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<FormControl
										variant="outlined"
										style={{ width: "100%", marginTop: "5px" }}
									>
										<InputLabel>Select Facility</InputLabel>
										<Select
											name="requestPickupFacility"
											value={this.state.requestPickupFacility}
											onChange={this.handleChange}
											label="Select Facility"
										>
											{
												this.state.facilities.length
												&&
												this.state.facilities.map((facility, index) => {
													return <MenuItem key={index} value={facility}>
														{facility.name}&nbsp;{
															facility.archived === "archived"
															&&
															<b>{" ( Archived )"}</b>
														}
													</MenuItem>
												})
											}
										</Select>
									</FormControl>
									<div className={this.hasError("requestPickupFacility") ? "inline-errormsg" : "hidden"}>
										<i className="fa fa-exclamation-circle" aria-hidden="true">
											&nbsp;This field is required.
										</i>
									</div>
								</div>
							</div>
						</div>
						{
							["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)
							&&
							<div className="row">
								<div className="col-12 col-md-6">
									<div className="form-group">
										<MobileTimePicker
											label="Start Time"
											value={this.state.start}
											onChange={(time) => this.handleTimeChange(time.$d, 'start')}
											minutesStep={30}
											renderInput={props => <TextField
												{...props}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
										<div className={this.hasError("start") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className="form-group">
										<MobileTimePicker
											label="End Time"
											value={this.state.end}
											onChange={(time) => this.handleTimeChange(time.$d, 'end')}
											minutesStep={30}
											renderInput={props => <TextField
												{...props}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton edge="end">
																<EventIcon />
															</IconButton>
														</InputAdornment>
													)
												}}
											/>}
										/>
										<div className={this.hasError("end") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
							</div>
						}
						{
							!["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)
							&&
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										{
											this.state.pickupTimeSlots.length > 0
											&&
											<FormControl
												variant="outlined"
												style={{ width: "100%", marginTop: "5px" }}
											>
												<InputLabel id="facility-label">Choose Pickup time</InputLabel>
												<Select
													name="requestPickupTime"
													value={this.state.requestPickupTime}
													onChange={this.handleChange}
													label="Choose Pickup time"
												>
													{
														this.state.pickupTimeSlots.map((slot, key) => {
															return <MenuItem key={key} value={slot.value}>
																{slot.label}
															</MenuItem>
														})
													}
												</Select>
											</FormControl>
										}
										{
											this.state.pickupTimeSlots.length === 0
											&&
											<label className="text-danger">There is no available time to select today</label>
										}
										<div className={this.hasError("requestPickupTime") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
							</div>
						}
						{
							((this.state.requestPickupTime && !["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)) || (this.state.start && this.state.end && ["AGTSuperAdmin", "AGTAdmin"].includes(this.props.role)))
							&&
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Please choose the earliest arrival time</label>
										<FormControl
											variant="outlined"
											style={{ width: "100%", marginTop: "5px" }}
										>
											<InputLabel id="facility-label">Choose earliest arrival time</InputLabel>
											<Select
												name="requestEarliestArrivalTime"
												value={this.state.requestEarliestArrivalTime}
												onChange={this.handleChange}
												label="Choose earliest arrival time"
											>
												{selectDom}
											</Select>
										</FormControl>
										<div className={this.hasError("requestEarliestArrivalTime") ? "inline-errormsg" : "hidden"}>
											<i className="fa fa-exclamation-circle" aria-hidden="true">
												&nbsp;This field is required.
											</i>
										</div>
									</div>
								</div>
							</div>
						}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => this.completeRequestPickup()} disabled={this.state.requestEarliestArrivalTime ? false : true}>
							Complete Request
						</Button>
					</Modal.Footer>
				</ModalStyled>
			</div>
		);
	}
}
