import { alpha, createTheme, darken } from '@mui/material';

export const Dark = createTheme({
  direction: "ltr",
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      blackBackgroundColor: '#222628'
    },
    mode: 'dark',
    primary: {
      light: "#e3f2fd",
      main: "#90caf9",
      dark: "#42a5f5",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    secondary: {
      light: "#f3e5f5",
      main: "#ce93d8",
      dark: "#ab47bc",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    success: {
      light: "#81c784",
      main: "#66bb6a",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    info: {
      light: "#4fc3f7",
      main: "#29b6f6",
      dark: "#0288d1",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    warning: {
      light: "#ffb74d",
      main: "#ffa726",
      dark: "#f57c00",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(darken('#000C57', 0.4), 0.2),
          backdropFilter: 'blur(2px)',

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)'
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3,
            backgroundColor: "#ee344e"
          }
        },
        colorPrimary: {
          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: "#ee344e"
          }
        }
      }
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: '"Poppins", sans-serif',
  }
});
