import React, { Component } from "react";
import BreadCrumb from "./breadcrumb";

class SearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div>
				<BreadCrumb />
			</div>
		);
	}
}

export default SearchMenu;
